import { Avatar, ListItemIcon, Menu, PaperProps } from '@mui/material'
import React, { Component, PropsWithChildren } from 'react'
import { Link, NavLink } from 'react-router-dom'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Logout from '@mui/icons-material/Logout'
import { ILogoutPath } from './types'
import { AuthStringAvatar } from './helpers'
interface AvatarProp {
  logoutPath?: ILogoutPath
  profilePath?: string
  urlAvatar?: string
}
interface AvatarState {
  IsOpen: boolean
  Element: HTMLElement | null
}
const stylePaper: Partial<PaperProps<'div', {}>> = {
  elevation: 0,
  sx: {
    minWidth: '200px',
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0
    }
  }
}
interface AvatarButtonReduxProps {}

type Props = PropsWithChildren<AvatarProp> & AvatarButtonReduxProps
export class AvatarButton extends Component<Props, AvatarState> {
  constructor(props: Props) {
    super(props)
    this.state = {
      IsOpen: false,
      Element: null
    }
  }
  toggle = () => this.setState({ IsOpen: !this.state.IsOpen })

  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ Element: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ Element: null })
  }

  render() {
    // console.log(this.props.logoutPath);
    return (
      <React.Fragment>
        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
          <Tooltip title='Account settings'>
            <IconButton
              onClick={this.handleClick}
              size='small'
              aria-controls={this.state.Element ? 'account-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={this.state.Element ? 'true' : undefined}
            >
              <Avatar src={this.props.urlAvatar} {...AuthStringAvatar(this.props.children as string)} />
            </IconButton>
          </Tooltip>
        </Box>
        <Menu
          anchorEl={this.state.Element}
          id='account-menu'
          open={!!this.state.Element}
          onClose={this.handleClose}
          onClick={this.handleClose}
          slotProps={{
            paper: stylePaper
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem component={Link} to={this.props.profilePath ?? ''}>
            <Avatar src={this.props.urlAvatar} {...AuthStringAvatar(this.props.children as string)} /> {this.props.children}
          </MenuItem>
          <Divider />
          <MenuItem component={NavLink} to={this.props.logoutPath ?? { pathname: '' }} state={this.props.logoutPath?.state} className='pb-2'>
            <ListItemIcon>
              <Logout fontSize='small' />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </React.Fragment>
    )
  }
}
