import React, { Component } from 'react'
import { connect } from 'react-redux'
import { RouteKey } from '@internal/route'
import { TableFormater } from '@lib/Helpers'
import { CreateHocLazy } from '@lib/ReduxBase'
import { IMediaDelivery } from '@shared/Types'
import { DeliveryShared } from '@shared/Pages'
import { CreateRoutePath } from '@lib/RouteBase'
import { IYoutubeVideoQueryParam } from '@shared/Services/QueryParamService'
import { ActionPannel, CreateTableTemplate, CRUDPannel, MapOprators } from '@lib/Table'
import UIStyleds from '@shared/UIStyleds'
import { IDeliveryReduxProps } from './redux.types'
import { mapDispatchToProps, mapStateToProps } from './redux.map'

const TableInstance = CreateTableTemplate<IMediaDelivery>('Redux', {
  getRowId: (x) => x.Id,
  config: {
    Name: { type: 'string', flex: 2, sortable: false },
    Email: { type: 'string', flex: 1, sortable: false, minWidth: 250, headerName: 'Submission', renderCell: (x) => TableFormater.noData(x.value) },
    Title: { type: 'string', flex: 1, sortable: false, minWidth: 150, renderCell: (x) => TableFormater.noData(x.value) },
    Description: { type: 'string', flex: 1, minWidth: 150, renderCell: (param) => TableFormater.tooltipHelp(param.value, 18) },
    DateCreated: { type: 'string', minWidth: 175, filterable: false, renderCell: (params) => TableFormater.tooltipDate(params.value) },
    Status: {
      type: 'string',
      minWidth: 140,
      filterable: false,
      renderCell: (param) => <DeliveryShared.UIStatusCell data={(param.row as IMediaDelivery).Status} />
    }
  },
  filterOperators: MapOprators, //server mode,
  minWidthColumnActions: 120
})

interface IProps extends IDeliveryReduxProps {}
class ViewBase extends Component<IProps> {
  render() {
    return (
      <UIStyleds.WrapProfile>
        <TableInstance
          ReduxOption={this.props.slice.tableInfo}
          onChange={this.props.onChangeTableInfo}
          CRUDPannel={() => (
            <CRUDPannel
              Title='Deliveries'
              Create={
                <DeliveryShared.FormCreate
                  onLinkRepoSubmit={async (value) => {
                    await this.props.CRUD.Create(value)
                  }}
                />
              }
            />
          )}
          ActionPannel={(p) => {
            const data = p.data as IMediaDelivery
            return <ActionPannel Buttons={{ ExtendBefore: this.renderButtonDetail(data) }} />
          }}
        />
      </UIStyleds.WrapProfile>
    )
  }

  renderButtonDetail = (data: IMediaDelivery) => {
    const path = CreateRoutePath<IYoutubeVideoQueryParam>([RouteKey.Profile, RouteKey.DeliveryDetail], {
      query: { deliveryId: data.Id, type: data.ContentType }
    })
    return [<DeliveryShared.UIBtnDetail to={path} type={data.ContentType} />]
  }
}

export * from './redux.thunks'

export { default as DeliverySlice } from './redux.slice'

const HocLazyInstace = CreateHocLazy(ViewBase)

export const DeliveryMapRedux = connect(mapStateToProps, mapDispatchToProps)(HocLazyInstace)

interface IDeliveryStatusProps extends IDeliveryReduxProps {
  data?: Partial<IMediaDelivery>
  onChangeValue?: (id: string, value: Partial<IMediaDelivery>) => Promise<void>
}

const DeliveryStatus: React.FC<IDeliveryStatusProps> = (props) => (
  <DeliveryShared.UIBtnStatus
    data={props.data}
    onChangeValue={async (i, v) => {
      await props.CRUD.Update(i, v)
      props.onChangeValue && (await props.onChangeValue(i, v))
    }}
  />
)

export const DeliveryStatusMapRedux = connect(mapStateToProps, mapDispatchToProps)(DeliveryStatus)
