import { AppDispatch, RootState } from '@internal/redux'
import { QuicklyAddToCart } from '../Cart'
import { IReduxMapDispatch, IReduxMapState } from './redux.types'
import { fetchExploreFilterThunk, fetchExploreThunk } from './redux.thunks'
import ExploreSlice from './redux.slice'
import { WindowScrollToTop } from '@lib/Helpers'

export const mapStateToProps = (state: RootState): IReduxMapState => ({
  status: state.ExploreSlice.status,
  slice: state.ExploreSlice
})

const FetchAccessory: { request?: { abort: () => void } } = {}
export const mapDispatchToProps = (dispatch: AppDispatch): IReduxMapDispatch => ({
  fetchData() {
    return dispatch(fetchExploreThunk())
  },
  onChangeStatus: (status) => {
    dispatch(ExploreSlice.actions.onChangeStatus(status))
  },
  updateFilter(params) {
    FetchAccessory.request?.abort()
    FetchAccessory.request = dispatch((dis) => {
      dis(ExploreSlice.actions.updateFilter(params))
      const valueChange = Array.isArray(params) ? params : [params]
      if (valueChange.some((x) => x.key === 'pagination')) {
        WindowScrollToTop()
      }
      return dis(fetchExploreFilterThunk())
    })
  },
  addToCart: (product, price) => {
    QuicklyAddToCart(dispatch, product, price)
  }
})
