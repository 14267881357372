import React, { Component } from 'react'
import { UIImage } from '@shared/UIStyleds'
import { Box, Container, Grid, styled, Typography } from '@mui/material'
import { FAQ_LIST } from './configs'
import UIAccordion from './ui.accordion'

export default class FAQ extends Component {
  render() {
    return (
      <Container maxWidth='xl'>
        <Grid container>
          <Grid item xs={12} md={6}>
            <ImageWrap>
              <UIImage src='faq-01' alt='berlintomek-faq' />
            </ImageWrap>
          </Grid>
          <Grid item xs={12} md={6}>
            <Content>
              <Typography variant='h4' sx={{ fontWeight: 700, mb: '10px' }}>
                Frequently asked questions
              </Typography>
              <Typography variant='subtitle2' sx={{ mb: '20px' }}>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Autem, quis!
              </Typography>
              <UIAccordion data={FAQ_LIST} />
            </Content>
          </Grid>
        </Grid>
      </Container>
    )
  }
}

const ImageWrap = styled(Box)({
  paddingTop: '50px',
  '& img': {
    width: '100%'
  }
})

const Content = styled(Box)({
  paddingTop: '150px'
})
