import React, { FC } from 'react'
import { ICart } from '@shared/Types'
import { CartShared } from '@shared/Pages'
import { Box, Divider, Typography, styled } from '@mui/material'
import { CheckOutHelper } from './helpers'
import UICartPreviewItem from './ui.cart-preview.item'
import UISkeletonCheckOutItem from './ui.skeletons'

interface IProps {
  loading?: boolean
  data: ICart[]
}

const UICartPreview: FC<IProps> = (props) => {
  const total = CheckOutHelper.getTotal(props.data)
  return (
    <Wrap>
      <CartShared.UIInfoTop title='Your cart' />
      <Box sx={{ pt: '12px', minHeight: 'calc(var(--height-item) * 3)' }}>
        <ul>
          {props.data.map((x, i) => (
            <li key={i}>
              <UICartPreviewItem data={x} />
            </li>
          ))}
        </ul>
        {props.loading === true && <UISkeletonCheckOutItem />}
      </Box>
      <Divider />
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px', mb: '-15px', p: '24px 0' }}>
        <Typography variant='h4' component='h5' sx={{ flex: 1 }}>
          Total
        </Typography>
        <Typography variant='h4' component='span'>
          $ {total}
        </Typography>
      </Box>
    </Wrap>
  )
}

export default UICartPreview

const Wrap = styled(Box)({
  '--height-item': '110px',
  padding: '15px 20px',
  background: '#fff',
  boxShadow: 'rgba(99, 99, 99, 0.1) 0px 2px 8px 0px',
  borderRadius: '4px',
  '& ul': {}
})
