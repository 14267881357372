import { ModelType } from 'graphql-ts-client-api'
import { mediaStoreGraphDbContext$ } from '@coreprj/graphql/__generated/media-store/fetchers'
import { category$$, pricePackage$$, youtubeVideo$$ } from '@coreprj/graphql/__generated/media-store/fetchers'
import { applicationUser$, mediaDelivery$$, submission$ } from '@coreprj/graphql/__generated/media-store/fetchers'

export type TCategory = ModelType<typeof category$$>

export type TPricePackage = ModelType<typeof pricePackage$$>
export type TCategoriesFilterParam = Parameters<typeof mediaStoreGraphDbContext$.categories>[0]

export type TYoutubeVideo = ModelType<typeof youtubeVideo$$>
export type TYtVideosFilterParam = Parameters<typeof mediaStoreGraphDbContext$.youtubeVideos>[0]

const MediaDeliverySelectorAll = mediaDelivery$$
  .creator(applicationUser$.displayName.email)
  .submission(submission$.title.email.firstName.lastName.kindlyDescribe)
export type TMediaDelivery = ModelType<typeof MediaDeliverySelectorAll>
