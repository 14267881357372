import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ELazyStatus } from '@lib/ReduxBase'
import { ProfileShared } from '@shared/Pages'
import { CreateHocLazy } from '@lib/ReduxBase'
import { WindowScrollToTop } from '@lib/Helpers'
import { EProfileFormType } from '@shared/Types'
import { Box, Container, Grid } from '@mui/material'
import { IProfileReduxProps } from './redux.types'
import { BackLinkFQAs, BackLinkPricing } from './uis'
import { mapDispatchToProps, mapStateToProps } from './redux.map'

const FormUIPersonalInfo = ProfileShared.GetFormUIPersonalInfo('Client')

interface IProps extends IProfileReduxProps {}
export default class ViewBase extends Component<IProps> {
  componentDidMount() {
    WindowScrollToTop()
  }
  render() {
    const data = this.props.slice.profileContentData
    return (
      <Container maxWidth={false} sx={{ pb: '64px' }}>
        {this.renderTop()}
        <FormUIPersonalInfo
          disabled={this.props.status === ELazyStatus.Loading}
          data={data[EProfileFormType.Personal]}
          onSubmit={async (value) => this.props.updateProfile({ key: EProfileFormType.Personal, value })}
        />
        <ProfileShared.FormUIBusinessInfo
          disabled={this.props.status === ELazyStatus.Loading}
          data={data[EProfileFormType.Business]}
          onSubmit={async (value) => this.props.updateProfile({ key: EProfileFormType.Business, value })}
        />
        <ProfileShared.FormUIPaymentInfo
          disabled={this.props.status === ELazyStatus.Loading}
          data={data[EProfileFormType.Payment]}
          onSubmit={async (value) => this.props.updateProfile({ key: EProfileFormType.Payment, value })}
        />
      </Container>
    )
  }

  renderTop = () => (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box sx={{ height: '5rem' }} />
        <ProfileShared.AvatarBox />
      </Grid>
      <Grid item xs={12} md={6}>
        <BackLinkPricing />
      </Grid>
      <Grid item xs={12} md={6}>
        <BackLinkFQAs />
      </Grid>
    </Grid>
  )
}

export * from './redux.thunks'
export { default as ProfileSlice } from './redux.slice'

const HocLazyInstance = CreateHocLazy(ViewBase)
export const ProfileMapRedux = connect(mapStateToProps, mapDispatchToProps)(HocLazyInstance)
