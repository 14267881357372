import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { Button, ButtonProps, IconButton, styled } from '@mui/material'
import * as IconsMaterial from '@mui/icons-material'

interface IReviewProps {
  to: string
  onClick?: () => void
}

export const UIBtnReview: FC<IReviewProps> = (props) => (
  <IconButton key={'Preview'} color='inherit' component={Link} to={props.to}>
    <IconsMaterial.PlayCircleOutline />
  </IconButton>
)

export const UIBtnLink = styled(({ children, ...props }: ButtonProps) => (
  <Button variant='outlined' size='small' color='inherit' startIcon={<IconsMaterial.Link />} {...props}>
    {children || 'Repository URLs'}
  </Button>
))({
  textTransform: 'unset',
  lineHeight: 1,
  fontWeight: 600,
  color: 'var(--color-gray)',
  borderColor: 'var(--color-gray2)',
  padding: '7px 12px',
  minHeight: 'var(--min-height-btn-small)',
  '&:hover': {
    color: 'var(--color-text-title)',
    borderColor: 'var(--color-text-title)'
  }
})

export const UIBtnUpload = styled(({ children, ...props }: ButtonProps) => (
  <Button variant='contained' size='small' color='primary' startIcon={<IconsMaterial.Upload />} {...props}>
    {children || 'Upload'}
  </Button>
))({
  textTransform: 'unset',
  padding: '7px 12px',
  lineHeight: 1,
  fontWeight: 600,
  minHeight: 'var(--min-height-btn-small)'
})
