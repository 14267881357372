import { ICart, IPriceJsonContent } from '@shared/Types'

export namespace CheckOutHelper {
  export const maxLink = 10

  export const getImageUrl = (resourceId: string) => {
    return '/api/v1/guest/Upload/VideoThumb/' + resourceId
  }

  export const getTotal = (value: ICart[]) => {
    const sum = value.reduce<number>((a, b) => {
      const price = b.Price ?? 0
      const amount = b.Amount ?? 0
      a += price * amount
      return a
    }, 0)
    return sum.toFixed(2)
  }

  export const getSubTotal = (value: ICart) => {
    const price = value.Price ?? 0
    const amount = value.Amount ?? 0
    return price * amount
  }

  export const getColor = (value: ICart) => {
    try {
      const obj: IPriceJsonContent = JSON.parse(value.PriceOrigin?.JsonContent ?? '')
      return obj.Color
    } catch (error) {
      return '#979797'
    }
  }

  export const getAmountStringLink = (value: ICart) => {
    if (value.JsonContent?.Links?.length) return `${value.JsonContent?.Links?.length} links`
    return 'Amount links/Exo 2/Regular/14px'
  }
}

export const CheckOutData = {
  description: `We have successfully processed your purchase. Transaction details have been\n sent to your email address, please check for more information.`
}
