import React, { Component } from 'react'
import { connect } from 'react-redux'
import { RouteKey } from '@internal/route'
import { CartShared } from '@shared/Pages'
import { MapNavigate } from '@lib/ApiContext'
import { CreateRoutePath } from '@lib/RouteBase'
import { NavigateFunction } from 'react-router-dom'
import { EPaymentMethod, ICart } from '@shared/Types'
import { ELazyStatus } from '@lib/ReduxBase'
import CheckOutService from '@internal/services/CheckoutService'
import { CheckOutError } from './uis'
import { HookCheckOutLifeCycle } from './hooks'
import { ICheckOutReduxProps } from './redux.types'
import { UIPaymentMethodPaypal } from './ui.payment.methods'
import { mapDispatchToProps, mapStateToProps } from './redux.map'
import UILayout from './ui.layout'
import UIPayment from './ui.payment'
import UICartPreview from './ui.cart-preview'
import { CreateHocLazy } from '@lib/ReduxBase'

interface IProps extends ICheckOutReduxProps {}

class ViewBase extends Component<IProps> {
  render() {
    const cartData = this.getCartData()
    return (
      <UILayout
        status={this.props.status}
        top={<CartShared.UIStepper activeStep={1} />}
        cart={<UICartPreview data={cartData} loading={cartData.length < 1 && this.props.slice.status === ELazyStatus.Loading} />}
        payment={
          <UIPayment
            configs={{
              [EPaymentMethod.PayPal]: {
                title: 'PayPal',
                body: MapNavigate((navigate) => (
                  <UIPaymentMethodPaypal
                    disabled={this.props.slice.status === ELazyStatus.Loading}
                    createOrder={this.handlePaypalCreateOrder}
                    onApprove={(x) => this.handleApprove(navigate, x.orderID)}
                  />
                ))
              }
            }}
          />
        }
      >
        <HookCheckOutLifeCycle {...this.props} />
      </UILayout>
    )
  }

  getCartData = (): ICart[] => {
    return this.props.slice.carts
  }

  Token = ''
  handlePaypalCreateOrder = async (): Promise<string> => {
    const referenceId = this.props.slice.referenceId
    if (!referenceId) return ''
    const res = await CheckOutService.payPalCreate(referenceId)
    this.Token = res?.Token ?? ''
    return res?.Id ?? ''
  }

  handleApprove = async (navigate: NavigateFunction, orderId: string) => {
    try {
      this.props.onChangeStatus(ELazyStatus.Loading)
      await CheckOutService.payPalCapture({ orderId, token: this.Token })
      this.props.removeAll()
      navigate(CreateRoutePath(RouteKey.CheckOutComplete), { replace: true })
    } catch (error) {
      // TODO handle error when the Approve status
      console.log(error)
    }
    this.props.onChangeStatus(ELazyStatus.Loaded)
  }
}

export * from './redux.thunks'

export { default as CheckOutSlice } from './redux.slice'

export { default as CheckOutComplete } from './ui.complete'

const HocLazyInstance = CreateHocLazy(ViewBase, { ErrorScreen: CheckOutError })
export const CheckOutMapRedux = connect(mapStateToProps, mapDispatchToProps)(HocLazyInstance)
