import React, { Component } from 'react'
import { INotification } from '@shared/Types'
import { GlobalModal, IGlobalModalContext, MapGlobalModalContext } from '@lib/ApiContext'
import { INotificationReduxProps } from '../redux.types'
import MenuWrap, { IMenuWrap } from './menu.wrap'
import Content from './Content'
import NotifyDetail from './NotifyDetail'

interface IProps extends INotificationReduxProps {
  onRead?: (value: INotification) => void
}

export default class NotifyIcon extends Component<IProps> {
  refMenuWrap: IMenuWrap | null = null
  render() {
    const data = this.getData()
    return (
      <GlobalModal>
        <MenuWrap ref={(ref) => (this.refMenuWrap = ref)} tooltipTitle='Notifications' count={this.getUnreadCount()}>
          {MapGlobalModalContext((c) => (
            <Content
              data={data}
              onClickItem={(value) => {
                this.refMenuWrap?.onClose()
                this.handleClickDetail(value, c)
              }}
            />
          ))}
        </MenuWrap>
      </GlobalModal>
    )
  }

  handleClickDetail = (value: INotification, context: IGlobalModalContext) => {
    context.ShowModal({
      sxWrap: { outline: 'none' },
      backdropActivated: true,
      ContentModal: () => (
        <NotifyDetail
          data={[value]}
          onRead={() => {
            !value.IsRead && this.props.readNotification({ ...value, IsRead: true })
            context.CloseModal()
          }}
          onClose={context.CloseModal}
        />
      )
    })
  }

  getData = (): INotification[] => {
    const notifications = this.props.slice.notifications
    return (notifications?.ids ?? []).reduce<INotification[]>((a, b) => {
      const data = notifications?.entities[b]
      if (data) a.push(data)
      return a
    }, [])
  }

  getUnreadData = () => {
    const data = this.getData()
    return data.filter((e) => !e.IsRead)
  }

  getUnreadCount = () => {
    const data = this.getData()
    return data.reduce((a, b) => {
      if (!b.IsRead) a++
      return a
    }, 0)
  }
}
