import React, { Component } from 'react'
import { MapGlobalModalContext } from '@lib/ApiContext'
import { Box, Button, Card, DialogActions, DialogContent, DialogTitle, styled } from '@mui/material'
import { FetchDelay } from '@lib/Helpers'

interface IParam<TModel> {
  title?: string
  content: (value?: TModel) => JSX.Element
  colors?: {
    yes?: 'inherit' | 'error' | 'primary' | 'secondary' | 'success' | 'info' | 'warning'
    no?: 'inherit' | 'error' | 'primary' | 'secondary' | 'success' | 'info' | 'warning'
  }
}
const CreateFormComfirm = function <TModel = any>(param?: IParam<TModel>) {
  interface IProps {
    data?: TModel
    title?: string
    onSubmit: (value?: TModel) => Promise<void>
    onCancel?: () => void
  }

  interface IState {
    loading?: boolean
  }

  class FormConfirm extends Component<React.PropsWithChildren<IProps>, IState> {
    constructor(props: IProps) {
      super(props)
      this.state = { loading: false }
    }

    render() {
      const content = this.props.children || (param?.content ? param?.content(this.props.data) : undefined)
      const title = this.props.title ?? param?.title ?? 'Are you sure?'
      return (
        <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {MapGlobalModalContext((context) => (
            <Card>
              <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
              <DialogContent sx={{ minWidth: '300px' }}>{content}</DialogContent>
              <DialogActions>
                <CustomButton
                  disabled={this.state.loading}
                  color={param?.colors?.no || 'inherit'}
                  onClick={() => {
                    context.CloseModal()
                    this.props.onCancel && this.props.onCancel()
                  }}
                >
                  No
                </CustomButton>
                <CustomButton
                  color={param?.colors?.yes || 'error'}
                  disabled={this.state.loading}
                  onClick={async () => {
                    this.setState({ loading: true })
                    if (!!this.props.onSubmit) {
                      await FetchDelay(() => this.props.onSubmit(this.props.data), 700)
                    }
                    context.CloseModal()
                    this.setState({ loading: false })
                  }}
                >
                  Yes
                </CustomButton>
              </DialogActions>
            </Card>
          ))}
        </Box>
      )
    }
  }
  return FormConfirm
}
export default CreateFormComfirm

const CustomButton = styled(Button)({
  textTransform: 'capitalize',
  fontWeight: 600,
  '&.MuiButton-colorInherit': {
    color: '#606060!important'
  }
})
