import React, { FC } from 'react'
import { connect } from 'react-redux'
import { CreateHocLazy } from '@lib/ReduxBase'
import { INotificationReduxProps } from './redux.types'
import { mapDispatchToProps, mapStateToProps } from './redux.map'
import NotifyIcon from './NotifyIcon'
import NotifyPopup from './notify.popup'

interface IProps extends INotificationReduxProps {}

const ViewBase: FC<IProps> = (props) => (
  <>
    <NotifyIcon {...props} />
    <NotifyPopup {...props} />
  </>
)

export * from './redux.thunks'
export { default as NotificationSlice } from './redux.slice'

const HocLazyInstance = CreateHocLazy(ViewBase)
export const NotificationMapRedux = connect(mapStateToProps, mapDispatchToProps)(HocLazyInstance)
