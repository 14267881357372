import React, { FC } from 'react'
import { Box, styled, Typography } from '@mui/material'
import AppConfig from '../AppConfig'

interface IProps {
  data: AppConfig.IFooterNav[]
}

const AppFooterNav: FC<IProps> = (props) => (
  <ul>
    {props.data.map((x, i) => (
      <li key={'key' + i}>
        <AppConfig.AppLink url={x.url}>
          <NavItem>
            <Typography component='span' variant='h5'>
              {x.label}
            </Typography>
          </NavItem>
        </AppConfig.AppLink>
      </li>
    ))}
  </ul>
)

export default AppFooterNav

const NavItem = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  padding: '5px 0',
  textDecoration: 'unset',
  '&::before': {
    content: '""',
    display: 'block',
    width: '0',
    height: '2px',
    background: 'var(--bg-orange)',
    marginRight: '6px',
    transition: '0.5s'
  },
  '&:hover::before': {
    width: '16px'
  },
  '& .MuiTypography-root': {
    color: 'var(--color-text-light)',
    transition: '0.3s',
    fontWeight: 600
  },
  '&:hover .MuiTypography-root': {
    color: 'var(--color-text-light)'
  }
})
