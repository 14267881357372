import React, { Component } from 'react'
import { BoxProps, SxProps, Theme, Typography, styled } from '@mui/material'

export type TStatusCellConfig<TEnum extends string> = { [key in TEnum]: SxProps<Theme> }

interface IOptions {
  sx?: SxProps<Theme>
}

const CreateStatusCell = function <TEnum extends string>(config: TStatusCellConfig<TEnum>, options?: IOptions) {
  interface IProps extends Pick<BoxProps, 'onClick'> {
    data?: TEnum
    title?: string
    sx?: SxProps<Theme>
  }
  class StatusCell extends Component<React.PropsWithChildren<IProps>> {
    render() {
      if (!this.props.data) return <></>
      return (
        <CustomTypography onClick={this.props.onClick} sx={this.getSxProps()}>
          {this.props.title || this.props.data}
        </CustomTypography>
      )
    }

    getSxProps = (): SxProps<Theme> => {
      const { data, sx } = this.props
      return Object.assign({}, data ? config[data] : {}, options?.sx, sx)
    }
  }
  return StatusCell
}

export default CreateStatusCell

const CustomTypography = styled(Typography)({
  borderRadius: '4px',
  fontSize: '0.8rem',
  padding: '4px 9px',
  fontWeight: 600,
  minWidth: '70px',
  textAlign: 'center',
  color: '#ffffff',
  display: 'inline-block'
})
