import { ISelectMultipleOption } from '@lib/Forms/Inputs'

export const ExcelAcceptInput = '.csv,text/csv,application/csv'

export const DownloadExcelUrl = '/api/v1/admin/YoutubeVideo/YoutubeVideoTemplate'

/**
 * Downloads an Excel file from the provided URL.
 * @param url - The API link to the Excel file.
 * @param fileName - The desired name of the downloaded file.
 */
export const DownloadExcelFile = async (url: string, fileName: string) => {
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: { 'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
    })

    if (!response.ok) {
      throw new Error(`Failed to download file: ${response.statusText}`)
    }

    // Convert the response to a Blob
    const blob = await response.blob()

    // Create a temporary URL for the Blob and trigger the download
    const downloadUrl = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = downloadUrl
    link.download = fileName
    document.body.appendChild(link)
    link.click()

    // Clean up the temporary URL and remove the link element
    link.remove()
    window.URL.revokeObjectURL(downloadUrl)
  } catch (error) {
    console.error('Error downloading the Excel file:', error)
  }
}

export const TryParseYtVideoTagsToArray = (value: string): string[] => {
  try {
    return value.split('|').filter((x) => !!x)
  } catch (error) {
    console.log(error)
    return []
  }
}

export const TryParseYtVideoTagsFromForm = (value: string): string => {
  try {
    const l = JSON.parse(value) as ISelectMultipleOption[]
    return l.map((x) => x.Label).join('|')
  } catch (error) {
    console.log(error)
    return ''
  }
}
