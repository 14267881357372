import { TableReduxType } from '@lib/Table'
import { ELazyStatus } from '@lib/ReduxBase'
import { ReduxBaseTable } from '@lib/ReduxBase'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IMediaDelivery, IYoutubeVideo } from '@shared/Types'
import { fetchYoutubeVideoThunk } from './redux.thunks'
import { IUpdateDeliveryAction, IYoutubeVideoSliceState } from './redux.types'

export const DefaultSlice = ReduxBaseTable.GetInitialSlice<IYoutubeVideoSliceState, IYoutubeVideo>(
  { GridSortModel: [{ field: 'DateCreated', sort: 'desc' }] },
  { categories: [] }
)

const YoutubeVideoSlice = createSlice({
  name: 'YoutubeVideoSlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState: DefaultSlice.initialState,
  reducers: {
    ...DefaultSlice.reducers,
    onChange: (state, action: PayloadAction<TableReduxType.OnChangeParam<IYoutubeVideo>>) => {
      state.tableInfo[action.payload.key] = action.payload.value
      state.tableInfo.details = action.payload.details
    },
    updateDeliveryByKey: (state, action: PayloadAction<IUpdateDeliveryAction<IMediaDelivery>>) => {
      const obj = { ...state.deliveryInfo, [action.payload.key]: action.payload.value } as IMediaDelivery
      state.deliveryInfo = obj
    },
    clean: (state) => {
      // state.delivery = undefined
      state.tableInfo = DefaultSlice.initialState.tableInfo
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchYoutubeVideoThunk.fulfilled, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.status = ELazyStatus.Loaded
        state.tableInfo = action.payload.tableInfo
        state.deliveryInfo = action.payload.delivery
        state.categories = action.payload.categories
      })
      .addCase(fetchYoutubeVideoThunk.rejected, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.status = ELazyStatus.Error
        state.tableInfo.isLoading = true
      })
      .addCase(fetchYoutubeVideoThunk.pending, (state, action) => {
        state.requestedId = action.meta.requestId
        state.status = ELazyStatus.Loading
        state.deliveryInfo = undefined
        state.tableInfo.isLoading = true
      })
  }
})

export default YoutubeVideoSlice
