import { QueryParam } from '@lib/Helpers'
import { AppDispatch, RootState } from '@internal/redux'
import { fetchOrderDetailThunk } from './redux.thunks'
import { IReduxMapDispatch, IReduxMapState, IOrderDetailRequired } from './redux.types'
import OrderDetailSlice from './redux.slice'

export const mapStateToProps = (state: RootState): IReduxMapState => ({
  status: state.OrderDetailSlice.status,
  dataDetail: state.OrderDetailSlice.dataDetail
})

export const mapDispatchToProps = (dispatch: AppDispatch): IReduxMapDispatch => ({
  fetchData: () => {
    const id = QueryParam.Gets<IOrderDetailRequired>('id').id ?? ''
    return dispatch(fetchOrderDetailThunk({ id: id.toString() }))
  },
  onChangeStatus: (status) => {
    dispatch(OrderDetailSlice.actions.onChangeStatus(status))
  }
})
