import React, { FC, useState, useRef, useEffect } from 'react'
import { IExploreFilterProps, TExploreFilterSort } from '@shared/Types'
import { Box, Button, Menu, MenuItem, SxProps, Theme, Typography, styled } from '@mui/material'
import StraightIcon from '@mui/icons-material/Straight'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
// import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt'
import { MapExploreFilter } from './helpers'

interface IConfig {
  type: TExploreFilterSort
  display?: string
  icon: React.ReactNode
}

const MenuConfig: IConfig[] = [
  // { type: 'Unset', display: "For Creator's", icon: <SentimentSatisfiedAltIcon /> },
  { type: 'Lastet', icon: <StraightIcon sx={{ transform: 'rotate(180deg)' }} /> },
  { type: 'Oldest', icon: <StraightIcon /> }
]

const GetMenuConfigDefault = (value?: TExploreFilterSort) => {
  return MenuConfig.find((x) => x.type === value) || MenuConfig[0]
}

interface IProps extends IExploreFilterProps {
  id: string
}

const UIFilterSort: FC<IProps> = (props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [selected, setSelected] = useState<IConfig>(GetMenuConfigDefault(props.filter?.sort))
  const refInput = useRef<HTMLInputElement>(null)

  const isOpen = Boolean(anchorEl)

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickMenuItem = (value: IConfig) => {
    handleClose()
    if (value.type === selected.type) return
    setSelected(value)
    props.onChange && props.onChange(MapExploreFilter({ key: 'sort', value: value.type }))
    if (refInput.current) {
      refInput.current.value = value.type
    }
  }

  useEffect(() => {
    setSelected(GetMenuConfigDefault(props.filter?.sort))
    return () => {}
  }, [props.filter?.sort])

  return (
    <Wrap>
      <input hidden name='Sort' ref={refInput} defaultValue={selected.type} />
      <CustomButton
        id={`btn-${props.id}`}
        aria-controls={isOpen ? props.id : undefined}
        aria-haspopup='true'
        aria-expanded={isOpen ? 'true' : undefined}
        disableElevation
        onClick={handleOpen}
        endIcon={isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        color='inherit'
        size='large'
      >
        <Typography variant='subtitle1' component='span' sx={{ flex: 1, textAlign: 'left' }}>
          {selected.display || selected.type}
        </Typography>
      </CustomButton>
      <Menu
        id={props.id}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        slotProps={{ paper: { elevation: 0, sx: sxPaperProps } }}
        open={isOpen}
        onClose={handleClose}
      >
        {MenuConfig.map((x, i) => {
          const isActivated = x.type === selected.type
          return (
            <CustomMenu key={`key${i}`} onClick={() => handleClickMenuItem(x)} className={isActivated ? 'active' : ''}>
              <Typography textAlign='center' sx={{ flex: 1, textAlign: 'left' }}>
                {x.display || x.type}
              </Typography>
              {x.icon}
            </CustomMenu>
          )
        })}
      </Menu>
    </Wrap>
  )
}

export default UIFilterSort

const WidthBase = '190px'

const sxPaperProps: SxProps<Theme> = {
  overflow: 'visible',
  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))'
}

const Wrap = styled(Box)({
  marginLeft: '12px',
  flex: '0 0 auto'
})

const CustomButton = styled(Button)({
  fontSize: '16px',
  textTransform: 'capitalize',
  fontWeight: '500',
  color: '#606060',
  width: WidthBase,
  height: '56px',
  padding: '8px 15px',
  '& .MuiButton-icon': { margin: 0 }
})

const CustomMenu = styled(MenuItem)({
  width: WidthBase,
  transition: 'all linear 0.2s',
  display: 'flex',
  gap: '15px',
  paddingTop: '10px',
  paddingBottom: '10px',
  '&:hover': { backgroundColor: '#dedede' },
  '&.active': { color: '#1976d2' }
})
