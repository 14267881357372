import { Link } from 'react-router-dom'
import { RouteKey } from '@internal/route'
import { EMediaType } from '@shared/Types'
import { UIHomeLink } from '@shared/UIStyleds'
import { CreateRoutePath } from '@lib/RouteBase'
import { Box, BoxProps, styled } from '@mui/material'
import { IExploreQueryParam } from '@shared/Services/QueryParamService'
import * as IconsMaterial from '@mui/icons-material'

namespace AppConfig {
  // ========= ========= ========= Profile ========= ========= =========
  interface IProfileMenu {
    icon?: JSX.Element
    title: string
    key: '/' | RouteKey
  }

  interface IProfile {
    menu: IProfileMenu[]
  }

  export const Profile: IProfile = {
    menu: [
      { title: 'Profile', key: '/', icon: <IconsMaterial.AccountCircleOutlined /> },
      { title: 'Orders', key: RouteKey.Order, icon: <IconsMaterial.History /> },
      { title: 'Submissions', key: RouteKey.Submission, icon: <IconsMaterial.Dns /> },
      // { title: 'Medias', key: RouteKey.Media, icon: <IconsMaterial.Movie /> },
      { title: 'Deliveries', key: RouteKey.Delivery, icon: <IconsMaterial.AllInbox /> },
      { title: 'Billings', key: RouteKey.Billing, icon: <IconsMaterial.RequestQuote /> },
      { title: 'Tickets', key: RouteKey.Ticket, icon: <IconsMaterial.BugReport /> }
    ]
  }

  // ========= ========= ========= Socials ========= ========= =========
  interface ISocial {
    link: string
    icon: JSX.Element
  }

  const SocialColor = '#606060'

  export const Socials: ISocial[] = [
    { link: 'http://google.com', icon: <IconsMaterial.YouTube sx={{ color: SocialColor }} /> },
    { link: 'http://google.com', icon: <IconsMaterial.Instagram sx={{ color: SocialColor }} /> },
    { link: 'http://google.com', icon: <IconsMaterial.Twitter sx={{ color: SocialColor }} /> },
    { link: 'http://google.com', icon: <IconsMaterial.Pinterest sx={{ color: SocialColor }} /> },
    { link: 'http://google.com', icon: <IconsMaterial.Reddit sx={{ color: SocialColor }} /> }
  ]

  // ========= ========= ========= Url ========= ========= =========
  type TUrl = { type: 'External'; value: string; target?: string } | { type: 'Internal'; value: RouteKey | RouteKey[]; query?: any }

  const MapQueryParam = function <T>(params: T) {
    return params
  }

  export const AppLink = styled(({ url, ...p }: BoxProps & { url?: TUrl }) => {
    let obj = {}
    switch (url?.type) {
      case 'Internal':
        obj = { component: Link, to: CreateRoutePath(url.value, { query: url.query }) }
        break
      case 'External':
        obj = { component: UIHomeLink, href: url.value, target: url.target }
        break
      default:
        break
    }
    return <Box {...p} {...obj} />
  })({
    textDecoration: 'unset'
  })

  // ========= ========= ========= Header ========= ========= =========
  export interface IHeaderNav {
    label: string
    url?: TUrl
    className?: string
    line?: boolean
    mobileOnly?: boolean
    children?: IHeaderNav[]
  }

  interface IHeader {
    navBefore: IHeaderNav[]
    navAfter: IHeaderNav[]
  }

  export const Header: IHeader = {
    navBefore: [
      { label: 'Home', url: { type: 'External', value: '/' } },
      { label: 'Pricing', url: { type: 'External', value: '/#Pricing' } },
      { label: 'Submit media', url: { type: 'Internal', value: RouteKey.Submit } },
      { label: 'FQAs', url: { type: 'External', value: '/#Faq' } }
    ],
    navAfter: [
      {
        label: 'Get Started',
        className: 'unit-underline-01',
        children: [
          { label: 'Pricing', url: { type: 'External', value: '/#Pricing' }, mobileOnly: true },
          { label: 'FAQs', url: { type: 'External', value: '/#Faq' }, mobileOnly: true },
          { label: 'Contact us', url: { type: 'External', value: '/#Contact' } },
          { label: 'Upload for licensing', url: { type: 'Internal', value: RouteKey.Submit } },
          {
            label: 'Purchase licenses',
            url: { type: 'Internal', value: RouteKey.Explore, query: MapQueryParam<IExploreQueryParam>({ type: EMediaType.Video }) }
          }
        ]
      }
    ]
  }
  // ========= ========= ========= End Header ========= ========= =========

  // ========= ========= ========= Menu Mobile ========= ========= =========
  export const MenuMobile: IHeaderNav[] = [
    { label: 'Home', url: { type: 'External', value: '/' } },
    { label: 'Pricing', url: { type: 'External', value: '/#Pricing' } },
    { label: 'About us', url: { type: 'External', value: '/#About' } },
    { label: 'Privacy policy', url: { type: 'Internal', value: RouteKey.PrivacyPolicy } },
    { label: 'Term of service', url: { type: 'Internal', value: RouteKey.TermsOfSubmission } },
    { label: 'Create account', url: { type: 'External', value: '/Identity/Account/Register' } },
    { label: 'FAQs', url: { type: 'External', value: '/#Faq' } },
    { label: 'About us', url: { type: 'External', value: '/#About' } },
    { label: 'Upload for licensing', url: { type: 'Internal', value: RouteKey.Submit } },
    {
      label: 'Purchase licenses',
      url: { type: 'Internal', value: RouteKey.Explore, query: MapQueryParam<IExploreQueryParam>({ type: EMediaType.Video }) }
    }
  ]
  // ========= ========= ========= End Menu Mobile ========= ========= =========

  // ========= ========= ========= Footer ========= ========= =========
  export interface IFooterNav {
    label: string
    url?: TUrl
  }

  interface IFooter {
    navLeft: IFooterNav[]
    navRight: IFooterNav[]
    description: string
  }

  export const Footer: IFooter = {
    navLeft: [
      { label: 'Home', url: { type: 'External', value: '/' } },
      { label: 'Pricing', url: { type: 'External', value: '/#Pricing' } },
      { label: 'About us', url: { type: 'External', value: '/#About' } },
      { label: 'Privacy policy', url: { type: 'Internal', value: RouteKey.PrivacyPolicy } },
      { label: 'Term of service', url: { type: 'Internal', value: RouteKey.TermsOfSubmission } }
    ],
    navRight: [
      { label: 'Create account', url: { type: 'External', value: '/Identity/Account/Register' } },
      { label: 'FAQs', url: { type: 'External', value: '/#Faq' } },
      { label: 'Contact us', url: { type: 'External', value: '/#Contact' } },
      { label: 'Upload for licensing', url: { type: 'Internal', value: RouteKey.Submit } },
      {
        label: 'Purchase licenses',
        url: { type: 'Internal', value: RouteKey.Explore, query: MapQueryParam<IExploreQueryParam>({ type: EMediaType.Video }) }
      }
    ],
    description: `We provide licensing services for music, images, and digital media,\ncopyright management, revenue optimization, and legal representation\nfor creators across multiple platforms.`
  }
}

export default AppConfig
