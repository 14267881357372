import React, { FC } from 'react'

const UITextLineBreak: FC<{ children?: string }> = (props) => {
  const texts = props.children?.split('\n').filter((x) => !!x)
  return (
    <React.Fragment>
      {texts?.map((x, i) => (
        <React.Fragment key={i}>
          {x}
          {i < texts.length - 1 && <br />}
        </React.Fragment>
      ))}
    </React.Fragment>
  )
}
export default UITextLineBreak
