import { createTheme } from '@mui/material'

declare module '@mui/material/styles' {
  interface ITheme {
    app: {
      container: { maxWidth: string }
      imageAspectRatio: string
      fontPrimary: string
      zIndex: { [key in 1 | 2 | 3 | 4 | 5 | 'infinity' | 'header' | 'modal' | 'backdrop']: number }
      boxShadow: { [key in 1]: string }
      color: {
        black: string
        black2: string
      }
    }
  }
  export interface Theme extends ITheme {}
  // allow configuration using `createTheme`
  export interface ThemeOptions extends Partial<ITheme> {}
}

export const theme = createTheme({
  typography: {
    fontFamily: `var(--font-primary)`,
    fontWeightRegular: 400,
    fontWeightMedium: 400
  },
  app: {
    container: { maxWidth: '1640px' },
    imageAspectRatio: '55.95%',
    fontPrimary: 'var(--font-primary)',
    zIndex: {
      1: 100,
      2: 200,
      3: 300,
      4: 400,
      5: 500,
      infinity: 2000,
      header: 1110,
      modal: 1150,
      backdrop: 1200
    },
    boxShadow: {
      1: 'rgba(145, 158, 171, 0.1) 0px 0px 2px 0px,rgba(145, 158, 171, 0.12) 0px 0px 24px -4px'
    },
    color: {
      black: '#1e1e1e',
      black2: '#1b1b1b'
    }
  }
})
