import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { ApiAlertContext } from '@lib/ApiContext'
import { getDeliveryStatus } from '@shared/Services'
import { EMediaDeliveryContentType } from '@shared/Types'
import { CreateSelectSimpleLoading } from '@shared/UIStyleds'
import { EMediaDeliveryStatus, IMediaDelivery } from '@shared/Types'
import { Box, Fade, IconButton, IconButtonProps, styled } from '@mui/material'
import ListAltIcon from '@mui/icons-material/ListAlt'
import SourceOutlinedIcon from '@mui/icons-material/SourceOutlined'
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined'
import { SvgMediaLink } from '../../Icons'
import { StatusCellConfigs } from './uis'

const ButtonLoadding = CreateSelectSimpleLoading<EMediaDeliveryStatus>()

interface IBtnStatusProps {
  data?: Partial<IMediaDelivery>
  onChangeValue?: (id: string, value: Partial<IMediaDelivery>) => Promise<void>
  disabled?: boolean
}

export const UIBtnStatus: FC<IBtnStatusProps> = (props) => {
  const handleChange = async (value: EMediaDeliveryStatus) => {
    const data: Partial<IMediaDelivery> = { ...props.data, Status: value as EMediaDeliveryStatus }
    if (!data.Id) {
      ApiAlertContext.ApiAlert?.PushWarning('Delivery ID is not found!')
      return
    }
    props.onChangeValue && (await props.onChangeValue(data.Id, data))
  }
  return (
    <ButtonLoadding
      disabled={props.disabled}
      styleds={StatusCellConfigs}
      options={getDeliveryStatus()}
      defaultValue={props.data?.Status}
      onChangeValue={handleChange}
      size='small'
      sx={{ minWidth: '115px' }}
    />
  )
}

interface IDetailProps {
  to: string
  target?: React.HTMLAttributeAnchorTarget
  type: EMediaDeliveryContentType
}

const GetDetailIcon = (contentType: EMediaDeliveryContentType) => {
  switch (contentType) {
    case EMediaDeliveryContentType.Url:
      return <SvgMediaLink />
    case EMediaDeliveryContentType.Upload:
      return <ListAltIcon />
    case EMediaDeliveryContentType.Internal:
    default:
      return <SourceOutlinedIcon />
  }
}

export const UIBtnDetail: FC<IDetailProps> = (props) => (
  <IconButton color='inherit' sx={{ cursor: 'pointer' }} {...{ component: Link, to: props.to, target: props.target }}>
    {GetDetailIcon(props.type)}
  </IconButton>
)

export const UIBtnBack: FC<Omit<IconButtonProps, 'disabled'> & { disabled?: boolean }> = (props) => {
  const { children, disabled, ...p } = props
  return (
    <Box>
      <Fade in={!disabled}>
        <CustomIconButton color='inherit' {...p}>
          <ArrowBackIosNewOutlinedIcon fontSize='small' />
        </CustomIconButton>
      </Fade>
    </Box>
  )
}

const CustomIconButton = styled(IconButton)({
  cursor: 'pointer',
  flex: '0 0 auto',
  '& svg': { transition: 'all 0.2s' },
  '&:hover svg': { color: '#1976D2' }
})
