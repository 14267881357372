import React, { FC } from 'react'
import { Box } from '@mui/material'
import { Outlet } from 'react-router-dom'
import { UIButtonScrollToTop } from '@shared/UIStyleds'
import AppHeader from './app.header'
import AppFooter from './app.footer'

const LayoutGlobal: FC = () => (
  <>
    <AppHeader />
    <Box sx={{ minHeight: 'calc(100vh - var(--height-header))' }}>
      <Outlet />
    </Box>
    <UIButtonScrollToTop />
    <AppFooter />
  </>
)
export default LayoutGlobal
