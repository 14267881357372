import React, { FC } from 'react'
import { ICart } from '@shared/Types'
import { Link } from 'react-router-dom'
import { RouteKey } from '@internal/route'
import { Box, Button, Divider, styled, Typography, TypographyProps } from '@mui/material'
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout'
import CartHelpers from './helpers'
import CartBadgeItem from './ui.cart-bagge.item'

interface IProps {
  data: ICart[]
  onClose?: () => void
  onRemove?: (value: ICart) => void
}

const UICartBadgeContent: FC<IProps> = (props) => {
  const handleClickRemove = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, value: ICart) => {
    event.preventDefault()
    event.stopPropagation()
    props.onRemove && props.onRemove(value)
  }

  return (
    <Box>
      <Box sx={{ padding: '16px', display: 'flex', whiteSpace: 'nowrap' }}>
        <Typography variant='h6' component='span' sx={{ flex: 1 }}>
          Your Cart {`(${CartHelpers.getTotalLinks(props.data)})`}
        </Typography>
        <Typography variant='h6' component='span'>
          Total: $ {CartHelpers.getTotal(props.data)}
        </Typography>
      </Box>
      <Divider />
      {props.data.length > 0 && (
        <WrapContent>
          {props.data.map((x, i) => (
            <CartBadgeItem key={'key' + i} data={x} onClose={props.onClose} onDelete={handleClickRemove} />
          ))}
        </WrapContent>
      )}
      {props.data.length < 1 && <CustomTypographyNoData>No data</CustomTypographyNoData>}
      <Divider />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '8px 20px 0' }}>
        <Button
          color='inherit'
          endIcon={<ShoppingCartCheckoutIcon />}
          sx={{ fontWeight: 700 }}
          component={Link}
          to={RouteKey.Cart}
          onClick={props.onClose}
        >
          Show in cart
        </Button>
      </Box>
    </Box>
  )
}

export default UICartBadgeContent

const CustomTypographyNoData = styled((p: TypographyProps) => <Typography component='p' {...p} />)({
  textAlign: 'center',
  padding: '20px 0',
  fontStyle: 'italic',
  color: 'var(--color-text-gray)',
  minWidth: '360px'
})

const WrapContent = styled(Box)({
  '--height-item': '80px',
  maxHeight: 'calc(var(--height-item) * 5)',
  overflowY: 'auto'
})
