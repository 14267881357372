import React, { Component } from 'react'
import { INotificationReduxProps } from './redux.types'
import CustomModal from './notify.popup.modal'
import NotifyPopupContent from './notify.popup.content'
import { ENotificationAreaType, INotification } from '@shared/Types'

interface IProps extends INotificationReduxProps {}

interface IState {
  open: boolean
}

export default class NotifyPopup extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = { open: true }
  }

  render() {
    return (
      <CustomModal open={this.getStatus()} onClose={this.handleClose}>
        <NotifyPopupContent data={this.getData()} onClose={this.handleClose} onRead={this.props.readNotification} />
      </CustomModal>
    )
  }

  handleClose = () => this.setState({ open: false })

  getStatus = () => {
    const data = this.getData()
    return this.state.open && data.length > 0
  }

  getData = () => {
    const list = Array.from(Object.values(this.props.slice.notifications?.entities ?? {}) as INotification[])
    return list.filter((e) => !e.IsRead && e.AreaType === ENotificationAreaType.Popup)
  }
}
