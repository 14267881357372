import React, { FC } from 'react'
import { GetImageUrlInternal, UIImage, UITextLineBreak } from '@shared/UIStyleds'
import { Box, BoxProps, Container, Divider, Grid, styled, Typography } from '@mui/material'
import { UIWrapLogo } from './uis'
import AppConfig from '../AppConfig'
import AppFooterNav from './app.footer.nav'

const AppFooter: FC = () => (
  <Wrap>
    <WrapContainer maxWidth={false}>
      <Grid container>
        <Grid item xs={12} lg={7}>
          <UIWrapLogo href='/' sx={{ mb: '20px' }}>
            <UIImage src='logo' alt='berlintomek-logo' />
          </UIWrapLogo>
          <Description variant='subtitle1' sx={{ color: 'var(--color-text-light2)' }}>
            <UITextLineBreak>{AppConfig.Footer.description}</UITextLineBreak>
          </Description>
        </Grid>
        <Grid item xs={12} lg={5}>
          <WrapList container>
            <Grid item xs={12} md={6}>
              <AppFooterNav data={AppConfig.Footer.navLeft} />
            </Grid>
            <Grid item xs={12} md={6}>
              <AppFooterNav data={AppConfig.Footer.navRight} />
            </Grid>
          </WrapList>
        </Grid>
        <Grid item xs={12} sx={{ display: { xs: 'block', md: 'none' } }}>
          <SocialList sx={{ display: 'flex', justifyContent: 'center', mt: '50px' }} socials={AppConfig.Socials} />
        </Grid>
      </Grid>
      <Divider sx={{ borderColor: '#1E1E1E', mt: { xs: '20px', md: '80px' } }} />
      <Box sx={{ padding: '30px 0' }}>
        <Grid container>
          <Grid item xs={12} md={3} sx={{ display: { xs: 'none', md: 'block' } }}>
            <SocialList socials={AppConfig.Socials} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant='subtitle1' noWrap sx={{ textAlign: 'center' }}>
              © Copyright 2024 by BERLINTOMEK.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </WrapContainer>
  </Wrap>
)

export default AppFooter

const SocialList: FC<BoxProps & { socials: typeof AppConfig.Socials }> = ({ socials, ...props }) => (
  <Box {...props}>
    {socials.map((item, i) => (
      <SocialIcon key={i} href={item.link} target='_blank'>
        {item.icon}
      </SocialIcon>
    ))}
  </Box>
)

const Wrap = styled('footer')({
  backgroundImage: `url(${GetImageUrlInternal('bg-01')})`,
  backgroundColor: 'var(--color-body)',
  color: 'var(--color-text-light)',
  paddingTop: '130px'
})

const WrapContainer = styled(Container)(({ theme }) => ({
  maxWidth: theme.app.container.maxWidth,
  [theme.breakpoints.up('xl')]: {
    padding: '0!important'
  }
}))

const WrapList = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    marginTop: '50px'
  },
  '&:hover .MuiTypography-root': {
    color: 'var(--color-text-light2)'
  }
}))

const SocialIcon = styled('a')({
  textDecoration: 'none',
  cursor: 'pointer',
  padding: '5px 10px',
  '& svg': {
    transition: 'all linear 0.2s',
    color: 'var(--color-text-light)'
  },
  '&:hover svg': {
    color: '#1976D2'
  }
})

const Description = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    '& br': {
      display: 'none'
    }
  }
}))
