import React, { FC, useEffect, useState } from 'react'
import { IExploreFilterProps } from '@shared/Types'
import { TextField, InputAdornment, IconButton, Fade, Tooltip, styled, Divider } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import CancelIcon from '@mui/icons-material/Cancel'
import { MapExploreFilter } from './helpers'

const UIFilterSearch: FC<IExploreFilterProps> = (props) => {
  const [keyword, setKeyword] = useState('')

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value)
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    props.onChange && props.onChange(MapExploreFilter({ key: 'keyword', value: keyword }))
  }

  const handleClear = () => {
    props.onChange && props.onChange(MapExploreFilter({ key: 'keyword', value: '' }))
  }

  useEffect(() => {
    setKeyword(props.filter?.keyword ?? '')
    return () => {}
  }, [props.filter?.keyword])

  return (
    <WrapTextField onSubmit={handleSubmit}>
      <TextField
        fullWidth
        variant='outlined'
        placeholder={`Search 50,352 medias...`}
        value={keyword}
        onChange={handleInputChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <IconButton type='submit'>
                <SearchIcon />
              </IconButton>
              <Divider flexItem orientation='vertical' sx={{ height: '24px', transform: 'translateY(-50%)', mx: '8px' }} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position='end'>
              <Fade in={!!props.filter?.keyword}>
                <Tooltip title='Clear'>
                  <IconButton onClick={handleClear}>
                    <CancelIcon fontSize='small' />
                  </IconButton>
                </Tooltip>
              </Fade>
            </InputAdornment>
          )
        }}
      />
    </WrapTextField>
  )
}

export default UIFilterSearch

const WrapTextField = styled('form')({
  flex: 1,
  '& .MuiInputBase-root': {
    backgroundColor: '#fafafa',
    boxShadow: 'rgba(0, 0, 0, 0.04) 0px 3px 5px'
  },
  '& .MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
    transition: '0.3s'
  },
  '&:hover .MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(0, 0, 0, 0.23)'
  },
  '& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#1976d2',
    borderWidth: '1px'
  }
})
