import { FetchDelay } from '@lib/Helpers'
import { ServiceBase, CreateHttpService } from '@lib/Http'
import { EProfileFormType, IBusinessInfo, IPaymentInfo, IPersonalInfo, IProfile, IProfileContentData } from '@shared/Types'

class ProfileServiceBase extends ServiceBase {
  constructor() {
    super(CreateHttpService(`${process.env.REACT_APP_API_URI}/api/v1/user/Profile`))
  }

  private urlProfile = '/GetProfile'
  getProfile = (signal?: AbortSignal) => {
    return FetchDelay(async () => {
      const res = await this.PushNotify(this.Get<IProfile>, this.urlProfile, { signal })
      return ProfileMaping.profileInfos(res)
    }, 500)
  }

  private urlUpdatePersonal = '/UpdatePersonalInfo'
  updatePersonal = (value: Partial<IPersonalInfo>, signal?: AbortSignal): Promise<IPersonalInfo | undefined> => {
    return FetchDelay(async () => {
      const body = ProfileMaping.personalInfoUpdate(value)
      const res = await this.PushNotify(this.Post<IPersonalInfo>, this.urlUpdatePersonal, body, { signal })
      return res
    }, 700)
  }

  private urlUpdateBusiness = '/UpdateBusinessInfo'
  updateBusiness = (value: Partial<IBusinessInfo>, signal?: AbortSignal): Promise<IBusinessInfo | undefined> => {
    return FetchDelay(async () => {
      const body = ProfileMaping.businessInfoUpdate(value)
      const res = await this.PushNotify(this.Post<IBusinessInfo>, this.urlUpdateBusiness, body, { signal, params: { Id: value.Id ?? '' } })
      return res
    }, 700)
  }

  private urlUpdatePayment = '/UpdatePaymentInfo'
  updatePayment = (value: Partial<IPaymentInfo>, signal?: AbortSignal): Promise<IPaymentInfo | undefined> => {
    return FetchDelay(async () => {
      const body = ProfileMaping.paymentInfoUpdate(value)
      const res = await this.PushNotify(this.Post<IPaymentInfo>, this.urlUpdatePayment, body, { signal, params: { Id: value.Id ?? '' } })
      return res
    }, 700)
  }
}
const ProfileService = new ProfileServiceBase()
export default ProfileService

interface IPersonalInfoUpdate {
  FirstName: string
  LastName: string
  DisplayName?: string
  Avatar?: string
  Birthday: string
  Address: string
  PhoneNumber: string
}

interface IBusinessInfoUpdate {
  CompanyName: string
  Position?: string
  Address?: string
  TaxId?: string
}

interface IPaymentUpdate {
  AccountNumber: string
  BankName: string
  IdentityCard: string
  SwiftNumber: string
  BeneficiaryName: string
  BankAddress: string
}

class ProfileMapingBase {
  personalInfoUpdate = (item: Partial<IPersonalInfo>): IPersonalInfoUpdate => ({
    FirstName: item.FirstName ?? '',
    LastName: item.LastName ?? '',
    DisplayName: item.DisplayName,
    // Avatar: '',
    Birthday: item.Birthday ?? '',
    Address: item.Address ?? '',
    PhoneNumber: item.PhoneNumber ?? ''
  })

  businessInfoUpdate = (item: Partial<IBusinessInfo>): IBusinessInfoUpdate => ({
    CompanyName: item.CompanyName ?? '',
    Position: item.Position ?? '',
    Address: item.Address ?? '',
    TaxId: item.TaxId ?? ''
  })

  paymentInfoUpdate = (item: Partial<IPaymentInfo>): IPaymentUpdate => ({
    AccountNumber: item.AccountNumber ?? '',
    BankName: item.BankName ?? '',
    IdentityCard: item.IdentityCard ?? '',
    SwiftNumber: item.SwiftNumber ?? '',
    BeneficiaryName: item.BeneficiaryName ?? '',
    BankAddress: item.BankAddress ?? ''
  })

  profileInfos = (value: IProfile): IProfileContentData => {
    const { BusinessInfo, PaymentInfo, ...personal } = value
    const data: IProfileContentData = {
      [EProfileFormType.Personal]: personal as IPersonalInfo,
      [EProfileFormType.Business]: BusinessInfo,
      [EProfileFormType.Payment]: PaymentInfo
    }
    return data
  }
}
export const ProfileMaping = new ProfileMapingBase()
