import React, { FC } from 'react'
import { SvgMediaLink } from '@shared/Icons'
import { EAppSiteName, EMediaDeliveryContentType } from '@shared/Types'
import { Box, Grid, styled, Typography } from '@mui/material'
import SourceIcon from '@mui/icons-material/Source'
import { ICON_SIZE, MapTexts } from './form.helpers'

interface ISeclecItemProps {
  title: string
  description: string
  onClick: () => void
  icon: JSX.Element
}
const SeclectItem: FC<ISeclecItemProps> = (props) => (
  <WrapItem onClick={props.onClick}>
    <Background className='bg'>{props.icon}</Background>
    <Content className='content'>
      <Typography variant='subtitle1' component='h6' className='tl'>
        {props.title}
      </Typography>
      <Typography variant='subtitle2' component='p' className='ds'>
        {props.description}
      </Typography>
    </Content>
  </WrapItem>
)

interface IProps {
  onClick: (value: EMediaDeliveryContentType) => void
  mode?: EAppSiteName
}
const FormCreateSelectMode: FC<IProps> = (props) => (
  <Grid container>
    <Grid item xs={12}>
      <CustomTypography variant='subtitle2'>Please choose 1 of the 3 options below to continue</CustomTypography>
    </Grid>
    {props.mode === EAppSiteName.Admin && (
      <Grid item xs={12} md={6} sx={{ p: '9px 6px 12px 12px' }}>
        <SeclectItem
          title={MapTexts.Internal}
          description='For internal use only.'
          icon={<SourceIcon sx={{ fontSize: ICON_SIZE.big }} />}
          onClick={() => props.onClick(EMediaDeliveryContentType.Internal)}
        />
      </Grid>
    )}
    <Grid item xs={12} md={6} sx={{ p: '9px 6px 12px 12px' }}>
      <SeclectItem
        title={MapTexts.Url}
        description='Send us the link to your repository and wait for us to process it.'
        icon={<SvgMediaLink sx={{ fontSize: ICON_SIZE.big }} />}
        onClick={() => props.onClick(EMediaDeliveryContentType.Url)}
      />
    </Grid>
  </Grid>
)
export default FormCreateSelectMode

const WrapItem = styled(Box)(({ theme }) => ({
  paddingBottom: '55%',
  position: 'relative',
  cursor: 'pointer',
  transition: 'linear 0.1s',
  borderRadius: '9px',
  overflow: 'hidden',
  '&:hover': { background: 'rgba(0,0,0,0.03)' },
  '& .tl': {
    color: '#606060',
    fontFamily: theme.app.fontPrimary,
    fontWeight: 600,
    transition: 'linear 0.1s',
    marginBottom: '24px',
    fontSize: '1.1rem'
  },
  '&:hover .tl': { color: '#1976D2' },
  '& .ds': {
    color: '#606060',
    fontFamily: theme.app.fontPrimary,
    transition: 'linear 0.1s',
    width: '100%'
  },
  '&:hover .ds': { color: '#1976D2' },
  '& svg, & svg path': { transition: 'linear 0.1s' },
  '&:hover svg, &:hover svg path': { color: '#1976D2', fill: '#1976D2' }
}))

const Content = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  alignItems: 'center',
  justifyContent: 'flex-start',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  transition: 'linear 0.1s',
  background: 'rgba(0,0,0,0.02)',
  padding: '15% 24px 24px'
})

const Background = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  opacity: 0.07
})

const CustomTypography = styled(Typography)(({ theme }) => ({
  fontFamily: theme.app.fontPrimary,
  textAlign: 'center',
  paddingBottom: '9px'
}))
