export const TryParseProductTags = (value?: string, symbol?: string): string[] => {
  try {
    if (typeof value !== 'string') {
      throw new Error('Type invalid!')
    }
    return value.split(symbol || '|').filter((x) => !!x)
  } catch (error) {
    return []
  }
}
