import { TCategory } from '@internal/services/ProductService'
import { IExploreFilter, IExploreFilterPagination, IExploreFilterValue } from '@shared/Types'
import StraightIcon from '@mui/icons-material/Straight'
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt'
import { IExploreMenu, IExploreMenuItem } from './types'

export const layuotBreakpoints = {
  md: '768.5px'
}

export const MapExploreFilter = <K extends keyof IExploreFilter>(value: IExploreFilterValue<K>) => value

export const GetLeftMenuConfig = (categories: TCategory[], isMobile?: boolean): IExploreMenu[] => {
  const list: IExploreMenu[] = [
    {
      title: 'Categories',
      items: categories.map<IExploreMenuItem>((x) => ({
        id: x.id,
        filterKey: 'categories',
        value: x.name ?? '',
        count: x.mediaCounts,
        imageUri: x.imageUri ?? ''
      }))
    }
  ]
  if (isMobile) {
    list.push({
      title: 'Sort',
      items: [
        { id: 'creator', filterKey: 'sort', value: 'Unset', label: "For creator's", icon: <SentimentSatisfiedAltIcon /> },
        { id: 'lastet', filterKey: 'sort', value: 'Lastet', icon: <StraightIcon sx={{ transform: 'rotate(180deg)' }} /> },
        { id: 'oldest', filterKey: 'sort', value: 'Oldest', icon: <StraightIcon /> }
      ]
    })
  }
  return list.reverse()
}

export const GetPaginationInfo = (pagination?: IExploreFilterPagination): { page: number; count: number; total: number } | undefined => {
  if (!pagination?.page || !pagination.total || !pagination.pageSize || pagination.pageSize <= 0) return
  return { page: pagination.page, count: Math.ceil(pagination.total / pagination.pageSize), total: pagination.total ?? 0 }
}
