import { createAsyncThunk } from '@reduxjs/toolkit'
import { CartLocalStorage } from '@internal/shareds/LocalStorages'
import { CheckOutQueryParam } from '@shared/Services/QueryParamService'
import CheckOutService, { CheckOutMapping } from '@internal/services/CheckoutService'
import { IReduxThunkReturned } from './redux.types'

export const fetchCheckOutThunk = createAsyncThunk<IReduxThunkReturned>('fetchCheckOutThunk', async (_, thunkAPI) => {
  // const state = thunkAPI.getState() as { CartSlice: ICartStateSlice }
  const localData = CartLocalStorage.getData()
  const qParams = CheckOutQueryParam.get()
  const res = await CheckOutService.createOrder(localData, qParams.referenceId, thunkAPI.signal)
  const carts = CheckOutMapping.carts(res.OrderDetails, localData)
  if (res.ReferenceId) CheckOutQueryParam.set({ referenceId: res.ReferenceId })
  return { carts, referenceId: res.ReferenceId }
})
