import { FC } from 'react'
import { ICart } from '@shared/Types'
import { Link } from 'react-router-dom'
import { RouteKey } from '@internal/route'
import { CreateRoutePath } from '@lib/RouteBase'
import { Box, BoxProps, IconButton, styled, Typography, TypographyProps } from '@mui/material'
import RemoveIcon from '@mui/icons-material/Remove'

interface IProps {
  data: ICart
  onDelete?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, value: ICart) => void
  onClose?: () => void
}

const CartBadgeItem: FC<IProps> = (props) => {
  const path = CreateRoutePath(RouteKey.Detail, { query: { type: props.data.Type, id: props.data.Id } })
  return (
    <Wrap>
      <ImageWrap to={path} onClick={props.onClose}>
        <img src={props.data.ImageUri} alt='media-pic' />
      </ImageWrap>
      <TypographyCustom to={path} onClick={props.onClose}>
        {props.data.Name}
      </TypographyCustom>
      <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: '2px', justifyContent: 'flex-end' }}>
        <Typography variant='subtitle1'>$ {props.data.PriceOrigin?.Price ?? 0}</Typography>
        <Typography variant='body1' sx={{ fontSize: '14px', mb: '3px', color: '#818181' }}>
          x{props.data.JsonContent?.Links?.length ?? 0} urls
        </Typography>
      </Box>
      <IconButton onClick={(e) => props.onDelete && props.onDelete(e, props.data)}>
        <RemoveIcon />
      </IconButton>
    </Wrap>
  )
}

export default CartBadgeItem

const Wrap = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  padding: '0 9px',
  transition: '0.3s',
  height: 'var(--height-item)',
  '& a': {
    textDecoration: 'none'
  },
  '&:hover': {
    background: '#fafafa'
  },
  '&:hover a': {
    textDecoration: 'underline'
  }
})

const ImageWrap = styled(({ children, ...props }: BoxProps & { to: string }) => (
  <Box component={Link} {...props}>
    <Box>{children}</Box>
  </Box>
))(({ theme }) => ({
  width: '86px',
  flex: '0 0 auto',
  '& > div': {
    position: 'relative',
    paddingBottom: theme.app.imageAspectRatio,
    borderRadius: '2px',
    overflow: 'hidden',
    boxShadow: 'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px',
    width: '100%'
  },
  '& > div > img': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  }
}))

const TypographyCustom = styled((p: TypographyProps & { to: string }) => <Typography noWrap variant='subtitle1' component={Link} {...p} />)({
  color: 'var(--color-body)',
  fontWeight: 600,
  flex: 1,
  margin: '0 12px',
  padding: '15px 0'
})
