import React, { FC } from 'react'
import { UIHomeLink, UIImage } from '@shared/UIStyleds'
import { Box, BoxProps, Button, ButtonProps, Stack, styled, Typography } from '@mui/material'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'

const UIErrorPage: FC = () => (
  <Stack sx={{ alignItems: 'center' }}>
    <WrapImage>
      <UIImage src='vector404' />
    </WrapImage>
    <Typography variant='subtitle1' component='p' sx={{ textAlign: 'center', mt: '20px' }}>
      Sorry, page not found or connection to server lost. Please come back later....
    </Typography>
    <CustomButton href='/'>Back to home page</CustomButton>
  </Stack>
)
export default UIErrorPage

const WrapImage = styled(({ children, ...props }: BoxProps) => (
  <Box {...props}>
    <div>{children}</div>
  </Box>
))({
  width: '100%',
  maxWidth: '560px',
  flex: '0 0 auto',
  '& > div': {
    width: '100%',
    paddingBottom: '100%',
    position: 'relative'
  },
  '& img': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  }
})

const CustomButton = styled((p: ButtonProps & { href: string }) => {
  return <Button component={UIHomeLink} color='inherit' variant='contained' size='large' endIcon={<ArrowRightAltIcon fontSize='small' />} {...p} />
})({
  backgroundColor: 'var(--color-black)',
  color: 'var(--color-text-light)',
  minWidth: '250px',
  marginTop: '24px',
  height: '56px',
  textTransform: 'none',
  fontWeight: 600,
  transition: '0.3s',
  '&:hover': {
    backgroundColor: 'var(--color-black2)',
    color: 'var(--color-text-light)'
  },
  '& .MuiButton-icon.MuiButton-endIcon': {
    transition: '0.3s'
  },
  '&:hover .MuiButton-icon.MuiButton-endIcon': {
    marginLeft: '16px'
  }
})
