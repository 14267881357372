import React, { Component } from 'react'
import { getErrorMessage, IFormInputBase } from '@lib/Forms'
import { Box, styled, TextField, Typography, TypographyProps } from '@mui/material'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { IFormUpload } from './form.types'
import { DownloadExcelFile, DownloadExcelUrl } from './form.helpers'

interface IProps extends IFormInputBase<IFormUpload> {
  accept: string
  multiple?: boolean
}

interface IState {}

class FormInputFile extends Component<IProps, IState> {
  refInput: HTMLInputElement | null = null

  render() {
    const eMessage = getErrorMessage(this.props.messageErrors, this.props.name)
    return (
      <div>
        <Box sx={{ display: 'flex', alignItems: 'center', m: '5px 0 10px' }}>
          <Typography variant='subtitle2' component='span'>
            Metadata file, please fill out the form we provide.
          </Typography>
          <CustomTypography variant='subtitle2' onClick={() => DownloadExcelFile(DownloadExcelUrl, 'SampleExcelFile.csv')}>
            <span>Download sample</span>
            <FileDownloadIcon fontSize='small' />
          </CustomTypography>
        </Box>
        <TextField
          inputRef={(ref) => (this.refInput = ref)}
          name={this.props.name?.toString()}
          error={eMessage.error}
          helperText={eMessage.message}
          variant='outlined'
          type='file'
          fullWidth
          inputProps={{ accept: this.props.accept, multiple: this.props.multiple ?? false }}
          onChange={this.handleChange}
          onClick={this.handleClick}
          onFocus={this.handleFocus}
        />
      </div>
    )
  }

  handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!this.props.name) return
    this.props.onBlur && this.props.onBlur(this.props.name)
  }

  isExploreOpen = false

  handleClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    this.isExploreOpen = true
  }

  handleFocus: React.FocusEventHandler<HTMLInputElement> = (e) => {
    if (this.isExploreOpen === true) {
      this.isExploreOpen = false
      if (!this.props.name) return
      this.props.onBlur && this.props.onBlur(this.props.name)
      setTimeout(() => {
        this.refInput && this.refInput.blur()
      }, 50)
    }
  }
}

export default FormInputFile

const CustomTypography = styled((p: TypographyProps) => <Typography {...p} component='span' />)({
  display: 'flex',
  alignItems: 'center',
  color: 'var(--color-blue)',
  cursor: 'pointer',
  fontWeight: 600,
  marginLeft: '6px',
  gap: '4px',
  '&:hover': {
    textDecoration: 'underline'
  }
})
