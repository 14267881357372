import { SvgMediaLink } from '@shared/Icons'
import { EMediaType, IMediaDelivery } from '@shared/Types'
import { getDeliveryStatus, getMediaTypes } from '@shared/Services'
import { CreateFormGridLayout, FormValidator, SingleRuleValidate, IFormGridLayoutConfig } from '@lib/Forms'
import { CreateInputPasteLinks, CreateInputTextMultiline, CreateSelectSimple, CreateSelectWithApi } from '@lib/Forms/Inputs'
import SourceIcon from '@mui/icons-material/Source'
import SelectService from '@internal/services/SelectService'
import { ICON_SIZE, MapTexts } from './form.helpers'
import FormActions from './form.actions'

interface IParam {
  disabledStatus?: boolean
}

const PasteLink = CreateInputPasteLinks<IMediaDelivery>()
const InputTextMul = CreateInputTextMultiline<IMediaDelivery>({ options: { multiline: { minRows: 3, maxRows: 5 } } })
const SelectSimple = CreateSelectSimple<IMediaDelivery>()
const SelectWithApiComponent = CreateSelectWithApi<IMediaDelivery>()

export const GetFormInternalType = (param?: IParam) => {
  const status: IFormGridLayoutConfig<IMediaDelivery> = {
    key: 'Status',
    label: 'Status',
    inputElement: (p) => <SelectSimple {...p} options={getDeliveryStatus()} />,
    alwaysDisplayed: (x) => !!x
  }
  return CreateFormGridLayout<IMediaDelivery>({
    configs: [
      { key: 'Name', label: 'Name', options: { defaultValue: new Date().getTime().toString() } },
      ...(param?.disabledStatus === true ? [] : [status]),
      { key: 'MediaType', label: 'Media Type', inputElement: (p) => <SelectSimple {...p} options={getMediaTypes()} /> },
      { key: 'Description', label: 'Description', inputElement: InputTextMul }
    ],
    validate: new FormValidator({
      Name: { Rules: [{ rule: SingleRuleValidate.Required }] },
      MediaType: { Rules: [{ rule: SingleRuleValidate.Required }] },
      Description: { Rules: [{ rule: SingleRuleValidate.Required }] }
    }),
    options: {
      action: (p) => <FormActions title={MapTexts.Internal} icon={<SourceIcon sx={{ fontSize: ICON_SIZE.small, color: '#848484' }} />} {...p} />
    }
  })
}

export const GetFormUrlType = (param?: IParam) => {
  const status: IFormGridLayoutConfig<IMediaDelivery> = {
    key: 'Status',
    label: 'Status',
    inputElement: (p) => <SelectSimple {...p} options={getDeliveryStatus()} />,
    alwaysDisplayed: (x) => !!x
  }
  return CreateFormGridLayout<IMediaDelivery>({
    configs: [
      { key: 'Name', label: 'Name', options: { defaultValue: new Date().getTime().toString() } },
      { key: 'ResourceId', label: 'Contract', inputElement: (p) => <SelectWithApiComponent {...p} fetchData={SelectService.fetchSubmissions} /> },
      ...(param?.disabledStatus === true ? [] : [status]),
      { key: 'Description', label: 'Description', inputElement: InputTextMul },
      { key: 'Content', label: 'Enter all repository links (maximum of 10 links)', inputElement: PasteLink }
    ],
    validate: new FormValidator({
      Name: { Rules: [{ rule: SingleRuleValidate.Required }] },
      ResourceId: { Rules: [{ rule: SingleRuleValidate.Required }] },
      Description: { Rules: [{ rule: SingleRuleValidate.Required }] },
      Content: { Rules: [{ rule: SingleRuleValidate.Required, message: 'The Repository url is required' }] }
    }),
    options: {
      action: (p) => <FormActions title={MapTexts.Url} icon={<SvgMediaLink sx={{ fontSize: ICON_SIZE.small }} fill={'#848484'} />} {...p} />,
      before: <input type='text' hidden defaultValue={EMediaType.Video} name='MediaType' />
    }
  })
}

// export const GetFormUploadType = (param?: IParam) => {
//   const status: IFormGridLayoutConfig<IMediaDelivery> = {
//     key: 'Status',
//     label: 'Status',
//     inputElement: (p) => <SelectSimple {...p} options={getDeliveryStatus()} />,
//     alwaysDisplayed: (x) => !!x
//   }
//   return CreateFormGridLayout<IMediaDelivery>({
//     configs: [
//       { key: 'Name', label: 'Name', options: { defaultValue: new Date().getTime().toString() } },
//       { key: 'ResourceId', inputElement: SelectResourceId },
//       ...(param?.disabledStatus === true ? [] : [status]),
//       { key: 'Description', label: 'Description', inputElement: InputTextMul }
//     ],
//     validate: new FormValidator({
//       Name: { Rules: [{ rule: SingleRuleValidate.Required }] },
//       ResourceId: { Rules: [{ rule: SingleRuleValidate.Required }] },
//       Description: { Rules: [{ rule: SingleRuleValidate.Required }] }
//     }),
//     options: {
//       action: (p) => (
//         <CustomAction
//           title='For internal use only.'
//           icon={<DriveFolderUploadOutlinedIcon sx={{ fontSize: ICON_SIZE.small, color: '#848484' }} />}
//           {...p}
//         />
//       )
//     }
//   })
// }
