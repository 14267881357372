import { ReduxBase } from '@lib/ReduxBase'
import { ELazyStatus } from '@lib/ReduxBase'
import { createSlice } from '@reduxjs/toolkit'
import { IPolicyFileSliceState } from './redux.types'
import { fetchPolicyFileThunk } from './redux.thunks'

export const DefaultSlice = ReduxBase.GetInitialSlice<IPolicyFileSliceState>({ state: {} })

const PolicyFileSlice = createSlice({
  name: 'PolicyFileSlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState: DefaultSlice.initialState,
  reducers: { ...DefaultSlice.reducers },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPolicyFileThunk.pending, (state) => {
        state.status = ELazyStatus.Loading
        state.galleryPdfInfo = undefined
      })
      .addCase(fetchPolicyFileThunk.fulfilled, (state, action) => {
        state.status = ELazyStatus.Loaded
        state.galleryPdfInfo = action.payload.galleryPdfInfo
      })
      .addCase(fetchPolicyFileThunk.rejected, (state) => {
        // state.Status = ELazyStatus.Error
      })
  }
})

export default PolicyFileSlice
