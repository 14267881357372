import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { ESubmissionStep } from '@shared/Types'
import { Box, Fade, Step, StepButton, StepLabel, StepLabelProps, Stepper, StepProps, styled, Typography } from '@mui/material'

export interface IStepperBarConfig {
  key: ESubmissionStep
  title: string
  subTitle?: string
  activated?: boolean
  to?: string
}

export interface IStepperBarProps {
  activeStep: number
  configs: IStepperBarConfig[]
}

export default class UIStepperBar extends Component<IStepperBarProps> {
  render() {
    if (this.props.configs.length < 1) return <></>
    return (
      <StickyTop>
        <Stepper nonLinear activeStep={this.props.activeStep}>
          {this.props.configs.map((config, index) => {
            const stepProps: StepProps = {}
            const stepLabelProps: StepLabelProps = {}

            if (!!config.activated) stepProps.completed = config.activated
            if (!!config.subTitle) stepLabelProps.optional = <Typography variant='caption'>{config.subTitle}</Typography>

            const StepComponent = <StepLabel {...stepLabelProps}>{config.title}</StepLabel>

            return (
              <WrapStep {...stepProps} key={config.title + index}>
                <Fade in={!!config.to && !!config.activated} unmountOnExit timeout={0}>
                  <Box>
                    <StepButton key={config.title + index} {...{ component: Link, to: config.to }}>
                      {StepComponent}
                    </StepButton>
                  </Box>
                </Fade>
                <Fade in={!(!!config.to && !!config.activated)} unmountOnExit timeout={0}>
                  <Box>{StepComponent}</Box>
                </Fade>
              </WrapStep>
            )
          })}
        </Stepper>
      </StickyTop>
    )
  }
}

const StickyTop = styled(Box)({
  position: 'sticky',
  top: 'var(--height-header)',
  zIndex: 10,
  backgroundColor: '#fff',
  padding: '36px 0 18px',
  boxShadow: 'rgba(27, 31, 35, 0.04) 0px 1px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px inset'
})

const WrapStep = styled(Step)({
  '& .MuiStepLabel-label.Mui-active': {
    color: '#1976d2',
    transition: 'linear 0.3s'
  }
})
