import { ReduxBaseTable } from '@lib/ReduxBase'
import { createAsyncThunk } from '@reduxjs/toolkit'
import BillingService, { BillingMapping } from '@internal/services/BillingService'
import { DefaultSlice } from './redux.slice'
import { IBillingSliceState, IReduxThunkArg, IReduxThunkReturned } from './redux.types'

export const fetchBillingThunk = createAsyncThunk<IReduxThunkReturned, IReduxThunkArg | undefined>('fetchBillingThunk', async (param, thunkAPI) => {
  const state = thunkAPI.getState() as { BillingSlice: IBillingSliceState }
  const tableInfoParam = ReduxBaseTable.ThunkArgMapping({ tableInfo: state.BillingSlice.tableInfo, ...param })

  const filter = BillingMapping.tableInfoToFilter(tableInfoParam)
  const res = await BillingService.AllGraphQL(filter, thunkAPI.signal)

  const tableInfo = ReduxBaseTable.ThunkReponseMapping(tableInfoParam, { Data: BillingMapping.billings(res?.items), Total: res?.totalItems })
  ReduxBaseTable.setParam(tableInfo, DefaultSlice.initialState.tableInfo)
  return { tableInfo }
})
