import React, { FC } from 'react'
import { Box } from '@mui/material'
import { RouteKey } from '@internal/route'
import { TryParseJson } from '@lib/Helpers'
import { MapNavigate } from '@lib/ApiContext'
import { CreateRoutePath } from '@lib/RouteBase'
import { ISubmission, ESubmissionStep, ISubmissionJson } from '@shared/Types'
import { CreateInputPasteLinks, CreateInputTextMultiline } from '@lib/Forms/Inputs'
import { CreateSetOfQuestion, FormValidator, SingleRuleValidate } from '@lib/Forms'
import { MapAnswers, EQuestionKey, MapAnswerConfings, MapQuestionList, MapSubQuestion } from '@shared/Pages/Submission'
import { UICustomTypographyTitle } from './uis'
import { SubmitMediaLocalStorage } from './helpers'
import UIActionsBar from './ui.actions-bar'

interface ICopyRightOwnership {
  PeopleAppearing: string
  SubPeopleAppearing?: string
  PersonWhoFilmed: string
  SubPersonWhoFilmed?: string
  SubmitedSocial: string
  SubSubmitedSocial?: string
  AnyoneReach: string
  SubAnyoneReach?: string
  LicensingAgreement: string
  SubLicensingAgreement?: string
}

const InputPasteLinksComponent = CreateInputPasteLinks<ICopyRightOwnership>()
const InputTextComponent = CreateInputTextMultiline<ICopyRightOwnership>({ options: { multiline: { minRows: 3, maxRows: 7 } } })

const SetOfQuestionComponent = CreateSetOfQuestion<ICopyRightOwnership>({
  configs: [
    {
      key: 'PeopleAppearing',
      question: MapQuestionList[EQuestionKey.PeopleAppearing],
      answers: MapAnswerConfings(MapAnswers[EQuestionKey.PeopleAppearing], ['No']),
      subQuestions: {
        key: 'SubPeopleAppearing',
        ...MapSubQuestion[EQuestionKey.PeopleAppearing],
        inputComponent: InputTextComponent
      }
    },
    {
      key: 'PersonWhoFilmed',
      question: MapQuestionList[EQuestionKey.PersonWhoFilmed],
      answers: MapAnswerConfings(MapAnswers[EQuestionKey.PersonWhoFilmed], ['Me', 'key1', 'key2', 'key3', 'key4', 'key5']),
      subQuestions: {
        key: 'SubPersonWhoFilmed',
        ...MapSubQuestion[EQuestionKey.PersonWhoFilmed]
      }
    },
    {
      key: 'SubmitedSocial',
      question: MapQuestionList[EQuestionKey.SubmitedSocial],
      answers: MapAnswerConfings(MapAnswers[EQuestionKey.SubmitedSocial], ['No']),
      subQuestions: {
        key: 'SubSubmitedSocial',
        ...MapSubQuestion[EQuestionKey.SubmitedSocial],
        inputComponent: InputPasteLinksComponent
      }
    },
    {
      key: 'AnyoneReach',
      question: MapQuestionList[EQuestionKey.AnyoneReach],
      answers: MapAnswerConfings(MapAnswers[EQuestionKey.AnyoneReach], ['No']),
      subQuestions: {
        key: 'SubAnyoneReach',
        ...MapSubQuestion[EQuestionKey.AnyoneReach],
        inputComponent: InputPasteLinksComponent
      }
    },
    {
      key: 'LicensingAgreement',
      question: MapQuestionList[EQuestionKey.LicensingAgreement],
      answers: MapAnswerConfings(MapAnswers[EQuestionKey.LicensingAgreement], ['No']),
      subQuestions: {
        key: 'SubLicensingAgreement',
        ...MapSubQuestion[EQuestionKey.LicensingAgreement],
        inputComponent: InputPasteLinksComponent
      }
    }
  ],
  validate: new FormValidator({
    PeopleAppearing: { Rules: [{ rule: SingleRuleValidate.Required }] },
    SubPeopleAppearing: {
      Rules: [
        {
          rule: SingleRuleValidate.Custom,
          message: 'Please fill in your answer',
          Value: (value, model) => {
            if (!value && model.PeopleAppearing === 'Yes') return true
            return false
          }
        }
      ]
    },

    PersonWhoFilmed: { Rules: [{ rule: SingleRuleValidate.Required }] },
    SubPersonWhoFilmed: {
      Rules: [
        {
          rule: SingleRuleValidate.Custom,
          message: 'Please fill in your answer',
          Value: (value, model) => {
            if (!value && model.PersonWhoFilmed === 'Other') return true
            return false
          }
        }
      ]
    },

    SubmitedSocial: { Rules: [{ rule: SingleRuleValidate.Required }] },
    SubSubmitedSocial: {
      Rules: [
        {
          rule: SingleRuleValidate.Custom,
          message: 'Please fill in your answer',
          Value: (value, model) => {
            if (!value && model.SubmitedSocial !== 'No') return true
            return false
          }
        }
      ]
    },

    AnyoneReach: { Rules: [{ rule: SingleRuleValidate.Required }] },
    SubAnyoneReach: {
      Rules: [
        {
          rule: SingleRuleValidate.Custom,
          message: 'Please fill in your answer',
          Value: (value, model) => {
            if (!value && model.AnyoneReach !== 'No') return true
            return false
          }
        }
      ]
    },

    LicensingAgreement: { Rules: [{ rule: SingleRuleValidate.Required }] },
    SubLicensingAgreement: {
      Rules: [
        {
          rule: SingleRuleValidate.Custom,
          message: 'Please fill in your answer',
          Value: (value, model) => {
            if (!value && model.LicensingAgreement !== 'No') return true
            return false
          }
        }
      ]
    }
  }),
  submitMapping: (params) => {
    let model = { ...params.value }

    const pa: keyof (typeof MapAnswers)[EQuestionKey.PeopleAppearing] = 'No'
    if (model.PeopleAppearing === pa) {
      delete model.SubPeopleAppearing
    }

    const pf: keyof (typeof MapAnswers)[EQuestionKey.PersonWhoFilmed] = 'Other'
    if (model.PersonWhoFilmed !== pf) {
      delete model.SubPersonWhoFilmed
    }

    const ss: keyof (typeof MapAnswers)[EQuestionKey.SubmitedSocial] = 'No'
    if (model.SubmitedSocial === ss) {
      delete model.SubSubmitedSocial
    }

    const ar: keyof (typeof MapAnswers)[EQuestionKey.AnyoneReach] = 'No'
    if (model.AnyoneReach === ar) {
      delete model.SubAnyoneReach
    }

    const la: keyof (typeof MapAnswers)[EQuestionKey.LicensingAgreement] = 'No'
    if (model.LicensingAgreement === la) {
      delete model.SubLicensingAgreement
    }

    return model
  }
})

type TObject = { [key: string]: string }
const MapAnswerToSubmisson = (item: Partial<ICopyRightOwnership>): Partial<ISubmission> => {
  const data: Partial<ISubmission> = {}

  if (!!item.PeopleAppearing) {
    const PeopleAppearingJson: ISubmissionJson = {
      question: MapQuestionList[EQuestionKey.PeopleAppearing],
      answer: [{ key: item.PeopleAppearing, value: (MapAnswers[EQuestionKey.PeopleAppearing] as TObject)[item.PeopleAppearing] }],
      secondaryAnswer: item.SubPeopleAppearing
    }
    const temp: keyof (typeof MapAnswers)[EQuestionKey.PeopleAppearing] = 'Yes'
    data.IsPeopleAppearingVerify = item.PeopleAppearing === temp ? true : false
    data.PeopleAppearingInfosVerify = JSON.stringify(PeopleAppearingJson)
  }

  if (!!item.PersonWhoFilmed) {
    const PersonWhoFilmedJson: ISubmissionJson = {
      question: MapQuestionList[EQuestionKey.PersonWhoFilmed],
      answer: [{ key: item.PersonWhoFilmed, value: (MapAnswers[EQuestionKey.PersonWhoFilmed] as TObject)[item.PersonWhoFilmed] }],
      secondaryAnswer: item.SubPersonWhoFilmed
    }
    data.PersonWhoFilmedVerify = JSON.stringify(PersonWhoFilmedJson)
  }

  if (!!item.SubmitedSocial) {
    const SubmitedSocialJson: ISubmissionJson = {
      question: MapQuestionList[EQuestionKey.SubmitedSocial],
      answer: [{ key: item.SubmitedSocial, value: (MapAnswers[EQuestionKey.SubmitedSocial] as TObject)[item.SubmitedSocial] }],
      secondaryAnswer: item.SubSubmitedSocial
    }
    const temp: keyof (typeof MapAnswers)[EQuestionKey.SubmitedSocial] = 'No'
    data.IsSubmitedSocialVerify = item.SubmitedSocial !== temp ? true : false
    data.SubmitedSocialUrlsVerify = JSON.stringify(SubmitedSocialJson)
  }

  if (!!item.AnyoneReach) {
    const AnyoneReachJson: ISubmissionJson = {
      question: MapQuestionList[EQuestionKey.AnyoneReach],
      answer: [{ key: item.AnyoneReach, value: (MapAnswers[EQuestionKey.AnyoneReach] as TObject)[item.AnyoneReach] }],
      secondaryAnswer: item.SubAnyoneReach
    }
    const temp: keyof (typeof MapAnswers)[EQuestionKey.AnyoneReach] = 'No'
    data.IsAnyoneReachVerify = item.AnyoneReach !== temp ? true : false
    data.AnyoneReachUrlsVerify = JSON.stringify(AnyoneReachJson)
  }

  if (!!item.LicensingAgreement) {
    const LicensingAgreementJson: ISubmissionJson = {
      question: MapQuestionList[EQuestionKey.LicensingAgreement],
      answer: [{ key: item.LicensingAgreement, value: (MapAnswers[EQuestionKey.LicensingAgreement] as TObject)[item.LicensingAgreement] }],
      secondaryAnswer: item.SubLicensingAgreement
    }
    const temp: keyof (typeof MapAnswers)[EQuestionKey.LicensingAgreement] = 'No'
    data.IsLicensingAgreementVerify = item.LicensingAgreement !== temp ? true : false
    data.LicensingAgreementUrlsVerify = JSON.stringify(LicensingAgreementJson)
  }

  return data
}

const MapSubmissionToQuestionData = (value?: ISubmission): ICopyRightOwnership => {
  const pa = TryParseJson<ISubmissionJson>(value?.PeopleAppearingInfosVerify)
  const pwf = TryParseJson<ISubmissionJson>(value?.PersonWhoFilmedVerify)
  const ss = TryParseJson<ISubmissionJson>(value?.SubmitedSocialUrlsVerify)
  const ar = TryParseJson<ISubmissionJson>(value?.AnyoneReachUrlsVerify)
  const la = TryParseJson<ISubmissionJson>(value?.LicensingAgreementUrlsVerify)

  const data: ICopyRightOwnership = {
    PeopleAppearing: pa?.answer?.[0]?.key ?? '',
    SubPeopleAppearing: pa.secondaryAnswer,
    PersonWhoFilmed: pwf?.answer?.[0]?.key ?? '',
    SubPersonWhoFilmed: pwf.secondaryAnswer,
    SubmitedSocial: ss?.answer?.[0]?.key ?? '',
    SubSubmitedSocial: ss.secondaryAnswer,
    AnyoneReach: ar?.answer?.[0]?.key ?? '',
    SubAnyoneReach: ar.secondaryAnswer,
    LicensingAgreement: la?.answer?.[0]?.key ?? '',
    SubLicensingAgreement: la.secondaryAnswer
  }
  return data
}

const StepCopyrightOwnership: FC = () => {
  const data = SubmitMediaLocalStorage.getData()
  return (
    <Box sx={{ marginTop: '48px' }}>
      <UICustomTypographyTitle>Let's verify the copyright ownership</UICustomTypographyTitle>
      {MapNavigate((navigate) => (
        <SetOfQuestionComponent
          data={MapSubmissionToQuestionData(data?.item)}
          onSubmit={async (value) => {
            const mapSubmit = MapAnswerToSubmisson(value)
            SubmitMediaLocalStorage.setData(Object.assign({}, data?.item, mapSubmit), ESubmissionStep.CopyrightOwnership)
            navigate(CreateRoutePath([RouteKey.Submit, RouteKey.SMTDigitalSignature]))
          }}
          slots={{
            actions: (p) => (
              <>
                <Box sx={{ height: '48px' }} />
                <UIActionsBar onPrev={() => navigate(CreateRoutePath([RouteKey.Submit, RouteKey.SMTAboutYouself]))} />
              </>
            )
          }}
        />
      ))}
    </Box>
  )
}

export default StepCopyrightOwnership
