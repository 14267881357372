import { AppDispatch, RootState } from '@internal/redux'
import { BillingQueryParam } from '@shared/Services/QueryParamService'
import { CreateBillingDetailService } from '@internal/services/BillingDetailService'
import { IReduxMapDispatch, IReduxMapState } from './redux.types'
import { fetchBillingDetailInitialThunk, fetchBillingDetailThunk } from './redux.thunks'
import BillingDetailSlice from './redux.slice'

export const mapStateToProps = (state: RootState): IReduxMapState => ({
  status: state.BillingDetailSlice.status,
  stateRedux: state.BillingDetailSlice,
  tableInfo: state.BillingDetailSlice.tableInfo
})

type TRequest = { abort: () => void }
const DefaultRequest: TRequest = { abort: () => {} }
const FetchAccessory: { request?: TRequest } = {}
export const mapDispatchToProps = (dispatch: AppDispatch): IReduxMapDispatch => ({
  fetchData: () => {
    const billingId = BillingQueryParam.get().billingId
    if (!billingId) return DefaultRequest
    return dispatch(fetchBillingDetailInitialThunk({ billingId, clear: true }))
  },
  onChangeStatus: (status) => {
    dispatch(BillingDetailSlice.actions.onChangeStatus(status))
  },
  onChangeTableInfo: (key, value, details) => {
    if (key === 'FilterModel') {
      dispatch(BillingDetailSlice.actions.onChangeTableInfo({ key, value, details }))
    }
    FetchAccessory.request?.abort()
    const billingId = BillingQueryParam.get().billingId
    if (!billingId) return DefaultRequest
    FetchAccessory.request = dispatch(fetchBillingDetailThunk({ tableInfoChange: { key, value: value, details }, billingId }))
  },
  CRUD: {
    Create: async (model) => {
      const billingId = BillingQueryParam.get().billingId
      if (!billingId) return
      await CreateBillingDetailService(billingId).Create(model)
      dispatch(fetchBillingDetailThunk({ billingId }))
    },
    Update: async (Id, model) => {
      const billingId = BillingQueryParam.get().billingId
      if (!billingId) return
      await CreateBillingDetailService(billingId).Update(Id, model)
      dispatch(fetchBillingDetailThunk({ billingId }))
    },
    Delete: async (Id) => {
      const billingId = BillingQueryParam.get().billingId
      if (!billingId) return
      const data = await CreateBillingDetailService(billingId).Remove(Id)
      if (data) {
        dispatch(fetchBillingDetailThunk({ billingId }))
      }
    }
  }
})
