import React, { Component } from 'react'
import { paperClasses, dividerClasses } from '@mui/material'
import { exploreClasses, headerNavClasses, UIImage } from '@shared/UIStyleds'
import { Divider, Drawer, IconButton, IconButtonProps, styled, Typography } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { UIWrapLogo } from './uis'
import { UITabMediaTypeMapRedux } from '../../Views/Explore'
import AppHeaderNav from './app.header.nav'
import AppConfig from '../AppConfig'

interface IProps {}

interface IState {
  isOpen: boolean
}

export default class UIMobileMenu extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = { isOpen: false }
  }

  toggleDrawer = (x: boolean) => () => {
    this.setState({ isOpen: x })
  }

  render() {
    return (
      <>
        <MenuIconButton onClick={this.toggleDrawer(true)} />
        <Wrap open={this.state.isOpen} onClose={this.toggleDrawer(false)}>
          <UIWrapLogo href='/' sx={{ minHeight: '80px', margin: '30px auto 0' }}>
            <UIImage src='logo' />
          </UIWrapLogo>
          <AppHeaderNav disableRollingText data={AppConfig.MenuMobile} onClickItem={this.toggleDrawer(false)} />
          <Divider />
          <Typography component='h6' variant='subtitle1' sx={{ fontWeight: 600, m: '30px 0 10px' }}>
            Media We Support
          </Typography>
          <UITabMediaTypeMapRedux onClick={this.toggleDrawer(false)} />
        </Wrap>
      </>
    )
  }
}

const MenuIconButton = styled(({ children, ...props }: IconButtonProps) => (
  <IconButton {...props}>
    <MenuIcon />
  </IconButton>
))(({ theme }) => ({
  color: '#fff',
  [theme.breakpoints.up('md')]: {
    display: 'none'
  }
}))

const Wrap = styled(Drawer)({
  [`& .${paperClasses.root}`]: {
    width: 'calc(100vw - 90px)',
    backgroundColor: '#1b1b1b',
    color: '#fff',
    padding: '20px',
    textAlign: 'center'
  },
  [`& .${headerNavClasses.root}`]: {
    padding: '30px 0',
    display: 'block',
    '& .header-nav-item::after': {
      content: 'none'
    },
    '& a': {
      display: 'block',
      width: '100%',
      textTransform: 'uppercase',
      margin: '3px'
    }
  },
  [`& .${exploreClasses.root}`]: {
    justifyContent: 'center'
  },
  [`& .${dividerClasses.root}`]: {
    borderColor: 'rgba(255, 255, 255, 0.12)'
  }
})
