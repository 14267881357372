import { ReduxBase } from '@lib/ReduxBase'
import { ELazyStatus } from '@lib/ReduxBase'
import { createSlice } from '@reduxjs/toolkit'
import { fetchMediaDetailThunk } from './redux.thunks'
import { IMediaDetailSliceState } from './redux.types'

export const DefaultSlice = ReduxBase.GetInitialSlice<IMediaDetailSliceState>({ state: { categories: [], prices: [], recommendProducts: [] } })

const MediaDetailSlice = createSlice({
  name: 'MediaDetailSlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState: DefaultSlice.initialState,
  reducers: { ...DefaultSlice.reducers },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMediaDetailThunk.fulfilled, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.status = ELazyStatus.Loaded
        state.productInfo = action.payload.productInfo
        state.categories = action.payload.categories
        state.prices = action.payload.prices
        state.recommendProducts = action.payload.recommendProducts
      })
      .addCase(fetchMediaDetailThunk.rejected, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.status = ELazyStatus.Error
      })
      .addCase(fetchMediaDetailThunk.pending, (state, action) => {
        state.requestedId = action.meta.requestId
        state.status = ELazyStatus.Loading
      })
  }
})
export default MediaDetailSlice
