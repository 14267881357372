import React, { FC } from 'react'
import { Box, Tooltip } from '@mui/material'
import { TooltipProps, styled, tooltipClasses } from '@mui/material'

interface IProps {
  children: React.ReactNode
  content: React.ReactNode
  size?: 'small' | 'medium' | 'large'
  tooltipProps?: Omit<TooltipProps, 'title' | 'children'>
}

const UITooltip: FC<IProps> = (props) => (
  <HtmlTooltip
    placement='right'
    title={
      <Box sx={{ py: '6px', minWidth: '200px', maxWidth: '400px ' }}>
        <Box sx={{ p: '6px 12px' }}>{props.content}</Box>
      </Box>
    }
    {...props.tooltipProps}
  >
    <Box>{props.children}</Box>
  </HtmlTooltip>
)

export default UITooltip

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} arrow />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    color: '#3c3c3c',
    maxWidth: 500,
    minHeight: 120,
    fontSize: theme.typography.pxToRem(12),
    borderRadius: '8px',
    boxShadow: 'rgba(0, 0, 0, 0.08) 0px 6px 30px'
  },
  [`& .${tooltipClasses.arrow}::before`]: {
    backgroundColor: '#fff'
  }
}))
