import React, { Component, PropsWithChildren } from 'react'
import { UIErrorPage } from '@shared/UIStyleds'
import { ELazyStatus, IReturnDispatch } from './types'
import { IDispatchBase, IStateBase } from './redux-base'
import { IErrorViewDefaultProps, IHocLazyWrapperProps } from './uis'

interface IHocLazyBase<TModel> extends IStateBase, IDispatchBase<TModel> {}

export interface IOptionBase {
  ErrorScreen?: React.ComponentType<IErrorViewDefaultProps>
  LazySpinner: React.ComponentType<PropsWithChildren<IHocLazyWrapperProps>>
}

export interface IHocLazyOtherProps<TModel> {
  param?: TModel
}

export interface IHocLazyOptions<TModel> extends IHocLazyOtherProps<TModel>, Omit<IOptionBase, 'LazySpinner'> {
  LazySpinner?: React.ComponentType<PropsWithChildren<IHocLazyWrapperProps>>
}

export const EmptyComponent: React.FC<React.PropsWithChildren<any>> = ({ children }) => <>{children}</>

export const OptionHocLazyDefault: IOptionBase = {
  LazySpinner: EmptyComponent
}

const CreateHocLazy = function <TChildProp extends IHocLazyBase<TModel>, TModel = any>(
  ChildComponent: React.ComponentType<TChildProp>,
  options?: IHocLazyOptions<TModel>
) {
  type TProps = TChildProp & IHocLazyOtherProps<TModel>
  class HocComponent extends Component<TProps> {
    optionsMerge: IOptionBase
    tokenSources?: AbortController
    fetchSource?: IReturnDispatch
    constructor(props: TProps) {
      super(props)
      this.optionsMerge = Object.assign({}, OptionHocLazyDefault, options) as IOptionBase
    }

    render() {
      const { optionsMerge: OptionsMerge } = this
      switch (this.props.status) {
        case ELazyStatus.Loading:
        case ELazyStatus.Loaded:
          return (
            <OptionsMerge.LazySpinner loading={this.props.status === ELazyStatus.Loading}>
              <ChildComponent {...this.props} />
            </OptionsMerge.LazySpinner>
          )
        case ELazyStatus.Error:
          return <UIErrorPage />
        default:
          return <div></div>
      }
    }

    initialParam = (): TModel => {
      const oData = options?.param ?? {}
      const pData = this.props?.param ?? {}
      return Object.assign({}, oData, pData) as TModel
    }

    componentDidMount = () => {
      if (this.props.fetchData) {
        const param = this.initialParam()
        this.tokenSources?.abort()
        this.tokenSources = new AbortController()
        this.fetchSource = this.props.fetchData(param, this.tokenSources.signal)
      }
    }

    componentWillUnmount() {
      this.tokenSources?.abort()
      this.fetchSource?.abort && this.fetchSource.abort()
    }
  }
  return HocComponent
}
export default CreateHocLazy
