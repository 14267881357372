import React, { FC } from 'react'
import { EProductType } from '@shared/Types'
import UIProductVideo from './ui.product.video'
import { IProductProps } from './ui.product.types'

const UIProduct: FC<IProductProps> = (props) => {
  switch (props.data.Type) {
    case EProductType.Video:
      return <UIProductVideo {...props} />
    default:
      return <></>
  }
}

export default UIProduct
