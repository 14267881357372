import React, { FC, useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ETicketStatus } from '@shared/Types'
import { Box, BoxProps, Button, CircularProgress, FormControlLabel, Switch, SxProps, Theme, Typography, styled } from '@mui/material'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'

const StatusColors: { [key in ETicketStatus]: SxProps<Theme> } = {
  Open: { background: 'linear-gradient(to right,#00b93b,#5adc82)' },
  Closed: { background: 'linear-gradient(to right,#c2c2c2,#dedede)' }
}

interface IStatusCellProps {
  status: ETicketStatus
}

export const StatusCell: FC<IStatusCellProps> = (props) => (
  <WrapStatusCell>
    <CustomTypography sx={StatusColors[props.status]}>{props.status}</CustomTypography>
  </WrapStatusCell>
)

const WrapStatusCell = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%'
})

const CustomTypography = styled(Typography)({
  borderRadius: '4px',
  fontSize: '0.8rem',
  padding: '4px 7px',
  fontWeight: '400',
  minWidth: '70px',
  textAlign: 'center',
  color: '#fff'
})

interface ITopBarProps {
  to?: string
  btnBackTitle?: string
  title?: string
  rootProps?: BoxProps
}

export const UITopBar: FC<ITopBarProps> = (props) => {
  const onBackClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (!props.to) {
        e.preventDefault()
        e.stopPropagation()
        window.history.back()
      }
    },
    [props]
  )
  return (
    <Box {...props.rootProps} sx={{ mt: '4px', mb: '4px', backgroundColor: '#fff', padding: '24px 0 9px', ...props.rootProps?.sx }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <ButtonBack color='inherit' {...{ component: Link, to: props.to }} onClick={onBackClick} startIcon={<KeyboardBackspaceIcon />}>
          <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>{props.btnBackTitle ?? 'back'}</Typography>
        </ButtonBack>
        {props.title && <Typography sx={{ fontSize: '18px', fontWeight: 600, color: '#606060' }}>{props.title}</Typography>}
        <Box sx={{ width: '160px', flex: '0 0 auto' }} />
      </Box>
    </Box>
  )
}

const ButtonBack = styled(Button)({
  textTransform: 'none',
  textDecoration: 'none!important',
  color: '#818181',
  width: '160px',
  flex: '0 0 auto',
  '&:hover': { color: '#006bd6' }
})

interface IStatusSwitchProps {
  status: ETicketStatus
  onChange?: (value: ETicketStatus) => Promise<void>
  disableWhenClosed?: boolean
}

export const UIStatusSwitch: FC<IStatusSwitchProps> = (props) => {
  const [isLoading, setLoading] = useState<boolean>(false)
  const [value, setValue] = useState<ETicketStatus>(props.status)

  const handleChange = useCallback(
    async (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      try {
        setLoading(true)
        const v = checked ? ETicketStatus.Open : ETicketStatus.Closed
        if (!props.onChange) throw new Error('The onChange function is not defined!')
        await props.onChange(v)
        if (props.disableWhenClosed !== true) {
          setValue(v)
        }
      } catch (e) {
        console.log(e)
      } finally {
        if (props.disableWhenClosed !== true) {
          setLoading(false)
        }
      }
    },
    [props]
  )

  useEffect(() => {
    setValue(props.status)
    setLoading(false)
  }, [props.status])

  return (
    <CustomFormControlLabel
      isLoading={isLoading}
      control={
        <Android12Switch
          color='success'
          checked={value === ETicketStatus.Open}
          onChange={handleChange}
          disabled={props.disableWhenClosed && value === ETicketStatus.Closed}
        />
      }
      label={isLoading ? <CircularProgress size={18} /> : value.toString()}
    />
  )
}

const getBackgroundImage = (params: { fill: string; size: number; path: { d: string } }) => {
  return `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="${params.size}" width="${params.size}" viewBox="0 0 24 24"><path fill="${params.fill}" d="${params.path.d}"/></svg>')`
}

const CustomFormControlLabel = styled(FormControlLabel)<{ isLoading: boolean }>(({ isLoading }) => ({
  pointerEvents: isLoading ? 'none' : 'all',
  '& .MuiTypography-root': {
    marginTop: '2px',
    lineHeight: 1
  }
}))

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 14,
      height: 14
    },
    '&::before': {
      backgroundImage: getBackgroundImage({
        fill: encodeURIComponent(theme.palette.getContrastText(theme.palette.success.main)),
        size: 14,
        path: {
          d: `M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6h2c0-1.66 1.34-3 3-3s3 1.34 3 3v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2m0 12H6V10h12zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2`
        }
      }),
      left: 12
    },
    '&::after': {
      backgroundImage: getBackgroundImage({
        fill: encodeURIComponent(theme.palette.getContrastText(theme.palette.success.main)),
        size: 14,
        path: {
          d: `M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2M9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9zm9 14H6V10h12zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2`
        }
      }),
      right: 12
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2
  }
}))
