export enum EAppSiteName {
  Admin = 'Admin',
  Client = 'Client'
}

export enum EProfileFormType {
  Business = 'Business',
  Personal = 'Personal',
  Payment = 'Payment'
}

// <datasource>-<area>-<list type>
export enum EDatasourceKey {
  Category = 'Category',
  Media = 'Media'
}

//key.contains("-Home-")
export enum EGalleryArea {
  Home = 'Home'
}

export enum EGalleryListType {
  VideoCategory = 'VideoCategory',
  AudioCategory = 'AudioCategory',
  VideoPopular = 'VideoPopular',
  VideoNew = 'VideoNew',
  AudioHot = 'AudioHot'
}

export enum EMediaStatus {
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected'
}

export enum EMediaType {
  Video = 'Video',
  Audio = 'Audio'
}

export enum EProductType {
  Video = 'Video',
  Audio = 'Audio'
}

export enum EOrderStatus {
  Draft = 'Draft',
  Pending = 'Pending',
  Done = 'Done',
  Error = 'Error'
}

export enum EOrderPayment {
  Paypal = 'Paypal',
  Payoneer = 'Payoneer'
}

export enum EClaimedMediaStatus {
  Pending = 'Pending',
  Processed = 'Processed'
}

export enum EBillingStatus {
  New = 'New',
  Paid = 'Paid',
  UnPaid = 'UnPaid'
}

export enum EBillingType {
  Revenue = 'Revenue'
}

export enum EPriceType {
  Media = 'Media',
  Pack = 'Pack'
}

export enum ECategoryType {
  Video = 'Video',
  Audio = 'Audio'
}

export enum EGalleryType {
  System = 'System',
  User = 'User'
}

export enum EGalleryKey {
  Videos1 = 'Videos1',
  Videos2 = 'Videos2',
  Audio1 = 'Audio1',
  Audio2 = 'Audio2',
  Audio3 = 'Audio3',
  Audio4 = 'Audio4',
  Audio5 = 'Audio5'
}

export enum ENotificationSendType {
  All = 'All',
  User = 'User'
}

export enum ENotificationAreaType {
  Header = 'Header',
  Popup = 'Popup'
}

export enum ETicketConversation {
  Text = 'Text',
  Image = 'Image',
  Audio = 'Audio',
  Video = 'Video',
  Other = 'Other'
}

export enum ETicketStatus {
  Open = 'Open',
  Closed = 'Closed'
}

export enum ETicketType {
  General = 'General',
  Order = 'Order',
  Billing = 'Billing',
  Error = 'Error'
}

export enum ECheckOutValidateStatus {
  Pending = 'Pending',
  Loading = 'Loading',
  Valid = 'Valid'
}

export enum EPaymentMethod {
  PayPal = 'PayPal'
}

export type ECartType = 'Video' | 'Audio' | 'Pack'

export enum ESubmissionStep {
  AboutMedia = 'AboutMedia',
  AboutYouself = 'AboutYouself',
  CopyrightOwnership = 'CopyrightOwnership',
  DigitalSignature = 'DigitalSignature',
  Complete = 'Complete'
}

export enum ESubmissionStatus {
  Pending = 'Pending',
  Done = 'Done',
  Rejected = 'Rejected'
}

export enum ERepositoryType {
  Drive = 'Drive',
  Dropbox = 'Dropbox',
  Other = 'Other'
}

export enum EMediaDeliveryStatus {
  Pending = 'Pending',
  Processing = 'Processing',
  Done = 'Done',
  Rejected = 'Rejected'
}

export enum EMediaDeliveryType {
  Submission = 'Submission',
  Other = 'Other'
}

export enum EMediaDeliveryContentType {
  Url = 'Url',
  Upload = 'Upload',
  Internal = 'Internal'
}

export enum EPdfInfoType {
  PdfFile = 'PdfFile',
  PdfSubmission = 'PdfSubmission',
  PdfSignature = 'PdfSignature'
}

export enum EPolicyFileType {
  All = 'All',
  PrivacyPolicy = 'PrivacyPolicy',
  TermsOfSubmission = 'TermsOfSubmission'
}

export enum EPolicyFileMode {
  PdfFile = 'PdfFile',
  TextEditor = 'TextEditor'
}

export enum EContactSubmitStatus {
  New = 'New',
  Read = 'Read'
}
