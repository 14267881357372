import React, { FC } from 'react'
import { PayPalButtons, PayPalScriptProvider, PayPalButtonsComponentProps } from '@paypal/react-paypal-js'

interface IPaypalProps extends Required<Pick<PayPalButtonsComponentProps, 'createOrder' | 'onApprove'>> {
  onBeforePayment?: () => void
  disabled?: boolean
}

export const UIPaymentMethodPaypal: FC<IPaypalProps> = (props) => (
  <PayPalScriptProvider
    options={{
      clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID as any,
      disableFunding: 'paylater', // TODO Disable PayPal Pay Later button
      environment: process.env.REACT_APP_PAYPAL as any
    }}
  >
    <PayPalButtons
      style={{ height: 42 }}
      disabled={props.disabled}
      createOrder={props.createOrder}
      onApprove={props.onApprove}
      fundingSource='paypal'
    />
  </PayPalScriptProvider>
)
