import { IYoutubeVideo } from '@shared/Types'
import { ReduxBaseTable } from '@lib/ReduxBase'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { YoutubeVideoQueryParam } from '@shared/Services/QueryParamService'
import YoutubeVideoService, { YoutubeVideoMapping } from '@internal/services/YoutubeVideoService'
import { DefaultSlice } from './redux.slice'
import { IReduxThunkArg, IReduxThunkReturned, IYoutubeVideoSliceState } from './redux.types'

export const fetchYoutubeVideoThunk = createAsyncThunk<IReduxThunkReturned, IReduxThunkArg>('fetchYoutubeVideoThunk', async (param, thunkAPI) => {
  const state = thunkAPI.getState() as { YoutubeVideoSlice: IYoutubeVideoSliceState }
  const qParam = YoutubeVideoQueryParam.Get()
  if (!qParam.deliveryId) {
    return { tableInfo: state.YoutubeVideoSlice.tableInfo, categories: [] }
  }

  const tableInfoParam = ReduxBaseTable.ThunkArgMapping({ tableInfo: state.YoutubeVideoSlice.tableInfo, ...param })

  const filter = YoutubeVideoMapping.tableInfoToFilter(tableInfoParam, qParam.deliveryId)
  const res = await YoutubeVideoService.AllGraphql(filter, qParam.deliveryId, thunkAPI.signal)

  const tableInfo = ReduxBaseTable.ThunkReponseMapping(tableInfoParam, {
    Data: YoutubeVideoMapping.youtubeVideos(res.youtubeVideos?.items),
    Total: res.youtubeVideos?.totalItems
  })
  ReduxBaseTable.setParam<IYoutubeVideo>(tableInfo, DefaultSlice.initialState.tableInfo)
  return {
    tableInfo,
    delivery: YoutubeVideoMapping.delivery(res.delivery),
    categories: YoutubeVideoMapping.categories(res.categories.items ?? [])
  }
})
