import React, { Component } from 'react'
import { styled } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker as MUIDatePicker, LocalizationProvider, DatePickerProps } from '@mui/x-date-pickers'
import dayjs, { Dayjs } from 'dayjs'
import { IFormInputBase } from '../../types'
import { getErrorMessage, MergeObjects } from '../../helper'

const formatDefault = 'MM/DD/YYYY'

interface IInputTextParams {
  datePickerProps?: DatePickerProps<Dayjs>
}

const CreateDatePicker = function <TModel extends Object>(params?: IInputTextParams) {
  interface IProps extends IFormInputBase<TModel> {
    format?: string
    options?: {
      datePickerProps: DatePickerProps<Dayjs>
    }
  }

  interface IState {
    value: Dayjs | null
  }

  class DatePicker extends Component<IProps, IState> {
    constructor(props: IProps) {
      super(props)
      this.state = { value: this.getDefaultValue() }
    }

    refInput: HTMLInputElement | null = null
    render() {
      const label = this.props.label ?? this.props.name?.toString()
      const format = this.props.format ?? formatDefault
      const eMessage = getErrorMessage<TModel>(this.props.messageErrors, this.props.name)
      const dpProps = this.getDatePickerProps()

      return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CustomDatePicker
            label={label}
            format={format}
            views={['day', 'month', 'year']}
            value={this.state.value}
            onChange={this.handleChange}
            disabled={this.props.disabled}
            {...dpProps}
            slotProps={{
              ...dpProps?.slotProps,
              textField: {
                onBlur: this.handleBlur,
                fullWidth: true,
                error: eMessage.error,
                helperText: eMessage.message,
                variant: 'outlined',
                ...dpProps?.slotProps?.textField
              }
            }}
          />
          <input
            hidden
            name={this.props.name?.toString()}
            defaultValue={this.getDefaultValue()?.toDate().toISOString()}
            ref={(ref) => (this.refInput = ref)}
          />
        </LocalizationProvider>
      )
    }

    handleBlur = () => {
      if (!this.props.name) return
      this.props.onBlur && this.props.onBlur(this.props.name)
    }

    handleChange = (newValue: Dayjs | null) => {
      this.setState({ value: newValue })
      if (this.refInput) {
        this.refInput.value = newValue && !isNaN(newValue.toDate().getTime()) ? newValue.toDate().toISOString() : ''
      }
      setTimeout(this.handleBlur, 50)
    }

    getDatePickerProps = (): DatePickerProps<Dayjs> => {
      return MergeObjects({}, this.props.options?.datePickerProps, params?.datePickerProps)
    }

    getDefaultValue = () => {
      const { data, name } = this.props
      const dValue = this.props.defaultValue ?? (name ? data?.[name] : undefined)
      return dValue ? dayjs(dValue.toString()) : null
    }
  }
  return DatePicker
}

export default CreateDatePicker

// export type DatePickerType<TModel extends Object> = ReturnType<typeof CreateDatePicker<TModel>>

const CustomDatePicker = styled(MUIDatePicker<Dayjs>)({
  '& .MuiInputBase-root::before, & .MuiInputBase-root::after': {
    borderBottom: 'none !important'
  }
})
