import { ITicket } from '@shared/Types'
import { ReduxBaseTable } from '@lib/ReduxBase'
import { ApiAlertContext } from '@lib/ApiContext'
import { AppDispatch, RootState } from '@internal/redux'
import TicketService from '@internal/services/TicketService'
import { fetchTicketThunk } from './redux.thunks'
import { ITicketMapDispatchRedux, IReduxMapState } from './redux.types'
import TicketSlice, { DefaultSlice } from './redux.slice'

export const mapStateToProps = (state: RootState): IReduxMapState => ({
  slice: state.TicketSlice,
  status: state.TicketSlice.status,
  tableInfo: state.TicketSlice.tableInfo
})

type TRequest = { abort: () => void }
const FetchAccessory: { request?: TRequest } = {}
export const mapDispatchToProps = (dispatch: AppDispatch): ITicketMapDispatchRedux => ({
  fetchData: () => {
    const tableInfoQueryParam = ReduxBaseTable.getParam<ITicket>(DefaultSlice.initialState.tableInfo)
    return dispatch(fetchTicketThunk({ tableInfoQueryParam }))
  },
  onChangeStatus: (status) => {
    dispatch(TicketSlice.actions.onChangeStatus(status))
  },
  onChangeTableInfo: (key, value, details) => {
    if (key === 'FilterModel') {
      dispatch(TicketSlice.actions.onChangeTableInfo({ key, value, details }))
    }
    FetchAccessory.request?.abort()
    FetchAccessory.request = dispatch(fetchTicketThunk({ tableInfoChange: { key, value: value, details } }))
  },
  CRUD: {
    Update: async (id, model, options) => {
      await TicketService.Update(id, model)
      ApiAlertContext.ApiAlert?.PushSuccess('Change status done!')
      if (options?.disableReload !== true) {
        dispatch(fetchTicketThunk())
      }
    }
  }
})
