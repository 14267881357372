import * as UIStyleds from './styleds'
export default UIStyleds

export * from './styleds'

export * from './classes'

export { default as CreateInfoBar } from './CreateInfoBar'
export * from './CreateInfoBar'

export { default as CreateStatusCell } from './create.status-cell'

export { default as CreateSelectSimpleLoading } from './CreateSelectSimpleLoading'

export * from './ui.buttons'

export { default as UIErrorPage } from './ui.error-page'

export { default as UIImage } from './ui.image'
export * from './ui.image'

export { default as UIMediaPlayer } from './ui.media-player'

export { default as UITextLineBreak } from './ui.text-line-break'
