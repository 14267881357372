import type { FieldOptions, DirectiveArgs } from 'graphql-ts-client-api';
import { ENUM_INPUT_METADATA } from '../EnumInputMetadata';
import type { ObjectFetcher } from 'graphql-ts-client-api';
import { createFetcher, createFetchableType } from 'graphql-ts-client-api';
import type { WithTypeName, ImplementationType } from '../CommonTypes';
import type {MediaType} from '../enums';

/*
 * Any instance of this interface is immutable,
 * all the properties and functions can only be used to create new instances,
 * they cannot modify the current instance.
 * 
 * So any instance of this interface is reuseable.
 */
export interface YoutubeVideoFetcher<T extends object, TVariables extends object> extends ObjectFetcher<'YoutubeVideo', T, TVariables> {

    on<XName extends ImplementationType<'YoutubeVideo'>, X extends object, XVariables extends object>(
        child: ObjectFetcher<XName, X, XVariables>, 
        fragmentName?: string // undefined: inline fragment; otherwise, otherwise, real fragment
    ): YoutubeVideoFetcher<
        XName extends 'YoutubeVideo' ?
        T & X :
        WithTypeName<T, ImplementationType<'YoutubeVideo'>> & (
            WithTypeName<X, ImplementationType<XName>> | 
            {__typename: Exclude<ImplementationType<'YoutubeVideo'>, ImplementationType<XName>>}
        ), 
        TVariables & XVariables
    >;


    directive(name: string, args?: DirectiveArgs): YoutubeVideoFetcher<T, TVariables>;


    readonly __typename: YoutubeVideoFetcher<T & {__typename: ImplementationType<'YoutubeVideo'>}, TVariables>;


    readonly id: YoutubeVideoFetcher<T & {readonly "id"?: string}, TVariables>;

    "id+"<
        XAlias extends string = "id", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"id", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): YoutubeVideoFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~id": YoutubeVideoFetcher<Omit<T, 'id'>, TVariables>;


    readonly title: YoutubeVideoFetcher<T & {readonly "title"?: string}, TVariables>;

    "title+"<
        XAlias extends string = "title", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"title", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): YoutubeVideoFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~title": YoutubeVideoFetcher<Omit<T, 'title'>, TVariables>;


    readonly description: YoutubeVideoFetcher<T & {readonly "description"?: string}, TVariables>;

    "description+"<
        XAlias extends string = "description", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"description", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): YoutubeVideoFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~description": YoutubeVideoFetcher<Omit<T, 'description'>, TVariables>;


    readonly userId: YoutubeVideoFetcher<T & {readonly "userId"?: string}, TVariables>;

    "userId+"<
        XAlias extends string = "userId", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"userId", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): YoutubeVideoFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~userId": YoutubeVideoFetcher<Omit<T, 'userId'>, TVariables>;


    readonly tags: YoutubeVideoFetcher<T & {readonly "tags"?: string}, TVariables>;

    "tags+"<
        XAlias extends string = "tags", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"tags", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): YoutubeVideoFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~tags": YoutubeVideoFetcher<Omit<T, 'tags'>, TVariables>;


    readonly type: YoutubeVideoFetcher<T & {readonly "type": MediaType}, TVariables>;

    "type+"<
        XAlias extends string = "type", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"type", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): YoutubeVideoFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: MediaType} : 
                {readonly [key in XAlias]: MediaType}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~type": YoutubeVideoFetcher<Omit<T, 'type'>, TVariables>;


    readonly deliveryId: YoutubeVideoFetcher<T & {readonly "deliveryId": string}, TVariables>;

    "deliveryId+"<
        XAlias extends string = "deliveryId", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"deliveryId", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): YoutubeVideoFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~deliveryId": YoutubeVideoFetcher<Omit<T, 'deliveryId'>, TVariables>;


    readonly publishedDate: YoutubeVideoFetcher<T & {readonly "publishedDate": string}, TVariables>;

    "publishedDate+"<
        XAlias extends string = "publishedDate", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"publishedDate", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): YoutubeVideoFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~publishedDate": YoutubeVideoFetcher<Omit<T, 'publishedDate'>, TVariables>;


    mediaDelivery<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'MediaDelivery', X, XVariables>
    ): YoutubeVideoFetcher<
        T & {readonly "mediaDelivery"?: X}, 
        TVariables & XVariables
    >;

    mediaDelivery<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "mediaDelivery", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'MediaDelivery', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"mediaDelivery", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): YoutubeVideoFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & XDirectiveVariables
    >;


    readonly dateCreated: YoutubeVideoFetcher<T & {readonly "dateCreated": string}, TVariables>;

    "dateCreated+"<
        XAlias extends string = "dateCreated", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"dateCreated", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): YoutubeVideoFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~dateCreated": YoutubeVideoFetcher<Omit<T, 'dateCreated'>, TVariables>;


    readonly dateUpdated: YoutubeVideoFetcher<T & {readonly "dateUpdated": string}, TVariables>;

    "dateUpdated+"<
        XAlias extends string = "dateUpdated", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"dateUpdated", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): YoutubeVideoFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~dateUpdated": YoutubeVideoFetcher<Omit<T, 'dateUpdated'>, TVariables>;
}

export const youtubeVideo$: YoutubeVideoFetcher<{}, {}> = 
    createFetcher(
        createFetchableType(
            "YoutubeVideo", 
            "OBJECT", 
            [], 
            [
                {
                    category: "ID", 
                    name: "id", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "title", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "description", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "userId", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "tags", 
                    undefinable: true
                }, 
                "type", 
                "deliveryId", 
                "publishedDate", 
                {
                    category: "REFERENCE", 
                    name: "mediaDelivery", 
                    targetTypeName: "MediaDelivery", 
                    undefinable: true
                }, 
                "dateCreated", 
                "dateUpdated"
            ]
        ), 
        ENUM_INPUT_METADATA, 
        undefined
    )
;

export const youtubeVideo$$ = 
    youtubeVideo$
        .id
        .title
        .description
        .userId
        .tags
        .type
        .deliveryId
        .publishedDate
        .dateCreated
        .dateUpdated
;
