import React, { FC } from 'react'
import { RouteKey } from '@internal/route'
import { ELazyStatus } from '@lib/ReduxBase'
import { useNavigate } from 'react-router-dom'
import { CreateRoutePath } from '@lib/RouteBase'
import { ECheckOutValidateStatus } from '@shared/Types'

interface IProps {
  status: ELazyStatus
  checkOutStatus: ECheckOutValidateStatus
  onCheckOutValid?: () => void
}

export const HookRedirectToCheckOut: FC<IProps> = (props) => {
  const navigate = useNavigate()

  React.useEffect(() => {
    if (props.checkOutStatus === ECheckOutValidateStatus.Valid && props.status === ELazyStatus.Loaded) {
      props.onCheckOutValid && props.onCheckOutValid()
      navigate(CreateRoutePath(RouteKey.CheckOut))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.checkOutStatus, props.status])

  return <></>
}
