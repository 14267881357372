import React, { FC, PropsWithChildren } from 'react'
import { Box, Grid } from '@mui/material'
import { RouteKey } from '@internal/route'
import { CreateRoutePath } from '@lib/RouteBase'
import { UIButtonBack, UIClientContainer } from '@shared/UIStyleds'

interface IProps {
  top?: JSX.Element
  content: JSX.Element
  summaryReport: JSX.Element
}

const UILayout: FC<PropsWithChildren<IProps>> = (props) => {
  const backPath = CreateRoutePath(RouteKey.Explore)
  return (
    <UIClientContainer>
      {props.top}
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          {props.content}
          <Box sx={{ display: { xs: 'none', md: 'block' }, mt: '35px' }}>
            <UIButtonBack to={backPath}>Continue explore</UIButtonBack>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          {props.summaryReport}
        </Grid>
        <Grid item xs={12} md={4} sx={{ display: { xs: 'block', md: 'none' } }}>
          <UIButtonBack to={backPath}>Continue explore</UIButtonBack>
        </Grid>
      </Grid>
      {props.children}
    </UIClientContainer>
  )
}

export default UILayout
