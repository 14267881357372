import { createAsyncThunk } from '@reduxjs/toolkit'
import GalleryService, { GalleryMapping } from '@internal/services/GalleryService'
import { GetPolicyFileType } from './helpers'
import { IReduxThunkReturned } from './redux.types'

export const fetchPolicyFileThunk = createAsyncThunk<IReduxThunkReturned>('fetchPolicyFileThunk', async (_, thunkAPI) => {
  const filter = GalleryMapping.policyFileFilter()
  const res = await GalleryService.All(filter, thunkAPI.signal)
  const policyFileType = GetPolicyFileType()
  if (!policyFileType) return {}
  return { galleryPdfInfo: GalleryMapping.policyFileActivated({ gallery: res?.Data[0], type: policyFileType }) }
})
