import React, { FC } from 'react'
import { EMediaType, ICart } from '@shared/Types'
import { alpha, Box, BoxProps, styled, Typography } from '@mui/material'
import MusicNoteIcon from '@mui/icons-material/MusicNote'
import { CheckOutHelper } from './helpers'

interface IProps {
  data: ICart
}

const UICartPreviewItem: FC<IProps> = (props) => {
  const subTotal = CheckOutHelper.getSubTotal(props.data)
  return (
    <Wrap>
      <Box>
        <UICartPreviewItemImage data={props.data} />
      </Box>
      <Box sx={{ flex: 1 }}>
        <LicenseTitle variant='subtitle1' color={CheckOutHelper.getColor(props.data)} noWrap>
          {props.data.PriceOrigin?.Name ?? 'Pricing package name/Exo 2/Regular/16px'}
        </LicenseTitle>
        <Typography variant='subtitle2' sx={{ mt: '6px' }}>
          {props.data.Name ?? 'Cart item name/Exo 2/Regular/14px'}
        </Typography>
        <Typography variant='subtitle2' sx={{ mt: '6px' }}>
          {CheckOutHelper.getAmountStringLink(props.data)}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant='subtitle1'>$ {subTotal}</Typography>
      </Box>
    </Wrap>
  )
}

export default UICartPreviewItem

const Wrap = styled(({ children, ...p }: BoxProps) => (
  <Box {...p}>
    <div>{children}</div>
  </Box>
))({
  height: 'var(--height-item)',
  '& > div': {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    gap: '18px',
    background: '#fafafa',
    paddingRight: '20px',
    borderRadius: '4px'
  }
})

const LicenseTitle = styled(Typography)<{ color?: string }>(({ color }) => ({
  fontWeight: 600,
  color,
  display: 'inline-block',
  lineHeight: 1.2
}))

const UICartPreviewItemImage: FC<IProps> = (props) => {
  switch (props.data.Type) {
    case EMediaType.Audio:
      return (
        <WraperImage>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <MusicNoteIcon sx={{ color: '#1976D2' }} />
          </Box>
        </WraperImage>
      )
    case EMediaType.Video:
      return (
        <WraperImage>
          <Box sx={{ backgroundImage: `url(${props.data.ImageUri})` }} />
        </WraperImage>
      )
    default:
      return <></>
  }
}

const WraperImage = styled(Box)(({ theme }) => ({
  flex: '0 0 auto',
  width: '168px',
  paddingBottom: theme.app.imageAspectRatio,
  borderRadius: '4px',
  position: 'relative',
  '& > div': {
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: alpha('#1976D2', 0.15),
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '4px',
    boxShadow: '0px 2px 14px 0px rgba(0, 0, 0, 0.15)'
  }
}))
