import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Box, Fade } from '@mui/material'
import { RouteKey } from '@internal/route'
import { TableFormater } from '@lib/Helpers'
import { ELazyStatus } from '@lib/ReduxBase'
import { MapGlobalModalContext } from '@lib/ApiContext'
import { ISelectMultipleOption } from '@lib/Forms/Inputs'
import { DeliveryShared, YoutubeVideoShared } from '@shared/Pages'
import { EMediaDeliveryContentType, IYoutubeVideo } from '@shared/Types'
import { CreateRoutePath, CreateRoutePathWithCurrent } from '@lib/RouteBase'
import { ActionPannel, CreateTableTemplate, CRUDPannel, MapOprators } from '@lib/Table'
import { IYoutubeVideoPreviewQueryParam, YoutubeVideoQueryParam } from '@shared/Services/QueryParamService'
import UIStyleds from '@shared/UIStyleds'
import { DeliveryStatusMapRedux } from '../Delivery'
import { IYoutubeVideoReduxProps } from './redux.types'
import { mapDispatchToProps, mapStateToProps } from './redux.map'
import { CreateHocLazy } from '@lib/ReduxBase'

type TBreadcrumbConfig = YoutubeVideoShared.IBreadcrumbConfig

const Table = CreateTableTemplate<IYoutubeVideo>('Redux', {
  getRowId: (x) => x.Id,
  config: {
    Title: { type: 'string', flex: 3, minWidth: 250 },
    Tags: { type: 'string', minWidth: 200, renderCell: (x) => <YoutubeVideoShared.UICellTags value={x.value} /> },
    PublishedDate: {
      type: 'string',
      headerName: 'Date created',
      minWidth: 190,
      filterable: false,
      renderCell: (params) => TableFormater.tooltipDate(params.value)
    },
    DateCreated: {
      type: 'string',
      headerName: 'Date created',
      minWidth: 190,
      filterable: false,
      renderCell: (params) => TableFormater.tooltipDate(params.value)
    }
  },
  filterOperators: MapOprators, //server mode,
  minWidthColumnActions: 75
})

interface IProps extends IYoutubeVideoReduxProps {}

class ViewBase extends Component<IProps> {
  render() {
    return (
      <UIStyleds.WrapProfile>
        <Table
          ReduxOption={this.props.tableInfo}
          onChange={this.props.onChangeTableInfo}
          InnerProps={{ sx: YoutubeVideoShared.SxTable }}
          CRUDPannel={this.renderCRUDPannel}
          ActionPannel={(x) => {
            const data = x.data as IYoutubeVideo
            return (
              <ActionPannel
                // Delete={<YoutubeVideoShared.FormDelete data={data} onSubmit={(x) => !!x && this.props.CRUD.Delete(data.Id, x)} />}
                // Edit={<YoutubeVideoShared.FormEdit options={this.getYtTags()} data={data} onSubmit={(x) => this.props.CRUD.Update(data.Id, x)} />}
                Buttons={{ ExtendBefore: [<YoutubeVideoShared.UIBtnReview to={this.generateUrlPreview(data)} />] }}
              />
            )
          }}
        />
      </UIStyleds.WrapProfile>
    )
  }

  renderCRUDPannel = () => {
    const deliveryInfo = this.props.Slice.deliveryInfo
    const isUpdateDeliveryStatus = !!deliveryInfo && Object.values(deliveryInfo).filter((x) => !!x).length > 0
    return (
      <CRUDPannel
        Left={<YoutubeVideoShared.UIBreadcrumb configs={this.getBreadcrumbConfigs()} />}
        Right={MapGlobalModalContext((context) => (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '9px' }}>
            <Fade in={isUpdateDeliveryStatus} unmountOnExit>
              <span>
                <DeliveryStatusMapRedux data={deliveryInfo} onChangeValue={(_, value) => this.props.updateDelivery('Status', value.Status)} />
              </span>
            </Fade>
            <YoutubeVideoShared.UIBtnUpload
              onClick={() => {
                context.ShowModal({
                  backdropActivated: true,
                  ContentModal: () => <YoutubeVideoShared.FormUpload onSubmit={this.props.submitUpload} categories={this.getCategories()} />
                })
              }}
            />
            {this.isBtnLink() && (
              <YoutubeVideoShared.UIBtnLink
                disabled={!deliveryInfo}
                onClick={() => {
                  context.ShowModal({ backdropActivated: true, ContentModal: () => <DeliveryShared.FormPreviewUrlType data={deliveryInfo} /> })
                }}
              />
            )}
          </Box>
        ))}
      />
    )
  }

  isBtnLink = () => {
    const qParam = YoutubeVideoQueryParam.Get()
    const check1 = qParam.type === EMediaDeliveryContentType.Url
    const check2 = this.props.Slice.deliveryInfo?.ContentType === EMediaDeliveryContentType.Url
    return check1 || check2
  }

  getCategories = () => {
    const mediaType = this.props.Slice.deliveryInfo?.MediaType
    return this.props.Slice.categories.filter((x) => x.Type.toString() === mediaType?.toString())
  }

  getYtTags = (): ISelectMultipleOption[] => {
    const categories = this.getCategories()
    return categories.map<ISelectMultipleOption>((x) => ({ Id: x.Name, Label: x.Name }))
  }

  generateUrlPreview = (data: IYoutubeVideo) => {
    return CreateRoutePathWithCurrent<IYoutubeVideoPreviewQueryParam>({ query: { videoId: data.Id }, modal: [RouteKey.YoutubeVideoPreview] })
  }

  getBreadcrumbConfigs = (): TBreadcrumbConfig[] => {
    const info = this.props.Slice.deliveryInfo
    let label = info?.Name || info?.Title || info?.Description || 'No name'
    if (this.props.Slice.status === ELazyStatus.Loading) label = 'Updating...'
    return [{ label: 'Deliveries', url: CreateRoutePath([RouteKey.Profile, RouteKey.Delivery]) }, { label: label }]
  }
}

export { fetchYoutubeVideoThunk } from './redux.thunks'

export { default as YoutubeVideoSlice } from './redux.slice'

const HocLazyInstance = CreateHocLazy(ViewBase)
export const YoutubeVideoMapRedux = connect(mapStateToProps, mapDispatchToProps)(HocLazyInstance)
