import React, { FC } from 'react'
import { Box, Stack, styled, Typography } from '@mui/material'
import { SvgLoading } from '@shared/Icons'

const ShadowDOM: FC<{ children: string }> = (props) => {
  const contentRef = React.useRef<HTMLDivElement | null>(null)

  React.useEffect(() => {
    // Lấy phần tử template
    const template = document.createElement('template')
    template.innerHTML = `
      <link rel="stylesheet" href="css/quill.snow.css">
      <div class='ql-editor'>${props.children}</div>
    `

    // Tạo shadow DOM và chèn nội dung từ template
    if (contentRef.current) {
      const shadowRoot = contentRef.current.shadowRoot || contentRef.current.attachShadow({ mode: 'open' })

      shadowRoot.innerHTML = '' // Reset nội dung cũ
      shadowRoot.appendChild(template.content.cloneNode(true))
    }
  }, [props.children])

  return (
    <>
      <div ref={contentRef}></div>
    </>
  )
}

interface IProps {
  content: string
  title?: string
}

const PdfPreviewReactQuill: FC<IProps> = (props) => {
  const [isLoading, setIsLoading] = React.useState(true)
  const timerRef = React.useRef<NodeJS.Timeout | null>(null)

  React.useEffect(() => {
    clearTimeout(timerRef.current as NodeJS.Timeout)
    timerRef.current = setTimeout(() => {
      setIsLoading(false)
    }, 800)
    return () => {
      clearTimeout(timerRef.current as NodeJS.Timeout)
    }
  }, [props.content])

  return (
    <Wrap>
      <Box sx={{ flex: 1, position: 'relative', overflow: 'hidden' }}>
        <WrapTitle className={isLoading ? '' : 'visible'}>
          <Typography variant='h6'>{props.title || 'PDF Preview'}</Typography>
        </WrapTitle>
        <ShadowDOM>{props.content}</ShadowDOM>
        {/* <Content className={props.mode}></Content> */}
      </Box>
      <Backdrop className={isLoading ? '' : 'hidden'}>
        <div>
          <SvgLoading sx={{ fontSize: '6.5rem' }} fill='#fb8c1e' />
          <Typography variant='subtitle2'>Loading . . .</Typography>
        </div>
      </Backdrop>
    </Wrap>
  )
}

export default PdfPreviewReactQuill

const Wrap = styled(Stack)({
  flex: 1,
  position: 'relative',
  height: '100%',
  padding: '4px 3px'
})

const WrapTitle = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: 'calc(100% - 8px)',
  height: '70px',
  padding: '8px 10px 25px',
  background: 'linear-gradient(to bottom, #fff 60%, transparent)',
  zIndex: 1,
  textAlign: 'center',
  opacity: 0,
  visibility: 'hidden',
  transition: 'all 0.3s ease',
  '&.visible': {
    opacity: 1,
    visibility: 'visible'
  }
})

// const Content = styled(Box)({
//   position: 'absolute',
//   top: 0,
//   left: 0,
//   width: '100%',
//   height: '100%',
//   overflowY: 'auto',
//   padding: '55px 25px 8px',
//   '&::-webkit-scrollbar': {
//     width: '6px',
//     height: '6px'
//   },
//   '&::-webkit-scrollbar-track': {
//     borderRadius: '3px'
//   }
// })

const Backdrop = styled(Box)({
  position: 'absolute',
  top: 0,
  bottom: 0,
  width: '100%',
  minHeight: '100vh',
  height: '100%',
  background: '#fff',
  flexDirection: 'column',
  transition: 'all 1s ease',
  opacity: 1,
  '&.hidden': {
    opacity: 0,
    visibility: 'hidden'
  },
  '& > div': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100vh'
  }
})
