import React, { Component } from 'react'
import { connect } from 'react-redux'
import { RouteKey } from '@internal/route'
import { Box, Container } from '@mui/material'
import { CreateHocLazy } from '@lib/ReduxBase'
import { QuestionPreviews } from '@shared/Pages/Submission'
import UIStyleds from '@shared/UIStyleds'
import LayoutModalRoute from '@lib/Layout/ModalRoute'
import { ISubmissionDetailProps } from './redux.types'
import { mapDispatchToProps, mapStateToProps } from './redux.map'

interface IProps extends ISubmissionDetailProps {}
export default class ViewBase extends Component<IProps> {
  render() {
    const data = this.props.slice.submission
    return (
      <UIStyleds.WrapScroll sx={{ mx: '-10px' }}>
        <Container maxWidth={false} sx={{ mt: '24px' }}>
          <QuestionPreviews.AboutMedia data={data ?? {}} />
          <QuestionPreviews.AboutYourself data={data ?? {}} />
          <QuestionPreviews.CopyrightOwnership data={data ?? {}} />
          {/* <QuestionPreviews.DigitalSignature data={data ?? {}} /> */}
        </Container>
        <Box sx={{ height: '10rem' }} />
      </UIStyleds.WrapScroll>
    )
  }
}

export { fetchSubmissionDetailThunk } from './redux.thunks'

export { default as SubmissionDetailSlice } from './redux.slice'

const HocLazyInstance = CreateHocLazy(ViewBase)
export const SubmissionDetailMapRedux = connect(mapStateToProps, mapDispatchToProps)(HocLazyInstance)

export const SubmissionDetailModal: React.FC = () => (
  <LayoutModalRoute.Wrapper size='lg' title='Submission detail' back={[RouteKey.Profile, RouteKey.Submission]}>
    <SubmissionDetailMapRedux />
  </LayoutModalRoute.Wrapper>
)
