import { FormValidator, SingleRuleValidate } from '@lib/Forms'
import { ICartItemFormDTO } from './types'
import CartHelpers from './helpers'

export const GetFormValidate = () => {
  return new FormValidator<Partial<ICartItemFormDTO>>({
    PasteSingleLink: {
      Rules: [
        {
          rule: SingleRuleValidate.Custom,
          message: 'Please add at least 1 link',
          Value: (value: string, model: ICartItemFormDTO) => {
            const list = CartHelpers.linksToArray(model.PasteLinks)
            return !value && list.length <= 0
          }
        },
        {
          rule: SingleRuleValidate.Custom,
          message: 'Links cannot be duplicated',
          Value: (value: string, model: ICartItemFormDTO) => {
            if (!value) return false
            return CartHelpers.isDuplicatedLink(model.PasteLinks, value)
          }
        },
        {
          rule: SingleRuleValidate.Custom,
          message: 'Link invalid',
          Value: (value: string) => {
            if (!value) return false
            const arr = CartHelpers.linksToArray(value)
            return arr.length < 1
          }
        }
      ]
    }
  })
}
