import React, { Component } from 'react'
import { ICart } from '@shared/Types'
import { CartItemContext } from './cart-item.context'
import { GetFormValidate } from './cart-item.validate'
import { ICartItemContext, ICartItemFormDTO } from './types'
import { ConvertFormDataToJson, FormValidator, PartialError, SingleRuleValidate } from '@lib/Forms'
import CartHelpers from './helpers'
import UICartItemContent from './ui.cart-item.content'

interface IProps {
  onDelete?: (value: ICart) => void
  onChange?: (value: ICart) => void
}

interface ICartItemProps extends IProps {
  data: ICart
}

class CartItem extends Component<ICartItemProps, ICartItemContext> {
  private formValidate: FormValidator<Partial<ICartItemFormDTO>>
  constructor(props: ICartItemProps) {
    super(props)
    this.formValidate = GetFormValidate()
    this.state = {
      onBlur: this.handleBlurPasteLink,
      onAdd: this.handleAddLink,
      onClearLink: this.handleClearLink,
      onDelete: this.handleDeleteLink,
      MessageError: {}
    }
  }

  refForm: HTMLFormElement | null = null
  render() {
    return (
      <form ref={(ref) => (this.refForm = ref)}>
        <CartItemContext.Provider value={this.state}>
          <UICartItemContent {...this.props} />
        </CartItemContext.Provider>
      </form>
    )
  }

  validate = () => {
    if (!this.refForm) return false
    const formData = new FormData(this.refForm)
    const data = ConvertFormDataToJson(formData)
    const messageError = this.formValidate.run(data)
    this.setState({ MessageError: messageError })
    return Object.keys(messageError).length < 1
  }

  handleBlurPasteLink = () => {
    if (!this.refForm) return
    const formData = new FormData(this.refForm)
    const data = ConvertFormDataToJson<ICartItemFormDTO>(formData)
    const messageError: PartialError<ICartItemFormDTO> = this.formValidate.run(data)
    this.setState({ modelState: data, MessageError: messageError })
  }

  handleDeleteLink = (value: string) => {
    let linksOld = this.props.data.JsonContent?.Links ?? []
    const links = CartHelpers.removeLink(linksOld, value)
    const cart: ICart = CartHelpers.updateLinkJsonContentCart(this.props.data, links)
    this.props.onChange && this.props.onChange(cart)
    this.setState({ modelState: { ...this.state.modelState, PasteLinks: CartHelpers.linksToString(links) } })
  }

  handleClearLink = () => {
    const cart: ICart = CartHelpers.updateLinkJsonContentCart(this.props.data, [])
    this.props.onChange && this.props.onChange(cart)
    this.setState({ modelState: { ...this.state.modelState, PasteLinks: CartHelpers.linksToString([]) } })
  }

  handleAddLink = () => {
    const formValidate = this.validateForm()
    if (!formValidate) return
    const { formData, messageError } = formValidate

    if (messageError.PasteSingleLink && messageError.PasteSingleLink.length > 0) {
      this.setState({ MessageError: messageError })
      return
    }

    const list = this.props.data.JsonContent?.Links ?? []
    const singleLinks = CartHelpers.linksToArray(formData.PasteSingleLink)
    const links = CartHelpers.joinLink(list, singleLinks)

    if (links.length + singleLinks.length > CartHelpers.maxLink) {
      messageError['PasteSingleLink'] = [{ rule: SingleRuleValidate.Custom, message: CartHelpers.messageTexts.maxLink }]
    }

    const cart: ICart = CartHelpers.updateLinkJsonContentCart(this.props.data, links.slice(0, CartHelpers.maxLink))

    formData.PasteLinks = CartHelpers.linksToString(links.slice(0, CartHelpers.maxLink))
    this.props.onChange && this.props.onChange(cart)
    this.setState({ modelState: formData, MessageError: messageError })
  }

  private getFormData = () => {
    if (!this.refForm) return
    const fData = new FormData(this.refForm)
    return ConvertFormDataToJson<ICartItemFormDTO>(fData)
  }

  private validateForm = () => {
    const formData = this.getFormData()
    if (!formData) return
    const messageError: PartialError<ICartItemFormDTO> = this.formValidate.run(formData)
    return { formData, messageError }
  }
}

export default CartItem
