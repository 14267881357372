import { IBilling } from '@shared/Types'
import { ELazyStatus } from '@lib/ReduxBase'
import { ReduxBaseTable } from '@lib/ReduxBase'
import { AppDispatch, RootState } from '@internal/redux'
import { fetchBillingThunk } from './redux.thunks'
import { IReduxMapDispatch, IReduxMapState } from './redux.types'
import BillingSlice, { DefaultSlice } from './redux.slice'

export const mapStateToProps = (state: RootState): IReduxMapState => ({
  status: ELazyStatus.Loading,
  slice: state.BillingSlice,
  tableInfo: state.BillingSlice.tableInfo
})

type TRequest = { abort: () => void }
const FetchAccessory: { request?: TRequest } = {}
export const mapDispatchToProps = (dispatch: AppDispatch): IReduxMapDispatch => ({
  fetchData: () => {
    const tableInfoQueryParam = ReduxBaseTable.getParam<IBilling>(DefaultSlice.initialState.tableInfo)
    return dispatch(fetchBillingThunk({ tableInfoQueryParam }))
  },
  onChangeStatus: (status) => {
    dispatch(BillingSlice.actions.onChangeStatus(status))
  },
  onChangeTableInfo: (key, value, details) => {
    if (key === 'FilterModel') {
      dispatch(BillingSlice.actions.onChangeTableInfo({ key, value, details }))
    }
    FetchAccessory.request?.abort()
    FetchAccessory.request = dispatch(fetchBillingThunk({ tableInfoChange: { key, value: value, details } }))
  }
})
