import './ui.style.css'
import React, { FC } from 'react'
import { connect } from 'react-redux'
import { RouteKey } from '@internal/route'
import { RootState } from '@internal/redux'
import { UIImage } from '@shared/UIStyleds'
import { ELazyStatus } from '@lib/ReduxBase'
import { LoadingAppState } from '@internal/redux/Slices'
import { AvatarButton, LoginMenu } from '@lib/ApiAuthorization'
import { Box, Container, Divider, Fade, LinearProgress, styled } from '@mui/material'
import { UIWrapLogo } from './uis'
import { CartBadgeMapRedux } from '../../Views/Cart'
import { UITabMediaTypeMapRedux } from '../../Views/Explore'
import { NotificationMapRedux } from '../../Views/Notification'
import AppConfig from '../AppConfig'
import AppHeaderNav from './app.header.nav'
import UIMobileMenu from './ui.mobile.menu'

interface IProps extends LoadingAppState {}

const ViewBase: FC<IProps> = (props) => (
  <React.Fragment>
    <Wrap>
      <Box className='section-header'>
        <WrapContainer maxWidth={false}>
          <UIWrapLogo href='/' sx={{ margin: '-6px 0 -20px' }}>
            <UIImage src='logo' />
          </UIWrapLogo>
          <Box sx={{ flex: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', gap: '6px' }}>
            <AppHeaderNav data={AppConfig.Header.navBefore} />
            <UITabMediaTypeMapRedux />
            <AppHeaderNav data={AppConfig.Header.navAfter} />
          </Box>
          <Box sx={{ width: { xs: '0', xl: '50px' } }} />
          <NotificationMapRedux />
          <CartBadgeMapRedux />
          <Divider orientation='vertical' sx={{ height: '24px', borderColor: 'var(--color-text-light)', mx: { xs: '9px', md: '25px' } }} />
          <LoginMenu
            AvatarButton={(p) => {
              const { profilePath, ...other } = p
              return <AvatarButton {...other} profilePath={RouteKey.Profile} />
            }}
          />
          <UIMobileMenu />
        </WrapContainer>
      </Box>
    </Wrap>
    <Fade in={props.Status === ELazyStatus.Loading}>
      <WrapLoading>
        <LinearProgress />
      </WrapLoading>
    </Fade>
  </React.Fragment>
)

const mapStateToProps = (state: RootState): LoadingAppState => ({
  Status: state.AppStatusSlice.Status
})

const AppHeader = connect(mapStateToProps)(ViewBase)

export default AppHeader

const Wrap = styled('header')(({ theme }) => ({
  '& > .section-header': {
    zIndex: theme.app.zIndex.header,
    height: 'var(--height-header)',
    backgroundColor: theme.app.color.black2,
    color: '#fff',
    position: 'fixed',
    top: 0,
    width: '100%'
  },
  '&::before': {
    content: '""',
    display: 'block',
    height: 'var(--height-header)',
    width: '100%'
  }
}))

const WrapContainer = styled(Container)(({ theme }) => ({
  maxWidth: theme.app.container.maxWidth,
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  [theme.breakpoints.up('xl')]: {
    padding: '0!important'
  }
}))

const WrapLoading = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  zIndex: theme.app.zIndex.infinity
}))
