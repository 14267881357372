import { Box, styled } from '@mui/material'
import React, { FC } from 'react'
import ReactPlayer from 'react-player/youtube'

interface IProps {
  url?: string
  defaultUrl?: string
  ytVideoId?: string
}

const UIMediaPlayer: FC<IProps> = (props) => {
  let url = props.url || props.defaultUrl || ''
  if (!!props.ytVideoId) {
    url = `https://www.youtube.com/watch?v=${props.ytVideoId}`
  }
  return (
    <Wrap>
      <ReactPlayer url={url} width='100%' height='100%' controls={true} />
    </Wrap>
  )
}

export default UIMediaPlayer

const Wrap = styled(Box)({
  position: 'relative',
  paddingBottom: '56.25%',
  width: '100%',
  borderRadius: '8px',
  overflow: 'hidden',
  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
  '& > div': {
    position: 'absolute',
    top: '0',
    left: '0'
  }
})
