import { Box, BoxProps, Container, styled } from '@mui/material'

export const UIWrap = styled(({ children, ...props }: BoxProps) => (
  <Box {...props}>
    <Container maxWidth={false}>{children}</Container>
  </Box>
))(({ theme }) => ({
  minHeight: 'calc(100vh - var(--height-header))',
  padding: '24px 0 0',
  marginBottom: '24px',
  background: '#fafafa',
  '& .MuiContainer-root': {
    maxWidth: theme.breakpoints.values.md,
    height: '100%',
    background: '#ffffff',
    borderRadius: '6px',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
  },
  [theme.breakpoints.up('xl')]: {
    '& .MuiContainer-root': {
      padding: '0!important'
    }
  }
}))
