/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppDispatch, RootState } from '@internal/redux'
import { QuicklyAddToCart } from '../Cart'
import { fetchMediaDetailThunk } from './redux.thunks'
import { IReduxMapDispatch, IReduxMapState } from './redux.types'
import MediaDetailSlice from './redux.slice'

export const mapStateToProps = (state: RootState): IReduxMapState => ({
  status: state.MediaDetailSlice.status,
  Slice: state.MediaDetailSlice
})

export const mapDispatchToProps = (dispatch: AppDispatch): IReduxMapDispatch => ({
  fetchData: () => {
    // return dispatch(fetchMediaDetailThunk(value))
    return { abort: () => {} }
  },
  onChangeStatus: (status) => {
    dispatch(MediaDetailSlice.actions.onChangeStatus(status))
  },
  fetchRefresh: () => {
    dispatch(fetchMediaDetailThunk())
  },
  addToCart: (media, price) => {
    QuicklyAddToCart(dispatch, media, price)
  }
})
