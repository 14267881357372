import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { RouteKey } from '@internal/route'
import { EMediaType } from '@shared/Types'
import { FormatDatetime2 } from '@lib/Helpers'
import { CreateRoutePath } from '@lib/RouteBase'
import { IMediaDetailQueryParam } from '@shared/Services/QueryParamService'
import { Box, IconButton, Tooltip, Typography, styled } from '@mui/material'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'
import { IProductProps } from './ui.product.types'
import UIYoutubeThumbnail from './ui.youtube-thumbnail'

interface IProps extends IProductProps {}

const UIProductVideo: FC<IProps> = (props) => {
  const componentLink = () => {
    const path = CreateRoutePath<IMediaDetailQueryParam>(RouteKey.Detail, {
      query: { type: EMediaType.Video, id: props.data.Id }
    })
    return { component: Link, to: path, target: props.target || '_self' }
  }

  return (
    <Wrap>
      <Box sx={{ position: 'relative' }}>
        <ImageWrap>
          <UIYoutubeThumbnail resourceId={props.data.Id} size='hqdefault' />
        </ImageWrap>
        <BoxHover className='box-horver' {...componentLink()} />
        <ControlPopsition className='box-icon'>
          <Tooltip title='Add to cart' placement='left'>
            <IconButton onClick={props.onAddToCart}>
              <AddShoppingCartIcon fontSize='small' sx={{ color: '#0ED197' }} />
            </IconButton>
          </Tooltip>
        </ControlPopsition>
      </Box>
      <Box sx={{ p: '12px' }}>
        <TypographyCustom variant='subtitle1'>{props.data.Title}</TypographyCustom>
        <Box sx={{ display: 'flex', alignItems: 'center', mt: '6px' }}>
          <Typography variant='body2' noWrap sx={{ color: 'var(--color-gray-2)' }}>
            {FormatDatetime2(props.data.DateCreated ?? '', 'MMMM D, YYYY')}
          </Typography>
          <Box flex={1} />
        </Box>
      </Box>
    </Wrap>
  )
}

export default UIProductVideo

const Wrap = styled(Box)(({ theme }) => ({
  maxWidth: '400px',
  borderRadius: '6px',
  backgroundColor: '#fff',
  overflow: 'hidden',
  boxShadow: 'rgba(17, 17, 26, 0.1) 0px 0px 16px',
  transition: 'all linear 0.3s',
  '&:hover .box-horver, &:hover .box-icon': { opacity: 1 },
  [theme.breakpoints.down('md')]: { '& .box-horver, & .box-icon': { opacity: 1 } },
  '&:hover .box-background': { transform: 'translate(-50%, -50%) scale(1.1)' }
}))

const ImageWrap = styled(Box)(({ theme }) => ({
  position: 'relative',
  paddingBottom: theme.app.imageAspectRatio,
  '& > img': {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    objectFit: 'cover'
  }
}))

const BoxHover = styled(Box)({
  cursor: 'pointer',
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  transition: 'all linear 0.3s',
  opacity: 0,
  borderRadius: '4px',
  background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.5) 100%)'
})

const ControlPopsition = styled('div')({
  borderRadius: '8px',
  backgroundColor: '#fff',
  boxShadow: '0 2px 14px rgba(0,0,0,0.23)',
  position: 'absolute',
  top: '12px',
  right: '12px',
  transition: 'all linear 0.3s',
  opacity: 0
})

const TypographyCustom = styled(Typography)({
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  textOverflow: 'ellipsis',
  lineHeight: '1.45em',
  minHeight: 'calc(1.45em * 2)',
  color: 'var(--color-body)'
})
