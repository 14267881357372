import React, { Component, Fragment } from 'react'
import { CreateInputText } from '@lib/Forms/Inputs'
import { Box, Button, Fade, Grid, GridOwnProps, styled, Typography } from '@mui/material'
import { Collapse, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'
import { CreateFormBase, getErrorMessage, IFormBase, IFormInputBase, FormValidator } from '@lib/Forms'
import { IAnswer, IQuestion, IQuestionSub } from './create.set-of-question.types'

interface ISubmitMappingParams<TModel> {
  value: Partial<TModel>
  configs?: IQuestion<TModel>[]
  oldValue?: TModel
}
type TSubmitMapping<TModel> = (params: ISubmitMappingParams<TModel>) => Partial<TModel>

interface ISlots<TModel> {
  gridOwnProps?: GridOwnProps
  actions?: React.ComponentType<IFormBase<TModel>>
}

// ========= ========= ========= Main ========= ========= =========
interface ISetOfQuestionParam<TModel> extends ISlots<TModel> {
  configs: IQuestion<TModel>[]
  validate: FormValidator<Partial<TModel>>
  submitMapping?: TSubmitMapping<TModel>
}

const CreateSetOfQuestion = function <TModel>(param: ISetOfQuestionParam<TModel>) {
  const InputTextComponent = CreateInputText<TModel>({ options: { maxLength: 250 } })

  // ========= ========= ========= Question Section ========= ========= =========
  interface IQuestionSectionProps extends Omit<IFormInputBase<TModel>, 'name'> {
    question: IQuestion<TModel>
    options?: ISlots<TModel>
  }
  interface IQuestionSectionState {
    value: string
  }
  class QuestionSection extends Component<IQuestionSectionProps, IQuestionSectionState> {
    constructor(props: IQuestionSectionProps) {
      super(props)
      this.state = { value: this.props.data?.[this.props.question.key]?.toString() ?? '' }
    }
    render() {
      return (
        <WrapQuestion>
          {this.renderQuestion(this.props.question)}
          {this.renderQuestionSub(this.props.question.subQuestions)}
        </WrapQuestion>
      )
    }
    renderQuestion = (qParams: IQuestion<TModel>) => {
      if (!!qParams?.inputComponent) {
        const QuestionElement = qParams.inputComponent
        const { question, options: slots, ...otherProps } = this.props
        return <QuestionElement name={question.key} {...otherProps} />
      }
      const { data: Model, messageErrors } = this.props
      const eMessage = getErrorMessage(messageErrors, qParams.key)
      const id = (qParams.key.toString() + 'label').toLocaleLowerCase().replaceAll(' ', '')
      const defaultValue = Model ? Model[qParams.key] ?? '' : ''
      return (
        <FormControl fullWidth error={eMessage.error} color={eMessage.error ? 'error' : undefined}>
          <FormLabel id={id}>{qParams.question}</FormLabel>
          <CustomRadioGroup
            row
            aria-labelledby={id}
            defaultValue={defaultValue}
            value={this.state.value}
            name={qParams.key?.toString()}
            onChange={this.handleChange}
          >
            {this.renderAnwers(qParams.answers)}
          </CustomRadioGroup>
        </FormControl>
      )
    }
    renderAnwers = (answers: IAnswer[]) => {
      return answers.map((answer, index) => {
        const label: string = answer.label ?? answer.key.toString()
        return (
          <FormControlLabel
            key={index}
            value={answer.key}
            control={<Radio />}
            label={
              <CustomLabel variant='subtitle2' {...{ component: 'span' }}>
                {label}
              </CustomLabel>
            }
          />
        )
      })
    }
    renderQuestionSub = (qSub?: IQuestionSub<TModel>) => {
      if (!qSub) return <></>
      const { options: slots, question, ...otherProps } = this.props
      let InputComponent = qSub.inputComponent ?? InputTextComponent
      const aItem = this.getAnswerActivated(this.state.value)
      return (
        <Collapse in={!!aItem && !aItem.disabledSub} unmountOnExit>
          <Box sx={{ m: '12px 9px 0' }}>
            <Fade in={!!qSub.question}>
              <Typography variant='subtitle2' sx={{ mb: '12px' }}>
                {qSub.question}
              </Typography>
            </Fade>
            <InputComponent name={qSub.key} label={qSub.label} placeholder={qSub.placeholder} {...otherProps} />
          </Box>
        </Collapse>
      )
    }
    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ value: (event.target as HTMLInputElement).value }, () => {
        this.props.onBlur && this.props.onBlur(this.props.question.key)
      })
    }
    getAnswerActivated = (key: string): IAnswer<TModel> | undefined => {
      return this.props.question.answers.find((x) => x.key === key)
    }
  }

  // ========= ========= ========= Set of Question ========= ========= =========
  const FormBaseInstance = CreateFormBase<TModel>()
  interface SetOfQuestionProps {
    data?: TModel
    onSubmit: (value: Partial<TModel>) => Promise<void>
    slots?: ISlots<TModel>
  }
  class SetOfQuestion extends Component<SetOfQuestionProps> {
    render() {
      const Actions = this.props.slots?.actions ?? InsideActions
      return (
        <FormBaseInstance.Form validate={param.validate} onSubmit={this.onSubmit}>
          {FormBaseInstance.ContextMapping((context) => (
            <>
              <Grid container spacing={2} {...this.props.slots?.gridOwnProps}>
                {param.configs.map((config, index) => (
                  <Fragment key={index}>
                    <Grid item xs={12}>
                      <QuestionSection data={this.props.data} onBlur={context.onBlur} messageErrors={context.messageErrors} question={config} />
                    </Grid>
                  </Fragment>
                ))}
              </Grid>
              <Actions {...context} />
            </>
          ))}
        </FormBaseInstance.Form>
      )
    }
    onSubmit = async (value: Partial<TModel>) => {
      const mapping = param.submitMapping ?? this.submitMapping
      return this.props.onSubmit(mapping({ value, oldValue: this.props.data, configs: param.configs }))
    }
    submitMapping: TSubmitMapping<TModel> = (smp) => {
      return Object.assign({}, smp?.oldValue, smp.value) as TModel
    }
  }
  return SetOfQuestion
}
export default CreateSetOfQuestion
export type SetOfQuestionType<TModel> = InstanceType<ReturnType<typeof CreateSetOfQuestion<TModel>>>

class InsideActions extends Component {
  render() {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', p: '9px 12px' }}>
        <Button variant='contained' type='submit'>
          Submit
        </Button>
      </Box>
    )
  }
}

const CustomRadioGroup = styled(RadioGroup)({
  border: '1px solid transparent',
  borderRadius: '6px',
  padding: '6px 0',
  marginTop: '12px',
  '&.Mui-error': {
    border: '1px solid #d32f2f'
  },
  '& .MuiFormControlLabel-root': {
    width: 'calc((100% / 3) - 12px)',
    backgroundColor: '#fafafa',
    borderRadius: '6px',
    margin: '6px',
    transition: '150ms cubic-bezier(0.4, 0, 0.2, 1) 50ms'
  },
  '& .MuiFormControlLabel-root:hover': {
    backgroundColor: '#f0f0f0'
  }
})

const CustomLabel = styled(Typography)({
  display: '-webkit-box',
  overflow: 'hidden',
  WebkitLineClamp: '3',
  WebkitBoxOrient: 'vertical',
  textOverflow: 'ellipsis',
  whiteSpace: 'normal'
})

const WrapQuestion = styled(Box)({
  marginTop: '48px',
  '& .MuiFormControl-root': {
    display: 'block'
  },
  '& .MuiFormLabel-root': {
    fontWeight: 600,
    color: '#606060!important'
  },
  '& .MuiFormControlLabel-root .MuiFormControlLabel-label': {
    display: 'flex',
    alignItems: 'center',
    padding: '6px 12px 6px 0',
    minHeight: '48px'
  }
})

// ========= ========= ========= Example ========= ========= =========
// interface IData {
//   IsChild: boolean
//   ChildInfos?: string
// }

// export const SetOfQuestionExample: React.FC = () => {
//   const SOQComponent = CreateSetOfQuestion<IData>({
//     configs: [
//       {
//         question: 'Are There People Appearing In The Video?',
//         answer: {
//           key: 'IsChild',
//           items: [
//             { label: 'Yes', value: true, subQuestionActivated: true },
//             { label: 'No', value: false }
//           ]
//         },
//         subQuestion: { key: 'ChildInfos', title: 'Who Are They?', label: 'Please mention them all' }
//       }
//     ],
//     validate: new FormValidator({
//       IsChild: { Rules: [{ rule: SingleRuleValidate.Required }] }
//     })
//   })
//   return (
//     <SOQComponent
//       onSubmit={async (value) => {
//         console.log(value)
//       }}
//     />
//   )
// }
