import { ReduxBase } from '@lib/ReduxBase'
import { ELazyStatus } from '@lib/ReduxBase'
import { createSlice } from '@reduxjs/toolkit'
import { IOrderDetailSliceState } from './redux.types'
import { fetchOrderDetailThunk } from './redux.thunks'

export const DefaultSlice = ReduxBase.GetInitialSlice<IOrderDetailSliceState>({ state: {} })

const OrderDetailSlice = createSlice({
  name: 'OrderDetailSlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState: DefaultSlice.initialState,
  reducers: { ...DefaultSlice.reducers },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrderDetailThunk.fulfilled, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.status = ELazyStatus.Loaded
        state.dataDetail = action.payload.dataDetail
      })
      .addCase(fetchOrderDetailThunk.rejected, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.status = ELazyStatus.Error
      })
      .addCase(fetchOrderDetailThunk.pending, (state, action) => {
        state.requestedId = action.meta.requestId
        state.status = ELazyStatus.Loading
        state.dataDetail = undefined
      })
  }
})

export default OrderDetailSlice
