import type { FieldOptions, DirectiveArgs } from 'graphql-ts-client-api';
import { ENUM_INPUT_METADATA } from '../EnumInputMetadata';
import type { ObjectFetcher } from 'graphql-ts-client-api';
import { createFetcher, createFetchableType } from 'graphql-ts-client-api';
import type { WithTypeName, ImplementationType } from '../CommonTypes';
import type {EMediaDelivery} from '../enums';
import type {EDeliveryMediaType} from '../enums';
import type {EMediaDeliveryType} from '../enums';
import type {EMediaDeliveryStatus} from '../enums';

/*
 * Any instance of this interface is immutable,
 * all the properties and functions can only be used to create new instances,
 * they cannot modify the current instance.
 * 
 * So any instance of this interface is reuseable.
 */
export interface MediaDeliveryFetcher<T extends object, TVariables extends object> extends ObjectFetcher<'MediaDelivery', T, TVariables> {

    on<XName extends ImplementationType<'MediaDelivery'>, X extends object, XVariables extends object>(
        child: ObjectFetcher<XName, X, XVariables>, 
        fragmentName?: string // undefined: inline fragment; otherwise, otherwise, real fragment
    ): MediaDeliveryFetcher<
        XName extends 'MediaDelivery' ?
        T & X :
        WithTypeName<T, ImplementationType<'MediaDelivery'>> & (
            WithTypeName<X, ImplementationType<XName>> | 
            {__typename: Exclude<ImplementationType<'MediaDelivery'>, ImplementationType<XName>>}
        ), 
        TVariables & XVariables
    >;


    directive(name: string, args?: DirectiveArgs): MediaDeliveryFetcher<T, TVariables>;


    readonly __typename: MediaDeliveryFetcher<T & {__typename: ImplementationType<'MediaDelivery'>}, TVariables>;


    readonly id: MediaDeliveryFetcher<T & {readonly "id": string}, TVariables>;

    "id+"<
        XAlias extends string = "id", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"id", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): MediaDeliveryFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~id": MediaDeliveryFetcher<Omit<T, 'id'>, TVariables>;


    readonly name: MediaDeliveryFetcher<T & {readonly "name"?: string}, TVariables>;

    "name+"<
        XAlias extends string = "name", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"name", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaDeliveryFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~name": MediaDeliveryFetcher<Omit<T, 'name'>, TVariables>;


    readonly type: MediaDeliveryFetcher<T & {readonly "type": EMediaDelivery}, TVariables>;

    "type+"<
        XAlias extends string = "type", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"type", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): MediaDeliveryFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: EMediaDelivery} : 
                {readonly [key in XAlias]: EMediaDelivery}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~type": MediaDeliveryFetcher<Omit<T, 'type'>, TVariables>;


    readonly mediaType: MediaDeliveryFetcher<T & {readonly "mediaType": EDeliveryMediaType}, TVariables>;

    "mediaType+"<
        XAlias extends string = "mediaType", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"mediaType", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): MediaDeliveryFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: EDeliveryMediaType} : 
                {readonly [key in XAlias]: EDeliveryMediaType}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~mediaType": MediaDeliveryFetcher<Omit<T, 'mediaType'>, TVariables>;


    readonly userId: MediaDeliveryFetcher<T & {readonly "userId"?: string}, TVariables>;

    "userId+"<
        XAlias extends string = "userId", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"userId", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaDeliveryFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~userId": MediaDeliveryFetcher<Omit<T, 'userId'>, TVariables>;


    readonly resourceId: MediaDeliveryFetcher<T & {readonly "resourceId"?: string}, TVariables>;

    "resourceId+"<
        XAlias extends string = "resourceId", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"resourceId", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaDeliveryFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~resourceId": MediaDeliveryFetcher<Omit<T, 'resourceId'>, TVariables>;


    readonly creatorId: MediaDeliveryFetcher<T & {readonly "creatorId"?: string}, TVariables>;

    "creatorId+"<
        XAlias extends string = "creatorId", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"creatorId", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaDeliveryFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~creatorId": MediaDeliveryFetcher<Omit<T, 'creatorId'>, TVariables>;


    readonly description: MediaDeliveryFetcher<T & {readonly "description"?: string}, TVariables>;

    "description+"<
        XAlias extends string = "description", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"description", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaDeliveryFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~description": MediaDeliveryFetcher<Omit<T, 'description'>, TVariables>;


    readonly contentType: MediaDeliveryFetcher<T & {readonly "contentType": EMediaDeliveryType}, TVariables>;

    "contentType+"<
        XAlias extends string = "contentType", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"contentType", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): MediaDeliveryFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: EMediaDeliveryType} : 
                {readonly [key in XAlias]: EMediaDeliveryType}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~contentType": MediaDeliveryFetcher<Omit<T, 'contentType'>, TVariables>;


    readonly content: MediaDeliveryFetcher<T & {readonly "content"?: string}, TVariables>;

    "content+"<
        XAlias extends string = "content", 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"content", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaDeliveryFetcher<
        T & {readonly [key in XAlias]?: string}, 
        TVariables & XDirectiveVariables
    >;

    readonly "~content": MediaDeliveryFetcher<Omit<T, 'content'>, TVariables>;


    readonly status: MediaDeliveryFetcher<T & {readonly "status": EMediaDeliveryStatus}, TVariables>;

    "status+"<
        XAlias extends string = "status", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"status", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): MediaDeliveryFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: EMediaDeliveryStatus} : 
                {readonly [key in XAlias]: EMediaDeliveryStatus}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~status": MediaDeliveryFetcher<Omit<T, 'status'>, TVariables>;


    readonly dateCreated: MediaDeliveryFetcher<T & {readonly "dateCreated": string}, TVariables>;

    "dateCreated+"<
        XAlias extends string = "dateCreated", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"dateCreated", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): MediaDeliveryFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~dateCreated": MediaDeliveryFetcher<Omit<T, 'dateCreated'>, TVariables>;


    readonly dateUpdated: MediaDeliveryFetcher<T & {readonly "dateUpdated": string}, TVariables>;

    "dateUpdated+"<
        XAlias extends string = "dateUpdated", 
        XDirectives extends { readonly [key: string]: DirectiveArgs } = {}, 
        XDirectiveVariables extends object = {}
    >(
        optionsConfigurer: (
            options: FieldOptions<"dateUpdated", {}, {}>
        ) => FieldOptions<XAlias, XDirectives, XDirectiveVariables>
    ): MediaDeliveryFetcher<
        T & (
            XDirectives extends { readonly include: any } | { readonly skip: any } ? 
                {readonly [key in XAlias]?: string} : 
                {readonly [key in XAlias]: string}
        ), 
        TVariables & XDirectiveVariables
    >;

    readonly "~dateUpdated": MediaDeliveryFetcher<Omit<T, 'dateUpdated'>, TVariables>;


    creator<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'ApplicationUser', X, XVariables>
    ): MediaDeliveryFetcher<
        T & {readonly "creator"?: X}, 
        TVariables & XVariables
    >;

    creator<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "creator", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'ApplicationUser', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"creator", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaDeliveryFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & XDirectiveVariables
    >;


    submission<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'Submission', X, XVariables>
    ): MediaDeliveryFetcher<
        T & {readonly "submission"?: X}, 
        TVariables & XVariables
    >;

    submission<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "submission", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'Submission', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"submission", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaDeliveryFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & XDirectiveVariables
    >;
}

export const mediaDelivery$: MediaDeliveryFetcher<{}, {}> = 
    createFetcher(
        createFetchableType(
            "MediaDelivery", 
            "OBJECT", 
            [], 
            [
                {
                    category: "ID", 
                    name: "id"
                }, 
                {
                    category: "SCALAR", 
                    name: "name", 
                    undefinable: true
                }, 
                "type", 
                "mediaType", 
                {
                    category: "SCALAR", 
                    name: "userId", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "resourceId", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "creatorId", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "description", 
                    undefinable: true
                }, 
                "contentType", 
                {
                    category: "SCALAR", 
                    name: "content", 
                    undefinable: true
                }, 
                "status", 
                "dateCreated", 
                "dateUpdated", 
                {
                    category: "REFERENCE", 
                    name: "creator", 
                    targetTypeName: "ApplicationUser", 
                    undefinable: true
                }, 
                {
                    category: "REFERENCE", 
                    name: "submission", 
                    targetTypeName: "Submission", 
                    undefinable: true
                }
            ]
        ), 
        ENUM_INPUT_METADATA, 
        undefined
    )
;

export const mediaDelivery$$ = 
    mediaDelivery$
        .id
        .name
        .type
        .mediaType
        .userId
        .resourceId
        .creatorId
        .description
        .contentType
        .content
        .status
        .dateCreated
        .dateUpdated
;
