import { createRequestBuilder } from '../../Requests/RequestParam'
import { category$$, categoryPage$ } from '../../__generated/media-store/fetchers'
import { TYtVideosFilterParam, TCategoriesFilterParam, TPricePackage } from './types'
import { query$, mediaStoreGraphDbContext$ } from '../../__generated/media-store/fetchers'
import { youtubeVideo$$, youtubeVideoPage$ } from '../../__generated/media-store/fetchers'
import { pricePackage$$, pricePackagePage$ } from '../../__generated/media-store/fetchers'

const CategorySelector = category$$
const YoutubeVideoSelector = youtubeVideo$$
const PricePackageSelector = pricePackage$$

export const ExploreInitial = (ytVideosParam: TYtVideosFilterParam, categoriesParam: TCategoriesFilterParam) => {
  const YoutubeVideoPage = youtubeVideoPage$.items(YoutubeVideoSelector).hasNextPage.hasPreviousPage.totalItems
  const CategoryPage = categoryPage$.items(CategorySelector).hasNextPage.hasPreviousPage.totalItems
  const PricePackagePage = pricePackagePage$.items(pricePackage$$).hasNextPage.hasPreviousPage.totalItems
  const pricePackageFilter = createRequestBuilder<TPricePackage>({ ignoreEmpty: true })
    .filterEnum('type', 'Media')
    .sort('price', { direction: 'ASC' })
  return query$.mediaStore(
    mediaStoreGraphDbContext$
      .youtubeVideos(ytVideosParam, YoutubeVideoPage)
      .categories(categoriesParam, CategoryPage)
      .pricePackages(pricePackageFilter.build(), PricePackagePage)
  )
}

export const Explore = (ytVideosParam: TYtVideosFilterParam, categoriesParam: TCategoriesFilterParam) => {
  const YoutubeVideoPage = youtubeVideoPage$.items(YoutubeVideoSelector).hasNextPage.hasPreviousPage.totalItems
  const CategoryPage = categoryPage$.items(CategorySelector).hasNextPage.hasPreviousPage.totalItems
  return query$.mediaStore(
    mediaStoreGraphDbContext$.youtubeVideos(ytVideosParam, YoutubeVideoPage).categories(categoriesParam, CategoryPage)
  )
}

interface IProductDetailParam {
  resourceId: string
  categories: TCategoriesFilterParam
  ytVideos: TYtVideosFilterParam
}

export const ProductDetailById = (param: IProductDetailParam) => {
  const YoutubeVideoPage = youtubeVideoPage$.items(YoutubeVideoSelector).hasNextPage.hasPreviousPage.totalItems
  const PricePackagePage = pricePackagePage$.items(PricePackageSelector).hasNextPage.hasPreviousPage.totalItems
  const CategoryPage = categoryPage$.items(CategorySelector).hasNextPage.hasPreviousPage.totalItems
  const pricePackageFilter = createRequestBuilder<TPricePackage>({ ignoreEmpty: true })
    .filterEnum('type', 'Media')
    .sort('price', { direction: 'ASC' })
  return query$.mediaStore(
    mediaStoreGraphDbContext$
      .youtubeVideo({ id: param.resourceId }, YoutubeVideoSelector)
      .youtubeVideos(param.ytVideos, YoutubeVideoPage)
      .categories(param.categories, CategoryPage)
      .pricePackages(pricePackageFilter.build(), PricePackagePage)
  )
}
