import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { ELazyStatus, IReturnDispatch } from './types'

export interface IStateBase {
  status: ELazyStatus
  requestedId?: string
}

export interface IDispatchBase<TFetchParam = any> {
  fetchData?: (param?: TFetchParam, signal?: AbortSignal) => IReturnDispatch
  onChangeStatus: (status: ELazyStatus) => void
}

namespace ReduxBase {
  // ========= ========= ========= redux.types.ts ========= ========= =========
  export interface ISliceState extends IStateBase {}

  export interface IMapState extends IStateBase {}

  export interface IMapDispatch<TFetchParam = any> extends IDispatchBase<TFetchParam> {}

  // ========= ========= ========= redux.slice.ts ========= ========= =========
  export interface IInitial<S extends IStateBase, R> {
    initialState: S
    reducers: R
  }

  export interface IReducers<S extends IStateBase> {
    onChangeStatus: CaseReducer<S, PayloadAction<ELazyStatus>>
  }

  type TInitialSliceParam<T extends ISliceState> = {
    state: Omit<T, keyof ISliceState>
  }

  export const GetInitialSlice = <TState extends ISliceState>(param: TInitialSliceParam<TState>): IInitial<TState, IReducers<TState>> => ({
    initialState: { status: ELazyStatus.Loading, ...param.state } as TState,
    reducers: {
      onChangeStatus: (state, action) => {
        state.status = action.payload
      }
    }
  })
}
export default ReduxBase
