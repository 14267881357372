import React, { Component, SyntheticEvent } from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { IFormInputBase } from '../types'
import { getErrorMessage } from '../helper'

export interface ISelectMultipleOption {
  Id: number | string
  Label: string
}

interface AutocompleteCustomProps {
  fullWidth?: boolean
  size?: 'small' | 'medium'
}

interface IOptions {}

const CreateSelectMultiple = function <TModel>(options?: IOptions) {
  interface IProps extends Partial<IFormInputBase<TModel>> {
    autocompleteProps?: AutocompleteCustomProps
    defaultValue?: ISelectMultipleOption[]
    options: ISelectMultipleOption[]
  }
  class SelectMultiple extends Component<IProps> {
    refInput: HTMLInputElement | null = null
    render() {
      const inputDefaultValue = this.props.defaultValue?.length ? JSON.stringify(this.props.defaultValue) : ''
      const errorMessage = getErrorMessage(this.props.messageErrors, this.props.name)
      return (
        <React.Fragment>
          <Autocomplete
            fullWidth
            multiple
            options={this.props.options}
            getOptionLabel={(o: ISelectMultipleOption) => o.Label}
            isOptionEqualToValue={(o, v) => o.Id === v.Id}
            defaultValue={this.props.defaultValue}
            filterSelectedOptions
            onChange={this.onChangeValue}
            renderInput={(params) => (
              <TextField
                {...params}
                label={this.props.label || 'Enter text'}
                placeholder={this.props.placeholder || 'Enter text'}
                onBlur={this.handleBlur}
                error={errorMessage.error}
                helperText={errorMessage.message}
              />
            )}
            {...this.props.autocompleteProps}
          />
          <input ref={(ref) => (this.refInput = ref)} hidden name={this.props.name?.toString()} defaultValue={inputDefaultValue} />
        </React.Fragment>
      )
    }
    handleBlur = () => {
      if (!this.props.onBlur || !this.props.name) return
      this.props.onBlur(this.props.name)
    }
    onChangeValue = (_: SyntheticEvent<Element, Event>, value: ISelectMultipleOption[]) => {
      if (!!this.refInput) {
        this.refInput.value = value?.length ? JSON.stringify(value) : ''
        this.handleBlur()
      }
    }
  }
  return SelectMultiple
}
export default CreateSelectMultiple
