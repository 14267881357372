import React, { FC } from 'react'
import { Box } from '@mui/material'
import { RouteKey } from '@internal/route'
import { MapNavigate } from '@lib/ApiContext'
import { CreateRoutePath } from '@lib/RouteBase'
import { CreateInputText } from '@lib/Forms/Inputs'
import { ISubmission, ESubmissionStep } from '@shared/Types'
import { CreateFormGridLayout, FormValidator, SingleRuleValidate } from '@lib/Forms'
import { UICustomTypographyTitle } from './uis'
import { SubmitMediaLocalStorage } from './helpers'
import UIActionsBar from './ui.actions-bar'

const InputTextComponent = CreateInputText<ISubmission>({
  options: { maxLength: 250, textFieldProps: { multiline: true, minRows: 4, maxRows: 5 } }
})

const FormGridLayoutComponent = CreateFormGridLayout<ISubmission>({
  configs: [
    {
      key: 'RepositoryUrl',
      label: 'Please copy and paste the link of your repository here'
    },
    { key: 'Title', label: 'Title Suggestion' },
    {
      key: 'KindlyDescribe',
      label: 'What happen in the your repository ?',
      inputElement: InputTextComponent
    }
  ],
  validate: new FormValidator({
    RepositoryUrl: {
      Rules: [{ rule: SingleRuleValidate.Required }, { rule: SingleRuleValidate.Url }]
    },
    Title: { Rules: [{ rule: SingleRuleValidate.Required }] },
    KindlyDescribe: { Rules: [{ rule: SingleRuleValidate.Required }] }
  })
})

const StepAboutMedia: FC = () => {
  const data = SubmitMediaLocalStorage.getData()
  return (
    <Box sx={{ marginTop: '48px' }}>
      <UICustomTypographyTitle>Let's learn more about your video</UICustomTypographyTitle>
      {MapNavigate((navigate) => (
        <FormGridLayoutComponent
          data={data?.item}
          onSubmit={async (value) => {
            SubmitMediaLocalStorage.setData(Object.assign({}, data?.item, value), ESubmissionStep.AboutMedia)
            navigate(CreateRoutePath([RouteKey.Submit, RouteKey.SMTAboutYouself]))
          }}
          slots={{
            sx: { minHeight: '50vh', display: 'flex', alignItems: 'center', justifyContent: 'stretch' },
            action: () => <UIActionsBar />
          }}
        />
      ))}
    </Box>
  )
}

export default StepAboutMedia
