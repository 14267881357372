import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { RouteKey } from '@internal/route'
import { IconSuccess } from '@lib/Component'
import { CreateRoutePath } from '@lib/RouteBase'
import { UIClientContainer, UITextLineBreak } from '@shared/UIStyleds'
import { Box, Button, ButtonProps, Stack, styled, Typography, TypographyProps } from '@mui/material'
import AppConfig from '@internal/shareds/AppConfig'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { CheckOutData } from './helpers'
import { CartShared } from '@shared/Pages'

const UIComplete: FC = () => (
  <UIClientContainer>
    <CartShared.UIStepper activeStep={2} />
    <Stack sx={{ alignItems: 'center', justifyContent: 'center', p: '20px 0 50px' }}>
      <IconSuccess />
      <Typography variant='h2' component='p' sx={{ textTransform: 'upercase', fontWeight: 600, mt: '20px' }}>
        THANK YOU!
      </Typography>
      <Typography variant='subtitle1' component='p' sx={{ textAlign: 'center', mt: '20px' }}>
        <UITextLineBreak>{CheckOutData.description}</UITextLineBreak>
      </Typography>
      <CustomButton to={CreateRoutePath([RouteKey.Profile, RouteKey.Order])} endIcon={<ArrowRightAltIcon fontSize='small' />}>
        View order confirmation
      </CustomButton>
      <LinkReadMore to={CreateRoutePath(RouteKey.PrivacyPolicy)}>Read about out privacy policy</LinkReadMore>
      <Box sx={{ display: 'flex', alignItems: 'center', mt: '50px' }}>
        <Typography variant='h6' component='span' sx={{ mr: '12px' }}>
          Let's Be Friend!
        </Typography>
        {AppConfig.Socials.map((item, i) => (
          <SocialIcon key={i} href={item.link} target='_blank'>
            {item.icon}
          </SocialIcon>
        ))}
      </Box>
    </Stack>
  </UIClientContainer>
)

export default UIComplete

const CustomButton = styled((p: ButtonProps & { to: string }) => {
  return <Button component={Link} color='inherit' variant='contained' size='large' {...p} />
})({
  backgroundColor: 'var(--color-black)',
  color: 'var(--color-text-light)',
  minWidth: '250px',
  marginTop: '24px',
  height: '56px',
  textTransform: 'none',
  fontWeight: 600,
  transition: '0.3s',
  '&:hover': {
    backgroundColor: 'var(--color-black2)',
    color: 'var(--color-text-light)'
  },
  '& .MuiButton-icon.MuiButton-endIcon': {
    transition: '0.3s'
  },
  '&:hover .MuiButton-icon.MuiButton-endIcon': {
    marginLeft: '16px'
  }
})

const LinkReadMore = styled((p: TypographyProps & { to: string }) => <Typography component={Link} {...p} />)({
  color: 'var(--color-text)',
  marginTop: '20px',
  transition: '0.3s',
  fontWeight: 600,
  '&:hover': {
    color: 'var(--color-blue)'
  }
})

const SocialIcon = styled('a')({
  textDecoration: 'none',
  cursor: 'pointer',
  padding: '5px 10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& svg': {
    transition: 'all linear 0.2s',
    color: 'var(--color-gray)'
  },
  '&:hover svg': {
    color: '#1976D2'
  }
})
