import { AppDispatch, RootState } from '@internal/redux'
import { IReduxMapDispatch, IReduxMapState } from './redux.types'
import { fetchProfileThunk, fetchProfileUpdateThunk } from './redux.thunks'
import ProfileSlice from './redux.slice'

export const mapStateToProps = (state: RootState): IReduxMapState => ({
  status: state.ProfileSlice.status,
  slice: state.ProfileSlice
})

export const mapDispatchToProps = (dispatch: AppDispatch): IReduxMapDispatch => ({
  fetchData: () => {
    return dispatch(fetchProfileThunk())
  },
  onChangeStatus: (status) => {
    dispatch(ProfileSlice.actions.onChangeStatus(status))
  },
  updateProfile: async (value) => {
    dispatch(fetchProfileUpdateThunk(value))
  }
})
