export enum ELazyStatus {
  Loading = 'Loading',
  Loaded = 'Loaded',
  Error = 'Error'
}

export interface IReturnDispatch {
  abort?: () => void
}

interface ICRUDBase<TModel, Id extends keyof TModel> {
  Create: (model: Partial<TModel>) => Promise<void>
  Update: (Id: TModel[Id], model: Partial<TModel>) => Promise<void>
  Delete: (Id: TModel[Id], model?: Partial<TModel>) => Promise<void>
}

export type ICRUDReduxMapDispatch<TModel, Id extends keyof TModel, Key extends keyof ICRUDBase<TModel, Id> = keyof ICRUDBase<TModel, Id>> = Pick<
  ICRUDBase<TModel, Id>,
  Key
>
