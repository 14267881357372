import React, { FC } from 'react'
import { Grid } from '@mui/material'
import { IPrice, IProduct } from '@shared/Types'
import { UIProduct } from '@internal/shareds/UIs'
import { FormQuicklyAddToCart } from '@internal/shareds/Views/Explore'
import { ApiPopover, IApiPopoverContext, MapApiPopoverContext } from '@lib/ApiContext'

interface IProps {
  data: IProduct[]
  prices: IPrice[]
  onSubmitAddToCart?: (product: IProduct, price: IPrice) => void
}

const UIRecommendProduct: FC<IProps> = (props) => {
  const showQuickyAddToCart = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, context: IApiPopoverContext, product: IProduct) => {
    context.showPopover({
      anchorEl: event.currentTarget,
      popoverProps: {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        transformOrigin: { vertical: 'top', horizontal: 'right' }
      },
      content: (
        <FormQuicklyAddToCart
          prices={props.prices}
          product={product}
          onSubmit={async (value) => {
            const price = props.prices.find((x) => x.Id === value.PriceSelectedId)
            if (!price || !props.onSubmitAddToCart) {
              context.closePopover()
              return
            }
            props.onSubmitAddToCart(product, price)
            context.closePopover()
          }}
        />
      )
    })
  }

  return (
    <ApiPopover>
      {MapApiPopoverContext((context) => (
        <Grid container spacing={2}>
          {props.data.map((x, i) => (
            <React.Fragment key={`key-${i}`}>
              <Grid item xs={12} sm={4} md={3}>
                <UIProduct data={x} onAddToCart={(e) => showQuickyAddToCart(e, context, x)} />
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      ))}
    </ApiPopover>
  )
}
export default UIRecommendProduct
