import { ReduxBase } from '@lib/ReduxBase'
import { ELazyStatus } from '@lib/ReduxBase'
import { createSlice } from '@reduxjs/toolkit'
import { IProfileSliceState } from './redux.types'
import { fetchProfileThunk, fetchProfileUpdateThunk } from './redux.thunks'

export const DefaultSlice = ReduxBase.GetInitialSlice<IProfileSliceState>({ state: { profileContentData: {} } })

const ProfileSlice = createSlice({
  name: 'ProfileSlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState: DefaultSlice.initialState,
  reducers: { ...DefaultSlice.reducers },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfileThunk.fulfilled, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.status = ELazyStatus.Loaded
        state.profileContentData = action.payload.profileContentData
      })
      .addCase(fetchProfileThunk.rejected, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.status = ELazyStatus.Error
      })
      .addCase(fetchProfileThunk.pending, (state, action) => {
        state.requestedId = action.meta.requestId
        state.status = ELazyStatus.Loading
      })

    builder
      .addCase(fetchProfileUpdateThunk.fulfilled, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.status = ELazyStatus.Loaded
        if (action.payload) {
          state.profileContentData = { ...state.profileContentData, ...action.payload }
        }
      })
      .addCase(fetchProfileUpdateThunk.rejected, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.status = ELazyStatus.Loaded
      })
      .addCase(fetchProfileUpdateThunk.pending, (state, action) => {
        state.requestedId = action.meta.requestId
        state.status = ELazyStatus.Loading
      })
  }
})

export default ProfileSlice
