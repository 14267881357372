import React, { FC, useState } from 'react'
import { Box, CircularProgress, Fade, styled } from '@mui/material'
import ReactPlayer from 'react-player/youtube'

interface IProps {
  url?: string
  defaultUrl?: string
  ytVideoId?: string
}

const UIMediaPlayer: FC<IProps> = (props) => {
  const [loading, setLoading] = useState(true)
  let url = props.url || props.defaultUrl || ''
  if (!!props.ytVideoId) {
    url = `https://www.youtube.com/watch?v=${props.ytVideoId}`
  }
  return (
    <Box sx={{ position: 'relative' }}>
      <WrapPlayer>
        <ReactPlayer url={url} width='100%' height='100%' controls={true} onReady={() => setLoading(false)} />
      </WrapPlayer>
      <Fade in={loading}>
        <WrapLoading>
          <CircularProgress size={48} sx={{ color: '#fff' }} />
        </WrapLoading>
      </Fade>
    </Box>
  )
}

export default UIMediaPlayer

const WrapPlayer = styled(Box)({
  position: 'relative',
  paddingBottom: '56.25%',
  width: '100%',
  borderRadius: '8px',
  overflow: 'hidden',
  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
  '& > div': {
    position: 'absolute',
    top: '0',
    left: '0'
  }
})

const WrapLoading = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'transition(-50%, -50%)'
})
