import { ReduxBase } from '@lib/ReduxBase'
import { ELazyStatus } from '@lib/ReduxBase'
import { createSlice } from '@reduxjs/toolkit'
import { fetchCheckOutThunk } from './redux.thunks'
import { ICheckOutSliceState } from './redux.types'

export const DefaultSlice = ReduxBase.GetInitialSlice<ICheckOutSliceState>({ state: { carts: [] } })

const CheckOutSlice = createSlice({
  name: 'CheckOutSlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState: DefaultSlice.initialState,
  reducers: { ...DefaultSlice.reducers },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCheckOutThunk.fulfilled, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.status = ELazyStatus.Loaded
        state.carts = action.payload.carts
        state.loaded = true
        state.referenceId = action.payload.referenceId
      })
      .addCase(fetchCheckOutThunk.rejected, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.status = ELazyStatus.Error
      })
      .addCase(fetchCheckOutThunk.pending, (state, action) => {
        state.requestedId = action.meta.requestId
        state.status = ELazyStatus.Loading
      })
  }
})

export default CheckOutSlice
