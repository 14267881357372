import React, { FC } from 'react'
import { IFormInputBase } from '@lib/Forms'
import { IYoutubeVideo } from '@shared/Types'
import { CreateSelectMultiple, ISelectMultipleOption } from '@lib/Forms/Inputs'
import { TryParseYtVideoTagsToArray } from './form.helpers'

const SelectCategoriesInstance = CreateSelectMultiple<IYoutubeVideo>()

interface IProps extends IFormInputBase<IYoutubeVideo> {
  options: ISelectMultipleOption[]
}

const FormSelectCategories: FC<IProps> = ({ defaultValue, ...props }) => {
  const data = TryParseYtVideoTagsToArray(props.data?.Tags ?? '')
  const dValue = data.map<ISelectMultipleOption>((x) => ({ Id: x, Label: x }))

  return <SelectCategoriesInstance {...props} defaultValue={dValue} />
}

export default FormSelectCategories
