import React, { Component } from 'react'
import { connect } from 'react-redux'
import { IOrder } from '@shared/Types'
import { RouteKey } from '@internal/route'
import { OrderShared } from '@shared/Pages'
import { CreateHocLazy } from '@lib/ReduxBase'
import { CreateRoutePath } from '@lib/RouteBase'
import { WindowScrollToTop, TableFormater } from '@lib/Helpers'
import { ActionPannel, CreateTableTemplate, CRUDPannel, MapOprators } from '@lib/Table'
import UIStyleds from '@shared/UIStyleds'
import { IOrderReduxProps } from './redux.types'
import { mapDispatchToProps, mapStateToProps } from './redux.map'
import UIPaidStatus from './ui.paid-status'

const INoData = () => (
  <b>
    <i>no data</i>
  </b>
)

const Table = CreateTableTemplate<IOrder & { Paid?: string }>('Redux', {
  getRowId: (x) => x.Id,
  config: {
    Name: { type: 'string', minWidth: 200, flex: 1 },
    ReferenceId: { flex: 1, minWidth: 300, headerName: 'Reference ID', renderCell: (params) => params.value ?? <INoData /> },
    TransactionId: { flex: 1, minWidth: 200, headerName: 'Transaction ID', renderCell: (params) => params.value ?? <INoData /> },
    Method: { type: 'string', minWidth: 150, filterable: false },
    Paid: { type: 'string', minWidth: 50, sortable: false, filterable: false, renderCell: () => <UIPaidStatus isPaid /> },
    DateCreated: {
      type: 'string',
      minWidth: 175,
      headerName: 'Date created',
      filterable: false,
      renderCell: (params) => TableFormater.tooltipDate(params.value)
    },
    Status: { type: 'string', minWidth: 120, filterable: false, renderCell: (params) => <OrderShared.UIStatusCell data={params.value} /> }
  },
  filterOperators: MapOprators, //server mode,
  GridColumnVisibilityModel: {
    ReferenceId: false,
    TransactionId: false
  },
  minWidthColumnActions: 75
})

interface IProps extends IOrderReduxProps {}
class ViewBase extends Component<IProps> {
  componentDidMount() {
    WindowScrollToTop()
  }
  render() {
    return (
      <UIStyleds.WrapProfile>
        <Table
          ReduxOption={this.props.tableInfo}
          onChange={this.props.onChangeTableInfo}
          CRUDPannel={(p) => <CRUDPannel Title='Orders' />}
          ActionPannel={(p) => {
            const data = p.data as IOrder
            const path = CreateRoutePath([RouteKey.Profile, RouteKey.Order], { query: { id: data.Id.toString() }, modal: RouteKey.OrderDetail })
            return <ActionPannel Buttons={{ Edit: <OrderShared.UIBtnDetail to={path} /> }} />
          }}
        />
      </UIStyleds.WrapProfile>
    )
  }
}

export * from './redux.thunks'
export { default as OrderSlice } from './redux.slice'

const HocLazyInstance = CreateHocLazy(ViewBase)
export const OrderMapRedux = connect(mapStateToProps, mapDispatchToProps)(HocLazyInstance)
