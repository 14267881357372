import React, { FC } from 'react'
import { Box, Chip, styled } from '@mui/material'
import { IExploreFilter, TOnChangeExploreFilter } from '@shared/Types'
import { MapExploreFilter } from './helpers'

interface IProps {
  filter: IExploreFilter
  onChange?: TOnChangeExploreFilter
}

const UIShowFiltered: FC<IProps> = ({ filter, ...props }) => {
  const isReset = !!filter.keyword || (!!filter.categories && filter.categories.length > 0)

  const handleReset = () => {
    if (!props.onChange) return
    props.onChange([
      MapExploreFilter({ key: 'categories', value: [] }),
      MapExploreFilter({ key: 'keyword', value: '' }),
      MapExploreFilter({ key: 'sort', value: 'Lastet' })
    ])
  }

  return (
    <Box sx={{ display: 'flex', gap: '6px', flexWrap: 'wrap' }}>
      {filter.keyword && (
        <ChipCustom
          label={`"${filter.keyword}"`}
          onDelete={() => props.onChange && props.onChange(MapExploreFilter({ key: 'keyword', value: '' }))}
        />
      )}
      {filter.categories?.map((x, i) => (
        <ChipCustom
          key={`key-${i}`}
          label={x.label || x.value}
          onDelete={() => {
            const list = filter.categories?.filter((y) => y.value !== x.value)
            props.onChange && props.onChange([{ key: 'categories', value: list }])
          }}
        />
      ))}
      {isReset && <ChipCustom label='Reset' onClick={handleReset} />}
    </Box>
  )
}

export default UIShowFiltered

const ChipCustom = styled(Chip)({
  boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
  maxWidth: '200px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  background: '#fff'
})
