import React, { FC, useEffect } from 'react'
import { connect } from 'react-redux'
import { useMediaQuery, useTheme } from '@mui/material'
import { ELazyStatus } from '@lib/ReduxBase'
import { CreateHocLazy } from '@lib/ReduxBase'
import { WindowScrollToTop } from '@lib/Helpers'
import { GetLeftMenuConfig } from './helpers'
import { IExploreReduxProps } from './redux.types'
import { UITopBar, UIInfoBar, UIPagination } from './ui.units'
import { mapDispatchToProps, mapStateToProps } from './redux.map'
import UILayout from './ui.layout'
import UIMenuLeft from './ui.menu-left'
import UIShowFiltered from './ui.show-filtered'
import UIFilterTabSelector from './ui.filter.tab-selector'
import UIShowFilterResults from './ui.show-filter-results'

interface IProps extends IExploreReduxProps {}

const ViewBase: FC<IProps> = (props) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    WindowScrollToTop({ behavior: 'auto' })
  }, [])

  return (
    <UILayout
      topBar={<UITopBar filter={props.slice.filter} onChange={props.updateFilter} />}
      leftMenu={
        <UIMenuLeft
          isLoading={props.status === ELazyStatus.Loading}
          data={GetLeftMenuConfig(props.slice.categories, matches)}
          filter={props.slice.filter}
          onChange={props.updateFilter}
        />
      }
      beforeBody={
        <UIInfoBar
          filter={props.slice.filter}
          resultCount={props.slice.filter.pagination?.total || 0}
          body={<UIShowFiltered filter={props.slice.filter} onChange={props.updateFilter} />}
          pagination={
            <UIPagination pagination={props.slice.filter.pagination} onChange={(value) => props.updateFilter({ key: 'pagination', value })} />
          }
        />
      }
      body={
        <UIShowFilterResults
          isLoading={props.status === ELazyStatus.Loading}
          data={props.slice.products}
          prices={props.slice.prices}
          onSubmitAddToCart={props.addToCart}
        />
      }
      pagination={<UIPagination pagination={props.slice.filter.pagination} onChange={(value) => props.updateFilter({ key: 'pagination', value })} />}
    />
  )
}

export * from './redux.thunks'

export { default as ExploreSlice } from './redux.slice'

export const ExploreMapRedux = connect(mapStateToProps, mapDispatchToProps)(CreateHocLazy(ViewBase))

export const UITabMediaTypeMapRedux = connect(mapStateToProps, mapDispatchToProps)(UIFilterTabSelector)
