import { RouteKey } from '@internal/route'
import { QueryParam, TryParseInt } from '@lib/Helpers'
import { ECategoryType, EMediaDeliveryContentType, EMediaType } from '@shared/Types'
import { IExploreFilter, IExploreFilterCategory, TExploreFilterSort, TExploreFilterType } from '@shared/Types'

export interface IModalRouteQueryParam {
  md?: string
}

export const CheckModalRouterExists = (): boolean => {
  try {
    const obj = QueryParam.Gets<IModalRouteQueryParam>('md')
    return !!obj.md
  } catch (error) {
    return false
  }
}

export interface IBillingQueryParam {
  billingId?: string
}

export const BillingQueryParam = {
  get: (): Partial<IBillingQueryParam> => {
    return QueryParam.Gets('billingId')
  },
  set: (value: IBillingQueryParam) => {
    QueryParam.Put(value)
  }
}

const mapMediaType: { [key: string]: EMediaType } = {
  [EMediaType.Audio]: EMediaType.Audio,
  [EMediaType.Audio.toLocaleLowerCase()]: EMediaType.Audio,
  [EMediaType.Video]: EMediaType.Video,
  [EMediaType.Video.toLocaleLowerCase()]: EMediaType.Video
}

export interface IMediaDetailQueryParam {
  id?: string
  type: EMediaType
}

export const GetMediaDetailQueryParam = (): IMediaDetailQueryParam => {
  const defaultMediaType = EMediaType.Video
  try {
    const obj = QueryParam.Gets<IMediaDetailQueryParam>('id', 'type')
    const mediaType = (obj.type ? mapMediaType[obj.type] : defaultMediaType) || defaultMediaType
    return {
      id: obj.id,
      type: mediaType
    }
  } catch (error) {
    return { type: defaultMediaType }
  }
}

export interface IMediaQueryParam {
  deliveryId?: string
  type?: EMediaDeliveryContentType
  md?: RouteKey
}

export const GetMediaQueryParam = (): IMediaQueryParam => {
  return QueryParam.Gets<IMediaQueryParam>('deliveryId', 'type')
}

export const GetDeliveryId = (): string | undefined => {
  try {
    const qp = QueryParam.Gets<IMediaQueryParam>('deliveryId')
    const num = qp.deliveryId
    return num
  } catch (error) {
    return undefined
  }
}

export const GetDeliveryType = (): EMediaDeliveryContentType => {
  try {
    const qp = QueryParam.Gets<{ type?: string }>('type')
    const typeValue = qp.type?.toString().toLowerCase()
    switch (typeValue) {
      case EMediaDeliveryContentType.Url.toLowerCase():
        return EMediaDeliveryContentType.Url
      case EMediaDeliveryContentType.Upload.toLowerCase():
      default:
        return EMediaDeliveryContentType.Upload
    }
  } catch (error) {
    return EMediaDeliveryContentType.Upload
  }
}

export const GetGalleryId = (): string | undefined => {
  try {
    const q = QueryParam.Gets<{ galleryId?: string }>('galleryId')
    return q.galleryId
  } catch (error) {
    return undefined
  }
}
export function stringToEnumCategory(value: string): ECategoryType | undefined {
  return ECategoryType[value as keyof typeof ECategoryType]
}

export const GetGalleryQueryParam = () => {
  try {
    const q = QueryParam.Gets<{ galleryId?: string; key?: string }>('galleryId', 'key')
    return q
  } catch (error) {
    return undefined
  }
}

export interface IExploreQueryParam {
  key?: string
  page?: number
  type?: TExploreFilterType
  sort?: TExploreFilterSort
  categories?: string
}

class ExploreQueryParamBase {
  GetToFilter = (defaultValue?: IExploreFilter): IExploreFilter => {
    try {
      return this.MapParamToFilter(this.Get(), defaultValue)
    } catch (error) {
      return {}
    }
  }

  Get = () => QueryParam.Gets<IExploreQueryParam>('key', 'page', 'type', 'sort', 'categories')

  Set = (value: IExploreFilter) => QueryParam.Put(this.MapFilterToParam(value))

  Equal = (value: { filter: IExploreFilter; queryParam: IExploreQueryParam }): boolean => {
    return JSON.stringify(value.queryParam) === JSON.stringify(this.MapFilterToParam(value.filter))
  }

  MapParamToFilter = (value: IExploreQueryParam, defaultValue?: IExploreFilter): IExploreFilter => {
    const filter: IExploreFilter = {
      pagination: {
        page: TryParseInt(value.page, defaultValue?.pagination?.page || 1),
        pageSize: defaultValue?.pagination?.pageSize || 24,
        total: defaultValue?.pagination?.total || 0
      },
      type: value.type || defaultValue?.type || 'Video',
      sort: value.sort ?? 'Lastet'
    }

    if (!!value.key) filter.keyword = value.key
    const categories = value.categories?.split('|').map<IExploreFilterCategory>((x) => ({ value: x }))
    if (!!categories?.length) filter.categories = categories

    return filter
  }

  MapFilterToParam = (value: IExploreFilter): IExploreQueryParam => {
    const obj: IExploreQueryParam = {
      key: value.keyword,
      page: value.pagination?.page,
      type: value.type,
      sort: value.sort,
      categories: value.categories?.map((x) => x.value).join('|')
    }
    const cleanedObj = Object.fromEntries(
      Object.entries(obj).filter(([key, v]) => {
        if (Array.isArray(v) && v.length < 1) return false
        if (key === 'page' && v < 2) return false
        if (key === 'sort' && v === 'Unset') return false
        return !!v
      })
    )
    return cleanedObj
  }
}
export const ExploreQueryParam = new ExploreQueryParamBase()

export interface IYoutubeVideoQueryParam {
  deliveryId?: string
  type?: EMediaDeliveryContentType
}

export const YoutubeVideoQueryParam = {
  Get: () => QueryParam.Gets<IYoutubeVideoQueryParam>('deliveryId', 'type'),
  Set: (value: IYoutubeVideoQueryParam) => QueryParam.Put(value)
}

export interface IYoutubeVideoPreviewQueryParam {
  videoId?: string
}

export const GetYoutubeVideoPreviewQueryParam = () => {
  return QueryParam.Gets<IYoutubeVideoPreviewQueryParam>('videoId')
}

export interface ISubmissionDetailQueryParam {
  submissionId?: string
}

export const SubmissionDetailQueryParam = {
  get: () => QueryParam.Gets<ISubmissionDetailQueryParam>('submissionId'),
  set: (value: ISubmissionDetailQueryParam) => QueryParam.Put(value)
}

export interface ICheckOutQueryParam {
  referenceId?: string
}

export const CheckOutQueryParam = {
  get: () => QueryParam.Gets<ICheckOutQueryParam>('referenceId'),
  set: (value: ICheckOutQueryParam) => QueryParam.Put(value)
}
