import React, { Component } from 'react'
import { CartShared } from '@shared/Pages'
import { EPaymentMethod } from '@shared/Types'
import { Accordion, AccordionDetails, AccordionSummary, alpha, Box, styled, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

interface IBaseConfig {
  title?: string
  subTitle?: JSX.Element
  body: JSX.Element
}

type TPaymentAccordionConfigs = { [key in EPaymentMethod]?: IBaseConfig }

interface IProps {
  configs: TPaymentAccordionConfigs
}

interface IState {
  paymentMethod?: EPaymentMethod
}

export default class UIPayment extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = { paymentMethod: EPaymentMethod.PayPal }
  }

  render() {
    return (
      <Wrap>
        <CartShared.UIInfoTop title='Payment' subTitle='All transactions are secure and encrypted.' />
        {this.renderAccordians()}
      </Wrap>
    )
  }

  renderAccordians = () => {
    const keys = Object.keys(this.props.configs) as EPaymentMethod[]
    return (
      <Box sx={{ m: '12px 0' }}>
        {keys.map((key) => {
          const item = this.props.configs[key]
          return (
            <Accordion
              sx={{ background: '#fafafa' }}
              key={key}
              elevation={0}
              expanded={key === this.state.paymentMethod}
              onChange={() => this.handleChangeMethod(EPaymentMethod.PayPal)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1bh-content' id='panel1bh-header'>
                <Typography variant='subtitle1' sx={{ width: '33%', flexShrink: 0 }}>
                  {item?.title ?? key.toString()}
                </Typography>
                {item?.subTitle ?? ''}
              </AccordionSummary>
              <AccordionDetails>{item?.body ?? ''}</AccordionDetails>
            </Accordion>
          )
        })}
        {keys.length < 1 && (
          <Box sx={{ padding: '9px 12px', background: alpha('#0ED197', 0.1), borderRadius: '6px' }}>
            <Typography variant='caption' sx={{ fontWeight: 600, color: '#0ED197' }}>
              Payments are not currently supported, we will update them soon
            </Typography>
          </Box>
        )}
      </Box>
    )
  }

  handleChangeMethod = (value: EPaymentMethod) => {
    if (value === this.state.paymentMethod) {
      this.setState({ paymentMethod: undefined })
    } else {
      this.setState({ paymentMethod: value })
    }
  }
}

const Wrap = styled(Box)({
  padding: '15px 20px',
  background: '#fff',
  boxShadow: 'rgba(99, 99, 99, 0.1) 0px 2px 8px 0px',
  borderRadius: '4px'
})
