import { AppDispatch, RootState } from '@internal/redux'
import { IReduxMapDispatch, IReduxMapState } from './redux.types'
import SubmitMediaSlice from './redux.slice'

export const mapStateToProps = (state: RootState): IReduxMapState => ({
  status: state.SubmitMediaSlice.status,
  submitModel: state.SubmitMediaSlice.submitModel
})

export const mapDispatchToProps = (dispatch: AppDispatch): IReduxMapDispatch => ({
  onChangeStatus: (status) => {
    dispatch(SubmitMediaSlice.actions.onChangeStatus(status))
  },
  updateSubmitModel: (value) => {
    dispatch(SubmitMediaSlice.actions.updateSubmitModel(value))
  }
})
