import React, { FC } from 'react'
import { UIImageIcon } from '@shared/UIStyleds'
import { NoDataBase64 } from '@shared/ImageBase64'
import { IExploreFilterPagination, IExploreFilterProps } from '@shared/Types'
import { Box, Divider, Fade, Pagination, styled, Typography, TypographyProps } from '@mui/material'
import { GetPaginationInfo } from './helpers'
import UIFilterSearch from './ui.filter.search'
import UIFilterSort from './ui.filter.sort'

export const UINoData: FC = () => (
  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '15rem' }}>
    <Box component='img' src={NoDataBase64} sx={{ height: '150px', opacity: 0.5 }} />
  </Box>
)

interface ITopBarProps extends IExploreFilterProps {}

export const UITopBar: FC<ITopBarProps> = (props) => (
  <Box sx={{ mx: '-24px' }}>
    <Box sx={{ display: 'flex', alignItems: 'center', p: '16px 24px 12px' }}>
      <UIFilterSearch filter={props.filter} onChange={props.onChange} />
      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        <UIFilterSort id='explore-filter__sort-01' filter={props.filter} onChange={props.onChange} />
      </Box>
    </Box>
    <Divider sx={{ display: { xs: 'none', sm: 'block' } }} />
  </Box>
)

interface IInfoBarProps extends IExploreFilterProps {
  resultCount: number
  body: JSX.Element
  pagination?: JSX.Element
}

export const UIInfoBar: FC<IInfoBarProps> = (props) => (
  <WrapInfoBar>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
      <UIImageIcon src={props.filter?.type === 'Audio' ? 'icon-audio' : 'icon-video'} size={26} sx={{ mb: '-3px' }} />
      <WrapInfoBarTypography>
        {props.filter?.type === 'Audio' ? 'Audios' : 'Videos'} ─ <span>{props.resultCount}</span>
      </WrapInfoBarTypography>
    </Box>
    <Box className='info-body'>{props.body}</Box>
    <Divider />
    <Box className='top-pagination'>{props.pagination}</Box>
  </WrapInfoBar>
)

const WrapInfoBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '6px',
  paddingTop: '10px',
  flexWrap: 'wrap',
  '& .info-body': {
    flex: 1
  },
  '& .top-pagination': {},
  [theme.breakpoints.down('md')]: {
    display: 'block',
    '& .info-body': {
      padding: '12px 9px'
    },
    '& .top-pagination': {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '10px'
    },
    '& .MuiDivider-root': {
      display: 'none'
    }
  }
}))

const WrapInfoBarTypography = styled((p: TypographyProps) => <Typography variant='h6' component='span' {...p} />)({
  fontWeight: 600,
  marginRight: '12px',
  lineHeight: 1,
  '& span': {
    fontSize: '0.85em'
  }
})

interface IPaginationProps {
  pagination?: IExploreFilterPagination
  onChange?: (value?: IExploreFilterPagination) => void
}

export const UIPagination: FC<IPaginationProps> = (props) => {
  const pInfo = GetPaginationInfo(props.pagination)
  const handleChangePagination = (_: React.ChangeEvent<unknown>, page: number) => {
    if (!props.pagination) return
    props.onChange && props.onChange({ ...props.pagination, page })
  }
  return (
    <Fade in={!!pInfo && pInfo.count > 1}>
      <Pagination count={pInfo?.count ?? 1} page={pInfo?.page ?? 1} onChange={handleChangePagination} />
    </Fade>
  )
}
