import { submission$ } from '../../__generated/media-store/fetchers'
import { applicationUser$ } from '../../__generated/media-store/fetchers'
import { query$, mediaStoreGraphDbContext$ } from '../../__generated/media-store/fetchers'
import { mediaDelivery$$, mediaDeliveryPage$ } from '../../__generated/media-store/fetchers'

const MediaDeliverySelector = mediaDelivery$$
  .creator(applicationUser$.displayName)
  .submission(submission$.title.email.firstName.lastName.kindlyDescribe)

export type TMediaDeliveriesFilterParams = Parameters<typeof mediaStoreGraphDbContext$.mediaDeliveries>[0]
export const MediaDeliveriesQuery = (params: TMediaDeliveriesFilterParams) => {
  const MediaDeliveryPage = mediaDeliveryPage$.items(MediaDeliverySelector).hasNextPage.hasPreviousPage.totalItems
  return query$.mediaStore(mediaStoreGraphDbContext$.mediaDeliveries(params, MediaDeliveryPage))
}

const MediaDeliveryUserSelector = mediaDelivery$$.submission(submission$.title.email.firstName.lastName.kindlyDescribe)
export const MediaDeliveriesUserQuery = (params: TMediaDeliveriesFilterParams) => {
  const MediaDeliveryPage = mediaDeliveryPage$.items(MediaDeliveryUserSelector).hasNextPage.hasPreviousPage.totalItems
  return query$.mediaStore(mediaStoreGraphDbContext$.mediaDeliveries(params, MediaDeliveryPage))
}
