import { createAsyncThunk } from '@reduxjs/toolkit'
import SubmissionService from '@internal/services/SubmissionService'
import { SubmissionDetailQueryParam } from '@shared/Services/QueryParamService'
import { IReduxThunkReturned } from './redux.types'

export const fetchSubmissionDetailThunk = createAsyncThunk<IReduxThunkReturned>('fetchSubmissionDetailThunk', async (_, thunkAPI) => {
  // const state = thunkAPI.getState() as { SubmissionDetailSlice: ISubmissionDetailStateSlice }
  const q = SubmissionDetailQueryParam.get()
  if (!q.submissionId) return {}

  const result = await SubmissionService.Single(q.submissionId, thunkAPI.signal)
  return { data: !!result ? result : undefined }
})
