import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { RouteKey } from '@internal/route'
import { Box, Breakpoint, styled, SxProps, Theme } from '@mui/material'
import EventEmitter from 'events'
import { MergeObjects } from '../../Helpers'
import { EMdStack, IsEMdStack } from './helper'
import { CreateRoutePath } from '../../RouteBase'
import MRStack from './modal-route.stack'

interface IMapSxProps {
  wrap: SxProps<Theme>
  content: SxProps<Theme>
  contentSize?: Breakpoint
}

type TOriginalEvent = React.MouseEvent<HTMLDivElement, MouseEvent> | React.MouseEvent<HTMLButtonElement, MouseEvent> | KeyboardEvent

export class EventClose extends CustomEvent<{ originalEvent: TOriginalEvent }> {}

interface IProps {
  back?: EMdStack | RouteKey | RouteKey[]
  disabledBackdrop?: boolean
  title?: string | JSX.Element
  size?: Breakpoint | 'full'
  linkState?: any
  slots?: {
    topBarRight?: JSX.Element
    topBarRightable?: boolean
  }
  onClose?: (e: EventClose) => void
}

interface EventMap {
  close: [EventClose]
}

class WrapperLayout extends Component<React.PropsWithChildren<IProps>> {
  refLink: HTMLAnchorElement | undefined | null
  events: EventEmitter<EventMap>
  constructor(props: any) {
    super(props)
    this.events = new EventEmitter<EventMap>({})
    this.props.onClose && this.events.addListener('close', this.props.onClose)
    this.events.addListener('close', this.onBgrClickDefault)
  }
  shouldComponentUpdate(nextProps: Readonly<React.PropsWithChildren<IProps>>, nextState: Readonly<{}>, nextContext: any): boolean {
    if (this.props.onClose && nextProps.onClose !== this.props.onClose) {
      this.events.removeListener('close', this.props.onClose)
    }
    return true
  }
  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
    this.events.removeAllListeners()
  }

  render() {
    const msp = this.mapSxProps()
    const backLink = this.getBackLink() ?? ''
    return (
      <>
        <Link ref={(ref) => (this.refLink = ref)} hidden to={backLink} />
        <Wrap sx={msp.wrap}>
          <BgBehaind onMouseDown={this.onBgrClick} />
          <WrapContent>{this.props.children}</WrapContent>
        </Wrap>
      </>
    )
  }

  handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      // const closeEvent = new EventClose('close', { detail: { originalEvent: event }, cancelable: true })
      // this.events.emit('close', closeEvent)
      this.handleBack()
    }
  }
  onBgrClickDefault = (ecl: EventClose) => {
    if (ecl.defaultPrevented) {
      return
    }
    const e = ecl.detail.originalEvent
    if (this.props.disabledBackdrop === true) return
    if (e.target === e.currentTarget) {
      const backLink = this.getBackLink()
      if (backLink !== undefined) {
        e.preventDefault()
        e.stopPropagation()
        const action = MRStack[backLink]
        action ? action(undefined, this.props.linkState) : this.refLink?.click()
      } else {
        MRStack.PopMdStack(undefined, this.props.linkState)
      }
    }
  }
  onBgrClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    const closeEvent = new EventClose('close', { detail: { originalEvent: e }, cancelable: true })
    this.events.emit('close', closeEvent)
  }
  onBackClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    // const closeEvent = new EventClose('close', { detail: { originalEvent: e }, cancelable: true })
    // this.events.emit('close', closeEvent)
    this.handleBack()
  }

  handleClose: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (e.target === e.currentTarget) {
      const backLink = this.getBackLink()
      if (backLink !== undefined) {
        e.preventDefault()
        e.stopPropagation()
        const action = MRStack[backLink]
        action ? action(undefined, this.props.linkState) : this.refLink?.click()
      } else {
        MRStack.PopMdStack(undefined, this.props.linkState)
      }
    }
  }

  handleBack = () => {
    const backLink = this.getBackLink()
    if (backLink !== undefined) {
      const action = MRStack[backLink]
      action ? action(undefined, this.props.linkState) : this.refLink?.click()
    } else {
      MRStack.PopMdStack(undefined, this.props.linkState)
    }
  }

  getBackLink = (): string | undefined => {
    const data = this.props.back
    if (!data) return undefined
    if (IsEMdStack(data)) return data.toString()
    return CreateRoutePath(data)
  }
  mapSxProps = (): IMapSxProps => {
    let sx: IMapSxProps = { wrap: {}, content: {} }
    if (this.props.size) {
      const { size } = this.props
      sx = MergeObjects({}, sx, {
        wrap: { padding: size === 'full' ? '0' : '12px 0 12px' },
        content: {
          borderRadius: size === 'full' ? 0 : '6px'
        },
        contentSize: size !== 'full' ? size : undefined
      })
    }
    return sx
  }
}
export default WrapperLayout

const Wrap = styled(Box)({
  flex: 1,
  position: 'relative'
})

const WrapContent = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxHeight: 'calc(100vh - 56px)'
})

const BgBehaind = styled(Box)({
  backgroundColor: '#00000095',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 0
})
