import React, { FC } from 'react'
import { Box, Skeleton } from '@mui/material'

const UISkeletonCheckOutItem: FC = () => (
  <div>
    {[...Array(3)].map((_, i) => (
      <Box key={'key' + i} sx={{ height: 'var(--height-item)' }}>
        <Skeleton variant='rounded' height='94px' width='100%' />
      </Box>
    ))}
  </div>
)

export default UISkeletonCheckOutItem
