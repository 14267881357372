import React, { FC } from 'react'
import { motion } from 'framer-motion'
import { Box, Fade, styled, Typography } from '@mui/material'

interface ILinkRowsProps {
  data: string[]
  maxLink?: number
  components?: {
    actionRow?: (value: string) => JSX.Element
    bottom?: JSX.Element
  }
}

const UILinkRows: FC<ILinkRowsProps> = (props) => (
  <Box sx={{ transition: 'leaner 0.2s' }}>
    <WrapList length={props.data.length} max={props.maxLink}>
      {props.data.map((item, index) => (
        <li key={item}>
          <UILinkRow value={item} index={index} action={props.components?.actionRow ? props.components.actionRow(item) : undefined} />
        </li>
      ))}
    </WrapList>
    {props.components?.bottom && props.components.bottom}
  </Box>
)
export default UILinkRows

interface ILinkRowProps {
  value: string
  index?: number
  action?: JSX.Element
}

export const UILinkRow: FC<ILinkRowProps> = (props) => (
  <WrapItem
    sx={{ borderRadius: '6px' }}
    initial={{ opacity: 0.3, backgroundColor: '#dedede' }}
    animate={{ opacity: 1, backgroundColor: '#fafafa' }}
    transition={{ ease: 'linear', duration: 0.1 }}
  >
    <div>
      <Fade in={props.index !== undefined} unmountOnExit>
        <Typography variant='subtitle2' sx={{ color: '#606060', lineHeight: 1 }} className='noselect'>
          {(props.index ?? 0) + 1}.
        </Typography>
      </Fade>
      <CustomText variant='subtitle2' noWrap {...{ component: 'a', href: props.value, target: '_blank' }}>
        {props.value}
      </CustomText>
      {props.action}
    </div>
  </WrapItem>
)

const WrapList = styled('ul')<{ length: number; max?: number }>(({ length, max }) => ({
  marginTop: '12px',
  maxHeight: max ? `${(max + 1) * 42}px` : undefined,
  overflow: 'auto',
  paddingRight: max ? (length > max ? `6px` : 0) : '12px'
}))

const WrapItem = styled(motion.div)({
  '& > div': {
    height: '38px',
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    marginTop: '4px',
    padding: '3px 9px'
  }
})

const CustomText = styled(Typography)({
  color: '#606060',
  flex: 1,
  textDecoration: 'unset !important',
  '&:hover': {
    color: '#1976d2'
  }
})
