import { QueryParam } from '@lib/Helpers'
import { RootState, AppDispatch } from '@internal/redux'
import CreateTicketConversationService from '@internal/services/TicketConversationService'
import { fetchTicketDetailThunk } from './redux.thunks'
import { IReduxMapDispatch, IReduxMapState } from './redux.types'
import TicketDetailSlice from './redux.slice'

const getTicketId = (): string => {
  const param = QueryParam.Gets<{ id?: string }>('id')
  return param.id?.toString() ?? ''
}

export const mapStateToProps = (state: RootState): IReduxMapState => ({
  status: state.TicketDetailSlice.status,
  requestedId: state.TicketDetailSlice.requestedId,
  slice: state.TicketDetailSlice
})

export const mapDispatchToProps = (dispatch: AppDispatch): IReduxMapDispatch => ({
  fetchData: () => {
    return dispatch(fetchTicketDetailThunk({ ticketId: getTicketId(), cleaned: true }))
  },
  onChangeStatus: (status) => {
    dispatch(TicketDetailSlice.actions.onChangeStatus(status))
  },
  CRUD: {
    Create: async (model) => {
      await CreateTicketConversationService(getTicketId()).Create(model)
      dispatch(fetchTicketDetailThunk({ ticketId: getTicketId() }))
    }
  },
  refresh: async () => {
    dispatch(fetchTicketDetailThunk({ ticketId: getTicketId(), isRefresh: true }))
  }
})
