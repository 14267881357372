import React, { Component } from 'react'
import { Badge, IconButton, Menu, SxProps, Theme, Tooltip, styled } from '@mui/material'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'

export interface IMenuWrap {
  onOpen: (event: React.MouseEvent<HTMLElement>) => void
  onClose: () => void
  currentTarget: Omit<IMenuWrap, 'currentTarget'>
}

interface IProps {
  count: number
  tooltipTitle: string
}

interface IState {
  anchorEl: null | HTMLElement
}

class MenuWrap extends Component<React.PropsWithChildren<IProps>, IState> implements IMenuWrap {
  constructor(props: IProps) {
    super(props)
    this.state = { anchorEl: null }
  }

  render() {
    const { anchorEl } = this.state
    const isOpen = Boolean(anchorEl)
    return (
      <>
        <Tooltip title={this.props.tooltipTitle}>
          <CustomButton
            {...{ component: 'div' }}
            id='btn-notification-appbar'
            aria-controls={isOpen ? 'notification-appbar' : undefined}
            aria-haspopup='true'
            aria-expanded={isOpen ? 'true' : undefined}
            onClick={this.onOpen}
            color='inherit'
          >
            <Badge component='div' badgeContent={this.props.count} color='error'>
              <NotificationsNoneIcon />
            </Badge>
          </CustomButton>
        </Tooltip>
        <Menu
          id='notification-appbar'
          keepMounted
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          slotProps={{ paper: { elevation: 0, sx: sxPaperProps } }}
          sx={{ mt: '45px' }}
          open={isOpen}
          onClose={this.onClose}
        >
          {this.props.children}
        </Menu>
      </>
    )
  }

  currentTarget = this

  onOpen = (event: React.MouseEvent<HTMLElement>) => this.setState({ anchorEl: event.currentTarget })

  onClose = () => this.setState({ anchorEl: null })
}
export default MenuWrap

const sxPaperProps: SxProps<Theme> = {
  overflow: 'visible',
  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
  marginTop: 1,
  minWidth: '360px',
  maxWidth: '550px',
  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 0,
    right: 14,
    width: 10,
    height: 10,
    backgroundColor: 'background.paper',
    transform: 'translateY(-50%) rotate(45deg)',
    zIndex: 0
  }
}

const CustomButton = styled(IconButton)({
  fontSize: '16px',
  textTransform: 'capitalize',
  fontWeight: '500',
  color: 'var(--color-text-light)',
  marginRight: '5px'
})
