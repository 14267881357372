export enum RouteKey {
  InitialPath = '/',
  Home = '/',
  Explore = 'explore',
  Cart = 'cart',
  CheckOut = 'checkout',
  CheckOutComplete = 'checkout-complete',
  Report = 'report',
  Detail = 'detail',
  Submit = 'submit',
  SMTAboutMedia = 'about-media',
  SMTAboutYouself = 'about-yourself',
  SMTCopyrightOwnership = 'copyright-ownership',
  SMTDigitalSignature = 'digital-signature',
  SMTComplete = 'complete',
  FQAs = 'fqas',
  PrivacyPolicy = 'privacy-policy',
  TermsOfSubmission = 'terms-of-submission',
  Pricing = 'price',
  Profile = 'profile',
  Order = 'order',
  OrderDetail = 'order-detail',
  AboutUs = 'about',
  Ticket = 'ticket',
  TicketDetail = 'ticket-detail',
  TicketCreate = 'ticket-create',
  Submission = 'submission',
  SubmissionDetail = 'submission-detail',
  Media = 'media',
  Delivery = 'delivery',
  DeliveryDetail = 'delivery-detail',
  Billing = 'billing',
  BillingDetail = 'billing-detail',
  YoutubeVideoPreview = 'youtube-video-preview'
}
export default RouteKey
