import React, { FC } from 'react'
import { Box, BoxProps, Container, ContainerProps, Stack, styled } from '@mui/material'

export const WrapContainer = styled((props: ContainerProps) => <Container {...props} maxWidth={false} />)({
  display: 'flex',
  flex: 1,
  flexDirection: 'column'
})

export const WrapScroll = styled(({ children, sx, ...props }: BoxProps) => (
  <Box {...props}>
    <Box>{children}</Box>
  </Box>
))({
  flex: 1,
  position: 'relative',
  '& > div': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden'
  }
})

export const WrapScrollRef = React.forwardRef<HTMLDivElement, BoxProps>((props, ref) => {
  return (
    <Box sx={{ ...props.sx, flex: 1, position: 'relative' }}>
      <Box {...props} ref={ref} sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', overflow: 'auto' }}>
        {props.children}
      </Box>
    </Box>
  )
})

export const WrapFullDisplay = styled(Stack)({
  height: 'calc(100vh - var(--height-header))'
})

export const WrapProfile = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100vh - var(--height-header))',
  paddingTop: '24px'
})

export const UIClientContainer = styled((p: ContainerProps) => <Container maxWidth='xl' {...p} />)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    maxWidth: theme.app.container.maxWidth,
    padding: '64px 0 128px'
  }
}))

const getFullURl = (uri: string) => {
  const a = document.createElement('a')
  a.href = uri
  return a.href
}

interface IHomeLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {}

export const UIHomeLink: FC<IHomeLinkProps> = ({ href, children, ...props }) => {
  const url = href ? new URL(getFullURl(href)) : new URL(getFullURl('/'))
  if (process.env.REACT_APP_HOME_HOST) {
    url.host = process.env.REACT_APP_HOME_HOST
  }
  return (
    <a href={url.href} {...props}>
      {children}
    </a>
  )
}
