import { CRUDServiceBase } from '@lib/Http'
import { EOperator, ITableTemplateState } from '@lib/Table'
import { ETableLogicOperator, TableToRequestFilter } from '@lib/Helpers'
import { ECategoryType, ICategory, IMediaDelivery, IYoutubeVideo } from '@shared/Types'
import { EMediaDeliveryContentType, EMediaDeliveryStatus, EMediaDeliveryType, EMediaType, EProductType } from '@shared/Types'
import Graphql from '@coreprj/graphql'

type TYtVideosFilterParam = Graphql.QMediaStore.TYtVideosFilterParam
type TYoutubeVideo = Graphql.QMediaStore.TYoutubeVideo

export type TMediaDelivery = Graphql.QMediaStore.TMediaDelivery
type TYoutubeVideosResult = Graphql.TGraphQlResult<TYoutubeVideo>

export type TCategory = Graphql.QMediaStore.TCategory
type TCategoriesResult = Graphql.TGraphQlResult<TCategory>

export interface IDeliveryCreatesRequest {
  DeliveryId: string
  Tags: string
  ExcelFile: File
}

class YoutubeVideoServiceBase extends CRUDServiceBase<IYoutubeVideo, string> {
  constructor() {
    super(`/api/v1/admin/YoutubeVideo`)
  }

  AllGraphql = async (filter: TYtVideosFilterParam, deliveryId: string, signal?: AbortSignal) => {
    const res = await Graphql.MediaStore.Query(Graphql.QMediaStore.YoutubeVideosInitial(filter, deliveryId), { signal, delay: 500 })
    return {
      youtubeVideos: res.mediaStore.youtubeVideos as TYoutubeVideosResult,
      delivery: res.mediaStore.mediaDelivery as TMediaDelivery,
      categories: res.mediaStore.categories as TCategoriesResult
    }
  }

  private deliveryCreateUrl = '/DeliveryCreates'
  DeliveryCreate = async (value: IDeliveryCreatesRequest, signal?: AbortSignal) => {
    await this.Post(this.deliveryCreateUrl, value, {
      signal,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }
}
const YoutubeVideoService = new YoutubeVideoServiceBase()
export default YoutubeVideoService

class YoutubeVideoMappingBase {
  tableInfoToFilter = (tableInfo: ITableTemplateState<IYoutubeVideo>, deliveryId?: string) => {
    let filter = new TableToRequestFilter.Graphql<IYoutubeVideo, TYoutubeVideo>({
      fieldMapping: {
        Id: { field: 'id' },
        Title: { field: 'title' },
        Description: { field: 'description' },
        Type: { field: 'type' },
        DeliveryId: { field: 'deliveryId' },
        Tags: { field: 'tags' },
        PublishedDate: { field: 'publishedDate' },
        DateCreated: { field: 'dateCreated' }
      }
    })
      .fromTable(tableInfo, ['Title', 'Description'])
      .sort({ field: 'Id', sort: 'asc' })
    if (!!deliveryId) {
      filter = filter.filter({ field: 'DeliveryId', value: deliveryId, operator: EOperator.Equal, logicOperator: ETableLogicOperator.And })
    }
    return filter.build()
  }

  youtubeVideo = (item: TYoutubeVideo): IYoutubeVideo => ({
    Id: item.id ?? '',
    Title: item.title ?? '',
    Description: item.description ?? '',
    Type: item.type as EProductType,
    DeliveryId: item.deliveryId,
    Tags: item.tags ?? '',
    PublishedDate: item.publishedDate,
    DateCreated: item.dateCreated
  })

  youtubeVideos = (items: TYoutubeVideo[] = []): IYoutubeVideo[] => items.map((x) => this.youtubeVideo(x))

  delivery = (item: TMediaDelivery): IMediaDelivery => ({
    Id: item.id,
    MediaType: EMediaType.Video,
    Type: item.type as EMediaDeliveryType,
    Status: item.status as EMediaDeliveryStatus,
    ContentType: item.contentType as EMediaDeliveryContentType,
    Content: item.content,
    ResourceId: item.resourceId ?? '',
    Description: item.description ?? '',
    DateCreated: item.dateCreated ?? '',
    DateUpdated: item.dateUpdated ?? '',
    Name: item.name ?? '',
    // relationship
    Title: item.submission?.title,
    Email: item.submission?.email,
    FirstName: item.submission?.firstName,
    LastName: item.submission?.lastName,
    KindlyDescribe: item.submission?.kindlyDescribe
  })

  categories = (items: TCategory[] = []): ICategory[] => {
    return items.map<ICategory>((item) => ({
      Id: item.id,
      Name: item.name ?? '',
      Description: item.description ?? '',
      ImageUri: item.imageUri ?? '',
      Type: item.type as ECategoryType,
      DateCreated: item.dateCreated ?? ''
    }))
  }

  update = (value: Partial<IYoutubeVideo>): Partial<IYoutubeVideo> => ({
    Title: value.Title,
    Description: value.Description,
    PublishedDate: value.PublishedDate,
    Type: value.Type,
    Tags: value.Tags
  })
}
export const YoutubeVideoMapping = new YoutubeVideoMappingBase()
