import { IRoute } from '@lib/RouteBase'
import { Navigate } from 'react-router-dom'
import { UIErrorPage } from '@shared/UIStyleds'
import { ApplicationPaths } from 'partner-oidc-auth'
import { ApiAuthorizationRoutes } from '@lib/ApiAuthorization'
import LayoutProfile from '@internal/shareds/LayoutProfile'
import RouteKey from './RouteKey'
import { BillingMapRedux } from '../Views/Billing'
import { BillingDetailModal } from '../Views/BillingDetail'
import { CartMapRedux } from '../Views/Cart'
import { CheckOutMapRedux, CheckOutComplete } from '../Views/CheckOut'
import { DeliveryMapRedux } from '../Views/Delivery'
import { ExploreMapRedux } from '../Views/Explore'
import { MediaDetailMapRedux } from '../Views/MediaDetail'
import { OrderMapRedux } from '../Views/Order'
import { OrderDetailModal } from '../Views/OrderDetail'
import { PolicyFileMapRedux } from '../Views/PolicyFile'
import { ProfileMapRedux } from '../Views/Profile'
import { SumissionStepComponents } from '../Views/SubmitMedia'
import { SubmissionDetailModal } from '../Views/SubmissionDetail'
import { SubmissionMapRedux } from '../Views/Submission'
import { SubmitMediaMapRedux } from '../Views/SubmitMedia'
import { TicketMapRedux } from '../Views/Ticket'
import { TicketDetailModal } from '../Views/TicketDetail'
import { YoutubeVideoMapRedux } from '../Views/YoutubeVideo'
import FAQ from '../Views/FAQ'
import AboutUsPage from '../Views/AboutUs'
import ReportScreen from '../Views/ReportScreen'
import AccessDenied from '../Views/AccessDenied'
import TicketCreate from '../Views/TicketCreate'
import YoutubeVideoPreview from '../Views/YoutubeVideoPreview'

const MapApiAuthorizationRoutes = () => {
  return ApiAuthorizationRoutes.map<IRoute>((item, index) => {
    return { element: item.element, Path: item.path }
  })
}

const AppName = 'BERLINTOMEK'

export const RouteConfigs: IRoute[] = [
  { Path: RouteKey.InitialPath, element: <Navigate to={RouteKey.Explore} replace /> },
  { Path: RouteKey.Explore, element: <ExploreMapRedux />, Prefix: AppName },
  { Path: RouteKey.Cart, element: <CartMapRedux />, Prefix: AppName },
  { Path: RouteKey.CheckOut, element: <CheckOutMapRedux />, Prefix: AppName, roles: [[]] },
  { Path: RouteKey.CheckOutComplete, element: <CheckOutComplete />, Prefix: AppName },
  { Path: RouteKey.Report, element: <ReportScreen />, Prefix: AppName },
  { Path: RouteKey.Detail, element: <MediaDetailMapRedux />, Prefix: AppName },
  {
    Path: RouteKey.Submit,
    element: <SubmitMediaMapRedux />,
    Prefix: AppName,
    Children: [
      { Path: '/', element: <SumissionStepComponents.AboutMedia />, Prefix: AppName },
      { Path: RouteKey.SMTAboutMedia, element: <SumissionStepComponents.AboutMedia />, Prefix: AppName },
      { Path: RouteKey.SMTAboutYouself, element: <SumissionStepComponents.AboutYouself />, Prefix: AppName },
      { Path: RouteKey.SMTCopyrightOwnership, element: <SumissionStepComponents.CopyrightOwnership />, Prefix: AppName },
      { Path: RouteKey.SMTDigitalSignature, element: <SumissionStepComponents.DigitalSignature />, Prefix: AppName },
      { Path: RouteKey.SMTComplete, element: <SumissionStepComponents.Complete />, Prefix: AppName }
    ]
  },
  { Path: RouteKey.FQAs, element: <FAQ />, Prefix: AppName },
  { Path: RouteKey.PrivacyPolicy, element: <PolicyFileMapRedux />, Prefix: AppName },
  { Path: RouteKey.TermsOfSubmission, element: <PolicyFileMapRedux />, Prefix: AppName },
  {
    Path: RouteKey.Profile,
    element: <LayoutProfile />,
    roles: [[]],
    Children: [
      { Path: '/', element: <ProfileMapRedux />, Prefix: AppName, roles: [[]] },
      { Path: RouteKey.Order, element: <OrderMapRedux />, Prefix: AppName, roles: [[]] },
      { Path: RouteKey.AboutUs, element: <AboutUsPage />, Prefix: AppName, roles: [[]] },
      { Path: RouteKey.Ticket, element: <TicketMapRedux />, Prefix: AppName, roles: [[]] },
      { Path: RouteKey.TicketCreate, element: <TicketCreate />, Prefix: AppName, roles: [[]] },
      { Path: RouteKey.Submission, element: <SubmissionMapRedux />, Prefix: AppName, roles: [[]] },
      { Path: RouteKey.DeliveryDetail, element: <YoutubeVideoMapRedux />, Prefix: AppName, roles: [[]] },
      { Path: RouteKey.Delivery, element: <DeliveryMapRedux />, Prefix: AppName, roles: [[]] },
      { Path: RouteKey.Billing, element: <BillingMapRedux />, Prefix: AppName, roles: [[]] }
    ]
  },
  { Path: RouteKey.OrderDetail, element: <OrderDetailModal />, Prefix: AppName, roles: [[]] },
  { Path: RouteKey.TicketDetail, element: <TicketDetailModal />, Prefix: AppName, roles: [[]] },
  { Path: RouteKey.SubmissionDetail, element: <SubmissionDetailModal />, Prefix: AppName, roles: [[]] },
  { Path: RouteKey.BillingDetail, element: <BillingDetailModal />, Prefix: AppName, roles: [[]] },
  { Path: RouteKey.AboutUs, element: <AboutUsPage />, Prefix: AppName, roles: [[]] },
  { Path: RouteKey.YoutubeVideoPreview, element: <YoutubeVideoPreview />, Prefix: AppName },
  { Path: ApplicationPaths.AccessDenied, element: <AccessDenied />, Prefix: AppName },
  { Path: '*', element: <UIErrorPage />, Prefix: AppName },
  ...MapApiAuthorizationRoutes()
]
export default RouteConfigs
