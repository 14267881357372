import { createAsyncThunk } from '@reduxjs/toolkit'
import { IExploreFilter } from '@shared/Types'
import { ExploreQueryParam } from '@shared/Services/QueryParamService'
import { TCategory, TPricePackage, TYoutubeVideo } from '@internal/services/ProductService'
import ProductService, { DefaultExploreFilterPagination, ProductMapping } from '@internal/services/ProductService'
import { IExploreSliceState, IReduxThunkReturned } from './redux.types'

export const fetchExploreThunk = createAsyncThunk<IReduxThunkReturned>('fetchExploreThunk', async (_, thunkAPI) => {
  // const state = thunkAPI.getState() as { ExploreSlice: IExploreStateSlice }
  const filter: IExploreFilter = ExploreQueryParam.GetToFilter()
  const res = await ProductService.AllGraphQL(filter, thunkAPI.signal)

  const products = ProductMapping.products(res.mediaStore.youtubeVideos?.items as TYoutubeVideo[])
  const categories = res.mediaStore.categories?.items as TCategory[]
  const prices = ProductMapping.prices(res.mediaStore.pricePackages?.items as TPricePackage[])

  ExploreQueryParam.Set(filter)
  filter.pagination = { ...(filter.pagination ?? DefaultExploreFilterPagination), total: res.mediaStore.youtubeVideos?.totalItems }
  return { products, categories, prices, filter }
})

export const fetchExploreFilterThunk = createAsyncThunk<Omit<IReduxThunkReturned, 'prices'>>('fetchExploreFilterThunk', async (_, thunkAPI) => {
  const state = thunkAPI.getState() as { ExploreSlice: IExploreSliceState }
  const qParam = ExploreQueryParam.GetToFilter()

  const mediaType = state.ExploreSlice.filter.type || qParam.type || 'Video'
  const filter: IExploreFilter = { ...state.ExploreSlice.filter, type: mediaType }
  const res = await ProductService.Filter(filter, thunkAPI.signal)

  ExploreQueryParam.Set(filter)

  const products = ProductMapping.products(res.mediaStore.youtubeVideos?.items as TYoutubeVideo[])
  const categories = res.mediaStore.categories?.items as TCategory[]

  filter.pagination = { ...(filter.pagination ?? DefaultExploreFilterPagination), total: res.mediaStore.youtubeVideos?.totalItems }
  return { products, categories, filter }
})
