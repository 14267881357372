import { RouteKey } from '@internal/route'
import { GetMRParam } from '@lib/Layout/ModalRoute/helper'
import { QueryParam } from '../Helpers'

interface IEmpty {}

export interface IRoutePathOption<TModel extends IEmpty = IEmpty> {
  modal?: RouteKey | RouteKey[]
  query?: TModel
}

export interface IRoutePath<TModel extends IEmpty = IEmpty> extends IRoutePathOption<TModel> {
  route: RouteKey | RouteKey[]
}

export const CreateRoutePath = <TModel extends IEmpty = IEmpty>(route: RouteKey | RouteKey[], options?: IRoutePathOption<TModel>) => {
  const query = Object.assign({}, options?.query, options?.modal ? { md: options?.modal } : {})
  const queryString = QueryParam.stringify(query)
  const routes = new Set(Array.isArray(route) ? route : route ? [route] : [])
  const list = Array.from(routes)
  let path = list.join('/')
  if (list[0] === '/') path = ''
  return `/${path}${queryString === '?' ? '' : queryString}`
}

export const CreateRoutePathWithCurrent = <TModel extends IEmpty = IEmpty>(options?: IRoutePathOption<TModel>) => {
  let query = QueryParam.GetAll()
  query = Object.assign({}, query, options?.query ?? {}, options?.modal ? { md: options?.modal } : {})
  const queryString = QueryParam.stringify(query)
  const baseUrl = '/' + GetBaseUrl()
  return `${window.location.pathname?.replace(baseUrl, '')}${queryString === '?' ? '' : queryString}`
}

export const GetBaseUrl = () => {
  let baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')
  // eslint-disable-next-line no-useless-escape
  return baseUrl?.replace(/[\/]/gi, '')
}

//if routeKeys is empty then remove all
export const RemoveRouteModalLink = (...routeKeys: RouteKey[]) => {
  const baseUrl = '/' + GetBaseUrl()
  const origin = window.location.pathname?.replace(baseUrl, '')
  let queryParsed = QueryParam.GetAll()
  if (!routeKeys.length) {
    delete queryParsed['md']
  } else {
    const mds = GetMRParam()
    const routeSet = new Set(routeKeys.map((x) => x?.toLocaleLowerCase().trim().toString()))
    const leftJoin = mds.filter((x) => !routeSet.has(x?.trim().toLocaleLowerCase()))
    leftJoin.length ? (queryParsed['md'] = leftJoin) : delete queryParsed['md']
  }
  const queryString = QueryParam.stringify(queryParsed)
  return `${origin}${queryString === '?' ? '' : queryString}`
}
