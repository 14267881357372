import React, { FC, PropsWithChildren } from 'react'
import { Grid } from '@mui/material'
import { RouteKey } from '@internal/route'
import { ELazyStatus } from '@lib/ReduxBase'
import { CreateRoutePath } from '@lib/RouteBase'
import { UIButtonBack, UIClientContainer } from '@shared/UIStyleds'

interface IProps {
  status?: ELazyStatus
  payment: JSX.Element
  cart: JSX.Element
  top?: JSX.Element
}

const UILayout: FC<PropsWithChildren<IProps>> = (props) => (
  <UIClientContainer sx={{ px: { md: '0 !important' } }}>
    {props.top}
    <Grid container spacing={4}>
      <Grid item xs={12} md={7}>
        {props.cart}
        <UIButtonBack to={CreateRoutePath(RouteKey.Cart)} sx={{ mt: '24px' }}>
          Continue edit
        </UIButtonBack>
      </Grid>
      <Grid item xs={12} md={5}>
        {props.payment}
      </Grid>
    </Grid>
    {props.children}
  </UIClientContainer>
)

export default UILayout
