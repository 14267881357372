import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { EBillingStatus } from '@shared/Types'
import { Button, ButtonProps, IconButton, IconButtonProps, styled, Tooltip } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import ListAltIcon from '@mui/icons-material/ListAlt'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'

interface IBaseProps {
  to: string
  target?: React.HTMLAttributeAnchorTarget
  disabled?: boolean
}

export const UIButtonDetail = styled((props: IconButtonProps & IBaseProps) => (
  <Tooltip title={props.disabled === true ? 'No detail' : 'Detail'}>
    <div>
      <IconButton component={Link} color='info' {...props}>
        <ListAltIcon />
      </IconButton>
    </div>
  </Tooltip>
))({
  cursor: 'pointer'
})

export const UIButtonPayment: FC<IBaseProps & { status: EBillingStatus }> = (props) => (
  <IconButton component={Link} to={props.to} target={props.target} sx={{ cursor: 'pointer' }}>
    <MonetizationOnIcon sx={{ color: props.status === EBillingStatus.Paid ? '#357a38' : 'goldenrod' }} />
  </IconButton>
)

export const UIButtonPaymentLabel = styled(({ to, target, ...props }: ButtonProps & { to: string; target?: React.HTMLAttributeAnchorTarget }) => (
  <Button component={Link} to={to} target={target} variant='outlined' startIcon={<MonetizationOnIcon sx={{ color: 'goldenrod' }} />} {...props}>
    Payment
  </Button>
))({
  margin: '0 5px',
  height: '30px'
})

export const UIButtonEdit: FC<IconButtonProps> = (props) => (
  <IconButton color='primary' {...props}>
    <EditIcon fontSize='small' />
  </IconButton>
)

export const UIButtonDelete: FC<IconButtonProps> = (props) => (
  <IconButton color='error' {...props}>
    <DeleteIcon fontSize='small' />
  </IconButton>
)
