import React, { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { WindowScrollToTop } from '@lib/Helpers'
import { ICheckOutReduxProps } from './redux.types'

export const useMoveHome = () => {}

const HookCheckOutLifeCycleBase: FC<ICheckOutReduxProps> = (props) => {
  const navigate = useNavigate()

  useEffect(() => {
    WindowScrollToTop()
    return () => {}
  }, [])

  useEffect(() => {
    if (props.slice.loaded && props.slice.carts.length < 1) {
      navigate('/home')
    }
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.slice.carts, props.slice.loaded])

  return <></>
}

export const HookCheckOutLifeCycle = React.memo(HookCheckOutLifeCycleBase, (prevProps, nextProps) => {
  if (nextProps.slice.loaded && nextProps.slice.carts.length < 1) {
    return true
  }
  return false
})
