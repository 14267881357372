import { ApiAlertContext } from '@lib/ApiContext'
import { createAsyncThunk } from '@reduxjs/toolkit'
import OrderService, { OrderMapping } from '@internal/services/OrderService'
import { IReduxThunkArg, IReduxThunkReturned } from './redux.types'

export const fetchOrderDetailThunk = createAsyncThunk<IReduxThunkReturned, IReduxThunkArg | undefined>('fetchOrderDetailThunk', async (param, thunkAPI) => {
  // const state = thunkAPI.getState() as { OrderDetailSlice: IOrderDetailStateSlice }
  if (!param?.id) {
    ApiAlertContext.ApiAlert?.PushError('Order not found!')
    return {}
  }
  const result = await OrderService.GraphQLSingle({ id: param.id }, thunkAPI.signal)
  const dataResponse: IReduxThunkReturned = { dataDetail: OrderMapping.order(result) }
  return dataResponse
})
