import React, { Component } from 'react'
import { motion } from 'framer-motion'
import { Box, Fade, styled, Typography } from '@mui/material'

interface IProps {
  data: string[]
  maxLink?: number
  components?: {
    actionRow?: (value: string) => JSX.Element
    bottom?: JSX.Element
  }
}

export class LinkRows extends Component<IProps> {
  render() {
    return (
      <Box sx={{ transition: 'leaner 0.2s' }}>
        {this.renderItems()}
        {this.props.components?.bottom && this.props.components.bottom}
      </Box>
    )
  }

  renderItems = () => {
    const list = this.props.data
    return (
      <WrapList length={list.length} max={this.props.maxLink}>
        {list.map((item, index) => {
          const action = this.props.components?.actionRow ? this.props.components.actionRow(item) : undefined
          return (
            <li key={item}>
              <LinkRow value={item} index={index} action={action} />
            </li>
          )
        })}
      </WrapList>
    )
  }
}
export default LinkRows

interface ILinkRowProps {
  value: string
  index?: number
  action?: JSX.Element
}

export class LinkRow extends Component<ILinkRowProps> {
  render() {
    const { value, index } = this.props
    return (
      <Item
        sx={{ borderRadius: '6px' }}
        initial={{ opacity: 0.3, backgroundColor: '#dedede' }}
        animate={{ opacity: 1, backgroundColor: '#fafafa' }}
        transition={{ ease: 'linear', duration: 0.5 }}
      >
        <div>
          <Fade in={index !== undefined} unmountOnExit>
            <Typography variant='subtitle2' sx={{ color: '#606060', lineHeight: 1 }} className='noselect'>
              {(index ?? 0) + 1}.
            </Typography>
          </Fade>
          <CustomText variant='subtitle2' noWrap {...{ component: 'a', href: value, target: '_blank' }}>
            {value}
          </CustomText>
          {this.props.action && this.props.action}
        </div>
      </Item>
    )
  }
}

const WrapList = styled('ul')<{ length: number; max?: number }>(({ length, max }) => ({
  marginTop: '12px',
  maxHeight: max ? `${(max + 1) * 42}px` : undefined,
  overflow: 'auto',
  paddingRight: max ? (length > max ? `6px` : 0) : '12px'
}))

const Item = styled(motion.div)({
  '& > div': {
    height: '38px',
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    marginTop: '4px',
    padding: '3px 9px'
  }
})

const CustomText = styled(Typography)({
  color: '#606060',
  flex: 1,
  textDecoration: 'unset !important',
  '&:hover': {
    color: '#1976d2'
  }
})
