import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Typography } from '@mui/material'
import { ELazyStatus } from '@lib/ReduxBase'
import { CreateHocLazy } from '@lib/ReduxBase'
import { WindowScrollToTop } from '@lib/Helpers'
import { ApiAlertContext } from '@lib/ApiContext'
import { Params, useParams } from 'react-router-dom'
import { GetMediaDetailQueryParam, IMediaDetailQueryParam } from '@shared/Services/QueryParamService'
import { ChooseLicenseMapRedux } from '../Cart'
import { IMediaDetailReduxProps } from './redux.types'
import { mapDispatchToProps, mapStateToProps } from './redux.map'
import { AudioSingleEventEmitter, IRecommendedMediasContext, RecommendedMediasContext } from './ui/ComponentContext'
import Layout from './ui.layout'
import UIPlayer from './ui.player'
import UICategories from './ui.categories'
import UIRecommendProduct from './ui.recommend.product'

interface IProps extends IMediaDetailReduxProps {
  params?: Readonly<Params<string>>
}

class ViewBaseContent extends Component<IProps> implements IRecommendedMediasContext {
  constructor(props: any) {
    super(props)
    this.audioEvents = new AudioSingleEventEmitter()
  }
  audioEvents: AudioSingleEventEmitter
  render() {
    const isLoading = this.props.status === ELazyStatus.Loading
    const qParam = GetMediaDetailQueryParam()
    return (
      <RecommendedMediasContext.Provider value={this}>
        <Layout
          isLoading={isLoading}
          player={
            <UIPlayer
              resourceId={qParam.id}
              product={this.props.Slice.productInfo}
              controlBar={
                <Typography component='h5' variant='h6' sx={{ flex: 1, mt: '15px' }}>
                  {this.props.Slice.productInfo?.Title}
                </Typography>
              }
            />
          }
          category={<UICategories data={this.props.Slice.categories} mediaType={qParam.type} />}
          license={<ChooseLicenseMapRedux prices={this.props.Slice.prices} product={this.props.Slice.productInfo} />}
          recommend={
            <UIRecommendProduct data={this.props.Slice.recommendProducts} prices={this.props.Slice.prices} onSubmitAddToCart={this.props.addToCart} />
          }
        />
      </RecommendedMediasContext.Provider>
    )
  }

  componentDidMount() {
    this.fetchAPI()
  }

  shouldComponentUpdate(): boolean {
    const obj = GetMediaDetailQueryParam()
    if (obj?.id !== this.queryParam?.id) {
      this.fetchAPI()
      return false
    }
    return true
  }

  queryParam?: IMediaDetailQueryParam
  fetchAPI = () => {
    const obj = GetMediaDetailQueryParam()
    this.queryParam = obj
    if (!obj.id) {
      ApiAlertContext.ApiAlert?.PushWarning('No media found!')
      return
    }
    this.props.fetchRefresh()
    WindowScrollToTop()
  }

  getRecommendedMedias = () => {
    const list = this.props.Slice.recommendProducts
    return list.filter((x) => x.Id !== this.queryParam?.id)
  }
}
const ViewBase: React.FC<IMediaDetailReduxProps> = (props) => {
  return <ViewBaseContent {...props} params={useParams()} />
}

export * from './redux.thunks'
export { default as MediaDetailSlice } from './redux.slice'

const HocLazyInstance = CreateHocLazy(ViewBase)
export const MediaDetailMapRedux = connect(mapStateToProps, mapDispatchToProps)(HocLazyInstance)
