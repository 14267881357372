import React, { Component } from 'react'
import { ICart } from '@shared/Types'
import { LoadingButton } from '@mui/lab'
import { CartShared } from '@shared/Pages'
import { Box, styled, Typography } from '@mui/material'
import { ECheckOutValidateStatus } from '@shared/Types'
import CartHelpers from './helpers'

interface IInfoConfig {
  key: string
  value: string | number
  big?: boolean
}

interface IProps {
  data: ICart[]
  checkOutStatus?: ECheckOutValidateStatus
  onCheckOut?: () => void
  disabled?: boolean
}

export default class SummaryReport extends Component<IProps> {
  render() {
    return (
      <Wrap>
        <CartShared.UIInfoTop title='Summary' />
        {this.renderInfo()}
        <LoadingButton
          fullWidth
          variant='contained'
          size='large'
          color='primary'
          sx={{ flex: '0 0 auto', mt: '20px' }}
          disabled={this.props.disabled}
          onClick={this.props.onCheckOut}
          loading={this.props.checkOutStatus === ECheckOutValidateStatus.Loading}
        >
          Check Out
        </LoadingButton>
      </Wrap>
    )
  }

  renderInfo = () => {
    const configs = this.getInfoConfig()
    return configs.map((item, index) => (
      <Box key={index} sx={{ display: 'flex', alignItems: 'flex-end', mt: '20px' }}>
        <Typography variant='subtitle1' sx={{ flex: 1 }}>
          {item.key}:
        </Typography>
        <Typography variant={item.big ? 'h5' : 'subtitle1'} sx={{ fontWeight: 700 }}>
          {item.value}
        </Typography>
      </Box>
    ))
  }

  getInfoConfig = (): IInfoConfig[] => {
    const total = CartHelpers.getTotal(this.props.data)
    const totalLink = CartHelpers.getTotalLinks(this.props.data)
    const list: IInfoConfig[] = [
      { key: 'Quantity', value: this.props.data.length },
      { key: 'Link totals', value: totalLink },
      { key: 'VAT', value: '10%' },
      { key: 'Total', value: `$ ${total}`, big: true }
    ]
    return list
  }
}

const Wrap = styled(Box)({
  position: 'sticky',
  top: 'calc(var(--height-header) + 20px)',
  padding: '15px 20px',
  background: '#fff',
  boxShadow: 'rgba(99, 99, 99, 0.1) 0px 2px 8px 0px',
  borderRadius: '4px'
})
