import { ISubmission } from '@shared/Types'
import { ESubmissionStep } from '@shared/Types'
import dayjs from 'dayjs'

export const SubmitMediaData = {
  complete: {
    description: `A digital contract has been sent to your email. Please check your inbox and follow the instructions to sign it.`
  }
}

export const MapSubmitData = (value: Partial<ISubmission>): Partial<ISubmission> => {
  delete value.IsReadAndAgreedTermOdService
  delete value.IsReadAndAgreedTermOdSubmitssion

  value.Birthday = dayjs(value.Birthday).toDate().toISOString()
  value.FlatformRepository = 'fb'
  return value
}

interface ISubmisstionLSD {
  item: ISubmission
  stepDones: ESubmissionStep[]
}

class SubmitMediaLocalStorageBase {
  private key = 'lssd_01'

  setData = (item: ISubmission, stepDone: ESubmissionStep) => {
    const stepDones = new Set<ESubmissionStep>(this.getData()?.stepDones ?? [])
    stepDones.add(stepDone)
    const str = JSON.stringify({ item, stepDones: Array.from(stepDones) })
    localStorage.setItem(this.key, str)
  }

  getData = (): ISubmisstionLSD | undefined => {
    try {
      const str = localStorage.getItem(this.key) ?? ''
      return JSON.parse(str)
    } catch (error) {}
  }

  clear = () => {
    localStorage.removeItem(this.key)
  }
}
export const SubmitMediaLocalStorage = new SubmitMediaLocalStorageBase()
