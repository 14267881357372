import React, { FC } from 'react'
import { connect } from 'react-redux'
import { RouteKey } from '@internal/route'
import { Box, styled } from '@mui/material'
import { ELazyStatus } from '@lib/ReduxBase'
import { MapNavigate } from '@lib/ApiContext'
import { AppDispatch } from '@internal/redux'
import { CreateRoutePath } from '@lib/RouteBase'
import { AppStatusSlice } from '@internal/redux/Slices'
import { QuestionPreviews } from '@shared/Pages/Submission'
import SubmissionService from '@internal/services/SubmissionService'
import { MapSubmitData, SubmitMediaLocalStorage } from './helpers'
import FormDigitalSignature from './form.digital-signature'

interface IDispatchRedux {
  onChangeStatus: (value: ELazyStatus) => void
}

interface IProps extends IDispatchRedux {}

const StepDigitalSignature: FC<IProps> = (props) => {
  const data = SubmitMediaLocalStorage.getData()
  return (
    <Box sx={{ marginTop: '48px' }}>
      <QuestionPreviews.AboutMedia data={data?.item ?? {}} />
      <QuestionPreviews.AboutYourself data={data?.item ?? {}} />
      <QuestionPreviews.CopyrightOwnership data={data?.item ?? {}} />
      <DigitalSignatureWrap>
        {MapNavigate((navigate) => (
          <FormDigitalSignature
            slots={{ sx: { maxHeight: 'unset!important' } }}
            data={data?.item}
            onSubmit={async (value) => {
              props.onChangeStatus(ELazyStatus.Loading)
              try {
                await SubmissionService.Create(MapSubmitData(value))
                // TODO SubmitMedia - open/close clear function
                SubmitMediaLocalStorage.clear()
                props.onChangeStatus(ELazyStatus.Loaded)
                navigate(CreateRoutePath([RouteKey.Submit, RouteKey.SMTComplete]))
              } catch (error) {
                console.log(error)
                props.onChangeStatus(ELazyStatus.Error)
              }
            }}
          />
        ))}
      </DigitalSignatureWrap>
    </Box>
  )
}

const mapDispatchToProps = (dispatch: AppDispatch): IDispatchRedux => ({
  onChangeStatus: (value) => {
    dispatch(AppStatusSlice.actions.changeLoading(value))
  }
})

const StepDigitalSignatureMapRedux = connect(() => ({}), mapDispatchToProps)(StepDigitalSignature)

export default StepDigitalSignatureMapRedux

const DigitalSignatureWrap = styled(Box)({
  width: '100%',
  marginTop: '36px',
  padding: '0 16px'
})
