import React, { Component } from 'react'
import { connect } from 'react-redux'
import { CreateHocLazy } from '@lib/ReduxBase'
import { EPolicyFileType } from '@shared/Types'
import { WindowScrollToTop } from '@lib/Helpers'
import { IPolicyFileReduxProps } from './redux.types'
import { mapDispatchToProps, mapStateToProps } from './redux.map'
import { UIWrap } from './uis'
import PdfPreviewReactQuill from './pdf-preview-react-quill'

interface IProps extends IPolicyFileReduxProps {}

class PolicyFilePage extends Component<IProps> {
  // render() {
  //   const imageUri = this.props.slice.galleryPdfInfo?.ImageUri
  //   const fileUrl = GetUrlPdfFlipBook(imageUri ?? '')
  //   return <UIWrap>{imageUri && <PdfViewerFlipbook src={fileUrl} />}</UIWrap>
  // }

  render() {
    const info = this.props.slice.galleryPdfInfo
    const title = info?.Type === EPolicyFileType.PrivacyPolicy ? 'PRIVACY POLICY' : 'TERMS OF SERVICE'
    return <UIWrap>{info?.Description && <PdfPreviewReactQuill content={info.Description} title={title} />}</UIWrap>
  }

  componentDidMount(): void {
    WindowScrollToTop()
  }
}

export * from './redux.thunks'
export { default as PolicyFileSlice } from './redux.slice'

const HocLazyInstance = CreateHocLazy(PolicyFilePage)
export const PolicyFileMapRedux = connect(mapStateToProps, mapDispatchToProps)(HocLazyInstance)
