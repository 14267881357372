import { createAsyncThunk } from '@reduxjs/toolkit'
import { CartLocalStorage } from '@internal/shareds/LocalStorages'
import CheckOutService from '@internal/services/CheckoutService'
import { IReduxThunkReturned } from './redux.types'

export const fetchCartThunk = createAsyncThunk<IReduxThunkReturned>('fetchCartThunk', async (_, thunkAPI) => {
  // const state = thunkAPI.getState() as { CartSlice: ICartStateSlice }
  const res = await CartLocalStorage.getDataAsync()
  return { carts: res }
})

export const fetchCheckOutValidateThunk = createAsyncThunk<{ status: boolean }>('fetchCheckOutValidateThunk', async (_, thunkAPI) => {
  // const state = thunkAPI.getState() as { CartSlice: ICartStateSlice }
  const dataLocal = CartLocalStorage.getData()
  const res = await CheckOutService.validate(dataLocal)
  return { status: res }
})

export const removeAllCartThunk = createAsyncThunk('fetchRemoveAllCartThunk', async (_, thunkAPI) => {})
