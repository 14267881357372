import React, { FC } from 'react'
import { Typography } from '@mui/material'
import { ICategory, IYoutubeVideo } from '@shared/Types'
import { ISelectMultipleOption } from '@lib/Forms/Inputs'
import { CreateInputTextMultiline, CreateSelectMultiple } from '@lib/Forms/Inputs'
import { FormValidator, SingleRuleValidate, FormModalWrapper, CreateFormGridLayout, CreateFormComfirm } from '@lib/Forms'
import { IFormUpload } from './form.types'
import { ExcelAcceptInput, TryParseYtVideoTagsFromForm } from './form.helpers'
import FormInputFile from './form.input-file'
import FormSelectCategories from './form.select.categories'

// ========= ========= ========= Form Upload Grid Layout ========= ========= =========
interface IFormUploadParam {
  categories: ICategory[]
}

const SelectCategoriesInstance = CreateSelectMultiple<IFormUpload>()

const GetFormUploadGridLayout = (param: IFormUploadParam) => {
  const categories = param.categories?.map<ISelectMultipleOption>((x) => ({ Id: x.Id, Label: x.Name })) ?? []
  return CreateFormGridLayout<IFormUpload>({
    configs: [
      { key: 'Categories', label: 'Categories', inputElement: (p) => <SelectCategoriesInstance {...p} options={categories} /> },
      { key: 'ExcelFile', inputElement: (p) => <FormInputFile {...p} accept={ExcelAcceptInput} /> }
    ],
    validate: new FormValidator({
      Categories: { Rules: [{ rule: SingleRuleValidate.Required }] },
      ExcelFile: { Rules: [{ rule: SingleRuleValidate.FileRequired }] }
    }),
    submitMapping: (value) => {
      value.Categories = TryParseYtVideoTagsFromForm(value.Categories ?? '')
      return value
    }
  })
}

// ========= ========= ========= Form Upload ========= ========= =========
interface IFormUploadProps extends IFormUploadParam {
  onSubmit: (value: Partial<IFormUpload>) => Promise<void>
}

export const FormUpload: FC<IFormUploadProps> = (props) => {
  const FormUploadGridLayout = GetFormUploadGridLayout({ categories: props.categories })
  return (
    <FormModalWrapper title='Upload' size='md'>
      <FormUploadGridLayout onSubmit={props.onSubmit} />
    </FormModalWrapper>
  )
}

// ========= ========= ========= Form Grid Layout ========= ========= =========
interface IFormParam {
  options: ISelectMultipleOption[]
}

const InputDescriptionInstance = CreateInputTextMultiline<IYoutubeVideo>({ options: { multiline: { minRows: 3, maxRows: 5 } } })

const GetFormGridLayout = (param: IFormParam) => {
  return CreateFormGridLayout<IYoutubeVideo>({
    configs: [
      { key: 'Title', label: 'Name' },
      {
        key: 'Tags',
        label: 'Tags',
        inputElement: (props) => <FormSelectCategories {...props} options={param.options} />
      },
      { key: 'Description', label: 'Description', inputElement: InputDescriptionInstance }
    ],
    validate: new FormValidator({
      Title: { Rules: [{ rule: SingleRuleValidate.Required }] }
    }),
    submitMapping: (value) => {
      value.Tags = TryParseYtVideoTagsFromForm(value.Tags ?? '')
      return value
    }
  })
}

// ========= ========= ========= Form Create ========= ========= =========
// interface IFormCreateProps {
//   onSubmit: (value: Partial<IYoutubeVideo>) => Promise<void>
// }

// export const FormCreate: FC<IFormCreateProps> = (props) => (
//   <FormModalWrapper title='Create' size='md'>
//     <FormGridLayoutInstance onSubmit={props.onSubmit} />
//   </FormModalWrapper>
// )

// ========= ========= ========= Form Edit ========= ========= =========
interface IFormEditProps extends IFormParam {
  data: IYoutubeVideo
  onSubmit: (value: Partial<IYoutubeVideo>) => Promise<void>
}

export const FormEdit: FC<IFormEditProps> = ({ options, ...props }) => {
  const FormGridLayoutInstance = GetFormGridLayout({ options })
  return (
    <FormModalWrapper title='Edit' size='md'>
      <FormGridLayoutInstance data={props.data} onSubmit={props.onSubmit} />
    </FormModalWrapper>
  )
}

// ========= ========= ========= Form Delete ========= ========= =========
export const FormDelete = CreateFormComfirm<IYoutubeVideo>({
  title: 'Are yout sure delete?',
  content: (x) => <Typography variant='subtitle2'>{x?.Title}</Typography>
})
