import React, { FC } from 'react'
import { Box, BoxProps, Grid, Skeleton, styled } from '@mui/material'

export const UISkeletonCategories: FC = () => (
  <div>
    {[...Array(9)].map((_, i) => (
      <Box key={'key' + i} sx={{ display: 'flex', alignItems: 'center', m: '6px 0' }}>
        <Skeleton variant='rounded' width='50px' height='50px' sx={{ flex: '0 0 auto' }} />
        <Skeleton variant='text' height='24px' sx={{ ml: '12px', flex: 1 }} />
        <Skeleton variant='text' width='36px' height='24px' sx={{ mr: '12px', ml: '9px' }} />
      </Box>
    ))}
  </div>
)

export const UISkeletonVideoFilterResult: FC = () => (
  <Grid container spacing={2} sx={{ mt: 0 }}>
    {[...Array(12)].map((_, i) => (
      <Grid item key={'key' + i} xs={12} sm={6} md={4} lg={3}>
        <Box sx={{ borderRadius: '6px', backgroundColor: '#fff', boxShadow: '0 2px 14px rgba(0, 0, 0, 0)' }}>
          <SkeletonWrap>
            <Skeleton variant='rounded' width='100%' height='100%' />
          </SkeletonWrap>
          <Box sx={{ p: '12px' }}>
            <Box sx={{ height: '45px' }}>
              <Skeleton variant='text' height='1rem' />
              <Skeleton variant='text' height='1rem' />
            </Box>
            <Skeleton variant='text' height='20px' width='120px' sx={{ mt: '6px' }} />
          </Box>
        </Box>
      </Grid>
    ))}
  </Grid>
)

const SkeletonWrap = styled(({ children, ...p }: BoxProps) => (
  <Box {...p}>
    <Box>{children}</Box>
  </Box>
))(({ theme }) => ({
  width: '100%',
  paddingBottom: theme.app.imageAspectRatio,
  position: 'relative',
  '& > div': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  }
}))
