import React, { Component } from 'react'
import { Box, Fade, alpha } from '@mui/material'
import { ELazyStatus } from '@lib/ReduxBase'

interface IProps {}
interface IState {
  loadingStatus: ELazyStatus
}

export default class UILoadingView extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = { loadingStatus: ELazyStatus.Loaded }
  }

  render() {
    return (
      <Fade in={this.state.loadingStatus === ELazyStatus.Loading}>
        <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: alpha('#fff', 0.5) }} />
      </Fade>
    )
  }

  load = () => {
    this.setState({ loadingStatus: ELazyStatus.Loading })
  }

  stop = () => {
    this.setState({ loadingStatus: ELazyStatus.Loaded })
  }
}
