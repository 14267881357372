import { createAsyncThunk } from '@reduxjs/toolkit'
import { GetMediaDetailQueryParam } from '@shared/Services/QueryParamService'
import ProductService, { ProductMapping, TPricePackage, TYoutubeVideo } from '@internal/services/ProductService'
import { ICategoryDTO } from './types'
import { IReduxThunkReturned } from './redux.types'

export const fetchMediaDetailThunk = createAsyncThunk<IReduxThunkReturned>('fetchMediaDetailThunk', async (params, thunkAPI) => {
  const defaultValue: IReduxThunkReturned = { recommendProducts: [], prices: [], categories: [] }
  const qParam = GetMediaDetailQueryParam()
  if (!qParam.id) return defaultValue
  const res = await ProductService.DetailById(qParam.id, { type: qParam.type, sort: 'Lastet', pagination: { page: 1, pageSize: 12 } })

  const productInfo = ProductMapping.product(res.mediaStore.youtubeVideo as TYoutubeVideo)
  const categories = res.mediaStore.categories?.items.map<ICategoryDTO>((x) => ({ value: x.name ?? '' })).filter((x) => !!x.value) || []
  const prices = ProductMapping.prices(res.mediaStore.pricePackages?.items as TPricePackage[])
  const recommendProducts = ProductMapping.products(res.mediaStore.youtubeVideos?.items as TYoutubeVideo[])
  return { productInfo, recommendProducts, prices, categories }
})
