import { styled } from '@mui/material'
import { UIHomeLink } from '@shared/UIStyleds'

export const UIWrapLogo = styled(UIHomeLink)(({ theme }) => ({
  cursor: 'pointer',
  display: 'inline-block',
  width: '160px',
  flex: '0 0 auto',
  minHeight: '70px',
  '& > img': {
    width: '100%'
  },
  [theme.breakpoints.down('xl')]: {
    width: '128px'
  }
}))
