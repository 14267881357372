import { ReduxBase } from '@lib/ReduxBase'
import { ELazyStatus } from '@lib/ReduxBase'
import { createSlice } from '@reduxjs/toolkit'
import { ITicketDetailSliceState } from './redux.types'
import { fetchTicketDetailThunk } from './redux.thunks'

export const DefaultSlice = ReduxBase.GetInitialSlice<ITicketDetailSliceState>({ state: {} })

const TicketDetailSlice = createSlice({
  name: 'TicketDetailSlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState: DefaultSlice.initialState,
  reducers: { ...DefaultSlice.reducers },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTicketDetailThunk.fulfilled, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.status = ELazyStatus.Loaded
        state.ticket = action.payload.ticket
        state.refreshStatus = false
      })
      .addCase(fetchTicketDetailThunk.rejected, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.status = ELazyStatus.Error
      })
      .addCase(fetchTicketDetailThunk.pending, (state, action) => {
        state.requestedId = action.meta.requestId
        state.status = ELazyStatus.Loading
        if (action.meta.arg?.cleaned) state.ticket = undefined
        if (action.meta.arg?.isRefresh) state.refreshStatus = true
      })
  }
})

export default TicketDetailSlice
