import React, { FC } from 'react'
import { useLocation } from 'react-router-dom'
import { CreateRoutePath } from '@lib/RouteBase'
import { styled, useMediaQuery, useTheme } from '@mui/material'
import { GetImageUrlInternal, headerNavClasses } from '@shared/UIStyleds'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AppConfig from '../AppConfig'
import UIRollingText from '../ui.rolling-text'

interface IProps {
  data: AppConfig.IHeaderNav[]
  disableRollingText?: boolean
  mobileMode?: boolean
  onClickItem?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const AppHeaderNav: FC<IProps> = (props) => {
  const location = useLocation()
  const classes = [headerNavClasses.root, 'header-nav-area']
  if (props.mobileMode) classes.push(headerNavClasses.mobileMode)
  return (
    <ul className={classes.join(' ')}>
      {props.data.map((x, i) => {
        const classes = ['header-nav-item', x.children?.length ? 'underline-1' : '']
        const isActive = x.url?.type === 'Internal' && location.pathname.includes(CreateRoutePath(x.url.value))
        if (isActive) classes.push('active')
        const WrapLabel = props.disableRollingText !== true ? UIRollingText : React.Fragment
        return (
          <CustomLi key={`key-${i}`} className={classes.filter((x) => !!x).join(' ')}>
            <AppConfig.AppLink url={x.url} className='nav-item-link' onClick={props.onClickItem}>
              <WrapLabel>{x.label}</WrapLabel>
            </AppConfig.AppLink>
            {x.children && <HeaderNavSub data={x.children} />}
          </CustomLi>
        )
      })}
    </ul>
  )
}
export default AppHeaderNav

const HeaderNavSub: FC<IProps> = (props) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('xl'))
  const navs = props.data ?? []
  const list = matches ? navs : navs.filter((x) => x.mobileOnly !== true)
  return (
    <>
      <ExpandMoreIcon sx={{ fontSize: '24px' }} />
      <ul className='sub-menu' style={{ display: 'block' }}>
        {list.map((x, i) => (
          <li key={i} className='menu-item'>
            <AppConfig.AppLink url={x.url} className='memu-item-link'>
              {x.label}
            </AppConfig.AppLink>
          </li>
        ))}
      </ul>
    </>
  )
}

const CustomLi = styled('li')({
  '&.underline-1::before ': {
    maskImage: `url(${GetImageUrlInternal('unit-01')})`
  },
  '& a': {
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '34px'
  }
})
