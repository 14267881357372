import React, { FC, useEffect, useRef } from 'react'
import { styled, Typography, TypographyProps } from '@mui/material'

interface IProps {
  children: string
  className?: string
}

const UIRollingText: FC<IProps> = (props) => {
  const textRef = useRef<HTMLSpanElement>(null)

  useEffect(() => {
    const element = textRef.current
    if (element) {
      let innerText = element.textContent || ''
      element.innerHTML = ''

      const textContainer = document.createElement('div')
      textContainer.classList.add('block')

      for (let letter of innerText) {
        const span = document.createElement('span')
        span.textContent = letter.trim() === '' ? '\xa0' : letter
        span.classList.add('letter')
        textContainer.appendChild(span)
      }

      element.appendChild(textContainer)
      element.appendChild(textContainer.cloneNode(true))
    }
  }, [])

  const getClassName = () => {
    return ['rolling-text', props.className].filter((x) => !!x).join(' ')
  }

  return (
    <Wrap ref={textRef} className={getClassName()}>
      {props.children}
    </Wrap>
  )
}
export default UIRollingText

const Wrap = styled(
  React.forwardRef<HTMLSpanElement, TypographyProps>((props, ref) => <Typography ref={ref} component='span' variant='h6' {...props} />)
)({
  fontWeight: 700,
  fontSize: '18px',
  display: 'inline-block',
  overflow: 'hidden',
  lineHeight: '34px !important',
  height: '34px !important',
  width: 'max-content',
  '& .letter': {
    display: 'inline-block',
    transition: 'transform 0.5s cubic-bezier(0.76, 0, 0.24, 1)'
  },
  '&:hover .letter, &.play .letter': {
    webkitTransform: 'translateY(-100%)',
    msTransform: 'translateY(-100%)',
    transform: 'translateY(-100%)'
  }
})
