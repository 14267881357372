import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ITicket } from '@shared/Types'
import { RouteKey } from '@internal/route'
import { TicketShared } from '@shared/Pages'
import { CreateHocLazy } from '@lib/ReduxBase'
import { CreateRoutePath } from '@lib/RouteBase'
import { WindowScrollToTop, TableFormater } from '@lib/Helpers'
import { ActionPannel, CreateTableTemplate, CRUDPannel, MapOprators } from '@lib/Table'
import { GlobalModal, IGlobalModalContext, MapGlobalModalContext } from '@lib/ApiContext'
import UIStyleds from '@shared/UIStyleds'
import { ITicketReduxProps } from './redux.types'
import { mapDispatchToProps, mapStateToProps } from './redux.map'

interface ITableParams {
  onChangeStatus: (value: ITicket, context: IGlobalModalContext) => Promise<void>
}

const GetTableInstance = (params: ITableParams) => {
  return CreateTableTemplate<ITicket>('Redux', {
    getRowId: (x) => x.Id,
    config: {
      Subject: { type: 'string', flex: 1, minWidth: 190 },
      Type: { type: 'string', minWidth: 140, filterable: false },
      DateCreated: { type: 'string', minWidth: 175, filterable: false, renderCell: (params) => TableFormater.tooltipDate(params.value) },
      Status: {
        type: 'string',
        minWidth: 140,
        filterable: false,
        renderCell: (x) => {
          return MapGlobalModalContext((context) => (
            <TicketShared.UIStatusSwitch
              disableWhenClosed
              status={x.value}
              onChange={async (status) => {
                const data = x.row as ITicket
                return params.onChangeStatus({ ...data, Status: status }, context)
              }}
            />
          ))
        }
      }
    },
    filterOperators: MapOprators, //server mode,
    minWidthColumnActions: 140
  })
}

interface IProps extends ITicketReduxProps {}

class ViewBase extends Component<IProps> {
  TableInstance: ReturnType<typeof GetTableInstance>
  constructor(props: IProps) {
    super(props)
    this.TableInstance = GetTableInstance({
      onChangeStatus: this.handleChangeStatus
    })
  }
  render() {
    const { TableInstance } = this
    return (
      <GlobalModal>
        <UIStyleds.WrapProfile>
          <TableInstance
            ReduxOption={this.props.tableInfo}
            onChange={this.props.onChangeTableInfo}
            CRUDPannel={() => (
              <CRUDPannel
                Title='Tickets'
                Buttons={{ ExtendAfter: [<TicketShared.TUIBCreate to={CreateRoutePath([RouteKey.Profile, RouteKey.TicketCreate])} />] }}
              />
            )}
            ActionPannel={(p) => {
              const data = p.data as ITicket
              const conversationPath = CreateRoutePath([RouteKey.Profile, RouteKey.Ticket], { query: { id: data.Id }, modal: RouteKey.TicketDetail })
              return <ActionPannel Buttons={{ ExtendAfter: [<TicketShared.UIBConversation to={conversationPath} />] }} />
            }}
          />
        </UIStyleds.WrapProfile>
      </GlobalModal>
    )
  }

  handleChangeStatus = async (value: ITicket, context: IGlobalModalContext) => {
    context.ShowModal({
      backdropActivated: true,
      ContentModal: () => <TicketShared.FormConfirmClose data={value} onSubmit={() => this.props.CRUD.Update(value.Id, value)} />
    })
  }

  componentDidMount() {
    WindowScrollToTop()
  }
}

export { fetchTicketThunk } from './redux.thunks'
export { default as TicketSlice } from './redux.slice'

const HocLazyInstance = CreateHocLazy(ViewBase)
export const TicketMapRedux = connect(mapStateToProps, mapDispatchToProps)(HocLazyInstance)
