import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { Box, Breadcrumbs, styled, Typography } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'

export interface IBreadcrumbConfig {
  label: string
  url?: string
}

interface IProps {
  configs: IBreadcrumbConfig[]
}

const UIBreadcrumb: FC<IProps> = (props) => {
  const { configs } = props
  return (
    <Breadcrumbs>
      {configs.map((x, i) => (
        <WrapTypography key={'key' + i}>
          <Typography {...(x.url ? { component: Link, to: x.url } : {})} variant='subtitle1' sx={{ fontWeight: 600 }}>
            {i < 1 && <ArrowBackIosIcon sx={{ fontSize: '16px', mb: '3px' }} />}
            <span>{x.label}</span>
          </Typography>
        </WrapTypography>
      ))}
    </Breadcrumbs>
  )
}

export default UIBreadcrumb

const WrapTypography = styled(Box)({
  '& .MuiTypography-root': {
    color: 'var(--color-title)'
  },
  '& a': {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center'
  },
  '&:hover a span': {
    textDecoration: 'underline'
  }
})
