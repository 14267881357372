import { FetchDelay } from '@lib/Helpers'
import { authService } from 'partner-oidc-auth'
import { CreateHttpService } from '@lib/Http/Getway'
import { ENotificationAreaType, ENotificationSendType, INotification } from '@shared/Types'
import Graphql from '@coreprj/graphql'
import ServiceBase from '@lib/Http/ServiceBase'

export type TNotificationInfo = Graphql.QMediaStore.TNotificationInfo
export type TNotificationStatus = Graphql.QMediaStore.TNotificationStatus
type TNotificationInfosGraphQlResult = Graphql.TGraphQlResult<TNotificationInfo>
type TNotificationInfosFilterParams = Graphql.QMediaStore.TNotificationInfosFilterParams
type TAllReponse = {
  NotificationInfos?: TNotificationInfosGraphQlResult
}
const checkTokenExpiry = async () => {
  try {
    const user = await authService.getUser()
    const token = await authService.getAccessToken()
    if (!user || !token) {
      console.log('No user is currently logged in.')
      return true
    }
    const now = Math.floor(Date.now() / 1000) // Current time in seconds since epoch
    const expiresIn = user.exp - now

    if (expiresIn <= 0) {
      console.log('The token has expired.')
      return true
    } else {
      console.log(`The token will expire in ${expiresIn} seconds.`)
      return false
    }
  } catch (error) {
    console.error('Error checking token expiry:', error)
    return true
  }
}
class NotifyServiceBase extends ServiceBase {
  constructor() {
    super(CreateHttpService(`/api/v1/user/NoticationUser`))
  }

  getGraphql = async () => {
    if (await authService.isAuthenticated()) {
      return Graphql.MediaStore
    } else {
      return Graphql.GeustMediaStore
    }
  }

  All = async (params: TNotificationInfosFilterParams, signal?: AbortSignal): Promise<TAllReponse | undefined> => {
    try {
      const dataReponse: TAllReponse = {}
      if ((await authService.isAuthenticated()) && !(await checkTokenExpiry())) {
        const res = await Graphql.MediaStore.Query(Graphql.QMediaStore.NotificationInfosQuery(params), { signal, delay: 0 })
        dataReponse.NotificationInfos = res.notification.notificationInfos as TNotificationInfosGraphQlResult
      } else {
        const res = await Graphql.GeustMediaStore.Query(Graphql.QMediaStore.GuestNotificationInfosQuery(params), { signal, delay: 0 })
        dataReponse.NotificationInfos = res.notification.notificationInfos as TNotificationInfosGraphQlResult
      }
      return dataReponse
    } catch (error: any) {
      // ProcessError(error)
    }
  }

  ReadNotification = async (id: string, signal?: AbortSignal): Promise<undefined> => {
    return FetchDelay(async () => {
      if (await authService.isAuthenticated()) {
        const data = await this.TryPushNotify(this.Put<any>, 'UpdateRead', { IsRead: true }, { signal, params: { NotificationId: id } })
        return data
      } else {
        return true
      }
    }, 500)
  }
}
const NotificationService = new NotifyServiceBase()
export default NotificationService

class NotifyMappingBase {
  getReadedNotificationIds = (items: TNotificationInfo[] = []) => {
    const setIds = items.reduce<Set<string>>((a, b) => {
      b.notificationStatuses?.map((x) => x?.notificationId && a.add(x.notificationId))
      return a
    }, new Set<string>([]))
    return Array.from(setIds)
  }

  notifications = (items: TNotificationInfo[] = [], readed: string[] = []): INotification[] => {
    let temp = this.getReadedNotificationIds(items)
    if (temp.length < 1) temp = readed
    const sIds = new Set(temp)
    return items.map<INotification>((item) => {
      const data: INotification = {
        Id: item.id,
        Title: item?.title ?? '',
        SubTitle: item?.subTitle ?? '',
        Content: item?.content ?? '',
        DateExpired: item?.dateExpired ?? '',
        SendType: item?.sendType as ENotificationSendType,
        AreaType: item?.areaType as ENotificationAreaType,
        IsRead: sIds.has(item.id),
        DateCreated: item?.dateCreated ?? ''
      }
      return data
    })
  }
}
export const NotifyMapping = new NotifyMappingBase()

export const NotifyLocalStorage = {
  key: 'notification_data',

  Set: (notificationIds: string[]) => {
    localStorage.removeItem(NotifyLocalStorage.key)
    localStorage.setItem(NotifyLocalStorage.key, JSON.stringify(notificationIds))
  },

  Get: (): string[] => {
    try {
      const data = localStorage.getItem(NotifyLocalStorage.key)
      return JSON.parse(data ?? '[]')
    } catch (error) {
      return []
    }
  },

  SetSingle: (notificationId: string) => {
    const setIds = new Set(NotifyLocalStorage.Get())
    setIds.add(notificationId)
    NotifyLocalStorage.Set(Array.from(setIds))
  }
}
