import React, { FC } from 'react'
import { Box } from '@mui/material'
import { RouteKey } from '@internal/route'
import { MapNavigate } from '@lib/ApiContext'
import { CreateRoutePath } from '@lib/RouteBase'
import { CreateDatePicker } from '@lib/Forms/Inputs'
import { ISubmission, ESubmissionStep } from '@shared/Types'
import { CreateFormGridLayout, FormValidator, SingleRuleValidate } from '@lib/Forms'
import { UICustomTypographyTitle } from './uis'
import { SubmitMediaLocalStorage } from './helpers'
import UIActionsBar from './ui.actions-bar'

const DatePickerComponent = CreateDatePicker<ISubmission>()

const FormGridLayoutComponent = CreateFormGridLayout<ISubmission>({
  configs: [
    { key: 'Email', label: 'Email' },
    { key: 'FirstName', label: 'First name', reponsives: { md: 6 } },
    { key: 'LastName', label: 'Last name', reponsives: { md: 6 } },
    { key: 'PhoneNumber', label: 'Phone number', reponsives: { md: 6 } },
    { key: 'Birthday', label: 'Birthday', reponsives: { md: 6 }, inputElement: DatePickerComponent }
  ],
  validate: new FormValidator({
    Email: { Rules: [{ rule: SingleRuleValidate.Required }, { rule: SingleRuleValidate.Email }] },
    LastName: { Rules: [{ rule: SingleRuleValidate.Required }] },
    FirstName: { Rules: [{ rule: SingleRuleValidate.Required }] },
    PhoneNumber: { Rules: [{ rule: SingleRuleValidate.Required }, { rule: SingleRuleValidate.PhoneNumber }] },
    Birthday: { Rules: [{ rule: SingleRuleValidate.Required }] }
  })
})

const StepAboutYouself: FC = () => {
  const data = SubmitMediaLocalStorage.getData()
  return (
    <Box sx={{ marginTop: '48px' }}>
      <UICustomTypographyTitle>Tell us more about yourself</UICustomTypographyTitle>
      {MapNavigate((navigate) => (
        <FormGridLayoutComponent
          data={data?.item}
          onSubmit={async (value) => {
            SubmitMediaLocalStorage.setData(Object.assign({}, data?.item, value), ESubmissionStep.AboutYouself)
            navigate(CreateRoutePath([RouteKey.Submit, RouteKey.SMTCopyrightOwnership]))
          }}
          slots={{
            sx: { minHeight: '50vh', display: 'flex', alignItems: 'stretch', justifyContent: 'center', flexDirection: 'column' },
            action: (p) => <UIActionsBar {...p} onPrev={() => navigate(CreateRoutePath([RouteKey.Submit, RouteKey.SMTAboutMedia]))} />
          }}
        />
      ))}
    </Box>
  )
}

export default StepAboutYouself
