import { EOrderStatus } from '@shared/Types'
import CreateStatusCell, { TStatusCellConfig } from '@shared/UIStyleds/create.status-cell'

export const StatusCellConfig: TStatusCellConfig<EOrderStatus> = {
  [EOrderStatus.Draft]: { background: 'linear-gradient(to right,#dedede,#f0f0f0)' },
  [EOrderStatus.Pending]: { background: 'linear-gradient(to right,#0d6efd,#82baff)' },
  [EOrderStatus.Done]: { background: 'linear-gradient(to right,#357a38,#1edd9b)' },
  [EOrderStatus.Error]: { background: 'linear-gradient(to right,#e72134,#eba1a1)' }
}

export const UIStatusCell = CreateStatusCell<EOrderStatus>(StatusCellConfig, { sx: { minWidth: '90px' } })
