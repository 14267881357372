import { AppDispatch, RootState } from '@internal/redux'
import NotificationService, { NotifyLocalStorage } from '@internal/services/NotifyService'
import { fetchNotificationThunk } from './redux.thunks'
import { IReduxMapDispatch, IReduxMapState } from './redux.types'
import NotificationSlice from './redux.slice'

export const mapStateToProps = (state: RootState): IReduxMapState => ({
  status: state.NotificationSlice.status,
  slice: state.NotificationSlice
})

export const mapDispatchToProps = (dispatch: AppDispatch): IReduxMapDispatch => ({
  fetchData: () => {
    return dispatch(fetchNotificationThunk())
  },
  onChangeStatus: (status) => {
    dispatch(NotificationSlice.actions.onChangeStatus(status))
  },
  readNotification: async (value) => {
    NotificationService.ReadNotification(value.Id)
    NotifyLocalStorage.SetSingle(value.Id)
    dispatch(NotificationSlice.actions.Update({ id: value.Id, changes: value }))
  }
})
