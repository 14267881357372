import { ISubmission } from '@shared/Types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISubmitMediaStateSlice } from './redux.types'
import { ReduxBase } from '@lib/ReduxBase'

export const DefaultSlice = ReduxBase.GetInitialSlice<ISubmitMediaStateSlice>({
  state: { submitModel: { IsPeopleAppearingVerify: true } }
})

const SubmitMediaSlice = createSlice({
  name: 'SubmitMediaSlice',
  initialState: DefaultSlice.initialState,
  reducers: {
    ...DefaultSlice.reducers,
    updateSubmitModel: (state, action: PayloadAction<Partial<ISubmission>>) => {
      const newSubmitModel = Object.assign({}, state.submitModel, action.payload)
      state.submitModel = newSubmitModel
    }
  }
})

export default SubmitMediaSlice
