import React, { Component } from 'react'
import { IPrice, IProduct } from '@shared/Types'
import { CartLocalStorage } from '@internal/shareds/LocalStorages'
import { CreateFormBase, FormValidator, SingleRuleValidate, IFormBase } from '@lib/Forms'
import { Box, Button, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'

export interface IFormQuicklyAddToCartData {
  PriceSelectedId?: string
  Prices?: IPrice[]
  Product?: IProduct
}

interface IState {
  Value?: string
}

interface IFormContentProps extends IFormBase<IFormQuicklyAddToCartData> {}
class FormContent extends Component<IFormContentProps, IState> {
  priceIdOld?: string
  constructor(props: IFormContentProps) {
    super(props)
    this.priceIdOld = this.getPriceId()
    this.state = { Value: this.priceIdOld }
  }

  render() {
    const disabled = this.props.disabled || (!!this.priceIdOld && this.priceIdOld === this.state.Value)
    return (
      <Box sx={{ padding: '12px 18px' }}>
        <Typography sx={{ fontSize: '18px', fontWeight: 600, color: '#606060' }}>Pricing Package</Typography>
        <Typography sx={{ fontSize: '14px', color: '#818181' }}>Please chose a video licence</Typography>
        {this.renderRadioGroup()}
        <Button fullWidth type='submit' variant='contained' color='success' sx={{ mt: '12px' }} disabled={disabled}>
          {this.priceIdOld ? 'Update Cart' : 'Add to Cart'}
        </Button>
      </Box>
    )
  }

  renderRadioGroup = () => {
    const list = this.props.data?.Prices ?? []
    return (
      <RadioGroup name='PriceSelectedId' value={this.state.Value?.toString() ?? null} onChange={this.handleChange} sx={{ mt: '9px' }}>
        {list.map((e, i) => (
          <FormControlLabel
            key={i}
            value={e.Id}
            control={<Radio />}
            sx={{ '& .MuiTypography-root': { width: '100%' } }}
            label={
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography sx={{ fontSize: '14px', color: '#606060' }}>{e.Name}</Typography>
                <Typography sx={{ fontSize: '14px', color: '#606060' }}>${e.Price}</Typography>
              </Box>
            }
          />
        ))}
      </RadioGroup>
    )
  }

  handleChange = (_: React.ChangeEvent<HTMLInputElement>, value: string) => {
    const id: string = value ?? ''
    this.setState({ Value: id })
  }

  getPriceId = () => {
    const carts = CartLocalStorage.getData()
    return carts.reduce<string | undefined>((a, b) => {
      if (b.Id === this.props.data?.Product?.Id) a = b.PriceOrigin?.Id
      return a
    }, undefined)
  }
}

const formValivate = new FormValidator<Partial<IFormQuicklyAddToCartData>>({
  PriceSelectedId: { Rules: [{ rule: SingleRuleValidate.Required }] }
})

const FromBaseInstance = CreateFormBase<IFormQuicklyAddToCartData>({ validate: formValivate })

interface IFormQuicklyAddToCart {
  prices: IPrice[]
  product: Partial<IProduct>
  onSubmit: (value: Partial<IFormQuicklyAddToCartData>) => Promise<void>
}

export default class FormQuicklyAddToCart extends Component<IFormQuicklyAddToCart> {
  render() {
    return (
      <FromBaseInstance.Form onSubmit={(value) => this.props.onSubmit(this.mapSubmit(value))}>
        {FromBaseInstance.ContextMapping((context) => (
          <FormContent
            onBlur={context.onBlur}
            messageErrors={context.messageErrors}
            data={{ Product: this.props.product as IProduct, Prices: this.props.prices }}
          />
        ))}
      </FromBaseInstance.Form>
    )
  }

  mapSubmit = (value: Partial<IFormQuicklyAddToCartData>) => {
    const priceId: string | number | undefined = value.PriceSelectedId
    if (typeof priceId === 'string') {
      value.PriceSelectedId = priceId ?? ''
    }
    return value
  }
}
