import React, { Component } from 'react'
import { Box, Container, Grid, styled } from '@mui/material'
import { Outlet } from 'react-router-dom'
import ProfileMenu from './ProfileMenu'

interface IProps {}

export default class LayoutProfile extends Component<IProps> {
  render() {
    return (
      <ContainerCustom maxWidth={false} sx={{ background: '#fff' }}>
        <Grid container>
          <Grid item xs={12} md={3} xl={2} sx={{ display: { xs: 'none', md: 'block' } }}>
            <ProfileMenu />
          </Grid>
          <Grid item xs={12} md={9}>
            <Box sx={{ minHeight: 'calc(100vh - var(--height-header))' }}>
              <Outlet />
            </Box>
          </Grid>
        </Grid>
      </ContainerCustom>
    )
  }
}

const ContainerCustom = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    padding: 0
  },
  '& .MuiDataGrid-footerContainer': {
    height: '64px'
  }
}))
