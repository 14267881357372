import React, { Component } from 'react'
import { Box } from '@mui/material'
import { connect } from 'react-redux'
import { CartShared } from '@shared/Pages'
import { Dictionary } from '@reduxjs/toolkit'
import { CreateHocLazy } from '@lib/ReduxBase'
import { ICartReduxProps } from './redux.types'
import { WindowScrollToTop } from '@lib/Helpers'
import { ApiAlertContext } from '@lib/ApiContext'
import { ECheckOutValidateStatus, ICart } from '@shared/Types'
import UILayout from './ui.layout'
import CartBadge from './cart-badge'
import CartItem from './cart-item'
import ChooseLicense from './choose-license'
import SummaryReport from './ui.summary-report'
import { HookRedirectToCheckOut } from './hooks'
import { UINoData, UIWrapCartItems } from './uis'
import { mapDispatchToProps, mapStateToProps } from './redux.map'

interface IProps extends ICartReduxProps {}

class ViewBase extends Component<IProps> {
  render() {
    const data = this.getCartData()
    return (
      <UILayout
        top={<CartShared.UIStepper activeStep={0} />}
        content={this.renderContent(data)}
        summaryReport={
          <SummaryReport
            disabled={data.length < 1}
            data={data}
            onCheckOut={this.handleSubmitCheckOut}
            checkOutStatus={this.props.slice.checkOutStatus}
          />
        }
      >
        <HookRedirectToCheckOut
          status={this.props.slice.status}
          checkOutStatus={this.props.slice.checkOutStatus}
          onCheckOutValid={() => this.props.setCheckOutStatus(ECheckOutValidateStatus.Pending)}
        />
      </UILayout>
    )
  }

  componentDidMount(): void {
    WindowScrollToTop()
  }

  itemMap: Dictionary<CartItem | null> = {}
  renderContent = (carts: ICart[]) => (
    <UIWrapCartItems>
      <CartShared.UIInfoTop title='Cart' subTitle='Please check this information is correct' />
      <Box sx={{ py: '24px' }}>
        {carts.map((item, index) => (
          <CartItem
            ref={(ref) => (this.itemMap[item.Id] = ref)}
            key={index}
            data={item}
            onChange={this.handleChangeItem}
            onDelete={this.handleDeleteItem}
          />
        ))}
        {carts.length < 1 && <UINoData />}
      </Box>
    </UIWrapCartItems>
  )

  validateAll = () => {
    let temp = true
    Object.values(this.itemMap)
      .filter((e) => !!e)
      .forEach((item) => {
        const status = item?.validate() ?? false
        if (temp) temp = status
      })
    return temp
  }

  getCartData = () => {
    return Object.values(this.props.slice.carts.entities) as ICart[]
  }

  handleSubmitCheckOut = async () => {
    const isValid = this.validateAll()
    if (!isValid) {
      ApiAlertContext.ApiAlert?.PushWarning('Please check your cart again!')
      return
    }
    this.props.checkOutValidate()
  }

  handleChangeItem = (value: ICart) => {
    this.props.updateCart(value)
  }

  handleDeleteItem = (value: ICart) => {
    delete this.itemMap[value.Id]
    this.props.removeCart(value.Id)
  }
}

export * from './redux.thunks'

export { default as CartSlice } from './redux.slice'

export { QuicklyAddToCart } from './redux.map'

const HocLazyInstance = CreateHocLazy(ViewBase)
export const CartMapRedux = connect(mapStateToProps, mapDispatchToProps)(HocLazyInstance)

export const ChooseLicenseMapRedux = connect(mapStateToProps, mapDispatchToProps)(ChooseLicense)

export const CartBadgeMapRedux = connect(mapStateToProps, mapDispatchToProps)(CartBadge)
