import { RootState } from '@internal/redux'
import { ReduxBaseTable } from '@lib/ReduxBase'
import { createAsyncThunk } from '@reduxjs/toolkit'
import MediaDeliveryService, { MediaDeliveryMapping } from '@internal/services/MediaDeliveryService'
import { DefaultSlice } from './redux.slice'
import { IReduxThunkArg, IReduxThunkReturned } from './redux.types'

export const fetchDeliveryThunk = createAsyncThunk<IReduxThunkReturned, IReduxThunkArg | undefined>('fetchDeliveryThunk', async (param, thunkAPI) => {
  const state = thunkAPI.getState() as RootState
  const tableInfoParam = ReduxBaseTable.ThunkArgMapping({ tableInfo: state.DeliverySlice.tableInfo, ...param })

  const filter = MediaDeliveryMapping.tableInfoToFilter(tableInfoParam)
  const res = await MediaDeliveryService.allGraphQL(filter, thunkAPI.signal)

  const tableInfo = ReduxBaseTable.ThunkReponseMapping(tableInfoParam, {
    Data: MediaDeliveryMapping.deliveries(res?.items ?? []),
    Total: res?.totalItems
  })
  ReduxBaseTable.setParam(tableInfo, DefaultSlice.initialState.tableInfo)
  return { tableInfo }
})
