import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { UIMediaPlayer } from '@shared/UIStyleds'
import { RemoveRouteModalLink } from '@lib/RouteBase'
import { Box, IconButton, styled, Tooltip } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import LayoutModalRoute from '@lib/Layout/ModalRoute'
import { useYtVideoIdParam } from './hooks'

const ModalPreviewMediaByYtId: FC = () => {
  const { videoId } = useYtVideoIdParam()
  if (!videoId) return <></>
  return (
    <LayoutModalRoute.WrapperLayout>
      <Wrap>
        <UIMediaPlayer ytVideoId={videoId} />
        <WrapActions>
          <Tooltip title='Close'>
            <CustomIconButton {...{ component: Link, to: RemoveRouteModalLink() }}>
              <CloseIcon fontSize='large' />
            </CustomIconButton>
          </Tooltip>
        </WrapActions>
      </Wrap>
    </LayoutModalRoute.WrapperLayout>
  )
}

export default ModalPreviewMediaByYtId

const Wrap = styled(Box)(({ theme }) => ({
  maxHeight: 'calc(100vh - 24px)',
  position: 'relative',
  width: theme.breakpoints.values.md,
  [theme.breakpoints.down('sm')]: { width: 'calc(100vw - 24px)' }
}))

const WrapActions = styled(Box)({
  position: 'absolute',
  top: '0',
  left: 'calc(100% + 15px)'
})

const CustomIconButton = styled(IconButton)({
  color: 'var(--color-light)',
  '&:hover': {
    color: '#fff'
  }
})
