import { EOperator } from '@lib/Table'
import { CRUDServiceBase } from '@lib/Http'
import { EPolicyFileType, IGallery, IGalleryPdf, IGalleryPdfInfo } from '@shared/Types'
import { TableToRequestFilter, TryParseObject } from '@lib/Helpers'

class GalleryServiceBase extends CRUDServiceBase<IGallery, string> {
  constructor() {
    super(`/api/v1/guest/Gallery`)
  }
}
const GalleryService = new GalleryServiceBase()
export default GalleryService

class GalleryMappingBase {
  private galleryPdfKey = 'GalleryPdf'

  policyFileFilter = () => {
    return new TableToRequestFilter.Swagger<IGallery>({
      acceptedFileds: ['Id', 'Name', 'Key', 'Type', 'DateCreated', 'DateUpdated']
    })
      .filter({ field: 'Key', operator: EOperator.Equal, value: this.galleryPdfKey })
      .build()
  }

  policyFileActivated = (param: { gallery?: IGallery; type: EPolicyFileType }): IGalleryPdf | undefined => {
    const obj = TryParseObject<IGalleryPdfInfo>(param.gallery?.Description, { Data: [] })
    return obj.Data.find((x) => x.Activated === true && x.Type === param.type)
  }
}
export const GalleryMapping = new GalleryMappingBase()
