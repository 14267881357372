import { TYtVideosFilterParam } from './types'
import { applicationUser$, mediaDelivery$$, submission$ } from '../../__generated/media-store/fetchers'
import { category$$, categoryPage$ } from '../../__generated/media-store/fetchers'
import { youtubeVideo$$, youtubeVideoPage$ } from '../../__generated/media-store/fetchers'
import { query$, mediaStoreGraphDbContext$ } from '../../__generated/media-store/fetchers'

export const YoutubeVideos = (ytVideosParam: TYtVideosFilterParam) => {
  const YoutubeVideoPage = youtubeVideoPage$.items(youtubeVideo$$).hasNextPage.hasPreviousPage.totalItems
  return query$.mediaStore(mediaStoreGraphDbContext$.youtubeVideos(ytVideosParam, YoutubeVideoPage))
}

const MediaDeliverySelector = mediaDelivery$$
  .creator(applicationUser$.displayName)
  .submission(submission$.title.email.firstName.lastName.kindlyDescribe)

export const YoutubeVideosInitial = (ytVideosParam: TYtVideosFilterParam, deliveryId: string) => {
  const YoutubeVideoPage = youtubeVideoPage$.items(youtubeVideo$$).hasNextPage.hasPreviousPage.totalItems
  const CategoryPage = categoryPage$.items(category$$).hasNextPage.hasPreviousPage.totalItems
  return query$.mediaStore(
    mediaStoreGraphDbContext$
      .youtubeVideos(ytVideosParam, YoutubeVideoPage)
      .mediaDelivery({ id: deliveryId }, MediaDeliverySelector)
      .categories({}, CategoryPage)
  )
}

export const SelectYoutubeVideos = (ytVideosParam: TYtVideosFilterParam) => {
  const YoutubeVideoPage = youtubeVideoPage$.items(youtubeVideo$$)
  return query$.mediaStore(mediaStoreGraphDbContext$.youtubeVideos(ytVideosParam, YoutubeVideoPage))
}
