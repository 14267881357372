import { ITicket } from '@shared/Types'
import { RootState } from '@internal/redux'
import { ReduxBaseTable } from '@lib/ReduxBase'
import { createAsyncThunk } from '@reduxjs/toolkit'
import TicketService, { TicketMapping } from '@internal/services/TicketService'
import { DefaultSlice } from './redux.slice'
import { IReduxThunkArg, IReduxThunkReturned } from './redux.types'

export const fetchTicketThunk = createAsyncThunk<IReduxThunkReturned, IReduxThunkArg | undefined>('fetchTicketThunk', async (param, thunkAPI) => {
  const state = thunkAPI.getState() as RootState
  const tableInfoParam = ReduxBaseTable.ThunkArgMapping({ tableInfo: state.TicketSlice.tableInfo, ...param })

  const filter = TicketMapping.tableInfoToFilter(tableInfoParam)
  const res = await TicketService.All(filter, thunkAPI.signal)

  const tableInfo = ReduxBaseTable.ThunkReponseMapping(tableInfoParam, { Data: res?.Data, Total: res?.Total })
  ReduxBaseTable.setParam<ITicket>(tableInfo, DefaultSlice.initialState.tableInfo)
  return { tableInfo }
})
