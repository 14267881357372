import React, { FC } from 'react'
import { connect } from 'react-redux'
import { RouteKey } from '@internal/route'
import { OrderShared } from '@shared/Pages'
import { CreateHocLazy } from '@lib/ReduxBase'
import { Box, Container } from '@mui/material'
import UIStyleds from '@shared/UIStyleds'
import LayoutModalRoute from '@lib/Layout/ModalRoute'
import { IOrderDetailReduxProps } from './redux.types'
import { mapDispatchToProps, mapStateToProps } from './redux.map'

interface IProps extends IOrderDetailReduxProps {}
const ViewBase: FC<IProps> = (props) => (
  <UIStyleds.WrapScroll sx={{ mx: '-10px' }}>
    <Container maxWidth={false} sx={{ mt: '24px' }}>
      <OrderShared.UIDetails key={new Date().getTime().toString()} data={props.dataDetail} />
    </Container>
    <Box sx={{ height: '10rem' }} />
  </UIStyleds.WrapScroll>
)

export * from './redux.thunks'

export { default as OrderDetailSlice } from './redux.slice'

const OrderDetailLazy = CreateHocLazy(ViewBase)
const OrderDetailMapRedux = connect(mapStateToProps, mapDispatchToProps)(OrderDetailLazy)

export const OrderDetailModal: React.FC = () => (
  <LayoutModalRoute.Wrapper size='lg' title='Order detail' back={[RouteKey.Profile, RouteKey.Order]}>
    <OrderDetailMapRedux />
  </LayoutModalRoute.Wrapper>
)
