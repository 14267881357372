import { createContext } from 'react'
import { ICartItemContext } from './types'

export const CartItemContext = createContext<ICartItemContext>({
  onBlur: () => {},
  onDelete: () => {},
  onAdd: () => {},
  onClearLink: () => {},
  MessageError: {}
})

export const MapCartItemContext = (context: (state: ICartItemContext) => React.ReactNode | JSX.Element) => {
  return <CartItemContext.Consumer>{context}</CartItemContext.Consumer>
}
