import { ELazyStatus } from '@lib/ReduxBase'
import { IBillingDetail } from '@shared/Types'
import { createSlice } from '@reduxjs/toolkit'
import { ReduxBaseTable } from '@lib/ReduxBase'
import { IBillingDetailSliceState } from './redux.types'
import { fetchBillingDetailInitialThunk, fetchBillingDetailThunk } from './redux.thunks'

export const DefaultSlice = ReduxBaseTable.GetInitialSlice<IBillingDetailSliceState, IBillingDetail>({
  GridSortModel: [{ field: 'DateCreated', sort: 'desc' }]
})

const initialState: IBillingDetailSliceState = DefaultSlice.initialState

const BillingDetailSlice = createSlice({
  name: 'BillingDetailSlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: { ...DefaultSlice.reducers },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBillingDetailThunk.fulfilled, (state, action) => {
        if (state.tableRequestedId !== action.meta.requestId) return
        state.status = ELazyStatus.Loaded
        state.tableInfo = action.payload.tableInfo
      })
      .addCase(fetchBillingDetailThunk.rejected, (state, action) => {
        if (state.tableRequestedId !== action.meta.requestId) return
        state.status = ELazyStatus.Error
        state.tableInfo.isLoading = true
      })
      .addCase(fetchBillingDetailThunk.pending, (state, action) => {
        state.tableRequestedId = action.meta.requestId
        state.status = ELazyStatus.Loading
        state.tableInfo.isLoading = true
        if (action.meta.arg.clear === true) {
          state.tableInfo = DefaultSlice.initialState.tableInfo
        }
      })

    builder
      .addCase(fetchBillingDetailInitialThunk.fulfilled, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.Billing = action.payload.Billing
      })
      .addCase(fetchBillingDetailInitialThunk.rejected, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
      })
      .addCase(fetchBillingDetailInitialThunk.pending, (state, action) => {
        state.requestedId = action.meta.requestId
        state.Billing = undefined
      })
  }
})

export default BillingDetailSlice
