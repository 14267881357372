import type { AcceptableVariables, UnresolvedVariables, FieldOptions, DirectiveArgs } from 'graphql-ts-client-api';
import { ENUM_INPUT_METADATA } from '../EnumInputMetadata';
import type { ObjectFetcher } from 'graphql-ts-client-api';
import { createFetcher, createFetchableType } from 'graphql-ts-client-api';
import type { WithTypeName, ImplementationType } from '../CommonTypes';
import type {MediaStoreGraphDbContextMediasSortInput} from '../inputs';
import type {MediaStoreGraphDbContextYoutubeVideosSortInput} from '../inputs';
import type {MediaStoreGraphDbContextPricePackagesSortInput} from '../inputs';
import type {MediaStoreGraphDbContextCategoriesSortInput} from '../inputs';
import type {MediaStoreGraphDbContextOrdersSortInput} from '../inputs';
import type {MediaStoreGraphDbContextTicketsSortInput} from '../inputs';
import type {MediaStoreGraphDbContextBillingsSortInput} from '../inputs';
import type {MediaStoreGraphDbContextBillingDetailsSortInput} from '../inputs';
import type {MediaStoreGraphDbContextSubmissionsSortInput} from '../inputs';
import type {MediaStoreGraphDbContextMediaDeliveriesSortInput} from '../inputs';
import type {MediaStoreGraphDbContextGalleriesSortInput} from '../inputs';
import type {MediaStoreGraphDbContextGalleryDetailsSortInput} from '../inputs';

/*
 * Any instance of this interface is immutable,
 * all the properties and functions can only be used to create new instances,
 * they cannot modify the current instance.
 * 
 * So any instance of this interface is reuseable.
 */
export interface MediaStoreGraphDbContextFetcher<T extends object, TVariables extends object> extends ObjectFetcher<'MediaStoreGraphDbContext', T, TVariables> {

    on<XName extends ImplementationType<'MediaStoreGraphDbContext'>, X extends object, XVariables extends object>(
        child: ObjectFetcher<XName, X, XVariables>, 
        fragmentName?: string // undefined: inline fragment; otherwise, otherwise, real fragment
    ): MediaStoreGraphDbContextFetcher<
        XName extends 'MediaStoreGraphDbContext' ?
        T & X :
        WithTypeName<T, ImplementationType<'MediaStoreGraphDbContext'>> & (
            WithTypeName<X, ImplementationType<XName>> | 
            {__typename: Exclude<ImplementationType<'MediaStoreGraphDbContext'>, ImplementationType<XName>>}
        ), 
        TVariables & XVariables
    >;


    directive(name: string, args?: DirectiveArgs): MediaStoreGraphDbContextFetcher<T, TVariables>;


    readonly __typename: MediaStoreGraphDbContextFetcher<T & {__typename: ImplementationType<'MediaStoreGraphDbContext'>}, TVariables>;


    media<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'Media', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "media"?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["media"]
    >;

    media<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['media']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'Media', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "media"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['media']>
    >;

    media<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "media", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'Media', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"media", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["media"] & XDirectiveVariables
    >;

    media<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['media']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "media", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'Media', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"media", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['media']> & XDirectiveVariables
    >;


    medias<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'MediaPage', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "medias"?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["medias"]
    >;

    medias<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['medias']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'MediaPage', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "medias"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['medias']>
    >;

    medias<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "medias", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'MediaPage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"medias", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["medias"] & XDirectiveVariables
    >;

    medias<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['medias']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "medias", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'MediaPage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"medias", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['medias']> & XDirectiveVariables
    >;


    youtubeVideo<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'YoutubeVideo', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "youtubeVideo"?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["youtubeVideo"]
    >;

    youtubeVideo<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['youtubeVideo']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'YoutubeVideo', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "youtubeVideo"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['youtubeVideo']>
    >;

    youtubeVideo<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "youtubeVideo", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'YoutubeVideo', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"youtubeVideo", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["youtubeVideo"] & XDirectiveVariables
    >;

    youtubeVideo<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['youtubeVideo']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "youtubeVideo", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'YoutubeVideo', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"youtubeVideo", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['youtubeVideo']> & XDirectiveVariables
    >;


    youtubeVideos<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'YoutubeVideoPage', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "youtubeVideos"?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["youtubeVideos"]
    >;

    youtubeVideos<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['youtubeVideos']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'YoutubeVideoPage', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "youtubeVideos"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['youtubeVideos']>
    >;

    youtubeVideos<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "youtubeVideos", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'YoutubeVideoPage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"youtubeVideos", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["youtubeVideos"] & XDirectiveVariables
    >;

    youtubeVideos<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['youtubeVideos']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "youtubeVideos", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'YoutubeVideoPage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"youtubeVideos", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['youtubeVideos']> & XDirectiveVariables
    >;


    pricePackage<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'PricePackage', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "pricePackage"?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["pricePackage"]
    >;

    pricePackage<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['pricePackage']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'PricePackage', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "pricePackage"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['pricePackage']>
    >;

    pricePackage<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "pricePackage", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'PricePackage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"pricePackage", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["pricePackage"] & XDirectiveVariables
    >;

    pricePackage<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['pricePackage']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "pricePackage", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'PricePackage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"pricePackage", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['pricePackage']> & XDirectiveVariables
    >;


    pricePackages<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'PricePackagePage', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "pricePackages"?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["pricePackages"]
    >;

    pricePackages<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['pricePackages']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'PricePackagePage', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "pricePackages"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['pricePackages']>
    >;

    pricePackages<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "pricePackages", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'PricePackagePage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"pricePackages", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["pricePackages"] & XDirectiveVariables
    >;

    pricePackages<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['pricePackages']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "pricePackages", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'PricePackagePage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"pricePackages", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['pricePackages']> & XDirectiveVariables
    >;


    category<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'Category', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "category"?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["category"]
    >;

    category<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['category']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'Category', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "category"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['category']>
    >;

    category<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "category", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'Category', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"category", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["category"] & XDirectiveVariables
    >;

    category<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['category']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "category", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'Category', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"category", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['category']> & XDirectiveVariables
    >;


    categories<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'CategoryPage', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "categories"?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["categories"]
    >;

    categories<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['categories']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'CategoryPage', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "categories"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['categories']>
    >;

    categories<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "categories", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'CategoryPage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"categories", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["categories"] & XDirectiveVariables
    >;

    categories<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['categories']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "categories", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'CategoryPage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"categories", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['categories']> & XDirectiveVariables
    >;


    order<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'Order', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "order"?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["order"]
    >;

    order<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['order']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'Order', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "order"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['order']>
    >;

    order<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "order", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'Order', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"order", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["order"] & XDirectiveVariables
    >;

    order<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['order']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "order", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'Order', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"order", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['order']> & XDirectiveVariables
    >;


    orders<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'OrderPage', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "orders"?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["orders"]
    >;

    orders<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['orders']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'OrderPage', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "orders"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['orders']>
    >;

    orders<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "orders", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'OrderPage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"orders", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["orders"] & XDirectiveVariables
    >;

    orders<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['orders']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "orders", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'OrderPage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"orders", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['orders']> & XDirectiveVariables
    >;


    ticket<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'Ticket', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "ticket"?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["ticket"]
    >;

    ticket<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['ticket']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'Ticket', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "ticket"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['ticket']>
    >;

    ticket<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "ticket", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'Ticket', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"ticket", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["ticket"] & XDirectiveVariables
    >;

    ticket<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['ticket']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "ticket", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'Ticket', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"ticket", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['ticket']> & XDirectiveVariables
    >;


    tickets<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'TicketPage', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "tickets"?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["tickets"]
    >;

    tickets<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['tickets']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'TicketPage', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "tickets"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['tickets']>
    >;

    tickets<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "tickets", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'TicketPage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"tickets", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["tickets"] & XDirectiveVariables
    >;

    tickets<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['tickets']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "tickets", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'TicketPage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"tickets", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['tickets']> & XDirectiveVariables
    >;


    billing<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'Billing', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "billing"?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["billing"]
    >;

    billing<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['billing']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'Billing', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "billing"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['billing']>
    >;

    billing<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "billing", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'Billing', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"billing", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["billing"] & XDirectiveVariables
    >;

    billing<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['billing']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "billing", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'Billing', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"billing", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['billing']> & XDirectiveVariables
    >;


    billings<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'BillingPage', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "billings"?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["billings"]
    >;

    billings<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['billings']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'BillingPage', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "billings"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['billings']>
    >;

    billings<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "billings", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'BillingPage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"billings", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["billings"] & XDirectiveVariables
    >;

    billings<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['billings']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "billings", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'BillingPage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"billings", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['billings']> & XDirectiveVariables
    >;


    billingDetail<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'BillingDetail', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "billingDetail"?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["billingDetail"]
    >;

    billingDetail<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['billingDetail']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'BillingDetail', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "billingDetail"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['billingDetail']>
    >;

    billingDetail<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "billingDetail", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'BillingDetail', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"billingDetail", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["billingDetail"] & XDirectiveVariables
    >;

    billingDetail<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['billingDetail']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "billingDetail", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'BillingDetail', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"billingDetail", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['billingDetail']> & XDirectiveVariables
    >;


    billingDetails<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'BillingDetailPage', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "billingDetails"?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["billingDetails"]
    >;

    billingDetails<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['billingDetails']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'BillingDetailPage', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "billingDetails"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['billingDetails']>
    >;

    billingDetails<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "billingDetails", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'BillingDetailPage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"billingDetails", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["billingDetails"] & XDirectiveVariables
    >;

    billingDetails<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['billingDetails']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "billingDetails", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'BillingDetailPage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"billingDetails", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['billingDetails']> & XDirectiveVariables
    >;


    submission<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'Submission', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "submission"?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["submission"]
    >;

    submission<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['submission']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'Submission', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "submission"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['submission']>
    >;

    submission<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "submission", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'Submission', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"submission", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["submission"] & XDirectiveVariables
    >;

    submission<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['submission']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "submission", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'Submission', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"submission", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['submission']> & XDirectiveVariables
    >;


    submissions<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'SubmissionPage', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "submissions"?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["submissions"]
    >;

    submissions<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['submissions']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'SubmissionPage', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "submissions"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['submissions']>
    >;

    submissions<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "submissions", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'SubmissionPage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"submissions", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["submissions"] & XDirectiveVariables
    >;

    submissions<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['submissions']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "submissions", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'SubmissionPage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"submissions", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['submissions']> & XDirectiveVariables
    >;


    mediaDelivery<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'MediaDelivery', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "mediaDelivery"?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["mediaDelivery"]
    >;

    mediaDelivery<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['mediaDelivery']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'MediaDelivery', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "mediaDelivery"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['mediaDelivery']>
    >;

    mediaDelivery<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "mediaDelivery", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'MediaDelivery', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"mediaDelivery", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["mediaDelivery"] & XDirectiveVariables
    >;

    mediaDelivery<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['mediaDelivery']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "mediaDelivery", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'MediaDelivery', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"mediaDelivery", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['mediaDelivery']> & XDirectiveVariables
    >;


    mediaDeliveries<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'MediaDeliveryPage', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "mediaDeliveries"?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["mediaDeliveries"]
    >;

    mediaDeliveries<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['mediaDeliveries']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'MediaDeliveryPage', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "mediaDeliveries"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['mediaDeliveries']>
    >;

    mediaDeliveries<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "mediaDeliveries", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'MediaDeliveryPage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"mediaDeliveries", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["mediaDeliveries"] & XDirectiveVariables
    >;

    mediaDeliveries<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['mediaDeliveries']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "mediaDeliveries", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'MediaDeliveryPage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"mediaDeliveries", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['mediaDeliveries']> & XDirectiveVariables
    >;


    gallery<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'Gallery', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "gallery"?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["gallery"]
    >;

    gallery<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['gallery']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'Gallery', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "gallery"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['gallery']>
    >;

    gallery<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "gallery", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'Gallery', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"gallery", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["gallery"] & XDirectiveVariables
    >;

    gallery<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['gallery']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "gallery", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'Gallery', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"gallery", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['gallery']> & XDirectiveVariables
    >;


    galleries<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'GalleryPage', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "galleries"?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["galleries"]
    >;

    galleries<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['galleries']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'GalleryPage', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "galleries"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['galleries']>
    >;

    galleries<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "galleries", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'GalleryPage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"galleries", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["galleries"] & XDirectiveVariables
    >;

    galleries<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['galleries']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "galleries", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'GalleryPage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"galleries", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['galleries']> & XDirectiveVariables
    >;


    galleryDetail<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'GalleryDetail', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "galleryDetail"?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["galleryDetail"]
    >;

    galleryDetail<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['galleryDetail']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'GalleryDetail', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "galleryDetail"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['galleryDetail']>
    >;

    galleryDetail<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "galleryDetail", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'GalleryDetail', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"galleryDetail", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["galleryDetail"] & XDirectiveVariables
    >;

    galleryDetail<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['galleryDetail']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "galleryDetail", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'GalleryDetail', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"galleryDetail", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['galleryDetail']> & XDirectiveVariables
    >;


    galleryDetails<
        X extends object, 
        XVariables extends object
    >(
        child: ObjectFetcher<'GalleryDetailPage', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "galleryDetails"?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["galleryDetails"]
    >;

    galleryDetails<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['galleryDetails']>, 
        X extends object, 
        XVariables extends object
    >(
        args: XArgs, 
        child: ObjectFetcher<'GalleryDetailPage', X, XVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly "galleryDetails"?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['galleryDetails']>
    >;

    galleryDetails<
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "galleryDetails", 
        XDirectiveVariables extends object = {}
    >(
        child: ObjectFetcher<'GalleryDetailPage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"galleryDetails", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & MediaStoreGraphDbContextArgs["galleryDetails"] & XDirectiveVariables
    >;

    galleryDetails<
        XArgs extends AcceptableVariables<MediaStoreGraphDbContextArgs['galleryDetails']>, 
        X extends object, 
        XVariables extends object, 
        XAlias extends string = "galleryDetails", 
        XDirectiveVariables extends object = {}
    >(
        args: XArgs, 
        child: ObjectFetcher<'GalleryDetailPage', X, XVariables>, 
        optionsConfigurer: (
            options: FieldOptions<"galleryDetails", {}, {}>
        ) => FieldOptions<XAlias, {readonly [key: string]: DirectiveArgs}, XDirectiveVariables>
    ): MediaStoreGraphDbContextFetcher<
        T & {readonly [key in XAlias]?: X}, 
        TVariables & XVariables & UnresolvedVariables<XArgs, MediaStoreGraphDbContextArgs['galleryDetails']> & XDirectiveVariables
    >;
}

export const mediaStoreGraphDbContext$: MediaStoreGraphDbContextFetcher<{}, {}> = 
    createFetcher(
        createFetchableType(
            "MediaStoreGraphDbContext", 
            "EMBEDDED", 
            [], 
            [
                {
                    category: "REFERENCE", 
                    name: "media", 
                    argGraphQLTypeMap: {id: 'ID!'}, 
                    targetTypeName: "Media", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "medias", 
                    argGraphQLTypeMap: {
                        sort: '[MediaStoreGraphDbContextMediasSortInput]', 
                        filter: 'String', 
                        skip: 'Int', 
                        take: 'Int'
                    }, 
                    targetTypeName: "MediaPage", 
                    undefinable: true
                }, 
                {
                    category: "REFERENCE", 
                    name: "youtubeVideo", 
                    argGraphQLTypeMap: {id: 'String!'}, 
                    targetTypeName: "YoutubeVideo", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "youtubeVideos", 
                    argGraphQLTypeMap: {
                        sort: '[MediaStoreGraphDbContextYoutubeVideosSortInput]', 
                        filter: 'String', 
                        skip: 'Int', 
                        take: 'Int'
                    }, 
                    targetTypeName: "YoutubeVideoPage", 
                    undefinable: true
                }, 
                {
                    category: "REFERENCE", 
                    name: "pricePackage", 
                    argGraphQLTypeMap: {id: 'ID!'}, 
                    targetTypeName: "PricePackage", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "pricePackages", 
                    argGraphQLTypeMap: {
                        sort: '[MediaStoreGraphDbContextPricePackagesSortInput]', 
                        filter: 'String', 
                        skip: 'Int', 
                        take: 'Int'
                    }, 
                    targetTypeName: "PricePackagePage", 
                    undefinable: true
                }, 
                {
                    category: "REFERENCE", 
                    name: "category", 
                    argGraphQLTypeMap: {id: 'ID!'}, 
                    targetTypeName: "Category", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "categories", 
                    argGraphQLTypeMap: {
                        sort: '[MediaStoreGraphDbContextCategoriesSortInput]', 
                        filter: 'String', 
                        skip: 'Int', 
                        take: 'Int'
                    }, 
                    targetTypeName: "CategoryPage", 
                    undefinable: true
                }, 
                {
                    category: "REFERENCE", 
                    name: "order", 
                    argGraphQLTypeMap: {id: 'ID!'}, 
                    targetTypeName: "Order", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "orders", 
                    argGraphQLTypeMap: {
                        sort: '[MediaStoreGraphDbContextOrdersSortInput]', 
                        filter: 'String', 
                        skip: 'Int', 
                        take: 'Int'
                    }, 
                    targetTypeName: "OrderPage", 
                    undefinable: true
                }, 
                {
                    category: "REFERENCE", 
                    name: "ticket", 
                    argGraphQLTypeMap: {id: 'ID!'}, 
                    targetTypeName: "Ticket", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "tickets", 
                    argGraphQLTypeMap: {
                        sort: '[MediaStoreGraphDbContextTicketsSortInput]', 
                        filter: 'String', 
                        skip: 'Int', 
                        take: 'Int'
                    }, 
                    targetTypeName: "TicketPage", 
                    undefinable: true
                }, 
                {
                    category: "REFERENCE", 
                    name: "billing", 
                    argGraphQLTypeMap: {id: 'ID!'}, 
                    targetTypeName: "Billing", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "billings", 
                    argGraphQLTypeMap: {
                        sort: '[MediaStoreGraphDbContextBillingsSortInput]', 
                        filter: 'String', 
                        skip: 'Int', 
                        take: 'Int'
                    }, 
                    targetTypeName: "BillingPage", 
                    undefinable: true
                }, 
                {
                    category: "REFERENCE", 
                    name: "billingDetail", 
                    argGraphQLTypeMap: {id: 'ID!'}, 
                    targetTypeName: "BillingDetail", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "billingDetails", 
                    argGraphQLTypeMap: {
                        sort: '[MediaStoreGraphDbContextBillingDetailsSortInput]', 
                        filter: 'String', 
                        skip: 'Int', 
                        take: 'Int'
                    }, 
                    targetTypeName: "BillingDetailPage", 
                    undefinable: true
                }, 
                {
                    category: "REFERENCE", 
                    name: "submission", 
                    argGraphQLTypeMap: {id: 'ID!'}, 
                    targetTypeName: "Submission", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "submissions", 
                    argGraphQLTypeMap: {
                        sort: '[MediaStoreGraphDbContextSubmissionsSortInput]', 
                        filter: 'String', 
                        skip: 'Int', 
                        take: 'Int'
                    }, 
                    targetTypeName: "SubmissionPage", 
                    undefinable: true
                }, 
                {
                    category: "REFERENCE", 
                    name: "mediaDelivery", 
                    argGraphQLTypeMap: {id: 'ID!'}, 
                    targetTypeName: "MediaDelivery", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "mediaDeliveries", 
                    argGraphQLTypeMap: {
                        sort: '[MediaStoreGraphDbContextMediaDeliveriesSortInput]', 
                        filter: 'String', 
                        skip: 'Int', 
                        take: 'Int'
                    }, 
                    targetTypeName: "MediaDeliveryPage", 
                    undefinable: true
                }, 
                {
                    category: "REFERENCE", 
                    name: "gallery", 
                    argGraphQLTypeMap: {id: 'ID!'}, 
                    targetTypeName: "Gallery", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "galleries", 
                    argGraphQLTypeMap: {
                        sort: '[MediaStoreGraphDbContextGalleriesSortInput]', 
                        filter: 'String', 
                        skip: 'Int', 
                        take: 'Int'
                    }, 
                    targetTypeName: "GalleryPage", 
                    undefinable: true
                }, 
                {
                    category: "REFERENCE", 
                    name: "galleryDetail", 
                    argGraphQLTypeMap: {id: 'ID!'}, 
                    targetTypeName: "GalleryDetail", 
                    undefinable: true
                }, 
                {
                    category: "SCALAR", 
                    name: "galleryDetails", 
                    argGraphQLTypeMap: {
                        sort: '[MediaStoreGraphDbContextGalleryDetailsSortInput]', 
                        filter: 'String', 
                        skip: 'Int', 
                        take: 'Int'
                    }, 
                    targetTypeName: "GalleryDetailPage", 
                    undefinable: true
                }
            ]
        ), 
        ENUM_INPUT_METADATA, 
        undefined
    )
;

export interface MediaStoreGraphDbContextArgs {

    readonly media: {
        readonly id: string
    }, 

    readonly medias: {
        readonly sort?: ReadonlyArray<MediaStoreGraphDbContextMediasSortInput | undefined>, 
        readonly filter?: string, 
        readonly skip?: number, 
        readonly take?: number
    }, 

    readonly youtubeVideo: {
        readonly id: string
    }, 

    readonly youtubeVideos: {
        readonly sort?: ReadonlyArray<MediaStoreGraphDbContextYoutubeVideosSortInput | undefined>, 
        readonly filter?: string, 
        readonly skip?: number, 
        readonly take?: number
    }, 

    readonly pricePackage: {
        readonly id: string
    }, 

    readonly pricePackages: {
        readonly sort?: ReadonlyArray<MediaStoreGraphDbContextPricePackagesSortInput | undefined>, 
        readonly filter?: string, 
        readonly skip?: number, 
        readonly take?: number
    }, 

    readonly category: {
        readonly id: string
    }, 

    readonly categories: {
        readonly sort?: ReadonlyArray<MediaStoreGraphDbContextCategoriesSortInput | undefined>, 
        readonly filter?: string, 
        readonly skip?: number, 
        readonly take?: number
    }, 

    readonly order: {
        readonly id: string
    }, 

    readonly orders: {
        readonly sort?: ReadonlyArray<MediaStoreGraphDbContextOrdersSortInput | undefined>, 
        readonly filter?: string, 
        readonly skip?: number, 
        readonly take?: number
    }, 

    readonly ticket: {
        readonly id: string
    }, 

    readonly tickets: {
        readonly sort?: ReadonlyArray<MediaStoreGraphDbContextTicketsSortInput | undefined>, 
        readonly filter?: string, 
        readonly skip?: number, 
        readonly take?: number
    }, 

    readonly billing: {
        readonly id: string
    }, 

    readonly billings: {
        readonly sort?: ReadonlyArray<MediaStoreGraphDbContextBillingsSortInput | undefined>, 
        readonly filter?: string, 
        readonly skip?: number, 
        readonly take?: number
    }, 

    readonly billingDetail: {
        readonly id: string
    }, 

    readonly billingDetails: {
        readonly sort?: ReadonlyArray<MediaStoreGraphDbContextBillingDetailsSortInput | undefined>, 
        readonly filter?: string, 
        readonly skip?: number, 
        readonly take?: number
    }, 

    readonly submission: {
        readonly id: string
    }, 

    readonly submissions: {
        readonly sort?: ReadonlyArray<MediaStoreGraphDbContextSubmissionsSortInput | undefined>, 
        readonly filter?: string, 
        readonly skip?: number, 
        readonly take?: number
    }, 

    readonly mediaDelivery: {
        readonly id: string
    }, 

    readonly mediaDeliveries: {
        readonly sort?: ReadonlyArray<MediaStoreGraphDbContextMediaDeliveriesSortInput | undefined>, 
        readonly filter?: string, 
        readonly skip?: number, 
        readonly take?: number
    }, 

    readonly gallery: {
        readonly id: string
    }, 

    readonly galleries: {
        readonly sort?: ReadonlyArray<MediaStoreGraphDbContextGalleriesSortInput | undefined>, 
        readonly filter?: string, 
        readonly skip?: number, 
        readonly take?: number
    }, 

    readonly galleryDetail: {
        readonly id: string
    }, 

    readonly galleryDetails: {
        readonly sort?: ReadonlyArray<MediaStoreGraphDbContextGalleryDetailsSortInput | undefined>, 
        readonly filter?: string, 
        readonly skip?: number, 
        readonly take?: number
    }
}
