import { authService } from 'partner-oidc-auth'
import { createAsyncThunk } from '@reduxjs/toolkit'
import TicketService, { TicketMapping } from '@internal/services/TicketService'
import { IReduxThunkArg, IReduxThunkReturned } from './redux.types'

export const fetchTicketDetailThunk = createAsyncThunk<IReduxThunkReturned, IReduxThunkArg>('fetchTicketDetailThunk', async (param, thunkAPI) => {
  // const state = thunkAPI.getState() as { TicketDetailSlice: ITicketDetailStateSlice }
  const user = await authService.getUser()
  const result = await TicketService.GraphQLSingle({ id: param.ticketId }, thunkAPI.signal)
  return { ticket: TicketMapping.ticket(user?.sub ?? '', result) }
})
