import React, { FC } from 'react'
import { Box, Button, ButtonProps, Collapse, Container, Grid, styled, useMediaQuery, useTheme } from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList'

interface IProps {
  leftMenu: JSX.Element
  topBar: JSX.Element
  body: JSX.Element
  beforeBody: JSX.Element
  pagination: JSX.Element
}

const UILayout: FC<IProps> = (props) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))
  const [showMobileFilter, setShowMobileFilter] = React.useState(false)

  const triggerCategory = () => {
    setShowMobileFilter(!showMobileFilter)
  }

  const getTriggerFilterButtonClasses = () => {
    const classes = ['btn-trigger-filter']
    if (showMobileFilter) classes.push('closed')
    return classes.join(' ')
  }

  return (
    <Wrap>
      <Container maxWidth={false}>
        <Box>{props.topBar}</Box>
        {matches && (
          <TriggerFilterButton className={getTriggerFilterButtonClasses()} onClick={triggerCategory}>
            {showMobileFilter ? 'Close filter' : 'Show filter'}
          </TriggerFilterButton>
        )}
        <Grid container spacing={2} sx={{ mt: 0 }}>
          <Grid item xs={12} sm={3} xl={2}>
            {!matches && props.leftMenu}
            {matches && (
              <Collapse in={showMobileFilter}>
                <Box>{props.leftMenu}</Box>
              </Collapse>
            )}
          </Grid>
          <Grid item xs={12} sm={9} xl={10}>
            <Box sx={{ minHeight: '100vh' }}>
              {props.beforeBody}
              {props.body}
              <Box className='pagination' sx={{}}>
                {props.pagination}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Wrap>
  )
}

export default UILayout

const Wrap = styled(Box)(({ theme }) => ({
  background: '#f0f1f3',
  paddingBottom: '30px',
  '& .pagination': {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '30px 0 150px'
  },
  [theme.breakpoints.down('md')]: {
    '& .pagination': {
      justifyContent: 'center'
    }
  }
}))

const TriggerFilterButton = styled((props: ButtonProps) => (
  <Button fullWidth variant='outlined' color='primary' startIcon={<FilterListIcon />} {...props} />
))({
  '& .MuiSvgIcon-root': {
    transition: 'all 0.3s ease'
  },
  '&.closed .MuiSvgIcon-root': {
    transform: 'scaleY(-1)'
  }
})
