import { createAsyncThunk } from '@reduxjs/toolkit'
import NotificationService, { NotifyLocalStorage, NotifyMapping } from '@internal/services/NotifyService'
import { IReduxThunkReturned } from './redux.types'

export const fetchNotificationThunk = createAsyncThunk<IReduxThunkReturned>('fetchNotificationThunk', async (_, thunkAPI) => {
  // const state = thunkAPI.getState() as { NotificationSlice: INotificationStateSlice }
  const res = await NotificationService.All({}, thunkAPI.signal)

  const notificationIds = NotifyMapping.getReadedNotificationIds(res?.NotificationInfos?.items)
  if (notificationIds.length > 0) NotifyLocalStorage.Set(notificationIds)

  return { notifications: NotifyMapping.notifications(res?.NotificationInfos?.items, NotifyLocalStorage.Get()) }
})
