import { GetUrlYoutubeThumbnail } from '@shared/Services'
import { ICart, IPrice, IProduct } from '@shared/Types'
import { AppDispatch, RootState } from '@internal/redux'
import { IReduxMapDispatch, IReduxMapState } from './redux.types'
import { fetchCartThunk, fetchCheckOutValidateThunk } from './redux.thunks'
import CartSlice from './redux.slice'

export const mapStateToProps = (state: RootState): IReduxMapState => ({
  status: state.CartSlice.status,
  slice: state.CartSlice
})

export const mapDispatchToProps = (dispatch: AppDispatch): IReduxMapDispatch => ({
  fetchData: () => {
    return dispatch(fetchCartThunk())
  },
  onChangeStatus: (status) => {
    dispatch(CartSlice.actions.onChangeStatus(status))
  },
  setCheckOutStatus: (value) => {
    dispatch(CartSlice.actions.setCheckOutStatus(value))
  },
  addCart: (value) => {
    dispatch(CartSlice.actions.addCart(value))
  },
  updateCart: (value) => {
    dispatch(CartSlice.actions.updateCart(value))
  },
  removeCart: (id) => {
    dispatch(CartSlice.actions.removeCart(id))
  },
  checkOutValidate: async () => {
    await dispatch(fetchCheckOutValidateThunk())
  }
})

const MapCart = (product: IProduct, price: IPrice): ICart => ({
  Id: product.Id,
  Name: product.Title ?? '',
  Description: product.Description,
  Type: product.Type,
  ImageUri: GetUrlYoutubeThumbnail(product.Id),
  Infos: [
    { Key: 'Type', Value: product.Type },
    { Key: 'Price', Value: `$ ${(price.Price ?? 0).toFixed(2)}` }
  ],
  PriceOrigin: price
})

export const QuicklyAddToCart = (dispatch: AppDispatch, product: IProduct, price: IPrice) => {
  const value = MapCart(product, price)
  dispatch(CartSlice.actions.addCart(value))
}
