import React, { Component, FC } from 'react'

export class CheckOutError extends Component {
  render() {
    return <div>CheckOutError</div>
  }
}

export const UIOverlayWhileLoading: FC = () => {
  return <div>uis</div>
}
