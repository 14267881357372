import React, { Component } from 'react'
import { Box, Divider, Grid, GridProps, Stack, styled, Typography, TypographyProps } from '@mui/material'

export interface IFormPreviewConfig<T, K extends keyof T = keyof T> {
  key: K
  label?: string
  props?: GridProps
  renderCell?: (value: T[K] | undefined, model: Partial<T>) => JSX.Element
}

interface IArgs<T> {
  configs: IFormPreviewConfig<T>[]
  options?: {
    minWidthLabelRow?: number
  }
}
const CreateFormPreview = function <T>(args: IArgs<T>) {
  interface IProps {
    data: Partial<T>
  }
  return class FormPreview extends Component<IProps> {
    render() {
      const { data } = this.props
      return (
        <Grid container>
          {args.configs.map((x, i) => {
            const v = data[x.key]
            let child = this.renderItem(x, i > 0)
            if (!!x.renderCell) child = x.renderCell(v, this.props.data)
            return (
              <React.Fragment key={'key' + i}>
                <Grid item xs={12} {...x.props}>
                  {child}
                </Grid>
              </React.Fragment>
            )
          })}
        </Grid>
      )
    }

    renderItem = (item: IFormPreviewConfig<T, keyof T>, line?: boolean) => {
      const { data } = this.props
      const label = (item.label ?? item.key).toString()
      const value = data[item.key]?.toString()
      return (
        <WrapItem>
          {line === true && <Divider />}
          <Box sx={{ display: 'flex', padding: '14px 24px 0 0' }}>
            <WrapLabel custom={{ minWidth: args.options?.minWidthLabelRow }}>{label}</WrapLabel>
            <Typography variant='subtitle1' component='p' sx={{ flex: 1 }}>
              {value}
            </Typography>
          </Box>
        </WrapItem>
      )
    }
  }
}

export default CreateFormPreview

const WrapItem = styled(Stack)({
  minHeight: '56px',
  paddingLeft: '24px',
  '& > .MuiBox-root': {
    flex: 1,
    height: '100%'
  }
})

const WrapLabel = styled((props: TypographyProps) => {
  return <Typography variant='subtitle1' component='h6' {...props} />
})<{ custom: { minWidth?: string | number } }>(({ custom: { minWidth } }) => ({
  fontWeight: 600,
  minWidth: minWidth || 180,
  color: '#606060'
}))
