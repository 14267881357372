import React, { Component } from 'react'
import { ELazyStatus } from '@lib/ReduxBase'
import { TicketShared } from '@shared/Pages'
import { CreateRoutePath } from '@lib/RouteBase'
import { Container, Divider } from '@mui/material'
import { NavigateFunction } from 'react-router-dom'
import { ApiAlertContext, MapNavigate } from '@lib/ApiContext'
import { ETicketConversation, ETicketType } from '@shared/Types'
import UIStyleds from '@shared/UIStyleds'
import TicketService from '@internal/services/TicketService'
import CreateTicketConversationService from '@internal/services/TicketConversationService'
import { FormCreate } from './Forms'
import { IFormCreateData } from './type'
import { RouteKey } from '../../Routers'
import { filesToArrayBase64, validateFilesSize } from './helpers'
import OptionBar from './Forms/OptionBar'
import UILoadingView from './ui.loading-view'

interface IProps {}
interface IState {
  loadingStatus: ELazyStatus
}

class TicketCreate extends Component<IProps, IState> {
  refOptionBar: OptionBar | null = null
  refLoadingView: UILoadingView | null = null
  render() {
    return (
      <UIStyleds.WrapFullDisplay sx={{ position: 'relative' }}>
        <TicketShared.UITopBar btnBackTitle='back to tickets' title='New ticket' to={CreateRoutePath([RouteKey.Profile, RouteKey.Ticket])} />
        <Divider />
        <UIStyleds.WrapScroll>
          {MapNavigate((navigate) => (
            <Container maxWidth={false} sx={{ mt: '24px' }}>
              <FormCreate Option={<OptionBar multiple ref={(ref) => (this.refOptionBar = ref)} />} onSubmit={(x) => this.handleCreate(navigate, x)} />
            </Container>
          ))}
        </UIStyleds.WrapScroll>
        <UILoadingView ref={(ref) => (this.refLoadingView = ref)} />
      </UIStyleds.WrapFullDisplay>
    )
  }

  handleCreate = async (navigate: NavigateFunction, value: Partial<IFormCreateData>) => {
    const files = this.refOptionBar?.getData() ?? []
    try {
      this.refLoadingView?.load()
      if (!validateFilesSize(files, { multiple: true })) {
        throw new Error('Attachment file is not valid!')
      }
      const attachs: string[] = await filesToArrayBase64(files)
      const res = await TicketService.Create({ Subject: value.Subject, Type: value.Type ?? ETicketType.General })
      const conversationAPI = CreateTicketConversationService(res.Id)
      await conversationAPI.Create({ Message: value.Message, Type: ETicketConversation.Image, AttachFile: JSON.stringify(attachs) })
      navigate(CreateRoutePath([RouteKey.Profile, RouteKey.Ticket]))
    } catch (error: any) {
      // console.log(error)
      ApiAlertContext.ApiAlert?.PushWarning(error.message ?? 'Please check again')
    } finally {
      this.refLoadingView?.stop()
    }
  }
}
export default TicketCreate
