import { FetchDelay } from '@lib/Helpers'
import { CRUDServiceBase } from '@lib/Http'
import { EPriceType, EProductType, IExploreFilter, IExploreFilterPagination, IPrice, IProduct } from '@shared/Types'
import Graphql from '@coreprj/graphql'

export interface IDownloadData {
  Url: string
  Extension: string
  FormatNote: string
  Width: number
  Height: number
  Resolution: string
  Bitrate: number
  FileSize: number
}

export const DefaultExploreFilterPagination: Required<IExploreFilterPagination> = { page: 1, pageSize: 24, total: 0 }

export type TCategory = Graphql.QMediaStore.TCategory

export type TPricePackage = Graphql.QMediaStore.TPricePackage

export type TYoutubeVideo = Graphql.QMediaStore.TYoutubeVideo

class ProductServiceBase extends CRUDServiceBase<IProduct, number> {
  constructor() {
    super('')
  }

  AllGraphQL = async (filter: IExploreFilter, signal?: AbortSignal) => {
    const fRequest = this.convertFilterToRequest(filter)
    const res = await Graphql.GeustMediaStore.Query(Graphql.QMediaStore.ExploreInitial(fRequest.yt.build(), fRequest.categories.build()), { signal })
    return res
  }

  Filter = async (filter: IExploreFilter, signal?: AbortSignal) => {
    const fRequest = this.convertFilterToRequest(filter)
    const res = await Graphql.GeustMediaStore.Query(Graphql.QMediaStore.Explore(fRequest.yt.build(), fRequest.categories.build()), { signal })
    return res
  }

  DetailById = async (resourceId: string, filter: IExploreFilter, signal?: AbortSignal) => {
    const fRequest = this.convertFilterToRequest(filter, resourceId)
    const res = await Graphql.GeustMediaStore.Query(
      Graphql.QMediaStore.ProductDetailById({
        resourceId,
        categories: fRequest.categories.build(),
        ytVideos: fRequest.yt.build()
      }),
      { signal }
    )
    return res
  }

  private downloadByVideoIdUrl = '/api/v1/user/Dowloader/GetDowloadInfo'
  DownloadByVideoId = async (videoId: string, signal?: AbortSignal) => {
    return FetchDelay(() => this.Get<IDownloadData[]>(this.downloadByVideoIdUrl, { signal, params: { videoId } }), 700)
  }

  convertFilterToRequest = (filter: IExploreFilter, exceptId?: string) => {
    const rbCategories = Graphql.createRequestBuilder<TCategory>({ ignoreEmpty: true })
    const rbYt = Graphql.createRequestBuilder<TYoutubeVideo>({ ignoreEmpty: true })

    const pageSize = filter.pagination?.pageSize || DefaultExploreFilterPagination.pageSize
    const page = (filter.pagination?.page || DefaultExploreFilterPagination.page) - 1
    rbYt.take(pageSize).skip(page * pageSize)

    if (!!filter.type) {
      rbYt.filterEnum('type', 'MediaType.' + filter.type)
      rbCategories.filterEnum('type', 'CategoryType.' + filter.type)
    }
    rbYt.scope(
      (q) => {
        if (!!filter.keyword) {
          const youtubeIdRegex = /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([\w-]+)/
          const match = filter.keyword.match(youtubeIdRegex)
          if (match && match[1]) {
            q.filter('id', match[1])
          } else {
            q.filterContains('id', filter.keyword)
              .filterContains('title', filter.keyword, { logic: 'Or' })
              .filterContains('description', filter.keyword, { logic: 'Or' })
          }
        }
        return q
      },
      { logic: 'And' }
    )
    const filterScopeTags = (reqFilter: typeof rbYt, value: string) => {
      if (!filter || !filter.categories || (filter.categories?.length ?? 0) < 1) return
      reqFilter
        .filter('tags', value, { logic: 'Or' }) // signle
        .filterContains('tags', `${value}|`, { logic: 'Or' }) // first + mid
        .filterLast('tags', '|' + value, { logic: 'Or' }) // only
    }
    rbYt.scope(
      (q) => {
        if (!!filter.categories && filter.categories.length > 0) {
          filter.categories.forEach((x) => filterScopeTags(q, x.value))
        }
        return q
      },
      { logic: 'And' }
    )
    // if (filter.categories?.length === 1) {
    // } else {
    //   rbYt.scope(
    //     (q) => {
    //       if (!!filter.categories && filter.categories.length > 0) {
    //         filter.categories.forEach((x) => q.filterContains('tags', x.value, { logic: 'Or' }))
    //       }
    //       return q
    //     },
    //     { logic: 'And' }
    //   )
    // }

    rbCategories.sort('name', { direction: 'ASC' })
    if (!!filter.sort) {
      rbYt.sort('dateCreated', { direction: filter.sort === 'Lastet' ? 'DESC' : 'ASC' })
    }

    if (!!exceptId) {
      rbYt.filterNotEquals('id', exceptId, { logic: 'And' })
    }

    return { yt: rbYt, categories: rbCategories }
  }
}
const ProductService = new ProductServiceBase()
export default ProductService

class ProductMappingBase {
  products = (items?: TYoutubeVideo[]): IProduct[] => {
    return (items || []).reduce<IProduct[]>((a, b) => {
      if (!!b?.id) a.push(this.product(b))
      return a
    }, [])
  }

  product = (item: TYoutubeVideo): IProduct => ({
    Id: item.id ?? '',
    Title: item.title ?? '',
    Description: item.description ?? '',
    Type: item.type as EProductType,
    DeliveryId: item.deliveryId,
    PublishedDate: item.publishedDate,
    Tags: item.tags ?? '',
    DateCreated: item.dateCreated,
    DateUpdated: item.dateUpdated
  })

  prices = (items?: TPricePackage[]): IPrice[] => {
    if (!items) return []
    return items.reduce<IPrice[]>((a, b) => {
      a.push({
        Id: b.id,
        Name: b.name ?? '',
        Description: b.description ?? '',
        Price: b.price,
        Type: b.type as EPriceType,
        JsonContent: b.jsonContent ?? '',
        DateCreated: b.dateCreated
      })
      return a
    }, [])
  }
}
export const ProductMapping = new ProductMappingBase()
