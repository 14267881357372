import { RootState, AppDispatch } from '@internal/redux'
import { fetchSubmissionDetailThunk } from './redux.thunks'
import { IReduxMapDispatch, IReduxMapState } from './redux.types'
import SubmissionDetailSlice from './redux.slice'

export const mapStateToProps = (state: RootState): IReduxMapState => ({
  status: state.SubmissionDetailSlice.status,
  slice: state.SubmissionDetailSlice
})

export const mapDispatchToProps = (dispatch: AppDispatch): IReduxMapDispatch => ({
  fetchData: () => {
    return dispatch(fetchSubmissionDetailThunk())
  },
  onChangeStatus: (status) => {
    dispatch(SubmissionDetailSlice.actions.onChangeStatus(status))
  }
})
