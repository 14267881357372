import { RouteKey } from '@internal/route'
import { EPolicyFileType } from '@shared/Types'

export const GetPolicyFileType = (): EPolicyFileType | undefined => {
  const pathname = window.location.pathname
  if (pathname.toLowerCase().includes(RouteKey.PrivacyPolicy.toLowerCase())) {
    return EPolicyFileType.PrivacyPolicy
  }

  if (pathname.toLowerCase().includes(RouteKey.TermsOfSubmission.toLowerCase())) {
    return EPolicyFileType.TermsOfSubmission
  }
}
