import React, { Component } from 'react'
import { GetImageUrlS3 } from '@shared/Services'
import { Box, Collapse, Fade, IconButton, styled, Typography } from '@mui/material'
import { IExploreFilter, IExploreFilterCategory, IExploreFilterProps } from '@shared/Types'
import CancelIcon from '@mui/icons-material/Cancel'
import { MapExploreFilter } from './helpers'
import { UISkeletonCategories } from './ui.skeleton'
import { IExploreMenu, IExploreMenuItem } from './types'

interface IProps extends IExploreFilterProps {
  isLoading?: boolean
  data: IExploreMenu[]
}

class UIMenuLeft extends Component<IProps> {
  handleChange = (item: IExploreMenuItem) => {
    if (!this.props.onChange) return
    switch (item.filterKey) {
      case 'categories':
        let list: IExploreFilterCategory[] = [...(this.props.filter?.categories || [])]
        if (list.some((x) => x.value === item.value)) {
          list = list.filter((x) => x.value !== item.value)
        } else {
          list.push({ value: item.value, label: item.value })
        }
        this.props.onChange(MapExploreFilter({ key: item.filterKey, value: list }))
        break
      case 'sort':
        this.props.onChange({ key: 'sort', value: item.value })
        break
      default:
        break
    }
  }

  getIsActivated = (value: IExploreMenuItem) => {
    return !!this.props.filter?.categories?.some((x) => x.value === value.value)
  }

  getConfigs = (menuConfigs: IExploreMenu[], filter?: IExploreFilter): IExploreMenu[] => {
    return menuConfigs.map((menuGroup) => {
      menuGroup.items = menuGroup.items.map((item, i) => {
        let isActive = false
        switch (item.filterKey) {
          case 'categories':
            isActive = !!filter?.categories?.some((x) => x.value === item.value)
            break
          case 'sort':
            isActive = filter?.sort === item.value
            break
          default:
            break
        }
        return { ...item, isActive }
      })
      return menuGroup
    })
  }

  render() {
    const config = this.getConfigs(this.props.data, this.props.filter)
    return (
      <Wrap sx={{ marginTop: '10px' }}>
        {config.map((item, index) => (
          <Box key={`key-${index}`}>
            <Typography variant='subtitle1' className='title'>
              {item.title}
            </Typography>
            <ul className='list-area'>
              {item.items.map((x, i) => {
                const liClasses: string[] = []
                if (x.isActive) liClasses.push('active')
                return (
                  <li className={liClasses.join(' ')} key={`key-${i}`}>
                    <WrapMenuItem className='menu-item' onClick={() => this.handleChange(x)}>
                      {x.imageUri && (
                        <WrapImage className='wrap-image'>
                          <img src={GetImageUrlS3(x.imageUri)} alt='category-thumbnail' />
                        </WrapImage>
                      )}
                      {x.icon && <WrapIcon className='wrap-icon'>{x.icon}</WrapIcon>}
                      <Typography variant='subtitle2' component='span' sx={{ flex: 1, ml: '12px' }}>
                        {x.label || x.value}
                      </Typography>
                      <Fade in={!x.isActive}>
                        <Typography variant='subtitle2' className='count' sx={{ mr: '12px' }}>
                          {x.count}
                        </Typography>
                      </Fade>
                      <Collapse in={x.isActive} orientation='horizontal'>
                        <IconButtonCustom>
                          <CancelIcon fontSize='small' />
                        </IconButtonCustom>
                      </Collapse>
                    </WrapMenuItem>
                  </li>
                )
              })}
            </ul>
            {item.items.length < 1 && this.props.isLoading && <UISkeletonCategories />}
          </Box>
        ))}
      </Wrap>
    )
  }
}

export default UIMenuLeft

const Wrap = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: 'calc(var(--height-header) + 25px)',
  '& > .MuiBox-root': {
    marginBottom: '20px'
  },
  '& li.active .menu-item': {
    borderColor: '#1976d2'
  },
  '& li .MuiTypography-root,& li .wrap-icon svg': {
    transition: '0.3s'
  },
  '& li.active .MuiTypography-root,& li.active .wrap-icon svg': {
    color: '#1976d2'
  },
  '& li.active .count': {
    marginRight: '6px'
  },
  '& .MuiTypography-root.title': {
    marginBottom: '10px'
  },
  [theme.breakpoints.up('md')]: {
    paddingBottom: '100px',
    '& .MuiTypography-root.title': {
      marginBottom: '20px'
    }
  }
}))

const WrapMenuItem = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  color: '#183153',
  border: '1px solid transparent',
  borderRadius: '4px',
  transition: '0.3s',
  textDecoration: 'none',
  cursor: 'pointer',
  margin: '6px 0',
  overflow: 'hidden',
  '&:hover': {
    borderColor: '#c3c6d1'
  }
})

const WrapImage = styled(Box)({
  position: 'relative',
  fontSize: '48px',
  width: '1em',
  height: '1em',
  '& > img': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  }
})

const WrapIcon = styled(Box)({
  fontSize: '36px',
  width: '1em',
  height: '1em',
  display: 'flex',
  flex: '0 0 auto',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '12px'
})

const IconButtonCustom = styled(IconButton)({
  color: 'rgba(0, 0, 0, 0.26)',
  transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  '&:hover': {
    color: 'rgba(0, 0, 0, 0.4)'
  }
})
