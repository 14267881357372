import { AppDispatch, RootState } from '@internal/redux'
import { fetchPolicyFileThunk } from './redux.thunks'
import { IReduxMapDispatch, IReduxMapState } from './redux.types'
import PolicyFileSlice from './redux.slice'

export const mapStateToProps = (state: RootState): IReduxMapState => ({
  status: state.PolicyFileSlice.status,
  slice: state.PolicyFileSlice
})

export const mapDispatchToProps = (dispatch: AppDispatch): IReduxMapDispatch => ({
  fetchData: () => {
    return dispatch(fetchPolicyFileThunk())
  },
  onChangeStatus: (status) => {
    dispatch(PolicyFileSlice.actions.onChangeStatus(status))
  }
})
