import React, { FC } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'

export const MapNavigate = (context: (navigate: NavigateFunction) => JSX.Element) => {
  const Elm: FC = () => {
    const navigate = useNavigate()
    return <>{context(navigate)}</>
  }
  return <Elm />
}
