import { ELazyStatus } from '@lib/ReduxBase'
import { createSlice } from '@reduxjs/toolkit'
import { IMediaDelivery } from '@shared/Types'
import { ReduxBaseTable } from '@lib/ReduxBase'
import { fetchDeliveryThunk } from './redux.thunks'
import { IDeliverySliceState } from './redux.types'

export const DefaultSlice = ReduxBaseTable.GetInitialSlice<IDeliverySliceState, IMediaDelivery>({
  GridSortModel: [{ field: 'DateCreated', sort: 'desc' }]
})

const initialState: IDeliverySliceState = DefaultSlice.initialState

const DeliverySlice = createSlice({
  name: 'DeliverySlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: { ...DefaultSlice.reducers },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDeliveryThunk.fulfilled, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.status = ELazyStatus.Loaded
        state.tableInfo = action.payload.tableInfo
      })
      .addCase(fetchDeliveryThunk.rejected, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.status = ELazyStatus.Error
        state.tableInfo.isLoading = true
      })
      .addCase(fetchDeliveryThunk.pending, (state, action) => {
        state.requestedId = action.meta.requestId
        state.status = ELazyStatus.Loading
        state.tableInfo.isLoading = true
      })
  }
})

export default DeliverySlice
