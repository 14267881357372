import React, { FC, useEffect, useState } from 'react'
import { WindowScrollToTop } from '@lib/Helpers'
import { Link, LinkProps } from 'react-router-dom'
import { Box, Button, ButtonProps, IconButton, IconButtonProps, styled, Tooltip } from '@mui/material'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'

interface IProps extends ButtonProps, Pick<LinkProps, 'to'> {}

export const UIButtonBack = styled(({ to, children, ...p }: IProps) => (
  <Button component={Link} {...p} startIcon={<KeyboardBackspaceIcon />} variant='text' to={to}>
    {children}
  </Button>
))({
  textTransform: 'unset',
  color: '#202020',
  fontWeight: 600,
  padding: '7px 12px',
  transition: 'linear 0.2s',
  fontSize: '0.9rem',
  '&:hover': {
    color: '#006bd6'
  }
})

export const UIButtonScrollToTop: FC<Omit<IconButtonProps, 'onClick'>> = (props) => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY
      setIsVisible(scrollTop > 0)
    }
    window.addEventListener('scroll', handleScroll)
    handleScroll()
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <Wrap className={isVisible ? 'visible' : ''}>
      <Tooltip title='Scroll to top' placement='left' arrow>
        <IconButton size='large' onClick={() => WindowScrollToTop()} {...props}>
          <ArrowUpwardIcon />
        </IconButton>
      </Tooltip>
    </Wrap>
  )
}

const Wrap = styled(Box)({
  position: 'fixed',
  bottom: '25px',
  right: '25px',
  display: 'flex',
  visibility: 'visible',
  opacity: 0,
  zIndex: 100,
  transition: 'linear 0.2s',
  '&.visible': {
    visibility: 'visible',
    opacity: 1
  },
  '& .MuiIconButton-root': {
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
    background: '#fff',
    transition: '0.4s'
  },
  '& .MuiIconButton-root:hover': {
    background: '#fefefe',
    color: 'var(--color-orange)',
    transition: '0.4s'
  }
})
