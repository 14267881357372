import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { RouteKey } from '@internal/route'
import { CreateRoutePath } from '@lib/RouteBase'
import { UITextLineBreak } from '@shared/UIStyleds'
import { WindowScrollToTopById } from '@lib/Helpers'
import { IconSuccess } from '@lib/Component/Animation'
import { Box, Button, ButtonProps, Container, Stack, styled, Typography, TypographyProps } from '@mui/material'
import AppConfig from '@internal/shareds/AppConfig'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { SubmitMediaData } from './helpers'

const CurrentId = 'submit-media-step-complete'

export default class StepComplete extends Component {
  componentDidMount(): void {
    WindowScrollToTopById(CurrentId)
  }

  render() {
    return (
      <Container id={CurrentId}>
        <Stack sx={{ alignItems: 'center', justifyContent: 'center', minHeight: '50vh' }}>
          <IconSuccess />
          <Typography variant='h2' component='p' sx={{ textTransform: 'upercase', fontWeight: 600 }}>
            THANK YOU!
          </Typography>
          <Typography variant='h6' component='p' sx={{ textAlign: 'center', mt: '20px' }}>
            A <b>digital contract</b> has been sent to your <b>email</b>. Please check your inbox and sign it.
          </Typography>
          <CustomButton to={CreateRoutePath([RouteKey.Profile, RouteKey.Submission])} endIcon={<ArrowRightAltIcon fontSize='small' />}>
            View submitted confirmation
          </CustomButton>
          <LinkReadMore to={CreateRoutePath(RouteKey.PrivacyPolicy)}>Read about out privacy policy</LinkReadMore>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: '50px' }}>
            <Typography variant='h6' component='span' sx={{ mr: '12px' }}>
              Let's Be Friend!
            </Typography>
            {AppConfig.Socials.map((x, i) => (
              <SocialIcon key={i} href={x.link} target='_blank'>
                {x.icon}
              </SocialIcon>
            ))}
          </Box>
        </Stack>
      </Container>
    )
  }
}

const CustomButton = styled((p: ButtonProps & { to: string }) => {
  return <Button component={Link} color='inherit' variant='contained' size='large' {...p} />
})({
  backgroundColor: 'var(--color-black)',
  color: 'var(--color-text-light)',
  minWidth: '250px',
  marginTop: '24px',
  height: '56px',
  textTransform: 'none',
  fontWeight: 600,
  transition: '0.3s',
  '&:hover': {
    backgroundColor: 'var(--color-black2)',
    color: 'var(--color-text-light)'
  },
  '& .MuiButton-icon.MuiButton-endIcon': {
    transition: '0.3s'
  },
  '&:hover .MuiButton-icon.MuiButton-endIcon': {
    marginLeft: '16px'
  }
})

const LinkReadMore = styled((p: TypographyProps & { to: string }) => <Typography component={Link} {...p} />)({
  color: 'var(--color-text)',
  marginTop: '20px',
  transition: '0.3s',
  fontWeight: 600,
  '&:hover': {
    color: 'var(--color-blue)'
  }
})

const SocialIcon = styled('a')({
  textDecoration: 'none',
  cursor: 'pointer',
  padding: '5px 10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& svg': {
    transition: 'all linear 0.2s',
    color: 'var(--color-gray)'
  },
  '&:hover svg': {
    color: '#1976D2'
  }
})
