import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { IconButton } from '@mui/material'
import * as IconsMaterial from '@mui/icons-material'

interface IProps {
  to: string
  target?: React.HTMLAttributeAnchorTarget
}

export const UIBtnDetail: FC<IProps> = (props) => (
  <IconButton color='info' {...{ component: Link, to: props.to, target: props.target }} sx={{ cursor: 'pointer' }}>
    <IconsMaterial.ListAlt />
  </IconButton>
)

// export const UIBtnEdit: FC<IProps> = (props) => (
//   <IconButton color='info' {...{ component: Link, to: props.to, target: props.target }} sx={{ cursor: 'pointer' }}>
//     <IconsMaterial.Edit />
//   </IconButton>
// )
