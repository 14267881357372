import { IBilling } from '@shared/Types'
import { ReduxBaseTable } from '@lib/ReduxBase'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { BillingDetailService, BillingDetailMapping } from '@internal/services/BillingDetailService'
import BillingService, { BillingMapping } from '@internal/services/BillingService'
import { IBillingDetailSliceState, IReduxThunkArg, IReduxThunkReturned } from './redux.types'

export const fetchBillingDetailThunk = createAsyncThunk<IReduxThunkReturned, IReduxThunkArg>('fetchBillingDetailThunk', async (param, thunkAPI) => {
  const state = thunkAPI.getState() as { BillingDetailSlice: IBillingDetailSliceState }
  const tableInfoParam = ReduxBaseTable.ThunkArgMapping({ tableInfo: state.BillingDetailSlice.tableInfo, ...param })

  const filter = BillingDetailMapping.tableInfoToFilter(tableInfoParam, param.billingId)
  const res = await BillingDetailService.AllGraphQL(filter, thunkAPI.signal)

  const tableInfo = ReduxBaseTable.ThunkReponseMapping(tableInfoParam, {
    Data: BillingDetailMapping.billingDetails(res?.items),
    Total: res?.totalItems
  })
  return { tableInfo }
})

export const fetchBillingDetailInitialThunk = createAsyncThunk<{ Billing?: IBilling }, IReduxThunkArg>(
  'fetchBillingDetailInitialThunk',
  async (param, thunkAPI) => {
    // const state = thunkAPI.getState() as { BillingDetailSlice: IBillingDetailStateSlice }
    thunkAPI.dispatch(fetchBillingDetailThunk(param))

    const res = await BillingService.SingleGraphQL({ id: param.billingId }, thunkAPI.signal)
    return { Billing: BillingMapping.billing(res) }
  }
)
