import React, { FC } from 'react'
import { IProduct } from '@shared/Types'
import { UITextLineBreak } from '@shared/UIStyleds'
import { Box, Collapse, Divider, Typography } from '@mui/material'
import UIMediaPlayer from '@internal/shareds/ui.media-player'
import { UIPlayerBasicInfo } from './ui.anything'

interface IProps {
  resourceId?: string
  product?: IProduct
  controlBar?: JSX.Element
}

const UIPlayer: FC<IProps> = (props) => (
  <div>
    <UIMediaPlayer ytVideoId={props.resourceId} />
    <Collapse in={!!props.product}>
      <Box sx={{ padding: '10px 0 24px' }}>
        {props.controlBar}
        <Divider sx={{ m: '12px 0 18px' }} />
        <UIPlayerBasicInfo data={props.product} />
        <Typography component='p' variant='body1' sx={{ marginTop: '10px' }}>
          <UITextLineBreak>{props.product?.Description}</UITextLineBreak>
        </Typography>
      </Box>
    </Collapse>
  </div>
)

export default UIPlayer
