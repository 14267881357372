import React, { FC } from 'react'
import { IProduct } from '@shared/Types'
import { FormatDatetime2 } from '@lib/Helpers'
import { Box, Typography } from '@mui/material'
import { TryParseProductTags } from './helpers'

export const UIPlayerBasicInfo: FC<{ data?: IProduct }> = (props) => {
  const tags = TryParseProductTags(props.data?.Tags)
  return (
    <Box sx={{ display: 'flex' }}>
      <Typography component='span' variant='subtitle2'>
        {FormatDatetime2(props.data?.PublishedDate ?? '', 'dddd, MMMM DD, YYYY')}
      </Typography>
      {tags.length > 0 && (
        <Typography component='span' variant='subtitle2' sx={{ color: 'var(--color-text-2)', ml: '24px' }}>
          {tags.join(' • ')}
        </Typography>
      )}
    </Box>
  )
}
