import { ReduxBase } from '@lib/ReduxBase'
import { ELazyStatus } from '@lib/ReduxBase'
import { IExploreFilterValue } from '@shared/Types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ExploreQueryParam } from '@shared/Services/QueryParamService'
import { DefaultExploreFilterPagination } from '@internal/services/ProductService'
import { IExploreSliceState } from './redux.types'
import { fetchExploreFilterThunk, fetchExploreThunk } from './redux.thunks'

export const DefaultSlice = ReduxBase.GetInitialSlice<IExploreSliceState>({
  state: { filter: ExploreQueryParam.GetToFilter({ pagination: DefaultExploreFilterPagination }), products: [], categories: [], prices: [] }
})

const ExploreSlice = createSlice({
  name: 'ExploreSlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState: DefaultSlice.initialState,
  reducers: {
    ...DefaultSlice.reducers,
    updateFilter: (state, action: PayloadAction<IExploreFilterValue | IExploreFilterValue[]>) => {
      const temps = Array.isArray(action.payload) ? action.payload : [action.payload]
      const list = temps.reduce<IExploreFilterValue[]>((a, b) => {
        if (!a.some((x) => x.key === b.key)) a.push(b)
        return a
      }, [])
      const obj = { ...state.filter }
      list.forEach((x) => {
        obj[x.key] = x.value as any
        if (x.key !== 'pagination' && !!obj.pagination) {
          obj.pagination.page = 1
        }
        if (x.key === 'type') {
          obj.categories = []
        }
      })
      state.filter = obj
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExploreThunk.fulfilled, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.status = ELazyStatus.Loaded

        state.filter = action.payload.filter
        state.prices = action.payload.prices
        state.products = action.payload.products
        state.categories = action.payload.categories
      })
      .addCase(fetchExploreThunk.rejected, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.status = ELazyStatus.Error
      })
      .addCase(fetchExploreThunk.pending, (state, action) => {
        state.requestedId = action.meta.requestId
        state.status = ELazyStatus.Loading
      })

    builder
      .addCase(fetchExploreFilterThunk.fulfilled, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.status = ELazyStatus.Loaded

        state.filter = action.payload.filter
        state.products = action.payload.products
        state.categories = action.payload.categories
      })
      .addCase(fetchExploreFilterThunk.rejected, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.status = ELazyStatus.Error
      })
      .addCase(fetchExploreFilterThunk.pending, (state, action) => {
        state.requestedId = action.meta.requestId
        state.status = ELazyStatus.Loading
      })
  }
})
export default ExploreSlice
