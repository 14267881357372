import React, { Component } from 'react'
import { motion } from 'framer-motion'
import { IFormInputBase } from '@lib/Forms'
import { IOrderDetail } from '@shared/Types'
import { CopyToClipboard } from '@lib/Component'
import { Box, Typography, styled } from '@mui/material'

export class UISocialUrl extends Component<IFormInputBase<IOrderDetail>> {
  render() {
    const { data, name } = this.props
    const dValue = this.props.defaultValue ?? (!!name ? data?.[name] : undefined)
    const list = this.parseData(dValue)
    return (
      <Wrap>
        {list.map((item, index) => {
          return (
            <Box key={index} component='li'>
              <MotionItem
                sx={{ borderRadius: '6px' }}
                initial={{ opacity: 0.3, backgroundColor: '#dedede' }}
                animate={{ opacity: 1, backgroundColor: '#fafafa' }}
                transition={{ ease: 'linear', duration: 1 }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant='subtitle2' className='noselect' sx={{ color: '#979797' }}>
                    {index + 1}.
                  </Typography>
                  <CustomText variant='subtitle2' {...{ component: 'a', href: item, target: '_blank' }} noWrap>
                    {item}
                  </CustomText>
                  <CopyToClipboard value={item} />
                </Box>
              </MotionItem>
            </Box>
          )
        })}
      </Wrap>
    )
  }

  parseData = (value?: any): string[] => {
    try {
      if (Array.isArray(value)) return value
      return JSON.parse(value ?? '[]')
    } catch (error) {
      return []
    }
  }
}

const Wrap = styled('ul')({
  padding: '9px 24px 12px 0'
})

const MotionItem = styled(motion.div)({
  '& > div': {
    height: '38px',
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    marginTop: '4px',
    padding: '3px 9px'
  }
})

const CustomText = styled(Typography)({
  color: '#606060',
  flex: 1,
  textDecoration: 'unset !important',
  '&:hover': {
    color: '#1976d2'
  }
})
