import { ITableTemplateState } from '@lib/Table'
import { TableToRequestFilter } from '@lib/Helpers'
import { CRUDServiceBase, ProcessError } from '@lib/Http'
import { EMediaDeliveryContentType, EMediaDeliveryStatus, EMediaDeliveryType, EMediaType, IMediaDelivery } from '@shared/Types'
import Graphql from '@coreprj/graphql'

export type TMediaDelivery = Graphql.QMediaStore.TMediaDelivery
type TMediaDeliveriesGraphQlResult = Graphql.TGraphQlResult<TMediaDelivery>
type TMediaDeliveriesFilterParams = Graphql.QMediaStore.TMediaDeliveriesFilterParams

class MediaDeliveryServiceBase extends CRUDServiceBase<IMediaDelivery, string> {
  constructor() {
    super(`/api/v1/user/mediaDelivery`)
  }

  allGraphQL = async (params: TMediaDeliveriesFilterParams, signal?: AbortSignal): Promise<TMediaDeliveriesGraphQlResult | undefined> => {
    try {
      const res = await Graphql.MediaStore.Query(Graphql.QMediaStore.MediaDeliveriesUserQuery(params), { signal, delay: 700 })
      return res.mediaStore.mediaDeliveries as TMediaDeliveriesGraphQlResult
    } catch (error: any) {
      console.log(error)
      ProcessError(error)
    }
  }
}
const MediaDeliveryService = new MediaDeliveryServiceBase()
export default MediaDeliveryService

class MediaDeliveryMappingBase {
  tableInfoToFilter = (tableInfo: ITableTemplateState<IMediaDelivery>) => {
    return new TableToRequestFilter.Graphql<IMediaDelivery, TMediaDelivery>({
      fieldMapping: {
        Description: { field: 'description' },
        Status: { field: 'status' },
        DateCreated: { field: 'dateCreated' },
        DateUpdated: { field: 'dateUpdated' }
      },
      fieldMappingDeep: {
        Title: { submission: { field: 'title' } },
        Email: { submission: { field: 'email' } },
        KindlyDescribe: { submission: { field: 'kindlyDescribe' } }
      }
    })
      .fromTable(tableInfo, ['Name', 'Title', 'Email', 'Description'])
      .sort({ field: 'Id', sort: 'asc' })
      .build()
  }

  delivery = (item: TMediaDelivery): IMediaDelivery => ({
    Id: item.id,
    Name: item.name ?? '',
    MediaType: EMediaType.Video,
    Type: item.type as EMediaDeliveryType,
    Status: item.status as EMediaDeliveryStatus,
    ContentType: item.contentType as EMediaDeliveryContentType,
    Content: item.content,
    ResourceId: item.resourceId ?? '',
    Description: item.description ?? '',
    DateCreated: item.dateCreated ?? '',
    DateUpdated: item.dateUpdated ?? '',
    // relationship
    Title: item.submission?.title,
    Email: item.submission?.email,
    FirstName: item.submission?.firstName,
    LastName: item.submission?.lastName,
    KindlyDescribe: item.submission?.kindlyDescribe
  })

  deliveries = (items: TMediaDelivery[]): IMediaDelivery[] => items.map(this.delivery)

  update = (item: Partial<IMediaDelivery>): Partial<IMediaDelivery> => ({
    Type: item.Type,
    ResourceId: item.ResourceId,
    Description: item.Description,
    Content: item.Content,
    Status: item.Status
  })
}
export const MediaDeliveryMapping = new MediaDeliveryMappingBase()
