import React, { FC } from 'react'
import { Box, Chip, styled, SxProps, Theme } from '@mui/material'
import UITooltip from './ui.tooltip'

export const SxTable: SxProps<Theme> = {
  [`& .MuiDataGrid-row.Mui-hovered .cell-tags::before`]: {
    background: 'linear-gradient(to left, #f5f5f5, transparent)'
  },
  [`& .MuiDataGrid-row.Mui-selected .cell-tags::before`]: {
    background: 'linear-gradient(to left, rgb(237 244 251), transparent)'
  },
  [`& .MuiDataGrid-row.Mui-hovered.Mui-selected .cell-tags::before`]: {
    background: 'linear-gradient(to left, #e3eefa, transparent)'
  }
}

interface ICellTagsProps {
  value: string
}

export const UICellTags: FC<ICellTagsProps> = (props) => {
  if (typeof props.value !== 'string') return <></>
  const maxCount = 3
  const categoryNames = props.value.split('|').filter((x) => !!x)
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <UITooltip
        tooltipProps={{ open: categoryNames.length < maxCount ? false : undefined }}
        content={
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '6px' }}>
            {categoryNames.map((x, i) => (
              <CustomChip key={'key' + i} label={x} variant='outlined' />
            ))}
          </Box>
        }
      >
        <Wrap className='cell-tags'>
          {categoryNames.map((x, i) => (
            <CustomChip key={'key' + i} label={x} variant='outlined' />
          ))}
        </Wrap>
      </UITooltip>
    </Box>
  )
}

const CustomChip = styled(Chip)({
  lineHeight: 1,
  '& > span': { marginTop: '2px' }
})

const Wrap = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '6px',
  position: 'relative',
  width: '180px',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: '100px',
    height: '100%',
    background: 'linear-gradient(to left, #fff, transparent)',
    pointerEvents: 'none'
  }
})
