import React, { FC } from 'react'
import { Container, Stepper, Step, StepLabel, Typography, Divider } from '@mui/material'

const steps = ['Personal Detail', 'Payment', 'Complete']

interface IStepperProps {
  activeStep?: number
}

export const UIStepper: FC<IStepperProps> = (props) => (
  <Container maxWidth='md' sx={{ mb: '50px' }}>
    <Stepper activeStep={props.activeStep}>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  </Container>
)

interface IInfoTopProps {
  title: string
  subTitle?: string
}

export const UIInfoTop: FC<IInfoTopProps> = (props) => (
  <>
    <Typography variant='h5' sx={{ fontWeight: 600 }}>
      {props.title}
    </Typography>
    <Typography variant='subtitle2' sx={{ color: '#414141', fontStyle: 'initial', mb: '10px', minHeight: '1.75em' }}>
      {props.subTitle}
    </Typography>
    <Divider />
  </>
)
