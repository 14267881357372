import React, { FC } from 'react'
import { EBillingStatus } from '@shared/Types'
import { Box, Typography } from '@mui/material'
import { FormatterUSD, FormatterVN } from '@lib/Helpers'
import { CreateStatusCell, IInfoBarItemProps } from '@shared/UIStyleds'
import { IBillingDTO } from './types'

export const UICellStatus = CreateStatusCell<EBillingStatus>({
  New: { background: 'linear-gradient(to right,#00b93b,#5adc82)' },
  Paid: { background: 'linear-gradient(to right,#357a38,#1edd9b)' },
  UnPaid: { background: 'linear-gradient(to right, rgb(178, 137, 0), rgb(235, 204, 99))' }
})

export const UICellDetailStatus: FC<IInfoBarItemProps<IBillingDTO>> = (props) => {
  if (!props.data?.Status) return <></>
  return <UICellStatus data={props.data?.Status} sx={{ justifyContent: 'flex-end' }} />
}

interface ICellTotalMoneyProps {
  Usd: number
  UsdRate: number
}

export const UICellTotalMoney: FC<ICellTotalMoneyProps> = (props) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
    <Typography variant='subtitle2'>{FormatterUSD(props.Usd)}</Typography>
    <Typography variant='subtitle2'>{FormatterVN(props.Usd * props.UsdRate)}</Typography>
  </Box>
)
