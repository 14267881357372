import React, { FC, Fragment } from 'react'
import { CreateFormUI } from '@lib/Forms'
import { CreateTextOnly } from '@lib/Forms/Inputs'
import { IOrder, IOrderDetail } from '@shared/Types'
import { TableFormater, TryParseArray } from '@lib/Helpers'
import { Box, Divider, IconButton, Tooltip, Typography } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { UISocialUrl } from './ui.social-url'
import { GetUrlYoutube } from '@shared/Services'

const TextOnlyInstance = CreateTextOnly<IOrder>()

const parseNameResourceInfo = (value?: string): string => {
  try {
    return JSON.parse(value ?? '{}')['Name'] ?? ''
  } catch (error) {
    return ''
  }
}

const CreateOrderInfo = (value?: Partial<IOrder>) => {
  return CreateFormUI<IOrder>({
    title: (
      <Typography variant='subtitle1' sx={{ fontWeight: 600, color: '#0094fe' }}>
        Order infomation
      </Typography>
    ),
    description: value?.Name ?? '',
    configs: [{ name: 'Method', label: 'Payment Method' }],
    options: {
      alwaysAction: false,
      inputElementDefault: ({ options, ...p }) => <TextOnlyInstance {...p} sx={{ mt: '5px' }} />
    }
  })
}

const CreateOrderDetail = (item: IOrderDetail, index: number) => {
  const urls = TryParseArray(item.SocialUrl)
  const price = TableFormater.formatCurrency(item.Price * urls.length)
  const descriptions = [item.Type, price, TableFormater.formatDate(item.DateCreated)].filter((x) => !!x)
  return CreateFormUI<IOrderDetail>({
    title: (
      <Box sx={{ display: 'flex', alignItems: 'center', pr: '24px' }}>
        <Typography variant='subtitle2' noWrap sx={{ fontWeight: 600, flex: 1 }}>
          <span className='noselect'>{index + 1}. </span>
          {parseNameResourceInfo(item.ResourceInfo)}
        </Typography>
        <Tooltip title='Watch on Youtube'>
          <IconButton component='a' target='_blank' href={GetUrlYoutube(item.ResourceId)} sx={{ mr: '9px' }}>
            <OpenInNewIcon fontSize='small' />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    description: (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '18px' }}>
        {descriptions.map((item, index) => (
          <Fragment key={item + index}>
            <Typography variant='caption' {...{ component: 'span' }}>
              {item}
            </Typography>
            {index < descriptions.length - 1 && <Divider orientation='vertical' flexItem />}
          </Fragment>
        ))}
      </Box>
    ),
    configs: [{ name: 'SocialUrl', label: 'Social URLs' }],
    options: { alwaysAction: false, itemElementDefault: UISocialUrl }
  })
}

interface IProps {
  data?: Partial<IOrder>
}

const UIDetails: FC<IProps> = (props) => {
  const list = props.data?.OrderDetails ?? []
  const OrderInfo = CreateOrderInfo(props.data)
  return (
    <Box sx={{ mt: '6px' }}>
      <OrderInfo key='OrderInfo' data={props.data} />
      {list.map((x, i) => {
        const OrderDetail = CreateOrderDetail(x, i)
        const name = parseNameResourceInfo(x.ResourceInfo)
        return <OrderDetail key={name + i} data={x} />
      })}
    </Box>
  )
}
export default UIDetails
