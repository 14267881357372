import React, { Component } from 'react'
import { MergeObjects } from '@lib/Helpers'
import { Collapse, FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectProps } from '@mui/material'
import { IFormInputBase } from '../../types'
import { getErrorMessage } from '../../helper'

export interface ISelectSimpleOption<TModel extends string = string> {
  name: string
  value: TModel
}

interface ISlots {
  selectProps?: Omit<SelectProps, 'variant'>
}

interface IArgs extends ISlots {
  options?: ISelectSimpleOption[]
}

const CreateSelectSimple = function <TModel = any>(args?: IArgs) {
  interface IProps extends Partial<IFormInputBase<TModel>> {
    options?: ISelectSimpleOption[]
    onChange?: (value: ISelectSimpleOption) => void
    slots?: ISlots
    fullWidth?: boolean
  }
  interface IState {
    value?: string
  }
  class SelectSimple extends Component<IProps, IState> {
    constructor(props: IProps) {
      super(props)
      this.state = { value: this.getDefaultValue()?.toString() }
    }
    mapProps = (): SelectProps => {
      const label = this.getLabel()
      const tfp: SelectProps = {
        id: this.props.name?.toString(),
        labelId: this.props.name?.toString() + label,
        name: this.props.name?.toString(),
        label: label,
        defaultValue: this.getDefaultValue(),
        value: this.state.value,
        onChange: (event) => {
          const value: string = event.target.value + ''
          this.setState({ value }, () => {
            if (!!this.props.name) {
              this.props.onBlur && this.props.onBlur(this.props.name)
            }
            const options = this.getOptions()
            const temp = options.find((x) => x.value?.toString() === value)
            if (!temp) return
            this.props.onChange && this.props.onChange(temp)
          })
        },
        disabled: this.props.disabled,
        fullWidth: this.props.fullWidth !== undefined ? this.props.fullWidth : true
      }
      const selectProps = this.props.slots?.selectProps ?? args?.selectProps
      return MergeObjects({}, tfp, selectProps)
    }
    render() {
      const data = this.getOptions()
      const label = this.getLabel()
      const errorMessage = getErrorMessage(this.props.messageErrors, this.props.name)
      return (
        <React.Fragment>
          {!!this.props.disabled && <input hidden name={this.props.name?.toString()} defaultValue={this.getDefaultValue()} />}
          <FormControl fullWidth disabled={this.props.disabled} error={errorMessage.error}>
            <InputLabel id={this.props.name?.toString() + label}>{label}</InputLabel>
            <Select {...this.mapProps()}>
              {data.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            <Collapse in={errorMessage.error}>
              <FormHelperText>{errorMessage.message}</FormHelperText>
            </Collapse>
          </FormControl>
        </React.Fragment>
      )
    }
    getLabel = () => {
      if (!!this.props.label && typeof this.props.label === 'string') return this.props.label
      return this.props.name?.toString() ?? ''
    }
    getDefaultValue = () => {
      const { data, name } = this.props
      const options = this.getOptions()
      return this.props.defaultValue?.toString() ?? (!!data && !!name ? data[name] : undefined) ?? options[0].value
    }
    getOptions = (): ISelectSimpleOption[] => {
      return args?.options ?? this.props.options ?? []
    }
  }
  return SelectSimple
}
export default CreateSelectSimple
export type SelectSimpleType<TModel> = ReturnType<typeof CreateSelectSimple<TModel>>
