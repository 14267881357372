import React, { FC } from 'react'
import { Box, BoxProps, styled, SxProps, Theme } from '@mui/material'
import { IconAudioBase64, IconVideoBase64 } from './assets/images/base64'
import Background01 from './assets/images/bg.01.webp'
import Background02 from './assets/images/bg.02.webp'
import Faq01 from './assets/images/faq.01.webp'
import LogoIcon from './assets/images/logo-icon.webp'
import LogoXamdo from './assets/images/logo-xamdo.webp'
import Logo from './assets/images/logo.webp'
import NoData01 from './assets/images/no-data.01.svg'
import NoData02 from './assets/images/no-data.02.webp'
import Unit01 from './assets/images/unit.01.webp'
import Vector404 from './assets/images/vector-404.webp'

type TIcon = 'icon-audio' | 'icon-video'

type TImage = 'vector404' | 'bg-01' | 'bg-02' | 'faq-01' | 'logo-icon' | 'logo-xamdo' | 'logo' | 'no-data-01' | 'no-data-02' | 'unit-01'

const MapImages: { [key in TImage | TIcon]: string } = {
  vector404: Vector404,
  'bg-01': Background01,
  'bg-02': Background02,
  'faq-01': Faq01,
  'unit-01': Unit01,
  'icon-audio': IconAudioBase64,
  'icon-video': IconVideoBase64,
  'logo-icon': LogoIcon,
  'logo-xamdo': LogoXamdo,
  logo: Logo,
  'no-data-01': NoData01,
  'no-data-02': NoData02
}

interface IProps {
  src: TImage
  alt?: string
  sx?: SxProps<Theme>
}

const UIImage: FC<IProps> = (props) => {
  return <Box sx={props.sx} component='img' src={MapImages[props.src]} alt={props.alt || 'berlintomek-pic'} />
}

export default UIImage

export const GetImageUrlInternal = (image: TImage) => MapImages[image]

interface IImageIconProps extends Pick<BoxProps, 'sx' | 'className'> {
  src: TIcon
  color?: string
  size?: number | string
}

export const UIImageIcon = styled('span')<IImageIconProps>(({ src: image, color: fillColor, size }) => ({
  fontSize: typeof size === 'string' ? size : size + 'px',
  display: 'inline-block',
  width: '1em',
  height: '1em',
  background: fillColor || '#000',
  maskSize: 'contain',
  maskRepeat: 'no-repeat',
  maskImage: `url('${MapImages[image]}')`
}))
