import { IOrder } from '@shared/Types'
import { ReduxBaseTable } from '@lib/ReduxBase'
import { AppDispatch, RootState } from '@internal/redux'
import { fetchOrderThunk } from './redux.thunks'
import { IReduxMapDispatch, IReduxMapState } from './redux.types'
import OrderSlice, { DefaultSlice } from './redux.slice'

export const mapStateToProps = (state: RootState): IReduxMapState => ({
  status: state.OrderSlice.status,
  slice: state.OrderSlice,
  tableInfo: state.OrderSlice.tableInfo
})

type TRequest = { abort: () => void }
const FetchAccessory: { request?: TRequest } = {}
export const mapDispatchToProps = (dispatch: AppDispatch): IReduxMapDispatch => ({
  fetchData: () => {
    const tableInfoQueryParam = ReduxBaseTable.getParam<IOrder>(DefaultSlice.initialState.tableInfo)
    return dispatch(fetchOrderThunk({ tableInfoQueryParam }))
  },
  onChangeStatus: (status) => {
    dispatch(OrderSlice.actions.onChangeStatus(status))
  },
  onChangeTableInfo: (key, value, details) => {
    if (key === 'FilterModel') {
      dispatch(OrderSlice.actions.onChangeTableInfo({ key, value, details }))
    }
    FetchAccessory.request?.abort()
    FetchAccessory.request = dispatch(fetchOrderThunk({ tableInfoChange: { key, value: value, details } }))
  }
})
