import { IMediaDelivery } from '@shared/Types'
import { ReduxBaseTable } from '@lib/ReduxBase'
import { ApiAlertContext } from '@lib/ApiContext'
import { RootState, AppDispatch } from '@internal/redux'
import MediaDeliveryService, { MediaDeliveryMapping } from '@internal/services/MediaDeliveryService'
import { fetchDeliveryThunk } from './redux.thunks'
import { IReduxMapDispatch, IReduxMapState } from './redux.types'
import DeliverySlice, { DefaultSlice } from './redux.slice'

export const mapStateToProps = (state: RootState): IReduxMapState => ({
  status: state.DeliverySlice.status,
  slice: state.DeliverySlice,
  tableInfo: state.DeliverySlice.tableInfo
})

type TRequest = { abort: () => void }
const FetchAccessory: { request?: TRequest } = {}
export const mapDispatchToProps = (dispatch: AppDispatch): IReduxMapDispatch => ({
  fetchData: () => {
    const tableInfoQueryParam = ReduxBaseTable.getParam<IMediaDelivery>(DefaultSlice.initialState.tableInfo)
    return dispatch(fetchDeliveryThunk({ tableInfoQueryParam }))
  },
  onChangeStatus: (status) => {
    dispatch(DeliverySlice.actions.onChangeStatus(status))
  },
  onChangeTableInfo: (key, value, details) => {
    if (key === 'FilterModel') {
      dispatch(DeliverySlice.actions.onChangeTableInfo({ key, value, details }))
    }
    FetchAccessory.request?.abort()
    FetchAccessory.request = dispatch(fetchDeliveryThunk({ tableInfoChange: { key, value: value, details } }))
  },
  CRUD: {
    Create: async (model) => {
      const data = await MediaDeliveryService.Create(model)
      ApiAlertContext.ApiAlert?.PushSuccess('Created successfully!')
      dispatch(fetchDeliveryThunk())
      return data
    },
    Update: async (Id, model) => {
      await MediaDeliveryService.Update(Id, MediaDeliveryMapping.update(model))
      ApiAlertContext.ApiAlert?.PushSuccess('Updated successfully!')
      dispatch(fetchDeliveryThunk())
    },
    Delete: async (Id) => {
      const data = await MediaDeliveryService.Remove(Id)
      if (!!data) {
        ApiAlertContext.ApiAlert?.PushSuccess('Deleted successfully!')
        dispatch(fetchDeliveryThunk())
      }
    }
  }
})
