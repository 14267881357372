export * as HelperService from './HelperService'
export * from './HelperService'

export * as QueryParamService from './QueryParamService'

export const GetImageUrlS3 = (value: string) => {
  return `/StaticFile/Images/${value}`
}

export const GetFileUrlS3 = (value: string) => {
  return `/StaticFile/Images/${value}`
}

export const GetUrlPdfFlipBook = (value: string) => {
  const fullDomain = window.location.origin
  const fileUrl = GetFileUrlS3(value)
  return `${process.env.REACT_APP_HOME_URL}/flipbook/index.html?pdf=${fullDomain}${fileUrl}`
}

export const GetUrlYoutubeThumbnail = (id: string, size: 'default' | 'mqdefault' | 'hqdefault' | 'sddefault' | 'maxresdefault' = 'hqdefault') => {
  return `https://i.ytimg.com/vi/${id}/${size}.jpg`
}

export const GetUrlYoutube = (videoId: string) => {
  return `https://www.youtube.com/watch?v=${videoId}`
}
