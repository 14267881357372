import React from 'react'
import { connect } from 'react-redux'
import { RouteKey } from '@internal/route'
import { CreateHocLazy } from '@lib/ReduxBase'
import { ESubmissionStep } from '@shared/Types'
import { CreateRoutePath } from '@lib/RouteBase'
import { IRouterProps, withRouter } from '@lib/Hocs'
import { Outlet, useNavigate } from 'react-router-dom'
import { GetImageUrlInternal, UIHomeLink } from '@shared/UIStyleds'
import { Box, Container, Stack, styled, Typography, TypographyProps } from '@mui/material'
import { IStepperBarConfig } from './ui.stepper-bar'
import { SubmitMediaLocalStorage } from './helpers'
import { ISubmitMediaReduxProps } from './redux.types'
import { mapDispatchToProps, mapStateToProps } from './redux.map'
import StepComplete from './step.complete'
import StepLayout from './step.layout'
import StepAboutMedia from './step.about-media'
import StepAboutYouself from './step.about-youself'
import StepDigitalSignature from './step.digital-signature'
import StepCopyrightOwnership from './step.copyright-ownership'

const MapStep: { [key: number]: ESubmissionStep } = {
  0: ESubmissionStep.AboutMedia,
  1: ESubmissionStep.AboutYouself,
  2: ESubmissionStep.CopyrightOwnership,
  3: ESubmissionStep.DigitalSignature
}

const StepperBarConfigs: IStepperBarConfig[] = [
  {
    key: ESubmissionStep.AboutMedia,
    title: `Let's learn more about your video`,
    to: CreateRoutePath([RouteKey.Submit, RouteKey.SMTAboutMedia])
  },
  {
    key: ESubmissionStep.AboutYouself,
    title: `Tell us more about yourself`,
    to: CreateRoutePath([RouteKey.Submit, RouteKey.SMTAboutYouself])
  },
  {
    key: ESubmissionStep.CopyrightOwnership,
    title: `Let's verify the copyright ownership`,
    to: CreateRoutePath([RouteKey.Submit, RouteKey.SMTCopyrightOwnership])
  },
  {
    key: ESubmissionStep.DigitalSignature,
    title: `Digital Signature`,
    to: CreateRoutePath([RouteKey.Submit, RouteKey.SMTDigitalSignature])
  }
]

export const SumissionStepComponents: { [key in ESubmissionStep]: React.ComponentType } = {
  [ESubmissionStep.AboutMedia]: (p) => (
    <StepLayout configs={StepperBarConfigs}>
      <StepAboutMedia {...p} />
    </StepLayout>
  ),
  [ESubmissionStep.AboutYouself]: (p) => (
    <StepLayout configs={StepperBarConfigs}>
      <StepAboutYouself {...p} />
    </StepLayout>
  ),
  [ESubmissionStep.CopyrightOwnership]: (p) => (
    <StepLayout configs={StepperBarConfigs}>
      <StepCopyrightOwnership {...p} />
    </StepLayout>
  ),
  [ESubmissionStep.DigitalSignature]: (p) => (
    <StepLayout configs={StepperBarConfigs}>
      <StepDigitalSignature {...p} />
    </StepLayout>
  ),
  [ESubmissionStep.Complete]: StepComplete
}

interface IProps extends ISubmitMediaReduxProps, IRouterProps {}
const SubmitMediaBase: React.FC<IProps> = () => {
  const navigate = useNavigate()
  React.useEffect(() => {
    const pathSubmit = CreateRoutePath(RouteKey.Submit)
    const data = SubmitMediaLocalStorage.getData()
    if (!data) {
      navigate(pathSubmit)
      return
    }
    const step = MapStep[data.stepDones.length] ?? ESubmissionStep.AboutMedia
    const config = StepperBarConfigs.find((x) => x.key === step)
    navigate(config?.to ?? pathSubmit)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box sx={{ minHeight: 'calc(100vh - var(--height-header))', background: '#fff' }}>
      <CustomBgImage>
        <Content>
          <Typography variant='h5' component='p' sx={{ color: '#fff', fontWeight: 600 }}>
            Ready to start generating income?
          </Typography>
        </Content>
        <Box className='box-dark' />
      </CustomBgImage>
      <Container>
        <Stack direction='row' gap='10px' py='35px'>
          <Typography variant='h4' component='h6' sx={{ fontWeight: 600 }}>
            Have questions? Check out our
          </Typography>
          {/* <CustomLink href={CreateRoutePath(RouteKey.FQAs)}>FQAs</CustomLink> */}
          <CustomLink href='/#Faq'>FQAs</CustomLink>
        </Stack>
        <Outlet />
        <Box sx={{ height: '15vh' }} />
      </Container>
    </Box>
  )
}

export { default as SubmitMediaSlice } from './redux.slice'

const HocLazyInstance = CreateHocLazy(withRouter(SubmitMediaBase))

export const SubmitMediaMapRedux = connect(mapStateToProps, mapDispatchToProps)(HocLazyInstance)

const CustomLink = styled(({ href, ...props }: TypographyProps & { href: string }) => (
  // <Typography variant='h4' component={Link} to={href} {...props} />
  <Typography variant='h4' component={UIHomeLink} href={href} {...props} />
))({
  color: '#1976D2',
  transition: 'all linear 0.2s',
  cursor: 'pointer',
  padding: '0 2.5px',
  fontWeight: 600
})

const CustomBgImage = styled('div')({
  backgroundImage: `url(${GetImageUrlInternal('bg-02')})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  position: 'relative',
  display: 'flex',
  '& .box-dark': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.45)'
  }
})

const Content = styled(Container)({
  position: 'relative',
  zIndex: 1,
  height: `calc(50vh - var(--height-header))`,
  display: 'flex',
  alignItems: 'flex-end',
  paddingBottom: '20px'
})
