import { IOrder } from '@shared/Types'
import { RootState } from '@internal/redux'
import { ReduxBaseTable } from '@lib/ReduxBase'
import { createAsyncThunk } from '@reduxjs/toolkit'
import OrderService, { OrderMapping } from '@internal/services/OrderService'
import { DefaultSlice } from './redux.slice'
import { IReduxThunkArg, IReduxThunkReturned } from './redux.types'

export const fetchOrderThunk = createAsyncThunk<IReduxThunkReturned, IReduxThunkArg>('fetchOrderThunk', async (param, thunkAPI) => {
  const state = thunkAPI.getState() as RootState
  const tableInfoParam = ReduxBaseTable.ThunkArgMapping({ tableInfo: state.OrderSlice.tableInfo, ...param })

  const filter = OrderMapping.tableInfoToFilter(tableInfoParam)
  const res = await OrderService.GraphQLAll(filter, thunkAPI.signal)

  const tableInfo = ReduxBaseTable.ThunkReponseMapping(tableInfoParam, { Data: OrderMapping.orders(res?.items), Total: res?.totalItems })
  ReduxBaseTable.setParam<IOrder>(tableInfo, DefaultSlice.initialState.tableInfo)
  return { tableInfo }
})
