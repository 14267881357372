import { IOrder } from '@shared/Types'
import { ELazyStatus } from '@lib/ReduxBase/types'
import { fetchOrderThunk } from './redux.thunks'
import { IOrderSliceState } from './redux.types'
import { ReduxBaseTable } from '@lib/ReduxBase'
import { createSlice } from '@reduxjs/toolkit'

export const DefaultSlice = ReduxBaseTable.GetInitialSlice<IOrderSliceState, IOrder>({
  GridSortModel: [{ field: 'DateCreated', sort: 'desc' }]
})

const OrderSlice = createSlice({
  name: 'OrderSlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState: DefaultSlice.initialState,
  reducers: { ...DefaultSlice.reducers },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrderThunk.fulfilled, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.status = ELazyStatus.Loaded
        state.tableInfo = action.payload.tableInfo
      })
      .addCase(fetchOrderThunk.rejected, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.status = ELazyStatus.Error
        state.tableInfo.isLoading = false
      })
      .addCase(fetchOrderThunk.pending, (state, action) => {
        state.requestedId = action.meta.requestId
        state.status = ELazyStatus.Loading
        state.tableInfo.isLoading = true
      })
  }
})

export default OrderSlice
