import { EMediaDeliveryContentType } from '@shared/Types'

export const ICON_SIZE = {
  big: 82,
  small: 22
}

export const MapTexts: { [key in EMediaDeliveryContentType]: string } = {
  Internal: 'With base repository',
  Upload: 'With upload repository',
  Url: 'With linked repository'
}
