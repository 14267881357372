import { ReduxBase } from '@lib/ReduxBase'
import { createEntityAdapter, createSlice, PayloadAction, Update } from '@reduxjs/toolkit'
import { ELazyStatus } from '@lib/ReduxBase/types'
import { fetchNotificationThunk } from './redux.thunks'
import { INotificationSliceState } from './redux.types'
import { INotification } from '@shared/Types'

const SliceAdapter = createEntityAdapter<INotification>({ selectId: (x) => x.Id })

export const DefaultSlice = ReduxBase.GetInitialSlice<INotificationSliceState>({ state: { notifications: SliceAdapter.getInitialState() } })

const NotificationSlice = createSlice({
  name: 'NotificationSlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState: DefaultSlice.initialState,
  reducers: {
    ...DefaultSlice.reducers,
    Update: (state, action: PayloadAction<Update<INotification>>) => {
      SliceAdapter.updateOne(state.notifications, action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotificationThunk.fulfilled, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.status = ELazyStatus.Loaded
        SliceAdapter.removeAll(state.notifications)
        SliceAdapter.addMany(state.notifications, action.payload.notifications)
      })
      .addCase(fetchNotificationThunk.rejected, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.status = ELazyStatus.Error
      })
      .addCase(fetchNotificationThunk.pending, (state, action) => {
        state.requestedId = action.meta.requestId
        state.status = ELazyStatus.Loading
      })
  }
})

export default NotificationSlice
