import React, { FC } from 'react'
import { UIImage } from '@shared/UIStyleds'
import { IPrice, IProduct } from '@shared/Types'
import { UIProduct } from '@internal/shareds/UIs'
import { Box, Grid, Grow, styled } from '@mui/material'
import { FormQuicklyAddToCart } from '@internal/shareds/Views/Explore'
import { ApiPopover, IApiPopoverContext, MapApiPopoverContext } from '@lib/ApiContext'
import { UISkeletonVideoFilterResult } from './ui.skeleton'

interface IProps {
  isLoading?: boolean
  data: IProduct[]
  prices: IPrice[]
  onSubmitAddToCart?: (product: IProduct, price: IPrice) => void
}

const UIShowFilterResults: FC<IProps> = (props) => {
  const showQuickyAddToCart = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, context: IApiPopoverContext, product: IProduct) => {
    context.showPopover({
      anchorEl: event.currentTarget,
      popoverProps: {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        transformOrigin: { vertical: 'top', horizontal: 'right' }
      },
      content: (
        <FormQuicklyAddToCart
          prices={props.prices}
          product={product}
          onSubmit={async (value) => {
            const price = props.prices.find((x) => x.Id === value.PriceSelectedId)
            if (!price || !props.onSubmitAddToCart) {
              context.closePopover()
              return
            }
            props.onSubmitAddToCart(product, price)
            context.closePopover()
          }}
        />
      )
    })
  }

  return (
    <ApiPopover>
      {MapApiPopoverContext((context) => (
        <Grid container spacing={2} sx={{ mt: 0 }}>
          {props.data.map((x, i) => (
            <React.Fragment key={`key-${i}`}>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <UIProduct data={x} onAddToCart={(e) => showQuickyAddToCart(e, context, x)} />
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      ))}
      {props.data.length < 1 && props.isLoading === true && <UISkeletonVideoFilterResult />}
      <Grow in={props.data.length < 1 && props.isLoading === false} unmountOnExit>
        <WrapNoData>
          <UIImage src='no-data-01' />
        </WrapNoData>
      </Grow>
    </ApiPopover>
  )
}
export default UIShowFilterResults

const WrapNoData = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  padding: '50px 0',
  '& > img': {
    width: '100%',
    maxWidth: '650px'
  }
})
