import CreateModalRoute from './CreateModalRoute'
import CreateModalRouteWrapper from './CreateWrapper'
import WrapperLayout from './wrapper-layout'

const LayoutModalRoute = {
  Provider: CreateModalRoute(),
  Wrapper: CreateModalRouteWrapper(),
  WrapperLayout: WrapperLayout
}
export default LayoutModalRoute

export * from './CreateWrapper'
