import React, { Component } from 'react'
import { ICart } from '@shared/Types'
import { Link } from 'react-router-dom'
import { EMediaType } from '@shared/Types'
import { RouteKey } from '@internal/route'
import { CreateRoutePath } from '@lib/RouteBase'
import { Grid, Box, styled, alpha, Typography, TypographyProps } from '@mui/material'
import MusicNoteIcon from '@mui/icons-material/MusicNote'
import { UIBtnConfirm } from './ui.buttons'
import { MapCartItemContext } from './cart-item.context'
import CartHelpers from './helpers'
import FormInputPasteLinks from './form.input.paste-links'
import { UICardItemInfo } from './uis'

interface IProps {
  data: ICart
  onDelete?: (value: ICart) => void
  onChange?: (value: ICart) => void
}

export default class UICartItemContent extends Component<IProps> {
  render() {
    const { data } = this.props
    const infoConfigs = CartHelpers.getCartItemInfos(this.props.data)
    const mediaDetailPath = CreateRoutePath(RouteKey.Detail, { query: { id: data.Id, type: data.Type } })
    return (
      <Grid container spacing={3} sx={{ pb: '24px' }}>
        <Grid item xs={2}>
          {this.renderImage(this.props.data)}
        </Grid>
        <Grid item xs={10}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '9px' }}>
            <Title variant='h6' to={mediaDetailPath}>
              {this.props.data.Name}
            </Title>
            <UIBtnConfirm onSubmit={this.handleClickDelete} />
          </Box>
          <Typography variant='body2' sx={{ pr: '50px' }}>
            {this.props.data.Description}
          </Typography>
          <UICardItemInfo data={infoConfigs} />
          {this.renderPasteInput()}
        </Grid>
      </Grid>
    )
  }

  renderImage = (value: ICart) => {
    const { data } = this.props
    const obj = { component: Link, to: CreateRoutePath(RouteKey.Detail, { query: { id: data.Id, type: data.Type } }) }
    switch (value.Type) {
      case EMediaType.Audio:
        return (
          <WraperImage {...obj}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <MusicNoteIcon fontSize='large' sx={{ color: '#1976D2' }} />
            </Box>
          </WraperImage>
        )
      case EMediaType.Video:
        return (
          <Box {...obj} sx={{ width: '160px' }}>
            <ImageWrap>
              <img src={this.props.data.ImageUri} alt='thumbnail' />
            </ImageWrap>
          </Box>
        )
      default:
        return <></>
    }
  }

  renderPasteInput = () => {
    return MapCartItemContext((context) => {
      const links = this.props.data.JsonContent?.Links
      const flinks = CartHelpers.linksToArray(context.modelState?.PasteLinks)
      const value = flinks.length > 0 ? flinks : links
      return <FormInputPasteLinks defaultValue={value} cartContext={context} />
    })
  }

  handleClickDelete = () => {
    this.props.onDelete && this.props.onDelete(this.props.data)
  }
}

const Title = styled(({ to, ...p }: TypographyProps & { to: string }) => <Typography component={Link} to={to} {...p} />)({
  flex: 1,
  fontWeight: 700,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  textDecoration: 'unset!important',
  color: 'var(--color-black)'
})

const WraperImage = styled(Box)({
  flex: '0 0 auto',
  width: '100%',
  paddingBottom: '100%',
  borderRadius: '4px',
  position: 'relative',
  display: 'block',
  '& > div': {
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: alpha('#1976D2', 0.15),
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '4px',
    boxShadow: '0px 2px 14px 0px rgba(0, 0, 0, 0.15)'
  }
})

const ImageWrap = styled(Box)(({ theme }) => ({
  position: 'relative',
  paddingBottom: theme.app.imageAspectRatio,
  borderRadius: '2px',
  overflow: 'hidden',
  boxShadow: 'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px',
  '& > img': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  }
}))
