import { RootState } from '@internal/redux'
import { ISubmission } from '@shared/Types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import SubmissionService, { SubmissionMapping } from '@internal/services/SubmissionService'
import { IReduxThunkArg, IReduxThunkReturned } from './redux.types'
import { ReduxBaseTable } from '@lib/ReduxBase'
import { DefaultSlice } from './redux.slice'

export const fetchSubmissionThunk = createAsyncThunk<IReduxThunkReturned, IReduxThunkArg | undefined>(
  'fetchSubmissionThunk',
  async (param, thunkAPI) => {
    const state = thunkAPI.getState() as RootState
    const tableInfoParam = ReduxBaseTable.ThunkArgMapping({ tableInfo: state.SubmissionSlice.tableInfo, ...param })

    const filter = SubmissionMapping.tableInfoToFilter(tableInfoParam)
    const res = await SubmissionService.All(filter, thunkAPI.signal)

    const tableInfo = ReduxBaseTable.ThunkReponseMapping(tableInfoParam, { Data: res?.Data, Total: res?.Total })
    ReduxBaseTable.setParam<ISubmission>(tableInfo, DefaultSlice.initialState.tableInfo)
    return { tableInfo }
  }
)
