import { ReduxBase } from '@lib/ReduxBase'
import { ELazyStatus } from '@lib/ReduxBase'
import { createSlice } from '@reduxjs/toolkit'
import { fetchSubmissionDetailThunk } from './redux.thunks'
import { ISubmissionDetailSliceState } from './redux.types'

export const DefaultSlice = ReduxBase.GetInitialSlice<ISubmissionDetailSliceState>({ state: {} })

const SubmissionDetailSlice = createSlice({
  name: 'SubmissionDetailSlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState: DefaultSlice.initialState,
  reducers: { ...DefaultSlice.reducers },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubmissionDetailThunk.fulfilled, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.status = ELazyStatus.Loaded
        state.submission = action.payload.data ?? undefined
      })
      .addCase(fetchSubmissionDetailThunk.rejected, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.status = ELazyStatus.Error
      })
      .addCase(fetchSubmissionDetailThunk.pending, (state, action) => {
        state.requestedId = action.meta.requestId
        state.status = ELazyStatus.Loading
        state.submission = undefined
      })
  }
})

export default SubmissionDetailSlice
