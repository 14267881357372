import React, { Component, FC } from 'react'
import { CopyToClipboard } from '@lib/Component'
import { Box, Collapse, Typography } from '@mui/material'
import { CreateFormComfirm, FormModalWrapper } from '@lib/Forms'
import { EAppSiteName, EMediaDeliveryContentType, EMediaDeliveryType, IMediaDelivery } from '@shared/Types'
import { UIBtnBack } from './ui.buttons'
import { UILinkRow } from './ui.link-rows'
import { GetFormInternalType, GetFormUrlType } from './form.by-site'
import FormCreateSelectMode from './form.create.select-mode'

// ========= ========= ========= Form Create ========= ========= =========
interface ICreateProps {
  onLinkRepoSubmit?: (value: Partial<IMediaDelivery>) => Promise<void>
  onInternalSubmit?: (value: Partial<IMediaDelivery>) => Promise<void>
  mode?: EAppSiteName
}

interface ICreateState {
  selectedOption?: EMediaDeliveryContentType
}

export class FormCreate extends Component<ICreateProps, ICreateState> {
  FormUrlType
  FormInternalType
  constructor(props: ICreateProps) {
    super(props)
    this.state = { selectedOption: props.mode !== EAppSiteName.Admin ? EMediaDeliveryContentType.Url : undefined }
    this.FormUrlType = GetFormUrlType({ disabledStatus: props.mode === EAppSiteName.Client })
    this.FormInternalType = GetFormInternalType()
  }
  render() {
    const { FormUrlType, FormInternalType } = this
    return (
      <FormModalWrapper
        title='Create'
        size='md'
        slots={{
          beforeTitle: <UIBtnBack disabled={this.state.selectedOption === undefined} onClick={() => this.setState({ selectedOption: undefined })} />,
          sxTopbarProps: !this.state.selectedOption ? { boxShadow: 'none' } : {}
        }}
      >
        <Box sx={{ minHeight: '12rem' }}>
          <Collapse in={!this.state.selectedOption} unmountOnExit>
            <FormCreateSelectMode onClick={(value) => this.setState({ selectedOption: value })} mode={this.props.mode} />
          </Collapse>
          <Collapse in={this.state.selectedOption === EMediaDeliveryContentType.Url} unmountOnExit>
            <FormUrlType
              onSubmit={async (value) => {
                if (!this.props.onLinkRepoSubmit) return
                await this.props.onLinkRepoSubmit({
                  ...value,
                  Type: EMediaDeliveryType.Submission,
                  ContentType: EMediaDeliveryContentType.Url
                })
              }}
            />
          </Collapse>
          <Collapse in={this.state.selectedOption === EMediaDeliveryContentType.Internal} unmountOnExit>
            <FormInternalType
              onSubmit={async (value) => {
                if (!this.props.onInternalSubmit) return
                await this.props.onInternalSubmit({
                  ...value,
                  Type: EMediaDeliveryType.Submission,
                  ContentType: EMediaDeliveryContentType.Internal
                })
              }}
            />
          </Collapse>
        </Box>
      </FormModalWrapper>
    )
  }
}

// ========= ========= ========= Form Edit ========= ========= =========
interface IEditProps {
  data?: IMediaDelivery
  onSubmit: (value: Partial<IMediaDelivery>) => Promise<void>
  mode?: EAppSiteName
}

export class FormEdit extends Component<IEditProps> {
  FormUrlType
  FormInternalType
  constructor(props: IEditProps) {
    super(props)
    this.state = { selectedOption: undefined }
    this.FormUrlType = GetFormUrlType({ disabledStatus: props.mode === EAppSiteName.Client })
    this.FormInternalType = GetFormInternalType()
  }
  render() {
    return (
      <FormModalWrapper title='Edit' size='md'>
        {this.renderContent(this.props.data?.ContentType)}
      </FormModalWrapper>
    )
  }
  renderContent(deliveryType?: EMediaDeliveryContentType) {
    const { FormUrlType, FormInternalType } = this
    switch (deliveryType) {
      case EMediaDeliveryContentType.Url: {
        return <FormUrlType data={this.props.data} onSubmit={this.props.onSubmit} />
      }
      case EMediaDeliveryContentType.Internal: {
        return <FormInternalType data={this.props.data} onSubmit={this.props.onSubmit} />
      }
      default:
        return <></>
    }
  }
}

// ========= ========= ========= Form Delete ========= ========= =========
export const FormDelete = CreateFormComfirm<IMediaDelivery>({
  title: 'Are yout sure delete?',
  content: (x) => <Typography variant='subtitle2'>{x?.Id}</Typography>
})

// ========= ========= ========= Form PreviewUrlType  ========= ========= =========
interface IPreviewUrlTypeProps {
  data?: Partial<IMediaDelivery>
}

export const FormPreviewUrlType: FC<IPreviewUrlTypeProps> = (props) => {
  const getLinks = (): string[] => {
    try {
      return JSON.parse(props.data?.Content ?? '') ?? []
    } catch (error) {
      console.log(error)
      return []
    }
  }

  return (
    <FormModalWrapper title='Repository urls' size='sm'>
      <Box sx={{ minHeight: `${38 * 5}px`, padding: '12px 12px 0' }}>
        {getLinks().map((item, index) => (
          <UILinkRow key={index} index={index} value={item} action={<CopyToClipboard value={item} />} />
        ))}
      </Box>
    </FormModalWrapper>
  )
}
