import React, { FC } from 'react'
import { EMediaType } from '@shared/Types'
import { RouteKey } from '@internal/route'
import { Box, styled } from '@mui/material'
import { exploreClasses, UIImageIcon } from '@shared/UIStyleds'
import { CreateRoutePath } from '@lib/RouteBase'
import { Link, useLocation } from 'react-router-dom'
import { IExploreQueryParam } from '@shared/Services/QueryParamService'
import UIRollingText from '@internal/shareds/ui.rolling-text'
import { IExploreReduxProps } from './redux.types'

interface IProps extends IExploreReduxProps {
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

export const UIFilterTabSelector: FC<IProps> = (props) => {
  const location = useLocation()

  const getClassName = (value: EMediaType) => {
    const isExplore = location.pathname.includes(RouteKey.Explore)
    if (isExplore && props.slice.filter.type === value) return 'active'
    return ''
  }

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, value: EMediaType) => {
    props.onClick && props.onClick(event)
    props.updateFilter({ key: 'type', value })
  }

  const getPath = (value: EMediaType) => {
    return CreateRoutePath<IExploreQueryParam>(RouteKey.Explore, { query: { type: value } })
  }

  return (
    <Wrap className={exploreClasses.root}>
      <Item className={getClassName(EMediaType.Video)} onClick={(e) => handleClick(e, EMediaType.Video)} to={getPath(EMediaType.Video)}>
        <UIImageIcon className='tab-icon' src='icon-video' color='var(--color-text-light)' size={24} />
        <UIRollingText>Videos</UIRollingText>
      </Item>
      <Item className={getClassName(EMediaType.Audio)} onClick={(e) => handleClick(e, EMediaType.Audio)} to={getPath(EMediaType.Audio)}>
        <UIImageIcon className='tab-icon' src='icon-audio' color='var(--color-text-light)' size={24} />
        <UIRollingText>Audios</UIRollingText>
      </Item>
    </Wrap>
  )
}

export default UIFilterTabSelector

const Wrap = styled(Box)({
  display: 'flex',
  alignItems: 'center'
})

const Item = styled(Link)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  margin: '0 20px',
  gap: '5px',
  color: '#fff',
  cursor: 'pointer',
  borderBottom: '1px solid transparent',
  transition: 'all linear 0.2s',
  textDecoration: 'unset !important',
  '& .rolling-text': { paddingLeft: '30px', zIndex: 0 },
  '& .tab-icon': { marginRight: '-26px', transition: 'all 0.35s ease' },
  '&:hover .tab-icon': { transform: 'rotate(-10deg)' },
  '&:hover .rolling-text .letter, &:hover .rolling-text.play .letter': {
    transform: 'translateY(-100%)'
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    display: 'block',
    bottom: 0,
    left: '1px',
    width: 0,
    height: '2px',
    borderRadius: '1px',
    background: 'var(--bg-orange)',
    transition: '0.35s'
  },
  '&.active::after': { width: '22px' },
  '&:hover::after': { width: '100%' },
  [theme.breakpoints.down('md')]: {
    margin: '0 12px'
  }
}))
