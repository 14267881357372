import { AppDispatch, RootState } from '@internal/redux'
import { removeAllCartThunk } from '../Cart'
import { fetchCheckOutThunk } from './redux.thunks'
import { IReduxMapDispatch, IReduxMapState } from './redux.types'
import CheckOutSlice from './redux.slice'

export const mapStateToProps = (state: RootState): IReduxMapState => ({
  status: state.CheckOutSlice.status,
  slice: state.CheckOutSlice
})

export const mapDispatchToProps = (dispatch: AppDispatch): IReduxMapDispatch => ({
  fetchData: () => {
    return dispatch(fetchCheckOutThunk())
  },
  onChangeStatus: (status) => {
    dispatch(CheckOutSlice.actions.onChangeStatus(status))
  },
  removeAll: () => {
    dispatch(removeAllCartThunk())
  }
})
