import { Component } from 'react'
import { RouteKey } from '@internal/route'
import { GetUrlYoutubeThumbnail } from '@shared/Services'
import { ICart, ICartInfo, IPrice, IProduct } from '@shared/Types'
import { ChooseLicenseSkeleton } from '@internal/shareds/Skeletons'
import { Box, Button, FormControlLabel, IconButton, Radio, RadioGroup, Tooltip, Typography, alpha, styled } from '@mui/material'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { IChooseLicenseReduxProps } from './redux.types'

interface IProps extends IChooseLicenseReduxProps {
  product?: IProduct
  prices: IPrice[]
}

interface IState {
  pricePackageId?: string
}

class ChooseLicense extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = { pricePackageId: this.getPricePackageId() }
  }

  render() {
    if (!this.props.product) return <ChooseLicenseSkeleton />
    return (
      <Wrap>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', pr: '6px' }}>
          <Box sx={{ flex: 1 }}>
            <Typography variant='body2' sx={{ color: 'var(--color-text-2)' }}>
              For social media, YouTube, blogs & educational use.
            </Typography>
            <Typography variant='h6' sx={{ flex: 1 }}>
              Which license do ss I need?
            </Typography>
          </Box>
          <Tooltip title='Read more about our services' placement='bottom-end'>
            <CustomIconButton size='small' {...{ component: 'a', href: RouteKey.Pricing, target: '_blank' }}>
              <HelpOutlineIcon />
            </CustomIconButton>
          </Tooltip>
        </Box>

        <RadioGroup
          aria-labelledby='demo-controlled-radio-buttons-group'
          name='controlled-radio-buttons-group'
          value={this.state.pricePackageId?.toString() ?? null}
          onChange={this.handleChange}
          sx={{ mt: '9px' }}
        >
          {this.renderRadioItems()}
        </RadioGroup>
        <Button fullWidth variant='contained' color='success' sx={{ mt: '15px' }} onClick={this.handleSubmit} disabled={this.isDisabledSubmit()}>
          {this.getTitleButton()}
        </Button>
      </Wrap>
    )
  }

  renderRadioItems = () => {
    return this.props.prices.map((e, i) => (
      <CustomFormControlLabel
        key={i}
        value={e.Id}
        control={<Radio />}
        label={
          <>
            <Typography variant='h6' component='span' sx={{ flex: 1, fontWeight: 600 }}>
              {e.Name}
            </Typography>
            <Typography variant='h6' component='span' sx={{ fontWeight: 600 }}>
              ${e.Price}
            </Typography>
          </>
        }
      />
    ))
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    const check1 = prevProps.slice.carts.ids.length !== this.props.slice.carts.ids.length
    const check2 = prevProps.product?.Id !== this.props.product?.Id
    if (check1 || check2) {
      const id = this.getPricePackageId()
      this.setState({ pricePackageId: id })
    }
  }

  getPricePackageId = () => {
    const list = Object.values(this.props.slice.carts.entities)
    const pricePackage = list.find((e) => e?.Id === this.props.product?.Id)
    return pricePackage?.PriceOrigin?.Id
  }

  handleChange = (_: React.ChangeEvent<HTMLInputElement>, value: string) => {
    this.setState({ pricePackageId: value ?? '' })
  }

  getTitleButton = () => {
    const pricePackageId = this.getPricePackageId()
    if (!!pricePackageId) return 'Update Cart'
    return 'Add to Cart'
  }

  isDisabledSubmit = () => {
    const pricePackage = this.props.prices.find((e) => e.Id === this.state.pricePackageId)
    const pricePackageId = this.getPricePackageId()
    return !this.state.pricePackageId || pricePackage?.Id === pricePackageId
  }

  handleSubmit = () => {
    const pricePackage = this.props.prices.find((e) => e.Id === this.state.pricePackageId)
    if (!pricePackage || !this.props.product) return
    const infos: ICartInfo[] = [
      { Key: 'Type', Value: this.props.product.Type },
      { Key: 'Price', Value: `$ ${(pricePackage.Price ?? 0).toFixed(2)}` }
    ]
    const cart: ICart = {
      Id: this.props.product.Id,
      Name: this.props.product.Title ?? '',
      Description: this.props.product.Description,
      Type: this.props.product.Type as any,
      ImageUri: GetUrlYoutubeThumbnail(this.props.product.Id, 'mqdefault'),
      Infos: infos,
      PriceOrigin: pricePackage
    }
    this.props.addCart(cart)
  }
}
export default ChooseLicense

const Wrap = styled(Box)({
  top: `calc(var(--height-header) + 20px)`,
  position: 'sticky',
  background: '#fafafa',
  padding: '24px 16px',
  borderRadius: '4px',
  overflow: 'hidden',
  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px'
})

const CustomFormControlLabel = styled(FormControlLabel)({
  '& .MuiTypography-root': {
    transition: '0.1s'
  },
  '& .MuiTypography-root.MuiFormControlLabel-label': {
    display: 'flex',
    width: '100%'
  },
  '&:hover .MuiTypography-root.MuiFormControlLabel-label': {
    color: '#1976d2'
  }
})

const CustomIconButton = styled(IconButton)({
  color: alpha('#0073e6', 0.8),
  '&:hover': {
    color: '#0073e6'
  }
})
