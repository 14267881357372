import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { authService, ApplicationPaths } from 'partner-oidc-auth'
import { ILoginMenuProps, ILoginMenuState, ILogoutPath } from './types'
import { Button } from '@mui/material'

export class LoginMenu extends Component<ILoginMenuProps, ILoginMenuState> {
  constructor(props: ILoginMenuProps) {
    super(props)

    this.state = {
      isAuthenticated: false
    }
  }
  _subscription?: number
  componentDidMount() {
    this._subscription = authService.subscribe(() => this.populateState())
    this.populateState()
  }

  componentWillUnmount() {
    authService.unsubscribe(this._subscription ?? 0)
  }

  async populateState() {
    const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
    this.setState({
      isAuthenticated,
      userName: user?.name,
      avatar: user?.picture,
      displayName: user?.nickname
    })
  }

  render() {
    const { isAuthenticated, displayName } = this.state
    if (!isAuthenticated) {
      const registerPath = `${ApplicationPaths.Register}`
      const loginPath = `${ApplicationPaths.Login}`
      return this.anonymousView(registerPath, loginPath)
    } else {
      const profilePath = `${ApplicationPaths.Profile}`
      const logoutPath: ILogoutPath = {
        pathname: `${ApplicationPaths.LogOut}`,
        state: { local: true }
      }
      return this.authenticatedView(displayName ?? 'User', profilePath, logoutPath)
    }
  }

  authenticatedView(userName: string, profilePath: string, logoutPath: ILogoutPath) {
    const { AvatarButton } = this.props
    return (
      <>
        <AvatarButton urlAvatar={this.state.avatar} profilePath={profilePath} logoutPath={logoutPath}>
          {userName}
        </AvatarButton>
      </>
    )
  }

  anonymousView(registerPath: string, loginPath: string) {
    return (
      <>
        <Button style={{ padding: 0 }} component={Link} to={loginPath}>
          {/* <FontAwesomeIcon className="mr-1" icon={faSignInAlt} /> */}
          Login
        </Button>
      </>
    )
  }
}
