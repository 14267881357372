export type variable<Tmodel extends object> = keyof Tmodel
type TLogic = 'Or' | 'And'
type SortDirectionEnum = 'ASC' | 'DESC'

export interface IFilterOption {
  logic: TLogic
  ignoreEmpty: boolean
}
interface ISortOption {
  direction: SortDirectionEnum
}
interface IRequestParamOption {
  ignoreEmpty: boolean
}

type TSortStore<Tmodel extends Object> = { [key in keyof Tmodel]?: SortDirectionEnum }

type TAllOption = IFilterOption & IRequestParamOption & ISortOption

export type IRequestParamResult<TModel extends Object> = {
  sort?: TSortStore<TModel>[]
  skip: number
  take: number
  filter?: string
  noTotal?: boolean
}

export class RequestParam<Tmodel extends object = any> {
  private readonly _option?: Partial<IRequestParamOption>
  constructor(option?: Partial<IRequestParamOption>) {
    this._option = option
  }

  private mergeOption = (...p: any[]): Partial<TAllOption> => {
    return p.reduce((a, b) => {
      return Object.assign(a, b ?? {})
    }, {})
  }
  private ignoreEmpty = (field2: string, option?: Partial<IFilterOption>) => {
    const mOption = this.mergeOption(this._option, option)
    return !field2 && mOption?.ignoreEmpty === true
  }
  private _filter: string[] = []
  private _filterBase = (field1: variable<Tmodel>, operation: string, field2: string, option?: Partial<IFilterOption>) => {
    const logicStr = this._filter.length ? ' ' + (option?.logic === 'And' ? '&&' : '||') + ' ' : ''
    this._filter.push(`${logicStr}${field1 as string}${operation}${field2 as string}`)
    return this
  }
  filter = (field1: variable<Tmodel>, field2: string | number, option?: Partial<IFilterOption>) => {
    if (typeof field2 === 'string' && this.ignoreEmpty(field2, option)) return this
    return this._filterBase(field1, '==', typeof field2 === 'string' ? `"${field2}"` : field2.toString(), option)
  }
  filterNotEquals = (field1: variable<Tmodel>, field2: string | number, option?: Partial<IFilterOption>) => {
    if (typeof field2 === 'string' && this.ignoreEmpty(field2, option)) return this
    return this._filterBase(field1, '!=', typeof field2 === 'string' ? `"${field2}"` : field2.toString(), option)
  }
  filterEnum = (field1: variable<Tmodel>, field2: string | number, option?: Partial<IFilterOption>) => {
    if (typeof field2 === 'string' && this.ignoreEmpty(field2, option)) return this
    return this._filterBase(field1, '==', typeof field2 === 'string' ? field2 : field2.toString(), option)
  }
  filterStartsWith = (field1: variable<Tmodel>, field2: string, option?: Partial<IFilterOption>) => {
    if (this.ignoreEmpty(field2, option)) return this
    return this._filterBase(field1, '.startsWith("' + (field2 as string) + '")', '', option)
  }
  filterContains = (field1: variable<Tmodel>, field2: string, option?: Partial<IFilterOption>) => {
    if (this.ignoreEmpty(field2, option)) return this
    return this._filterBase(field1, '.Contains("' + (field2 as string) + '")', '', option)
  }
  filterLast = (field1: variable<Tmodel>, field2: string, option?: Partial<IFilterOption>) => {
    if (this.ignoreEmpty(field2, option)) return this
    return this._filterBase(field1, '.endsWith("' + (field2 as string) + '")', '', option)
  }
  filterNumber = (field1: variable<Tmodel>, operator: string, field2: string, option?: Partial<IFilterOption>) => {
    if (this.ignoreEmpty(field2, option)) return this
    return this._filterBase(field1, ` ${operator} ` + (field2 as string), '', option)
  }
  filterGreater = (field1: variable<Tmodel>, field2: string, option?: Partial<IFilterOption>) => {
    if (this.ignoreEmpty(field2, option)) return this
    return this._filterBase(field1, " > '" + (field2 as string) + "'", '', option)
  }
  filterCustom = (field1: string, option?: Partial<IFilterOption>) => {
    const logicStr = this._filter.length ? ' ' + (option?.logic === 'And' ? '&&' : '||') + ' ' : ''
    this._filter.push(`${logicStr}${field1 as string}`)
    return this
  }

  scope = (action: (crd: RequestParam<Tmodel>) => RequestParam<Tmodel>, option?: Partial<IFilterOption>) => {
    const crd = action(new RequestParam(this._option))
    const temp = crd._buildFilter()
    if (temp) {
      const logicStr = this._filter.length ? ' ' + (option?.logic === 'And' ? '&&' : '||') + ' ' : ''
      this._filter.push(`${logicStr}(${crd._buildFilter()})`)
    }
    return this
  }

  private _skip?: number
  skip = (skip: number) => {
    this._skip = skip
    return this
  }
  private _take?: number
  take = (take: number) => {
    this._take = take
    return this
  }

  private _sorts: TSortStore<Tmodel>[] = []
  private _sortBase = (field1: variable<Tmodel>, direction: SortDirectionEnum, option?: Partial<ISortOption>) => {
    const temp = {} as TSortStore<Tmodel>
    temp[field1] = direction
    if (!this._sorts.some((item) => Object.keys(item)[0] === Object.keys(temp)[0])) {
      this._sorts.push(temp)
    }
    return this
  }

  sort = (field1: variable<Tmodel>, option?: Partial<ISortOption>) => {
    this._sortBase(field1, option?.direction ? option.direction : 'ASC')
    return this
  }

  private _noTotal?: boolean

  noTotal = (value: boolean = true) => {
    this._noTotal = value
    return this
  }

  private _buildFilter = () => {
    return this._filter.join('')
  }

  private _buildPage = () => {
    const temp = {} as any
    if (this._skip) {
      temp.skip = this._skip
    }
    if (this._take) {
      temp.take = this._take
    }
    return temp as { skip: number; take: number }
  }

  build = (): IRequestParamResult<Tmodel> => {
    const obj: IRequestParamResult<Tmodel> = { ...this._buildPage() }
    if (this._sorts.length > 0) obj.sort = this._sorts
    const filter = this._buildFilter()
    if (filter) obj.filter = filter
    if (this._noTotal) obj.noTotal = this._noTotal
    return obj
  }
}

export const createRequestBuilder = <Tmodel extends object>(option?: Partial<IRequestParamOption>) => {
  return new RequestParam<Tmodel>(option)
}
