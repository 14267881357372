import { FC } from 'react'
import { UIImage } from '@shared/UIStyleds'
import { Typography, Box, styled } from '@mui/material'
import { IInfoItemCartConfigDTO } from './types'

export const UIWrapCartItems = styled(Box)({
  padding: '15px 20px',
  background: '#fff',
  boxShadow: 'rgba(99, 99, 99, 0.1) 0px 2px 8px 0px',
  borderRadius: '4px'
})

export const UICardItemInfo: FC<{ data?: IInfoItemCartConfigDTO[] }> = (props) => (
  <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '6px' }}>
    {(props.data ?? []).map((e, i) => (
      <WrapCardItemInfo key={i}>
        <Typography variant='subtitle2'>{e.key}:</Typography>
        <Typography variant='subtitle2' sx={{ fontWeight: 700 }}>
          {e.value}
        </Typography>
      </WrapCardItemInfo>
    ))}
    <Box width='10px' />
  </Box>
)

const WrapCardItemInfo = styled(Box)({
  display: 'flex',
  gap: '12px',
  alignItems: 'center',
  marginRight: '18px'
})

export const UINoData: FC = () => (
  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', pt: '56px' }}>
    <UIImage src='no-data-02' sx={{ height: '280px', width: '280px' }} />
  </Box>
)
