import { ITicket } from '@shared/Types'
import { ELazyStatus } from '@lib/ReduxBase'
import { createSlice } from '@reduxjs/toolkit'
import { ReduxBaseTable } from '@lib/ReduxBase'
import { ITicketSliceState } from './redux.types'
import { fetchTicketThunk } from './redux.thunks'

export const DefaultSlice = ReduxBaseTable.GetInitialSlice<ITicketSliceState, ITicket>({
  GridSortModel: [{ field: 'DateCreated', sort: 'desc' }]
})

const initialState: ITicketSliceState = DefaultSlice.initialState

const TicketSlice = createSlice({
  name: 'TicketSlice',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: { ...DefaultSlice.reducers },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTicketThunk.fulfilled, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.status = ELazyStatus.Loaded
        state.tableInfo = action.payload.tableInfo
      })
      .addCase(fetchTicketThunk.rejected, (state, action) => {
        if (state.requestedId !== action.meta.requestId) return
        state.status = ELazyStatus.Error
        state.tableInfo.isLoading = false
      })
      .addCase(fetchTicketThunk.pending, (state, action) => {
        state.requestedId = action.meta.requestId
        state.status = ELazyStatus.Loading
        state.tableInfo.isLoading = true
      })
  }
})
export default TicketSlice
