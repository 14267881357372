import { combineReducers } from 'redux'
import { AppStatusSlice } from './Slices'

import { BillingSlice } from '../Views/Billing'
import { BillingDetailSlice } from '../Views/BillingDetail'
import { CartSlice } from '../Views/Cart'
import { CheckOutSlice } from '../Views/CheckOut'
import { DeliverySlice } from '../Views/Delivery'
import { ExploreSlice } from '../Views/Explore'
import { MediaDetailSlice } from '../Views/MediaDetail'
import { NotificationSlice } from '../Views/Notification'
import { OrderSlice } from '../Views/Order'
import { OrderDetailSlice } from '../Views/OrderDetail'
import { PolicyFileSlice } from '../Views/PolicyFile'
import { ProfileSlice } from '../Views/Profile'
import { TicketSlice } from '../Views/Ticket'
import { SubmissionSlice } from '../Views/Submission'
import { SubmissionDetailSlice } from '../Views/SubmissionDetail'
import { SubmitMediaSlice } from '../Views/SubmitMedia'
import { TicketDetailSlice } from '../Views/TicketDetail'
import { YoutubeVideoSlice } from '../Views/YoutubeVideo'

const CombinedReducers = combineReducers({
  AppStatusSlice: AppStatusSlice.reducer,
  BillingSlice: BillingSlice.reducer,
  BillingDetailSlice: BillingDetailSlice.reducer,
  CartSlice: CartSlice.reducer,
  CheckOutSlice: CheckOutSlice.reducer,
  DeliverySlice: DeliverySlice.reducer,
  ExploreSlice: ExploreSlice.reducer,
  MediaDetailSlice: MediaDetailSlice.reducer,
  NotificationSlice: NotificationSlice.reducer,
  OrderSlice: OrderSlice.reducer,
  OrderDetailSlice: OrderDetailSlice.reducer,
  PolicyFileSlice: PolicyFileSlice.reducer,
  ProfileSlice: ProfileSlice.reducer,
  SubmissionSlice: SubmissionSlice.reducer,
  SubmissionDetailSlice: SubmissionDetailSlice.reducer,
  SubmitMediaSlice: SubmitMediaSlice.reducer,
  TicketSlice: TicketSlice.reducer,
  TicketDetailSlice: TicketDetailSlice.reducer,
  YoutubeVideoSlice: YoutubeVideoSlice.reducer
})

export default CombinedReducers
