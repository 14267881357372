import React, { Component } from 'react'
import { connect } from 'react-redux'
import { RouteKey } from '@internal/route'
import { ISubmission } from '@shared/Types'
import { CreateHocLazy } from '@lib/ReduxBase'
import { CreateRoutePath } from '@lib/RouteBase'
import { SubmissionButtons } from '@shared/Pages/Submission'
import { WindowScrollToTop, TableFormater } from '@lib/Helpers'
import { ISubmissionDetailQueryParam } from '@shared/Services/QueryParamService'
import { ActionPannel, CreateTableTemplate, CRUDPannel, MapOprators } from '@lib/Table'
import UIStyleds from '@shared/UIStyleds'
import { ISubmissionReduxProps } from './redux.types'
import { mapDispatchToProps, mapStateToProps } from './redux.map'

const Table = CreateTableTemplate<ISubmission>('Redux', {
  getRowId: (x) => x.Id,
  config: {
    Title: { type: 'string', flex: 1, minWidth: 120 },
    RepositoryUrl: { type: 'string', flex: 2, minWidth: 200 },
    KindlyDescribe: { type: 'string', flex: 1, minWidth: 130 },
    Email: { type: 'string', flex: 1, minWidth: 150 },
    FirstName: { type: 'string', flex: 1, minWidth: 100 },
    LastName: { type: 'string', flex: 1, minWidth: 100 },
    PhoneNumber: { type: 'string', flex: 1, minWidth: 150 },
    Birthday: { type: 'string', minWidth: 175, filterable: false, valueFormatter: (params) => TableFormater.formatDate(params.value, 'DD/MM/YYYY') },
    DateCreated: { type: 'string', minWidth: 175, filterable: false, renderCell: (params) => TableFormater.tooltipDate(params.value) }
  },
  filterOperators: MapOprators, //server mode,
  GridColumnVisibilityModel: {
    Birthday: false,
    FirstName: false,
    LastName: false
  },
  minWidthColumnActions: 75
})

class ViewBase extends Component<ISubmissionReduxProps> {
  componentDidMount() {
    WindowScrollToTop()
  }
  render() {
    return (
      <UIStyleds.WrapProfile>
        <Table
          ReduxOption={this.props.tableInfo}
          onChange={this.props.onChangeTableInfo}
          CRUDPannel={() => <CRUDPannel Title='Submissions' />}
          ActionPannel={(p) => {
            const data = p.data as ISubmission
            const path = CreateRoutePath<ISubmissionDetailQueryParam>([RouteKey.Profile, RouteKey.Submission], {
              query: { submissionId: data.Id.toString() },
              modal: RouteKey.SubmissionDetail
            })
            return <ActionPannel Buttons={{ Edit: <SubmissionButtons.Detail to={path} /> }} />
          }}
        />
      </UIStyleds.WrapProfile>
    )
  }
}

export { fetchSubmissionThunk } from './redux.thunks'
export { default as SubmissionSlice } from './redux.slice'

const HocLazyInstance = CreateHocLazy(ViewBase)
export const SubmissionMapRedux = connect(mapStateToProps, mapDispatchToProps)(HocLazyInstance)
