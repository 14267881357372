import React, { FC } from 'react'
import { Box, SxProps, Theme } from '@mui/material'

interface IProps {
  resourceId: string
  className?: string
  alt?: string
  sx?: SxProps<Theme>
  size?: 'default' | 'mqdefault' | 'hqdefault' | 'sddefault' | 'maxresdefault'
}

const UIYoutubeThumbnail: FC<IProps> = (props) => {
  const size = props.size || 'default'
  const src = `https://i.ytimg.com/vi/${props.resourceId}/${size}.jpg`
  return <Box src={src} component='img' className={props.className} sx={props.sx} alt={props.alt || 'thumbnail'} />
}

export default UIYoutubeThumbnail
