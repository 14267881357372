import { AppDispatch, RootState } from '@internal/redux'
import { YoutubeVideoQueryParam } from '@shared/Services/QueryParamService'
import { fetchYoutubeVideoThunk } from './redux.thunks'
import { IReduxMapDispatch, IReduxMapState } from './redux.types'
import YoutubeVideoService, { YoutubeVideoMapping } from '@internal/services/YoutubeVideoService'
import YoutubeVideoSlice from './redux.slice'

export const mapStateToProps = (state: RootState): IReduxMapState => ({
  status: state.YoutubeVideoSlice.status,
  Slice: state.YoutubeVideoSlice,
  tableInfo: state.YoutubeVideoSlice.tableInfo
})

type TRequest = { abort: () => void }
const FetchAccessory: { request?: TRequest } = {}
export const mapDispatchToProps = (dispatch: AppDispatch): IReduxMapDispatch => ({
  fetchData: () => {
    return dispatch(fetchYoutubeVideoThunk({}))
  },
  onChangeStatus: (status) => {
    dispatch(YoutubeVideoSlice.actions.onChangeStatus(status))
  },
  onChangeTableInfo: (key, value, details) => {
    if (key === 'FilterModel') {
      dispatch(YoutubeVideoSlice.actions.onChangeTableInfo({ key, value, details }))
    }
    FetchAccessory.request?.abort()
    FetchAccessory.request = dispatch(fetchYoutubeVideoThunk({ tableInfoChange: { key, value: value, details } }))
  },
  CRUD: {
    Update: async (Id, model) => {
      await YoutubeVideoService.Update(Id, YoutubeVideoMapping.update(model))
      dispatch(fetchYoutubeVideoThunk({}))
    },
    Delete: async (Id) => {
      try {
        const data = await YoutubeVideoService.Remove(Id)
        if (data) {
          dispatch(fetchYoutubeVideoThunk({}))
        }
      } catch (error) {
        console.log(error)
      }
    }
  },
  updateDelivery: async (key, value) => {
    if (!value) return
    dispatch(YoutubeVideoSlice.actions.updateDeliveryByKey({ key, value }))
  },
  submitUpload: async (value) => {
    console.log(value)
    const qParam = YoutubeVideoQueryParam.Get()
    if (!qParam.deliveryId) return
    await YoutubeVideoService.DeliveryCreate({
      DeliveryId: qParam.deliveryId,
      Tags: value.Categories ?? '',
      ExcelFile: value.ExcelFile as any
    })
  }
})
