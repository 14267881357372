import React, { Component } from 'react'
import { Box, Container, Divider, Grid, Stack, styled } from '@mui/material'

interface IProps {
  isLoading?: boolean
  player?: JSX.Element
  license?: JSX.Element
  category?: JSX.Element
  recommend?: JSX.Element
}

export default class Layout extends Component<IProps> {
  render() {
    return (
      <Container maxWidth='xl'>
        <Box height='20px' />
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            {this.props.player}
          </Grid>
          <Grid item xs={12} md={4}>
            {this.props.license}
          </Grid>
          <Grid item xs={12}>
            <Stack sx={{ gap: '10px', padding: '12px 0px' }}>
              <Title>Recommend</Title>
              <Divider role='presentation' sx={{ width: '100%' }} />
            </Stack>
            {this.props.category}
            <Box height='12px' />
            {this.props.recommend}
          </Grid>
        </Grid>
        <Box height='128px' />
      </Container>
    )
  }
}

const Title = styled('h4')({
  fontWeight: 700,
  fontSize: '20px'
})
