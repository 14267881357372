import { GridCallbackDetails } from '@mui/x-data-grid'
import { CaseReducer, PayloadAction } from '@reduxjs/toolkit'
import { ELazyStatus } from './types'
import { DecodeBase64, DeepMerge, EncodeBase64, MergeObjects, QueryParam, TryParseJson } from '../Helpers'
import { IFetchPagination, IPagingModel, ITableTemplateState, TFetchChange, TOnTableTemplateChange } from '../Table'
import ReduxBase, { IDispatchBase, IStateBase } from './redux-base'

namespace ReduxBaseTable {
  const initialPagination: Pick<IPagingModel<any>, 'Page' | 'Amount'> = { Page: 0, Amount: 25 }

  interface ITableInfoChangeParam<T> {
    key: keyof ITableTemplateState<T>
    value: any
    details: GridCallbackDetails
  }
  // ========= ========= ========= redux.types.ts ========= ========= =========
  export interface ISliceState<T> extends IStateBase {
    tableInfo: ITableTemplateState<T>
  }

  export interface IMapState<T> extends IStateBase {
    tableInfo: ITableTemplateState<T>
  }

  export interface IMapDispatch<T, TFetchParam = any> extends IDispatchBase<TFetchParam> {
    onChangeTableInfo: TOnTableTemplateChange<T, TFetchChange<T>>
  }

  export interface IThunkArg<T> {
    tableInfoQueryParam?: ITableTemplateState<T>
    tableInfoChange?: ITableInfoChangeParam<T>
  }

  export interface IThunkReturned<T> {
    tableInfo: ITableTemplateState<T>
  }

  // ========= ========= ========= redux.thunk.ts ========= ========= =========
  interface IThunkArgMappingParam<T> {
    tableInfo: ITableTemplateState<T>
    tableInfoChange?: ITableInfoChangeParam<T>
    tableInfoQueryParam?: ITableTemplateState<T>
  }
  export const ThunkArgMapping = <T>(param: IThunkArgMappingParam<T>): ITableTemplateState<T> => {
    const obj: ITableTemplateState<T> = Object.assign({}, param.tableInfo)
    if (!!param.tableInfoChange) {
      const { key, value, details } = param.tableInfoChange
      obj[key] = value
      obj.details = details
      if (key !== 'PageInfo' && obj.PageInfo.page !== initialPagination.Page) {
        obj.PageInfo = { ...obj.PageInfo, page: initialPagination.Page }
      }
    }
    return param.tableInfoQueryParam ? MergeObjects(obj, param.tableInfoQueryParam) : obj
  }

  export const ThunkReponseMapping = <T>(state: ITableTemplateState<T>, tableData: Partial<IPagingModel<T>>) => {
    const { Data = [], Page = initialPagination.Page, Amount = initialPagination.Amount, Total = 0 } = tableData

    const temp: Partial<ITableTemplateState<T>> = {
      isLoading: false,
      PageInfo: { data: Data, page: state.PageInfo.page ?? Page, pageSize: Amount, rowTotal: Total }
    }

    const obj: ITableTemplateState<T> = Object.assign({}, state, temp)
    return obj
  }

  // ========= ========= ========= redux.slice.ts ========= ========= =========

  interface IReducers<S extends IStateBase, T> extends ReduxBase.IReducers<S> {
    onChangeTableInfo: CaseReducer<S, PayloadAction<ITableInfoChangeParam<T>>>
  }

  interface InitialSlice<S extends ISliceState<T>, T> {
    initialState: S
    reducers: IReducers<S, T>
  }

  type TInitialSliceParam<TState extends ISliceState<T>, T> = Omit<TState, keyof ISliceState<T>>

  export const GetInitialSlice = <TState extends ISliceState<T>, T>(
    state?: Partial<ITableTemplateState<T>>,
    param?: TInitialSliceParam<TState, T>
  ): InitialSlice<TState, T> => {
    const { Page, Amount } = initialPagination
    const tableInfo = DeepMerge<ITableTemplateState<T>>({ isLoading: true, PageInfo: { data: [], page: Page, pageSize: Amount } }, state)
    return {
      initialState: { status: ELazyStatus.Loading, tableInfo, ...param } as TState,
      reducers: {
        onChangeStatus: (state, action) => {
          state.status = action.payload
        },
        onChangeTableInfo: (state, action) => {
          state.tableInfo[action.payload.key] = action.payload.value
          state.tableInfo.details = action.payload.details
        }
      }
    }
  }
  // ========= ========= ========= Query Param ========= ========= =========
  interface ITableQueryParam {
    table?: string
  }

  const extractData = <TModel>(tableInfo: Partial<ITableTemplateState<TModel>>): Partial<ITableTemplateState<TModel>> => {
    const { FilterModel, GridSortModel, PageInfo } = tableInfo
    const obj: Partial<ITableTemplateState<TModel>> = {
      GridSortModel,
      PageInfo: { pageSize: PageInfo?.pageSize ?? 0, page: PageInfo?.page ?? 0 } as IFetchPagination<TModel>
    }
    const filters = FilterModel?.items.filter((x) => !!x.value) ?? []
    const quickSearch = FilterModel?.quickFilterValues ?? []
    if (filters.length > 0 || quickSearch.length > 0) {
      obj.FilterModel = { ...FilterModel, items: filters }
    } else {
      delete obj.FilterModel
    }
    return obj
  }

  const equals = <TModel>(tableInfo: Partial<ITableTemplateState<TModel>>, tableInfoOld: Partial<ITableTemplateState<TModel>>) => {
    return JSON.stringify(extractData(tableInfo)) === JSON.stringify(extractData(tableInfoOld))
  }

  export const setParam = <TModel>(tableInfo: Partial<ITableTemplateState<TModel>>, defaultValue: ITableTemplateState<TModel>) => {
    try {
      if (!equals(tableInfo, defaultValue)) {
        const data = extractData(tableInfo)
        const tqp: ITableQueryParam = { table: EncodeBase64(JSON.stringify(data)) }
        QueryParam.Patch(tqp)
      } else {
        QueryParam.Deletes<ITableQueryParam>('table')
      }
    } catch (error) {
      console.log(error)
    }
  }

  export const getParam = <TModel>(defaultValue: ITableTemplateState<TModel>): ITableTemplateState<TModel> => {
    const temp = QueryParam.GetAll() as ITableQueryParam
    if (!temp?.table) return defaultValue
    const data = TryParseJson<ITableTemplateState<TModel>>(DecodeBase64(temp.table ?? ''))
    if (Object.keys(data).length < 1) return defaultValue
    return data as ITableTemplateState<TModel>
  }
}
export default ReduxBaseTable
